
import Vue from 'vue';
import IrisAvatar from './IrisAvatar.vue';
import { generateUniqueId } from '@/utils';
import { deprecationMixin } from './../mixins/deprecationMixin';

interface Item {
    label: string;
    avatarProps: object;
}

/**
 * The Floating Action Button provides a "call-to-action" button or list of buttons.
 */

export default Vue.extend({
    name: 'IrisFloatingActionButton',

    components: {
        IrisAvatar,
    },

    mixins: [deprecationMixin],

    props: {
        /**
         * Sets the id (HTML global attribute) for the component. If an id is not provided, one will be generated automatically.
         */
        elementId: String,
        /**
         * Aria label to describe the list of items.
         */
        ariaLabel: {
            type: String,
            required: true,
        },
        /**
         * Array of objects describing each item in the menu list.
         * <br /><br />
         * label: required - label to describe the item, is used as an aria-label for the item when needed
         * <br /><br />
         * avatarProps: required - each item uses an avatar, refer to that component's props for this object
         */
        items: {
            type: Array,
            required: true,
            validator: (items: Item[]) => {
                return items.every((item) => {
                    const thisItem = item as Item;
                    const props = [
                        Object.keys(thisItem).includes('label'),
                        Object.keys(thisItem).includes('avatarProps'),
                    ];

                    return !props.includes(false);
                });
            },
        },
        /**
         * Display and click behavior of button:
         * <br /><br />
         * condensed: displays as icon, expands to show labels and items <br />
         * extended: displays as icon and text, expands to show items <br />
         * nolabels: displays as icon, expands to show items as icons
         * <br /><br />
         * mulitple items: first click opens menu and second click is for the chosen list button
         * <br /><br />
         * single item:
         * <ul>
         * <li>extended and nolabels: first click is for the button</li>
         * <li>condensed: first click expands to show label and select click is for the list button</li>
         * </ul>
         */
        kind: {
            type: String,
            default: 'condensed',
            validator: (value: string) => {
                return ['condensed', 'extended', 'nolabels'].includes(value.toLowerCase());
            },
        },
        /**
         * Position of button on the page.
         * <br /><br />
         * static: stays in place dictated by HTML <br />
         * floating: always stays fixed to the lower right of the page <br />
         * dynamic: static for large displays, floating for smaller displays
         */
        position: {
            type: String,
            default: 'dynamic',
            validator: (value: string) => {
                return ['static', 'floating', 'dynamic'].includes(value.toLowerCase());
            },
        },
        /**
         * Sets the border radius, but only used as an override since default border radius value should be set by the theme. Expects a css value for border radius. Can be between 1-4 values for each corner of the shape. Examples '44px', '10% 30% 50% 70%'
         */
        borderRadiusOverride: {
            type: String,
        },
    },

    data() {
        return {
            identifier_: this.elementId || generateUniqueId('irisv_floating_action_button') as string,
            currentItem: 0 as number,
            isOpen: false as boolean,
            mql: '' as any,
        };
    },

    methods: {
        documentClick(event: Event) {
            const target = event.target as HTMLElement;

            if (!(this.$el.contains(target))) {
                this.toggleMenu(false, false, true);
            }
        },
        fastCloseMenu() {
            // force the menu to close immediately without animation
            // this is intended to close the menu in cases of window resize and future needs
            // just in case a shift in size may cause visual issues
            const trigger = this.$refs.trigger as HTMLButtonElement;
            const firstItem = this.$refs.firstItem as HTMLElement;
            const moreItems = this.$refs.moreItems as any[];
            const itemsContainer = this.$refs.itemsContainer as HTMLElement;

            if (this.isOpen) {
                trigger.style.display = 'block';

                this.isOpen = false;
                this.currentItem = 0;

                if (moreItems) {
                    // all secondary items go to 0 immediately
                    moreItems.forEach((item) => {
                        item.style.transition = 'none';
                        item.style.opacity = 0;
                        item.style.height = '0';
                        item.setAttribute('tabindex', '-1');
                    });
                }

                firstItem.setAttribute('tabindex', '-1');
                itemsContainer.style.transition = 'none';
                itemsContainer.style.width = this.kind === 'extended' ? `${itemsContainer.dataset.collapsedWidth}px` : `${itemsContainer.dataset.iconWidth}px`;

                this.$emit('floating-action-button-open', false);
            }
        },
        isExpandable() {
            return this.items ? this.items.length > 1 || this.items.length === 1 && this.kind === 'condensed' : false;
        },
        itemClick(event: any) {
            if (this.isExpandable()) {
                // normal behavior if more than one item or condensed
                // first click expands/opens menu and second click emits event
                if (!this.isOpen) {
                    this.toggleMenu(true, true);
                } else {
                    this.toggleMenu(false, true);
                    this.$emit('floating-action-button-click', {index: parseInt(event.target.dataset.index, 10), label: event.target.dataset.label});
                }
            } else {
                // there is only item and no reason to expand/open the menu
                // just emit immediately
                this.$emit('floating-action-button-click', {index: parseInt(event.target.dataset.index, 10), label: event.target.dataset.label});
            }
        },
        setItemFocus(dir: string) {
            // setting focus based on up/down arrow keys
            const itemsContainer = this.$refs.itemsContainer as HTMLElement;
            const items = [this.$refs.firstItem, ...(this.$refs.moreItems as any[])];
            const previousItem = items[this.currentItem] as HTMLElement;

            this.currentItem = dir === 'down' ? this.currentItem + 1 : this.currentItem - 1;
            this.currentItem = this.currentItem > items.length - 1 ? 0 : this.currentItem;
            this.currentItem = this.currentItem < 0 ? items.length - 1 : this.currentItem;

            (items[this.currentItem] as HTMLElement).setAttribute('tabindex', '0');
            (items[this.currentItem] as HTMLElement).focus();

            previousItem.setAttribute('tabindex', '-1');

            itemsContainer.setAttribute('aria-activedescendant', (items[this.currentItem] as HTMLElement).id);
        },
        setMenuSizes() {
            // called on mounted and whenever a size reset is required
            const fab = this.$refs.fab as HTMLElement;
            const firstItem = this.$refs.firstItem as HTMLElement;
            const firstItemIcon = (this.$refs.firstItemIcon as any).$el as HTMLElement;
            const itemsContainer = this.$refs.itemsContainer as HTMLElement;
            const moreItems = this.$refs.moreItems as any[];

            // reset this so calculations will be correct
            itemsContainer.style.width = 'initial';
            itemsContainer.style.right = '';

            // make sure initial sizes are set before changing everything
            Vue.nextTick(() => {
                // most sizes are based on the first item with the assumption that all items are the same size
                // five sizes are stored in data attributes on the root element:
                // iconWidth: width of the first item icon
                // iconHeight: height of the first item icon (overall height of an item)
                // collapsedWidth: width of the first item in case it's smaller than another item
                // expandedWidth: width of the container at its widest to expand outward

                itemsContainer.dataset.iconWidth = firstItemIcon.offsetWidth.toString();
                itemsContainer.dataset.iconHeight = firstItemIcon.offsetHeight.toString();
                itemsContainer.dataset.expandedWidth = firstItem.offsetWidth.toString();

                if (moreItems) {
                    moreItems.forEach((item) => {
                        // items are animated to icon height in "toggleMenu" method
                        item.style.height = '0';
                        // items need to be gone to get the "collapsedWidth" of the first item
                        item.style.display = 'none';
                    });
                }

                // determine and store the natural width of the first item
                // this is used for initial width of the component
                itemsContainer.dataset.collapsedWidth = firstItem.offsetWidth.toString();
                // if extended use "collapsedWidth" which shows the label, otherwise collapsed down to width of the icon
                itemsContainer.style.width = this.kind === 'extended' ? `${itemsContainer.dataset.collapsedWidth}px` : `${itemsContainer.dataset.iconWidth}px`;

                if (moreItems) {
                    moreItems.forEach((item) => {
                        // restore actual display of items
                        item.style.display = 'flex';
                    });
                }

                // these two settings are mostly for static placement of the component to make room for the component
                // these are set but don't change in future animations
                // set initial height of collapsed menu
                fab.style.height = `${itemsContainer.dataset.iconHeight}px`;
                // set initial width of collapsed menu
                fab.style.width = this.kind === 'extended' ? `${itemsContainer.dataset.collapsedWidth}px` : `${itemsContainer.dataset.iconWidth}px`;

                // if the component is more to the right of window center
                // that way the menu will expand to the left
                if (fab.getBoundingClientRect().left > window.innerWidth / 2) {
                    itemsContainer.style.right = '0';
                } else {
                    itemsContainer.style.right = '';
                }
            });
        },
        toggleMenu(open: boolean, click: boolean = false, doc: boolean = false) {
            const trigger = this.$refs.trigger as HTMLButtonElement;
            const firstItem = this.$refs.firstItem as HTMLElement;
            const moreItems = this.$refs.moreItems as any[];
            const moreItemsLength = moreItems ? moreItems.length : 0;
            const itemsContainer = this.$refs.itemsContainer as HTMLElement;
            const fixed = window.getComputedStyle(this.$refs.fab as HTMLElement).getPropertyValue('position') === 'fixed';

            // in cases of a single action that doesn't require an expanding/collpasing menu
            // none of this should happen so we check against that
            if (this.isExpandable()) {
                if (open) {
                    trigger.style.display = 'none';

                    this.isOpen = true;
                    this.currentItem = 0;

                    // assume if menu opens we'll focus on the first item
                    // try to see if the menu was opened with a click
                    // otherwise the first item is in a focus state and hover colors don't work
                    firstItem.setAttribute('tabindex', '0');

                    if (!click) {
                        firstItem.focus();
                        itemsContainer.setAttribute('aria-activedescendant', firstItem.id);
                    }

                    if (moreItems) {
                        // all secondary items animate their height to appear
                        moreItems.forEach((item, index) => {
                            item.style.order = fixed ? moreItemsLength - index : index;
                            item.style.transition = `height 100ms ${100 + (50 * index)}ms`;
                            item.style.opacity = 1;
                            item.style.height = `${itemsContainer.dataset.iconHeight}px`;
                        });
                    }

                    // items container animates the width
                    itemsContainer.style.transition = 'width 100ms';
                    itemsContainer.style.width = this.kind !== 'nolabels' ? `${itemsContainer.dataset.expandedWidth}px` : `${itemsContainer.dataset.iconWidth}px`;

                    this.$emit('floating-action-button-open', true);
                } else {
                    trigger.style.display = 'block';

                    this.isOpen = false;
                    this.currentItem = 0;

                    if (moreItems) {
                        // all secondary items animate height to 0
                        moreItems.forEach((item, index) => {
                            item.style.transition = `100ms ${50 * (moreItemsLength - index)}ms`;
                            item.style.opacity = 0;
                            item.style.height = '0';
                            item.setAttribute('tabindex', '-1');
                        });
                    }

                    firstItem.setAttribute('tabindex', '-1');

                    // items container delays collapsing animation based on number of secondary items
                    // this way it appears to wait until secondary items are hidden before collapsing on first item
                    itemsContainer.style.transition = `width 100ms ${50 * moreItemsLength}ms`;
                    itemsContainer.style.width = this.kind === 'extended' ? `${itemsContainer.dataset.collapsedWidth}px` : `${itemsContainer.dataset.iconWidth}px`;

                    if (!click) {
                        itemsContainer.removeAttribute('aria-activedescendant');
                    }

                    if (!doc) {
                        // if close is from document click we don't want to steal focus
                        trigger.focus();
                    }

                    this.$emit('floating-action-button-open', false);
                }
            } else {
                // if a single action with no expanding/collapsing menu
                // just pass along the click to the first item
                firstItem.click();
            }
        },
        checkFabMediaQuery() {
            // this exists to check for the fab switching from floating to static as dynamic kind
            // need to reset the right property of the items container so it opens in correct direction
            // this is tied to the dynamic kind so it should never fire with the other positions
            const itemsContainer = this.$refs.itemsContainer as HTMLElement;
            itemsContainer.style.right = '';
        },
    },

    computed: {
        firstItem(): Item | boolean {
            const firstItem = this.items ?
                [...(this.items as any[])].shift() as Item :
                {   label: '',
                    avatarProps: {},
                };

            return firstItem;
        },
        moreItems(): Item[] {
            const moreItems = this.items ?
                [...(this.items as any[])].splice(1) as Item[] :
                [{   label: '',
                    avatarProps: {},
                }];

            return moreItems;
        },
    },

    watch: {
        kind() {
            this.setMenuSizes();
        },
        isOpen(open) {
            if (open) {
                document.addEventListener('click', this.documentClick);
            } else {
                document.removeEventListener('click', this.documentClick);
            }
        },
        items() {
            Vue.nextTick(() => {
                this.fastCloseMenu();
            });
        },
    },

    mounted() {
        const fab = this.$refs.fab as HTMLElement;
        const firstItem = this.$refs.firstItem as HTMLElement;
        const moreItems = this.$refs.moreItems as any[];

        if (this.items) {
            firstItem.id = generateUniqueId('item');

            if (moreItems) {
                moreItems.forEach((item) => {
                    item.id = generateUniqueId('item');
                });
            }

            window.addEventListener('resize', this.fastCloseMenu);

            this.setMenuSizes();

            fab.addEventListener('animationstart', this.checkFabMediaQuery);
        }
    },

    beforeDestroy() {
        const fab = this.$refs.fab as HTMLElement;

        window.removeEventListener('resize', this.fastCloseMenu);
        document.removeEventListener('click', this.documentClick);
        fab.removeEventListener('animationstart', this.checkFabMediaQuery);
    },
});
