var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"mobileContainer",class:[ _vm.isOpen ? 'irisv-mobile-dropdown--open' : '', ],attrs:{"data-cy":_vm.isOpen && _vm.testHooks.container}},[_c('div',{staticClass:"irisv-mobile-dropdown__scrim",attrs:{"data-cy":_vm.testHooks.scrim},on:{"click":function($event){return _vm.$emit('list-request-to-close')}}}),_c('transition',{attrs:{"name":"irisv-mobile-dropdown__transition"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],ref:"menu",class:[
                'irisv-mobile-dropdown__menu',
                _vm.showListDividers ? 'irisv-mobile-dropdown__menu--with-dividers' : '',
                _vm.hasFilter ? 'irisv-mobile-dropdown__menu--with-filter' : '',
            ]},[_c('div',{ref:"topContainer",staticClass:"irisv-mobile-dropdown__menu-top-container"},[_c('div',{staticClass:"irisv-mobile-dropdown__menu-grabber",attrs:{"data-cy":_vm.testHooks.grabber},on:{"click":function($event){return _vm.$emit('list-request-to-close')},"touchstart":function($event){$event.stopPropagation();return _vm.touchStart.apply(null, arguments)},"touchmove":function($event){$event.stopPropagation();return _vm.touchMove.apply(null, arguments)},"touchend":function($event){$event.stopPropagation();return _vm.touchEnd.apply(null, arguments)}}}),(_vm.showMobileListTitle && _vm.mobileListTitleText !== '')?_c('div',{class:[
                        'irisv-mobile-dropdown__menu-label',
                        'font-small-heading',
                        'pad-x--small',
                        {'mar-bottom--small' : !_vm.hasFilter},
                    ],attrs:{"data-cy":_vm.testHooks.listTitle}},[_vm._v(" "+_vm._s(_vm.mobileListTitleText)+" ")]):_vm._e(),_vm._t("filterSlot")],2),_vm._t("listSlot"),_c('iris-dropdown-bottom-slot',{ref:"bottomSlot",on:{"bottom-slot-keydown":function($event){return _vm.$emit('bottom-slot-keydown', $event)},"bottom-slot-keyup":function($event){return _vm.$emit('bottom-slot-keyup', $event)}}},[_vm._t("bottomSlot")],2)],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }