
import Vue from 'vue';
import IrisIcon from './IrisIcon.vue';
import { deprecationMixin } from './../mixins/deprecationMixin';

enum BrandedShapeName {
    sharp = 'sharp',
    soft = 'soft',
    squircle = 'squircle',
    rounded = 'rounded',
}

enum IrisAvatarSize {
    xsmall = 'xsmall',
    small = 'small',
    medium = 'medium',
    large = 'large',
    xlarge = 'xlarge',
}

const ShapeBrandedSharp = '0 0 0 0';
const ShapeBrandedSoft = '8px 8px 8px 8px';
const ShapeBrandedSquircle = '16px 16px 16px 16px';
const ShapeRoundedSmall = '24px 24px 24px 24px';

/**
 * Avatars are used to show a graphic representation of a person, a business, or any icon.
 * @default ariaLabel
 */
export default Vue.extend({
    name: 'IrisAvatar',
    components: {
        IrisIcon,
    },
    mixins: [deprecationMixin],
    props: {
        /**
         * Sets the behavior of the avatar and the lower right icon (if displayed to be static or clickable.
         */
        isStatic: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets the size of the avatar component. Valid values are 'xsmall', 'small', 'medium', 'large', and 'xlarge'.
         */
        size: {
            type: String,
            default: 'medium',
            validator: (value: string) => {
                return ['xsmall', 'small', 'medium', 'large', 'xlarge'].includes(value.toLowerCase());
            },
        },
        /**
         * Sets the border radius, but only used as an override since default border radius value should be set by the theme. Expects a css value for border radius. Can be between 1-4 values for each corner of the shape. Examples '44px', '10% 30% 50% 70%'
         */
        borderRadiusOverride: {
            type: String,
        },
        /**
         * Sets the background color of the avatar to a custom value. Hex colors or standard color names are
         * acceptable. The default is Branded Guidance.
         */
        backgroundColor: {
            type: String,
        },
        /**
         * If an image or icon is not displayed in the component, up to 2 characters may be displayed. Characters will
         * be converted to upper case automatically.
         */
        fallbackLetter: {
            type: String,
            default: '',
        },
        /**
         * Sets the color of the fallback character(s) or main icon to a custom value. Hex colors or standard color
         * names are acceptable. The default is Branded Guidance On-color.
         */
        letterIconColor: String,
        /**
         * Sets the aria-label for the component. Please include any needed punctuation in the value.
         */
        ariaLabel: {
            type: String,
            default: '',
        },
        /**
         * Sets the avatar to have an aria-hidden of true in order to hide it from screen readers. When used, the 'isStatic' prop will be locked to true as well. This prop should only be used when the avatar is purely presentational and there is other text present to decribe the avatar's intent.
         */
        isAriaHidden: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets the icon to use in the avatar component. If the 'image' attribute has a value, the icon will not be
         * visible.
         */
        mainIconName: {
            type: String,
            default: '',
        },
        /**
         * Sets the image to use as the background of the avatar component.
         */
        image: {
            type: String,
            default: '',
        },
        /**
         * Display an indicator in the top right corner of the component to get the user's attention.
         */
        showIndicator: {
            type: Boolean,
            default: false,
        },
        /**
         * Displays a border around the component to convey a status to the user. If the 'showProgressBar' boolean
         * attribute is also used, the status line will not be visible.
         */
        showStatusLine: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets the color of the border around the component to a custom value. Hex colors or standard color names are
         * acceptable. The default is #B3B3B3 (Platform Gray 300).
         */
        statusLineColor: {
            type: String,
            default: '#B3B3B3',
        },
        /**
         * Sets the aria-label to describe the border around the component. Please include any needed punctuation in
         * the value.
         */
        statusLineAriaLabel: {
            type: String,
            default: '',
        },
        /**
         * Displays a progress bar around the component to convey completed progress of a task to the user.
         */
        showProgressBar: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets the integer value of the progress bar around the component. The value should be treated as a
         * percentage of completion. Do not include the '%' in the value of this property.
         */
        progressBarValue: {
            type: [Number, String],
            default: 0,
            validator: (value: number | string) => {
                return (value >= 0 && value <= 100);
            },
        },
        /**
         * Sets the color of the progress bar around the component to a custom value. Hex colors or standard color
         * names are acceptable. The default is #088856 (Platform Success).
         */
        progressBarColor: {
            type: String,
            default: '#088856',
        },
        /**
         * Sets the icon to use in the lower right area. Note: This icon is only visible when the 'size' is 'xlarge'.
         */
        subIconName: {
            type: String,
            default: 'remote-deposit',
        },
        /**
         * Sets the aria-label for the icon in the lower right area. Please include any needed punctuation in the value.
         */
        subIconAriaLabel: String,
        /**
         * Shows the skeleton loader as a placeholder option. This may be useful when page content
         * is loading in the background.
         */
        showSkeleton: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            pathLength: 0,
            borderRadius: BrandedShapeName.rounded,
        };
    },
    computed: {
        compSVGVars(): string {
            let sizeToUse: string = '';

            sizeToUse = this._sizeToUse(this.size);

            return `width: ${sizeToUse}; height: ${sizeToUse};`;
        },
        compProgressRingFillVars(): string {
            let colorToUse: string = '';
            let progressIsValid: boolean = false;
            let dashOffsetToUse: number;

            if (this.progressBarColor !== '') {
                colorToUse = this.progressBarColor;
            }

            progressIsValid = this._validateProgressValue(this.progressBarValue);

            if (progressIsValid) {
                dashOffsetToUse = this.$data.pathLength * ((100 - Number(this.progressBarValue)) * .01);
            } else {
                dashOffsetToUse = this.$data.pathLength;
            }

            return `stroke: ${colorToUse}; stroke-dasharray: ${this.$data.pathLength}; stroke-dashoffset: ${dashOffsetToUse}`;
        },
        compProgressPath(): string {
            const pathMap: {[index: string]: any} = {
                rounded: 'M52.05, 2.17a50, 50, 0, 1, 1-50, 50, 50, 50, 0, 0, 1, 50-50',
                sharp: 'M52 2 L102 2 L102 102 L2 102 L2 2 Z',
                soft: 'M52.05, 2.17h40a10, 10, 0, 0, 1, 10, 10v80a10, 10, 0, 0, 1-10, 10h-80a10, 10, 0, 0, 1-10-10v-80a10, 10, 0, 0, 1, 10-10h40',
                squircle: 'M52, 2.57c15.92, 0, 26.41, 4.17, 26.41, 4.17, 11.15, 3.58, 16.42, 10, 19.42, 19.42A92.47, 92.47, 0, 0, 1, 102, 52.57, 92.47, 92.47, 0, 0, 1, 97.83, 79c-3, 9.44-8.27, 15.84-19.42, 19.42, 0, 0-10.49, 4.17-26.41, 4.17S25.59, 98.4, 25.59, 98.4C14.44, 94.82, 9.17, 88.42, 6.17, 79A92.47, 92.47, 0, 0, 1, 2, 52.57, 92.47, 92.47, 0, 0, 1, 6.17, 26.16c3-9.44, 8.27-15.84, 19.42-19.42, 0, 0, 10.52-4.16, 26.41-4.17',
            };

            return pathMap[this.borderRadius];
        },
        compStatusLineVars(): string {
            let styleToReturn: string = '';
            let sizeToUse: string = '';
            let borderColorToUse: string = '';
            let borderRadiusToUse: string = '';
            const thicknessMap: {[index: string]: any} = {
                xsmall: '1px',
                small: '1px',
                medium: '2px',
                large: '2px',
                xlarge: '3px',
            };

            sizeToUse = this._sizeToUse(this.size);
            styleToReturn = `width: ${sizeToUse}; height: ${sizeToUse};`;

            if (this.showStatusLine && !this.showProgressBar && !this.showSkeleton) {
                borderRadiusToUse = this._borderRadiusToUse();

                if (this.statusLineColor !== '') {
                    borderColorToUse = this.statusLineColor;
                }

                styleToReturn += ` border-width: ${thicknessMap[this.size]}; border-color: ${borderColorToUse}; border-radius: ${borderRadiusToUse};`;
            }

            return styleToReturn;
        },
        compSkeletonVars(): string {
            let sizeToUse: string = '';
            let borderRadiusToUse: string = '';

            sizeToUse = this._sizeToUse(this.size);
            borderRadiusToUse = this._borderRadiusToUse();

            return `width: ${sizeToUse}; height: ${sizeToUse}; border-radius: ${borderRadiusToUse};`;
        },
        compContentVars(): string {
            let sizeToUse: string = '';
            let borderRadiusToUse: string = '';
            let backgroundToUse: string = '';

            sizeToUse = this._badgeSizeToUse(this.borderRadius, this.size, this.showIndicator, this.showStatusLine, this.showProgressBar);

            borderRadiusToUse = this._borderRadiusToUse();

            if (this.backgroundColor !== '') {
                backgroundToUse = this.backgroundColor;
            }

            if (this.image !== '') {
                backgroundToUse = `url("${this.image}") center/cover no-repeat`;
            }

            return `width: ${sizeToUse}; height: ${sizeToUse}; border-radius: ${borderRadiusToUse}; background: ${backgroundToUse};`;
        },
        compMainIconLetterVars(): string {
            let sizeToUse: string = '';
            let colorToUse: string = '';

            const singleFallbackCharacter = this.fallbackLetter && this.fallbackLetter.length < 2;
            const fontSizeMap: {[index: string]: any} = {
                xsmall: singleFallbackCharacter ? '12px' : '8px',
                small: singleFallbackCharacter ? '16px' : '12px',
                medium: singleFallbackCharacter ? '20px' : '14px',
                large: singleFallbackCharacter ? '32px' : '24px',
                xlarge: singleFallbackCharacter ? '52px' : '40px',
            };

            if (this.letterIconColor !== '') {
                colorToUse = this.letterIconColor;
            }

            sizeToUse = this._badgeSizeToUse(this.borderRadius, this.size, this.showIndicator, this.showStatusLine, this.showProgressBar);

            return `width: ${sizeToUse}; height: ${sizeToUse}; font-size: ${fontSizeMap[this.size]}; color: ${colorToUse};`;
        },
        compAriaLabel(): string | boolean {
            let readerText: string = '';

            if (this.ariaLabel !== '') {
                readerText += `${this.ariaLabel}`;
            }
            if (this.showIndicator) {
                readerText += ' New notification.';
            }
            if (this.showStatusLine && this.statusLineAriaLabel !== '') {
                readerText += ` ${this.statusLineAriaLabel} `;
            }
            if (this.showProgressBar && this._validateProgressValue(this.progressBarValue)) {
                readerText += `Progress is ${this.progressBarValue}% complete.`;
            }

            readerText = this._cleanupAriaText(readerText);

            if (readerText === '') { // This check prevents empty aria attributes
                return false;
            } else {
                return readerText;
            }
        },
        compFallbackLetter(): string {
            const compareFallbackLetter = this.fallbackLetter ? this.fallbackLetter : '';

            return compareFallbackLetter.trim().slice(0, 2).toUpperCase();
        },
        compIndicatorVars(): string {
            const sizeMap: {[index: string]: any} = {
                xsmall: '4px',
                small: '4px',
                medium: '6px',
                large: '8px',
                xlarge: '12px',
            };

            const thicknessMap: {[index: string]: any} = {
                xsmall: '1px',
                small: '2px',
                medium: '2px',
                large: '3px',
                xlarge: '4px',
            };

            const showStatusLineOrProgressBar: boolean = (this.showStatusLine || this.showProgressBar);

            const roundedSquircleMap: {[index: string]: any} = {
                xsmall : showStatusLineOrProgressBar ? '3px' : '0',
                small : showStatusLineOrProgressBar ? '4px' : '1px',
                medium : showStatusLineOrProgressBar ? '6px' : '1px',
                large : showStatusLineOrProgressBar ? '8px' : '2px',
                xlarge : showStatusLineOrProgressBar ? '15px' : '4px',
            };

            const sharpSoftMap: {[index: string]: any} = {
                xsmall : showStatusLineOrProgressBar ? '1px' : '0',
                small : showStatusLineOrProgressBar ? '1px' : '0',
                medium : showStatusLineOrProgressBar ? '2px' : '0',
                large : showStatusLineOrProgressBar ? '2px' : '0',
                xlarge : showStatusLineOrProgressBar ? '3px' : '0',
            };

            const marginMap: {[index: string]: any} = {
                rounded: roundedSquircleMap[this.size],
                sharp: sharpSoftMap[this.size],
                soft: sharpSoftMap[this.size],
                squircle: roundedSquircleMap[this.size],
            };

            return `width: ${sizeMap[this.size]}; height: ${sizeMap[this.size]}; border-width: ${thicknessMap[this.size]}; right: ${marginMap[this.borderRadius]}; top: ${marginMap[this.borderRadius]};`;
        },
        compSubIconAriaLabel(): string {
            let readerText: string = '';

            if (this.subIconAriaLabel !== '' && typeof(this.subIconAriaLabel) !== 'undefined') {
                readerText += `${this.subIconAriaLabel}`;
            }

            readerText = this._cleanupAriaText(readerText);

            return readerText;
        },
    },
    methods: {
        onClickAvatar($event: Event) {
            if (!this.isStatic && !this.isAriaHidden) {
                /**
                 * Emitted when the avatar component is clicked. If the 'isStatic' or 'isAriaHidden' boolean attributes are specified, this event will not be emitted.
                 */
                this.$emit('avatar-click', $event);
            }
        },
        onClickIcon($event: Event) {
            if (!this.isStatic && !this.isAriaHidden) {
                /**
                 * Emitted when the icon is clicked. If the 'isStatic' or 'isAriaHidden' boolean attributes are specified, this event will not be emitted.
                 */
                this.$emit('avatar-click-sub-icon', $event);
            }
        },
        _getPathLength() {
            if (this.showProgressBar) {
                const progressRing = this.$refs.progressRing as any;

                this.$data.pathLength = progressRing.getTotalLength();
            }
        },
        _cleanupAriaText(ariaText: string) {
            let cleanText: string = ariaText;

            // This handles single quotes, double quotes, and ampersands
            cleanText = cleanText.replace(/&#39/g, `'`).replace(/&quot;/g, `"`).replace(/&amp;/g, `&`).replace(/';/g, `'`);

            return cleanText;
        },
        _sizeToUse(size: string) {
            const sizeToUse: {[index: string]: any} = {
                xsmall: '24px',
                small: '32px',
                medium: '40px',
                large: '64px',
                xlarge: '104px',
            };

            return sizeToUse[size];
        },
        _borderRadiusToUse(): string {
            if (this.showProgressBar || ((this.size === 'large' || this.size === 'xlarge') && (this.showStatusLine))) { return '999px'; }

            // Calculate border radius with either the override or the default
            return this.borderRadiusOverride ? this.borderRadiusOverride : this._determineRadius();
        },
        _determineRadius() {
            const tokenValue = getComputedStyle(document.querySelector('body') as HTMLElement).getPropertyValue('--shapeBrandedSmall') || ShapeRoundedSmall;
            const shapeSizeMap: Record<BrandedShapeName, Record<IrisAvatarSize, string>> = {
                [BrandedShapeName.sharp]: {
                    xsmall: '0',
                    small: '0',
                    medium: '0',
                    large: '0',
                    xlarge: '0',
                },
                [BrandedShapeName.soft]: {
                    xsmall: '4px',
                    small: '4px',
                    medium: '4px',
                    large: '4px',
                    xlarge: '4px',
                },
                [BrandedShapeName.squircle]: {
                    xsmall: '8px',
                    small: '8px',
                    medium: '10px',
                    large: '12px',
                    xlarge: '12px',
                },
                [BrandedShapeName.rounded]: {
                    xsmall: '24px',
                    small: '32px',
                    medium: '40px',
                    large: '64px',
                    xlarge: '104px',
                },
            };

            const tokenToShapeMap: {[index: string]: BrandedShapeName} = {
                [ShapeBrandedSharp]: BrandedShapeName.sharp,
                [ShapeBrandedSoft]: BrandedShapeName.soft,
                [ShapeBrandedSquircle]: BrandedShapeName.squircle,
                [ShapeRoundedSmall]: BrandedShapeName.rounded,
            };

            // Retrieve the shape from the token to shape map
            const shape = tokenToShapeMap[tokenValue] || BrandedShapeName.rounded;
            return shapeSizeMap[shape][this.size as IrisAvatarSize];
        },
        _badgeSizeToUse(borderRadius: string, size: string, showIndicatorDot: boolean, showStatusLine: boolean, showProgressBar: boolean) {
            const showStatusLineOrProgressBar: boolean = (showStatusLine || showProgressBar);
            const dotOnly: boolean = (showIndicatorDot && !showStatusLineOrProgressBar);

            const roundedSquircleMap: {[index: string]: any} = {
                xsmall : showStatusLineOrProgressBar ? '18px' : '24px',
                small : showStatusLineOrProgressBar ? '26px' : '32px',
                medium : showStatusLineOrProgressBar ? '30px' : '40px',
                large : showStatusLineOrProgressBar ? '50px' : '64px',
                xlarge : showStatusLineOrProgressBar ? '84px' : '104px',
            };

            const sharpSoftMap: {[index: string]: any} = {
                xsmall : dotOnly ? '22px' : (showStatusLineOrProgressBar ? '18px' : '24px'),
                small :  dotOnly ? '28px' : (showStatusLineOrProgressBar ? '26px' : '32px'),
                medium : dotOnly ? '34px' : (showStatusLineOrProgressBar ? '30px' : '40px'),
                large :  dotOnly ? '56px' : (showStatusLineOrProgressBar ? '50px' : '64px'),
                xlarge : showStatusLineOrProgressBar ? '84px' : '92px',
            };

            const sizeToUse: {[index: string]: any} = {
                rounded: roundedSquircleMap[size],
                sharp: sharpSoftMap[size],
                soft: sharpSoftMap[size],
                squircle: roundedSquircleMap[size],
            };

            return sizeToUse[borderRadius];
        },
        _validateProgressValue(value: any) {
            return (Number(value) >= 0 && Number(value) <= 100);
        },
    },
    updated() {
        this._getPathLength();
    },
    mounted() {
        this._getPathLength();
    },
});
