
import Vue from 'vue';
import IrisIcon from '../IrisIcon.vue';
import { IrisDropdownTestHook } from './IrisDropdown.testHooks';

export default Vue.extend({
    name: 'IrisDropdownFilter',
    components: { IrisIcon },
    props: {
        filterVersion: String,
        filterText: String,
        placeholderText: String,
    },
    data() {
        return {
            localFilterText: '' as string,
            testHooks: IrisDropdownTestHook,
        };
    },
    created() {
        this.localFilterText = this.filterText; // Set the local filter text to what the parent passed in
    },
    watch: {
        filterText(val: string) {
            this.localFilterText = val; // If the parent updates the filter text, this keeps our local filter text in sync
        },
    },
});
