import { generateUniqueId } from '@/utils';

export function tooltip(triggerEl: any, binding: any, vnode: any, oldVnode: any): void {
    // check for tooltip message or dupe node
    const isMessageString = binding.value && typeof binding.value === 'string' && binding.value.length > 0;
    const isMessageObject = binding.value && binding.value.message && binding.value.message.length > 0;
    const newTooltipId = vnode.elm && vnode.elm.dataset ? (vnode.elm.dataset as DOMStringMap).irisTooltip : null;
    const oldTooltipId = oldVnode.elm && oldVnode.elm.dataset ? (oldVnode.elm.dataset as DOMStringMap).irisTooltip : null;
    const isDupeNode = newTooltipId === oldTooltipId;

    // bail out if dupe or not a tooltip message
    if (isDupeNode || !(isMessageString || isMessageObject)) { return; }

    // create tooltip element
    vnode.context.$nextTick(() => {
        const uid: string = generateUniqueId('tooltip');

        // tooltip element
        const tooltipElement: HTMLDivElement = document.createElement('div');
        tooltipElement.id = uid;

        if (binding.value.message) {
            tooltipElement.innerText = binding.value.message;
        } else {
            tooltipElement.innerText = binding.value;
        }
        tooltipElement.setAttribute('role', 'tooltip');
        (tooltipElement as any).open = false;
        tooltipElement.setAttribute(
            'style', `
                background: white;
                box-shadow: var(--elevationPlatformRestingSurfaceBoxShadow);
                color: var(--colorPlatformGray900);
                contain: layout;
                max-width: 440px;
                opacity: 0;
                padding: var(--spacingPlatformTiny) var(--spacingPlatformSmall);
                pointer-events: none;
                position: fixed;
                text: align-center;
                z-index: 10002;`,
        );
        document.body.appendChild(tooltipElement);

        // trigger element
        triggerEl.setAttribute('aria-describedby', uid);
        triggerEl.setAttribute('data-iris-tooltip', uid);

        // listen for touch, click, or hover events
        const widthOfTooltip = tooltipElement.offsetWidth;
        const widthOfTrigger = triggerEl.offsetWidth;
        if ('ontouchstart' in window) {
            if (binding.modifiers.longpress || binding.value.longpress) {
                initLongPress(triggerEl, tooltipElement, binding, widthOfTooltip, widthOfTrigger);
            } else {
                onClick(triggerEl, tooltipElement, binding, widthOfTooltip, widthOfTrigger);
            }
        } else {
            if (binding.modifiers.click || binding.value.triggerByClick || binding.value.click) {
                onClick(triggerEl, tooltipElement, binding, widthOfTooltip, widthOfTrigger);
            } else {
                onFocus(triggerEl, tooltipElement, binding, widthOfTooltip, widthOfTrigger);
                onMouseEnter(triggerEl, tooltipElement, binding, widthOfTooltip, widthOfTrigger);
            }
        }

        onScroll(triggerEl, tooltipElement, binding, widthOfTooltip, widthOfTrigger);
        onSheetScroll(triggerEl, tooltipElement, binding, widthOfTooltip, widthOfTrigger);
    });
}

// Methods
const setBorderRadius = (tooltipElement: any, binding: any): void => {

    // add shape
    tooltipElement.style.borderRadius = 'var(--shapeBrandedSmall)';

    // set to large when large version modifier is there
    if (binding.modifiers.large || binding.value.size === 'large') {
        tooltipElement.style.borderRadius = 'var(--shapeBrandedMedium)';
        tooltipElement.style.padding = '24px';
    }
};

const setLocation = (triggerEl: any, tooltipElement: any, binding: any, widthOfTooltip: any, widthOfTrigger: any): void => {
    const triggerX = triggerEl.getBoundingClientRect().x;
    const triggerY = triggerEl.getBoundingClientRect().y;
    const heightOfTrigger = triggerEl.offsetHeight;
    const heightOfTooltip = tooltipElement.offsetHeight;

    const positionBottom = triggerY + heightOfTrigger + 10;
    const positionTop = triggerY - heightOfTooltip - 30;
    tooltipElement.style.top = positionTop.toString() + 'px';

    // check modifiers for placement left, right, or center
    if (binding.modifiers.right || binding.value.position === 'right') {
        const positionRight = triggerX + widthOfTrigger - widthOfTooltip;
        tooltipElement.style.left = positionRight.toString() + 'px';
    } else if (binding.modifiers.left || binding.value.position === 'left') {
        const positionLeft = triggerX;
        tooltipElement.style.left = positionLeft.toString() + 'px';
    } else {
        const positionCenter = triggerX - widthOfTooltip / 2 + widthOfTrigger / 2;
        tooltipElement.style.left = positionCenter.toString() + 'px';
    }

    // check if top of tooltip overlaps top of window
    const top = tooltipElement.getBoundingClientRect().top;
    if (Math.sign(top) === -1) {
        tooltipElement.style.top = positionBottom.toString() + 'px';
    }

    // check if left of tooltip overlaps left of window
    const tooltipX = tooltipElement.getBoundingClientRect().x;
    if (Math.sign(tooltipX) === -1) {
        tooltipElement.style.left = triggerX.toString() + 'px';
    }

    // check if right of tooltip overlaps right of window
    const tooltipRight = widthOfTooltip + tooltipX;
    if (tooltipRight > window.innerWidth) {
        const moveTooltipLeft = window.innerWidth - widthOfTooltip;
        tooltipElement.style.left = moveTooltipLeft.toString() + 'px';
    }
};

const showTooltip = (triggerEl: any, tooltipElement: any, binding: any, widthOfTooltip: any, widthOfTrigger: any, isClick: boolean): void => {
    tooltipElement.classList.remove('transition-exit');
    tooltipElement.classList.add(isClick ? 'transition-entrance-click' : 'transition-entrance');
    setBorderRadius(tooltipElement, binding);
    setLocation(triggerEl, tooltipElement, binding, widthOfTooltip, widthOfTrigger);
    tooltipElement.open = true;
};

const hideTooltip = (tooltipElement: any): void => {
    tooltipElement.classList.remove('transition-entrance', 'transition-entrance-click');
    tooltipElement.classList.add('transition-exit');
    tooltipElement.open = false;
};

const onLongPress = (triggerEl: HTMLElement, callback: any): void => {
    let timer: any = null;

    triggerEl.addEventListener('touchstart', () => {
        timer = setTimeout(() => {
            timer = null;
            callback();
        }, 300);
    });

    function cancel() {
        clearTimeout(timer);
    }

    triggerEl.addEventListener('touchend', cancel);
    triggerEl.addEventListener('touchmove', cancel);
};

// Events
const initLongPress = (triggerEl: any, tooltipElement: any, binding: any, widthOfTooltip: any, widthOfTrigger: any): void => {
    onLongPress(triggerEl, () => {
        showTooltip(triggerEl, tooltipElement, binding, widthOfTooltip, widthOfTrigger, true);
    });

    window.addEventListener('touchend', () => {
        hideTooltip(tooltipElement);
    });
};

const onClick = (triggerEl: any, tooltipElement: any, binding: any, widthOfTooltip: any, widthOfTrigger: any): void => {
    let eventType = 'click';

     // For iris checkboxes, cloacked checkmarks, and radios we need to listen for change events, not click
    if (triggerEl.nodeName === 'LABEL' &&
        (triggerEl.classList.contains('irisv-checkbox') ||
        triggerEl.classList.contains('irisv-cloaked-checkmark') ||
        triggerEl.classList.contains('irisv-radio-button'))) {
        eventType = 'change';
    }

    triggerEl.addEventListener(eventType, () => {
        if (tooltipElement.open) {
            hideTooltip(tooltipElement);
        } else {
            showTooltip(triggerEl, tooltipElement, binding, widthOfTooltip, widthOfTrigger, true);
            listenForEscape(tooltipElement);
        }
    });

    window.addEventListener('mousedown', (e: Event) => {
        // This is for iris checkboxes, cloaked checkmarks, and radios where clicking on the text span causes the event to fire on the label and input
        if (triggerEl.contains(e.target as Node)) {
            return;
        }

        if (e.target !== triggerEl && tooltipElement.open) {
            hideTooltip(tooltipElement);
        }
    });

    // if in an iframe and can access parent frame, add eventlistener in parent
    if (canAccessParentFrame()) {
        window.parent.document.addEventListener('mousedown', () => {
            if (tooltipElement.open) {
                hideTooltip(tooltipElement);
            }
        });
    }
};

const onMouseLeave = (triggerEl: any, tooltipElement: any): void => {
    triggerEl.addEventListener('mouseleave', () => {
        hideTooltip(tooltipElement);
    });
};

const onMouseEnter = (triggerEl: any, tooltipElement: any, binding: any, widthOfTooltip: any, widthOfTrigger: any): void => {
    triggerEl.addEventListener('mouseenter', () => {
        showTooltip(triggerEl, tooltipElement, binding, widthOfTooltip, widthOfTrigger, false);
        onMouseLeave(triggerEl, tooltipElement);
    });
};

const listenForEscape = (tooltipElement: any): void => {
    window.addEventListener('keydown', (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            hideTooltip(tooltipElement);
        }
    });
};

const onBlur = (triggerEl: any, tooltipElement: any): void => {
    triggerEl.addEventListener('blur', () => {
        hideTooltip(tooltipElement);
    });
};

const onFocus = (triggerEl: any, tooltipElement: any, binding: any, widthOfTooltip: any, widthOfTrigger: any): void => {
    triggerEl.addEventListener('focus', () => {
        showTooltip(triggerEl, tooltipElement, binding, widthOfTooltip, widthOfTrigger, true);
        onBlur(triggerEl, tooltipElement);
        listenForEscape(tooltipElement);
    });
};

const onScroll = (triggerEl: any, tooltipElement: any, binding: any, widthOfTooltip: any, widthOfTrigger: any): void => {
    window.addEventListener('scroll', () => {
        setLocation(triggerEl, tooltipElement, binding, widthOfTooltip, widthOfTrigger);
    });
};

const onSheetScroll = (triggerEl: any, tooltipElement: any, binding: any, widthOfTooltip: any, widthOfTrigger: any): void => {
    document.addEventListener('iris-sheet-scroll', () => {
        setLocation(triggerEl, tooltipElement, binding, widthOfTooltip, widthOfTrigger);
    });
};

const canAccessParentFrame = (): boolean => {
    // is in iframe
    if (window.parent !== window) {
        try {
            // can access parent frame
            return Boolean(window.parent.location.origin);
        } catch (e) {
            return false;
        }
    }

    return false;
};