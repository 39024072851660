
import Vue from 'vue';
import { IrisDropdownTestHook } from './IrisDropdown.testHooks';

export default Vue.extend({
    name: 'IrisDropdownItemCheckbox',
    props: {
        checked: {
            default: false,
            type: Boolean,
        },
        showCheckboxes: {
            default: false,
            type: Boolean,
        },
    },
    data() {
        return {
            testHooks: IrisDropdownTestHook,
        };
    },
});
