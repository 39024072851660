
import Vue from 'vue';
import IrisTextfieldContainer from './IrisTextfieldContainer.vue';
import { textfieldMixin } from './../mixins/textfieldMixin';
import { currencyFormatter } from '@/filters/currencyFormatter';

export default Vue.extend({
    name: 'IrisTextfieldCurrency',
    model: {
        prop: 'value',
        event: 'textfield-input',
    },
    components: {IrisTextfieldContainer},
    mixins: [textfieldMixin],
    filters: {
        currencyFormatter,
    },
    props: {
        /**
         * Controls the currency and locale formatting for isCurrencyInput being 'true'.<br /><br />
         * This is an object that mostly follows the guidelines of the <a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat">Intl.NumberFormat</a> standard.<br /><br />
         * Most use cases will be changing the currency to use as locale defaults to the browser's language settings.<br /><br />
         * Examples:<br />
         * { currency: 'USD' } // US Dollar currency<br />
         * { currency: 'USD', locale: 'en-US' } // US Dollar currency and US English local
         */
        currencyFormatterOptions: Object,
        /**
         * The input value
         */
        value: String,
        /**
         * Sets the aria-label for the clearable icon.
         */
        clearIconAriaLabel: {
            type: String,
            default: 'Delete text',
        },
        /**
         * Sets the aria-label for the trailing icon. We have included the icon's name as a fallback, but please update to something descriptive for screen reader user.
         */
        trailingIconAriaLabel: String,
        /**
         * Displays an icon to clear text from textfield. The clearable icon only appears when the textfield contains a value and is in focus.
         */
        isClearable: {
            type: Boolean,
            default: true,
        },
        /**
         * Overrides the default internal error messaging displayed when the currency textfield's value does not match the component's expected internal currency pattern.
         * Ex: (A user enters '$3,293..29' into the textfield). This prop is typically used in the form of a sitetext key for translation purposes.
         */
        currencyPatternMismatchMessage: {
            type: String,
            default: 'Invalid dollar amount',
        },
        /**
         * Sets the trailing icon name. Ex: 'info'.
         */
        trailingIconName: String,
        /**
         * Sets the validation behavior to check after user input in addition to after blur.
         */
        validateOnInput: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return ({
            formattedValue: '',
            unformattedValue: '',
            startingValue: '',
            debounce: null,
        });
    },
    computed: {
        computedCurrencyFormatterOptions(): object {
            return {
                ...this.currencyFormatterOptions,
                hasImpliedDecimal: true,
            };
        },
        listeners(): object {
            return {
                ...this.$listeners,
                'input': this.onInput,
                'blur': this.onBlur,
                'focus': this.onFocus,
                'keypress.enter': this.onChange,
                'keydown': this.onKeyDown,
            };
        },
    },
    methods: {
        clearEverything(): void {
            this.internalValue_ = ''; // Set this internalValue_ needed by the textfieldMixin
            this.formattedValue = '';
            this.unformattedValue = '';
            this.$emit('textfield-input-currency', {
                formatted: '',
                unformatted: '',
                formatParts: '',
            });
            this.$emit('textfield-input', '');
            (this.$refs.textfield as HTMLInputElement).value = '';
        },
        clearText(): void {
            this.clearEverything();
            (this.$refs.textfield as HTMLElement).focus();

            /**
             * Emitted when the clearable icon is clicked.
             */
            this.$emit('textfield-clear');
        },
        onFocus(event: Event): void { // Replaces textfieldMixin onFocus method
            this.isFocused = true; //  textfieldMixin data
            this.startingValue = (this.$refs.textfield as HTMLInputElement).value; // Capture what is in the input before changes

            /**
             * Emitted when the textfield gains focus.
             */
            this.$emit('textfield-focus', event);
        },
        onBlur(event: Event): void { // Replaces textfieldMixin onBlur method
            this.isFocused = false; // textfieldMixin data

            if (this.formattedValue !== this.startingValue) { // Check if the current input value is different than the starting value
                /**
                 * Emitted when the change event is triggered by user interaction.
                 */
                this.$emit('textfield-change', this.unformattedValue);
            }

            /**
             * Emitted after the textfield loses focus (before any formatting).
             */
            this.$emit('textfield-blur', event);
        },
        onKeyDown(event: KeyboardEvent) {
            if (event.key === 'Backspace' && this.unformattedValue === '0.00') {
                this.clearEverything();
            }
        },
        onInput() { // Replaces textfieldMixin onInput method
            const value = (this.$refs.textfield as HTMLInputElement).value;
            const formatParts = this.$options.filters!.currencyFormatter(value, { ...this.computedCurrencyFormatterOptions, formatToParts: true });
            let joinedParts = '';
            this.formattedValue = '';

            if (typeof formatParts === 'object') {
                joinedParts = formatParts
                    .filter((x: any) => x.type === 'minusSign' || x.type === 'integer' || x.type === 'decimal' || x.type === 'fraction')
                    .map((x: any) => x.type === 'decimal' ? '.' : x.value)
                    .join('');

                this.formattedValue = this.$options.filters!.currencyFormatter(value, this.computedCurrencyFormatterOptions);
            }

            /**
             * Emitted when input values change.<br /><br />
             * Provides an object with data associated to currency formatting.<br /><br />
             * Key descriptions:<br />
             * <strong>'formatted'</strong>: this is the current input value formatted into currency<br /><br />
             * <strong>'unformatted'</strong>: this is the current input value with no formatting except decimals<br /><br />
             * <strong>'formatParts'</strong>: data provided by <a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/formatToParts">Intl.NumberFormat.prototype.formatToParts()</a>
             */
            this.$emit('textfield-input-currency', {
                formatted: this.formattedValue,
                unformatted: joinedParts,
                formatParts,
            });
            this.$emit('textfield-input', joinedParts);
            this.internalValue_ = this.formattedValue; // Set this internalValue_ needed by the textfieldMixin
            this.unformattedValue = joinedParts;

            (this.$refs.textfield as HTMLInputElement).value = this.formattedValue;
        },
    },
    watch: {
        value(newValue) {
            const input = (this.$refs.textfield as HTMLInputElement);

            if (input.value !== newValue) { // This helps us determine that the change came from v-model, not the user typing
                input.value = newValue;
                this.onInput();

                if (!this.validateOnInput) {
                    this.validate(); // validationMixin function
                } else {
                    clearTimeout(this.$data.debounce);
                    this.$data.debounce = setTimeout(() => {
                        this.validate(); // validationMixin function
                    }, this.validationDelay); // textfieldMixin prop
                }
            }
        },
        currencyFormatterOptions() {
            this.onInput();
        },
    },
    mounted() {
        (this.$refs.textfield as HTMLInputElement).value = this.value;
        this.onInput();
    },
});
