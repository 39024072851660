
import Vue from 'vue';
import IrisTextfieldContainer from './IrisTextfieldContainer.vue';
import IrisIcon from './IrisIcon.vue';
import { textfieldMixin } from './../mixins/textfieldMixin';

const PASSWORD_VISIBLE_CLASS = 'font-body-1';
const PASSWORD_OBSCURED_CLASS = 'font-subtitle-1';

export default Vue.extend({
    name: 'IrisTextfieldPassword',
    model: {
        event: 'textfield-input',
    },
    components: {
        IrisIcon,
        IrisTextfieldContainer,
    },
    mixins: [textfieldMixin],
    props: {
        /**
         * Sets the aria-label for the hide password icon.
         */
        hidePasswordIconAriaLabel: {
            type: String,
            default: 'Hide password',
        },
        /**
         * Sets the aria-label for the show password icon.
         */
        showPasswordIconAriaLabel: {
            type: String,
            default: 'Show password',
        },
        /**
         * Sets password strength meter.
         */
        passwordStrength: {
            type: String,
            validator: (value: string) => {
                return ['weak', 'fair', 'strong'].includes(value.toLowerCase());
            },
        },
        /**
         * Sets password strength meter label.
         * Required keys are:
         * "weak" (string): Sets the weak option string text <br>
         * "fair" (string): Sets the fair option string text <br>
         * "strong" (string): Sets the strong option string text <br>
         */
        passwordStrengthLabel: {
            type: Object,
            default: () => ({}),
        },

    },
    data() {
        return ({
            showPassword: false as boolean,
        });
    },
    computed: {
        computedValue: { // This will be called whenever computedValue is changed - either from textfieldMixin or v-model
            get(): string {
                return this.internalValue_;
            },
            set(value: string) {
                this.internalValue_ = value;
                this.callToValidateHandler(); // textfieldMixin function
                /**
                 * Emitted when component receives input.
                 */
                this.$emit('textfield-input', value);
            },
        },
        mergedStrings(): any {
            return {
                weak: 'weak',
                fair: 'fair',
                strong: 'strong',
                ...this.passwordStrengthLabel,
            };
        },
        listeners(): object {
            return {
                ...this.$listeners,
                'input': this.onInput,
                'blur': this.onBlur,
                'change': this.onChange,
                'focus': this.onFocus,
                'keypress.enter': this.onChange,
            };
        },
    },
    methods: {
        setPasswordClasses() { // Method needed to preserve added 3rd party classes. Using a ternary in the template code removes all classes then adds them back (except for ones that we added from 3rd parties!)
            const passwordField = (this.$refs.textfield as HTMLInputElement);

            this.showPassword ? passwordField.classList.replace(PASSWORD_OBSCURED_CLASS, PASSWORD_VISIBLE_CLASS) : passwordField.classList.replace(PASSWORD_VISIBLE_CLASS, PASSWORD_OBSCURED_CLASS);
        },
        handleShowPasswordClick() {
            this.showPassword = !this.showPassword;
            this.$emit('textfield-show-password-click', {
                showPassword: this.showPassword,
            });
        },
    },
    mounted() {
        this.setPasswordClasses();
    },
    watch: {
        value(newValue: string) {
            if (newValue !== this.computedValue) { // This helps us determine that the change came from v-model, not the user typing
                this.validateImmediate = true; // textfieldMixin data
                this.computedValue = newValue;
            }
        },
        showPassword() {
            this.setPasswordClasses();
        },
    },
});
