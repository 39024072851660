var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.kind === 'action' || _vm.kind === 'navigation')?_c('iris-dropdown-item-navigation-action',{attrs:{"id":_vm.id,"is-action":_vm.kind === 'action',"item":_vm.item,"selected":_vm.selected,"show-checkboxes":_vm.showCheckboxes},on:{"click":_vm.emitListItemClick}}):_c('li',{class:[
        'irisv-dropdown__menu-item',
        (_vm.kind !== 'account' && _vm.item.disabled) ? 'item-disabled' : '',
    ],attrs:{"id":_vm.id,"data-cy":_vm.testHooks.listItem,"data-value":_vm.item.value,"data-checked":_vm.selected,"role":"option","aria-disabled":_vm.kind !== 'account' && _vm.item.disabled,"aria-selected":_vm.selected},on:{"click":_vm.emitListItemClick}},[(_vm.kind === 'account')?[_c('iris-account',_vm._b({attrs:{"balance-display-class":"subtitle2"}},'iris-account',typeof _vm.item === 'object' ? _vm.item : null,false))]:[(_vm.item.iconName)?_c('iris-icon',{class:[
                'irisv-dropdown__menu-item-icon',
                (_vm.item.secondLine && _vm.item.secondLine !== '') ? 'irisv-dropdown__menu-item-icon--top' : null,
            ],attrs:{"name":_vm.item.iconName,"aria-hidden":"true"}}):_vm._e(),(!_vm.item.iconName && _vm.listHasIcons)?_c('div',{staticClass:"irisv-dropdown__menu-item-icon-spacer"}):_vm._e(),_c('div',{staticClass:"irisv-dropdown__menu-item-text-container"},[_c('div',{staticClass:"irisv-dropdown__menu-item-text font-body-1",attrs:{"data-cy":_vm.testHooks.listItemText}},[_vm._v(_vm._s(_vm.item.label))]),(_vm.item.secondLine && _vm.item.secondLine !== '')?_c('div',{staticClass:"irisv-dropdown__menu-item-second-text font-caption",attrs:{"data-cy":_vm.testHooks.listItemTextSecondLine}},[_vm._v(" "+_vm._s(_vm.item.secondLine)+" ")]):_vm._e()])],_c('iris-dropdown-item-checkbox',{attrs:{"checked":_vm.selected,"show-checkboxes":_vm.showCheckboxes}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }