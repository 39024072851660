
import Vue from 'vue';
import IrisTextfieldContainer from './IrisTextfieldContainer.vue';
import { textfieldMixin } from './../mixins/textfieldMixin';

export default Vue.extend({
    name: 'IrisTextfield',
    inheritAttrs: false,
    model: {
        event: 'textfield-input',
    },
    components: {IrisTextfieldContainer},
    mixins: [textfieldMixin],
    props: {
        /**
         * Sets the aria-label for the clearable icon.
         */
        clearIconAriaLabel: {
            type: String,
            default: 'Delete text',
        },
        /**
         * Sets the aria-label for the trailing icon. We have included the icon's name as a fallback, but please update to something descriptive for screen reader user.
         */
        trailingIconAriaLabel: String,
        /**
         * Displays an icon to clear text from textfield. The clearable icon only appears when the textfield contains a value and is in focus.
         */
        isClearable: {
            type: Boolean,
            default: true,
        },
        /**
         * Overrides the default internal error messaging displayed when a textfield of type 'email' has a value that does not match the textfield component's expected internal email pattern.
         * Ex: (A user enters 'Email@@@Input.com' into the textfield). This prop is typically used in the form of a sitekey for translation purposes.
         */
        emailPatternMismatchMessage: {
            type: String,
            default: 'This input field does not meet the requirement',
        },
        /**
         * Sets the type of textfield. Valid values are 'email', 'number', 'text' or 'url'.
         */
        textfieldType: {
            type: String,
            default: 'text',
            validator: (value: string) => {
                return ['text', 'email', 'url', 'number'].includes(value.toLowerCase());
            },
        },
        /**
         * Sets the trailing icon name. Ex: 'info'.
         */
        trailingIconName: String,
        /**
         * Overrides the default internal error messaging displayed when a textfield of type 'url' has a value that does not match the textfield component's expected internal url pattern.
         * Ex: (A user enters 'www.url..com' into the textfield). This prop is typically used in the form of a sitekey for translation purposes.
         */
        urlPatternMismatchMessage: {
            type: String,
            default: 'Invalid url format',
        },
    },
    computed: {
        computedValue: { // This will be called whenever computedValue is changed - either from textfieldMixin or v-model
            get(): string {
                return this.internalValue_;
            },
            set(value: string) {
                this.internalValue_ = value;
                this.callToValidateHandler(); // textfieldMixin function
                /**
                 * Emitted when component receives input.
                 */
                this.$emit('textfield-input', value);
            },
        },
        listeners(): object {
            return {
                ...this.$listeners,
                'input': this.onInput,
                'blur': this.onBlur,
                'change': this.onChange,
                'focus': this.onFocus,
                'keypress.enter': this.onChange,
            };
        },
    },
    methods: {
        clearText(): void {
            this.computedValue = '';
            this.$emit('textfield-change', this.computedValue); // @TODO: This line should be removed. Change should only occur after the input loses focus. The user has only cleared the field at this point.
            (this.$refs.textfield as HTMLElement).focus();

            /**
             * Emitted when the clearable icon is clicked.
             */
            this.$emit('textfield-clear');
        },
    },
    watch: {
        value(newValue: string) {
            if (newValue !== this.computedValue) { // This helps us determine that the change came from v-model, not the user typing
                this.validateImmediate = true; // textfieldMixin data
                this.computedValue = newValue;
            }
        },
    },
});
