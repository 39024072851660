
import Vue from 'vue';
import IrisQuickActionButton from './IrisQuickActionButton.vue';

/**
 * The scrollable Quick Action Button Container component is a container of a group of Quick Action Buttons that allows dragging to scroll left and right and top and bottom.
 */
export default Vue.extend({
    name: 'IrisQuickActionButtonContainer',
    components: {
        IrisQuickActionButton,
    },
    data() {
        return {
            activeButton: 0 as number,
            dragDownTimer: 0 as number,
            duration: '250ms' as string,
            easing: 'ease-out' as string,
            previousItemX: 0 as number,
            sliding: false as boolean,
            vertical: false as boolean,
        };
    },
    props: {
        /**
         * Prop exposes the quick action buttons variation for the programmatic manipulation needed in the sheet component.
         */
        variation: {
            type: String,
            default: 'embedded',
        },
        /**
         * Sets the border radius of the quick action button, but only used as an override since default border radius value should be set by the theme. Expects a css value for border radius. Can be between 1-4 values for each corner of the shape. Examples '44px', '10% 30% 50% 70%'
         */
        borderRadiusOverride: {
            type: String,
        },
        /**
         * Object of props for the IrisAvatar child component.<br /><br />
         * Refer to the IrisAvatar docs for available props.<br /<br />
         */
        avatarProps: Object,
        /**
         * Sets the aria-label for the component. Please include any needed punctuation in the value.
         */
        ariaLabel: {
            type: String,
            default: '',
            required: true,
        },
        /**
         * Prop data to build the group of buttons components. The data should be an object of objects where each key represents a Quick Action Button and its props to be passed along. The key for each buttons is used for internal id purposes.
         */
        quickActionButtonsData: {
            type: Object,
            required: true,
        },
        /**
         * allows the container to shift directions based on screen width
         */
        directionShifting: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        clickHandler(button: any) {
            /**
             * Emitted when a quick action button in the container is clicked.
             */
            this.$emit('quick-action-button-container-click', button);
        },
        toggleHandler(emittedValue: boolean, button: any) {
            /**
             * Emitted when a quick action button in the container is toggled. A reference to the button and the selected state (true or false) will be returned.
             */
            this.$emit('quick-action-button-container-toggle', { button, toggle: emittedValue });
        },
        hasKey(obj: object, key: keyof any): key is keyof object {
            // this method exists solely so we can use square bracket notation on $refs
            return obj ? key in obj : false;
        },
        leftRightKeyup(e: KeyboardEvent) {
            const buttons = (this.$refs.buttons as Vue[]);
            const buttonsLength = buttons.length;
            let nextActiveButton = e.code === 'ArrowRight' ? this.activeButton + 1 : this.activeButton - 1;

            // lock down nextActive to the range of actual number of buttons in container
            nextActiveButton = nextActiveButton < 0 ? 0 : nextActiveButton;
            nextActiveButton = nextActiveButton > buttonsLength - 1 ? buttonsLength - 1 : nextActiveButton;

            if (this.hasKey(buttons, this.activeButton)) {
                const currentButton = buttons[this.activeButton] as any;

                // set the previous button out of the tabindex
                currentButton.$el.setAttribute('tabindex', '-1');
            }

            if (this.hasKey(buttons, nextActiveButton)) {
                const currentButton = buttons[nextActiveButton] as any;
                const slider = (this.$refs.slider as any).$el;

                // set new button with tabindex of 0, then focus it
                currentButton.$el.setAttribute('tabindex', '0');
                currentButton.$el.focus();

                // move slider in relation to new active button
                slider.style.cssText = `transition: left ${this.duration} ${this.easing}; left: ${-currentButton.$el.offsetLeft}px`;
            }

            this.activeButton = nextActiveButton;
        },
        dragDown() {
            const sliderWrapper = this.$refs.sliderWrapper as HTMLElement;
            const slider = (this.$refs.slider as any).$el;

            // if group is static then don't allow dragging
            // if sliderWrapper is larger than the slider don't allow dragging
            if (sliderWrapper.offsetWidth <= slider.offsetWidth && !this.vertical) {
                // timer is to allow for clicking on button without triggering drag
                this.dragDownTimer = window.setTimeout(() => {
                    this.sliding = true;

                    document.addEventListener('mousemove', this.dragMove);
                    document.addEventListener('touchmove', this.dragMove);
                }, 300);

                document.addEventListener('mouseup', this.dragUp);
                document.addEventListener('touchend', this.dragUp);
            }
        },
        dragMove(e: MouseEvent | TouchEvent) {
            window.requestAnimationFrame(() => {
                const slider = (this.$refs.slider as any).$el;
                const sliderX: number = slider.style.left ? parseInt(slider.style.left, 10) : 0;
                const clientX: number = (e as MouseEvent).type === 'mousemove' ? (e as MouseEvent).clientX : Math.floor((e as TouchEvent).touches[0].clientX);

                // we need to know the previous x coordinate of the slider
                // this is so we can compare current and past coordinates to know the direction of the drag
                // but the first time there is no past coordinate so use current coordinate
                // this does produce a lag for first tick, but it's one tick so we can let that one go
                this.previousItemX = this.previousItemX ? this.previousItemX : clientX;

                // use the previous coordinate to shift slider element's left property the correct amount, positive or negative
                // since we're shifting by pixels don't allow a transition as that might mess things up
                slider.style.cssText = `transition: none; left: ${sliderX + (clientX - this.previousItemX)}px; width: auto;`;

                // now save current coordinate to be the next past coordinate for next tick
                this.previousItemX = clientX;
            });
        },
        dragUp() {
            const sliderWrapper = this.$refs.sliderWrapper as HTMLElement;
            const slider = (this.$refs.slider as any).$el;
            const sliderLeft: number = parseInt(slider.style.left, 10);
            const sliderScrollWidth: number = slider.scrollWidth;

            // clear the timer from the dragDown method
            window.clearTimeout(this.dragDownTimer);

            // this timer is to restore things after a small delay
            window.setTimeout(() => {
                this.sliding = false;

                // if the first button is to the right of the left side of the group, slide back to the left
                // if the last button is to the left of the right side of the group, slide back to the right
                // this prevents empty space between the first/last buttons and their relevant sides
                if (sliderLeft > 0 && !this.vertical) {
                    slider.style.cssText = `transition: left ${this.duration} ${this.easing}; left: 0;`;
                } else if (-sliderLeft > sliderScrollWidth - sliderWrapper.offsetWidth && !this.vertical) {
                    slider.style.cssText = `transition: left ${this.duration} ${this.easing}; left: ${-sliderScrollWidth + sliderWrapper.offsetWidth}px;`;
                }

                // clear out relevant events
                document.removeEventListener('mousemove', this.dragMove);
                document.removeEventListener('touchmove', this.dragMove);
                document.removeEventListener('mouseup', this.dragUp);
                document.removeEventListener('touchend', this.dragUp);
            }, 150);

            window.setTimeout(() => {
                // this reset needs a bit more time than the timer above provides
                // don't know why, it just does...
                this.previousItemX = 0;
            }, parseInt(this.duration, 10));
        },
        changeOrientationDirection() {
            const mobileView = window.innerWidth < 560;
            const slider = (this.$refs.slider as any).$el;

            if (this.directionShifting) {
                this.vertical = !mobileView;

                if (!mobileView) {
                    slider.removeAttribute('style');
                }
            }
        },
    },

    mounted() {
        const wrapperAnimationCheckPoint = this.$refs.irisvQuickActionButtonContainer as HTMLElement;

        wrapperAnimationCheckPoint.addEventListener('animationstart', this.changeOrientationDirection);
        this.vertical = window.innerWidth > 560;
    },

    watch: {
        quickActionButtonsData() {
            // if quickActionButtonsData prop changes reset slider positioning, just in case
            (this.$refs.slider as any).$el.removeAttribute('style');
        },
        directionShifting() {
            this.changeOrientationDirection();
        },
    },
});
