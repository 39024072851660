
import Vue from 'vue';
import { deprecationMixin } from './../mixins/deprecationMixin';

export default Vue.extend({
    name: 'IrisSwitch',
    data() {
        return {
            isEnabled: false as boolean,
        };
    },
    mixins: [deprecationMixin],
    props: {
        /**
         * A required prop. Each label should indicate the relationship to what the toggle is used for, e.g. 'recieve email messages'.
         */
        ariaLabel: {
            type: String,
            required: true,
        },
        /**
         * Sets the border radius, but only used as an override since default border radius value should be set by the theme. Expects a css value for border radius. Can be between 1-4 values for each corner of the shape. Examples '44px', '10% 30% 50% 70%'
         */
        borderRadiusOverride: {
            type: String,
        },
        /**
         * Toggles the state of the switch button. This prop is read during the `created` lifecycle. Visit for more information https://vuejs.org/v2/api/#created.
         */
        isSelected: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets the disabled state of the switch button.
         */
        isDisabled: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onChange() {
            // Emits the value and returns the value of the selected switch button.
            this.isEnabled = !this.isEnabled;
            this.$emit('switch-change', this.isEnabled);
        },
    },
    created() {
        // Sets switches state on start up.
        this.isEnabled = this.isSelected;
    },
    watch: {
        // watcher for the prop toggle.
        isSelected(value) {
            this.isEnabled = value;
            this.$emit('switch-change', this.isEnabled);
        },
    },
});
