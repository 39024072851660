var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"simpleModal",class:[
        'irisv-simple-modal',
        _vm.isVisible ? 'irisv-simple-modal--visible' : ''
    ],attrs:{"id":_vm.identifier_,"role":"dialog","aria-labelledby":_vm.identifier_+'_title',"aria-describedby":_vm.identifier_+'_content',"aria-hidden":!_vm.isVisible}},[_c('div',{ref:"scrim",staticClass:"irisv-simple-modal__scrim",attrs:{"tabindex":"-1"},on:{"click":_vm.scrimClick}}),_c('transition',{attrs:{"name":"irisv-simple-modal__transition"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isVisible),expression:"isVisible"}],staticClass:"irisv-simple-modal__container",on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.escKeyHandler.apply(null, arguments)}}},[(_vm.iconProps.name && _vm.iconProps.name !== '')?_c('iris-icon',_vm._b({staticClass:"irisv-simple-modal__container__icon",attrs:{"size":"md","role":"img","is-aria-hidden":true}},'iris-icon',_vm.iconProps,false)):_vm._e(),_c('div',{ref:"title",staticClass:"irisv-simple-modal__container__title font-small-heading",attrs:{"id":_vm.identifier_+'_title',"tabindex":"0"},on:{"keydown":_vm.titleKeyHandler}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{ref:"slotContainer",staticClass:"irisv-simple-modal__container__content",attrs:{"id":_vm.identifier_+'_content',"tabindex":"0"}},[_c('div',{ref:"topBuffer"}),_vm._t("default"),_c('div',{ref:"bottomBuffer"})],2),_c('div',{staticClass:"irisv-simple-modal__container__button-container"},[(_vm.secondaryButtonText && _vm.secondaryButtonText !== '')?_c('span',{staticClass:"irisv-simple-modal__container__button-container__secondary pad-right--tiny"},[_c('iris-button',{attrs:{"kind":"mediumEmphasis","full-width":true,"text":_vm.secondaryButtonText},on:{"button-click":_vm.secondaryButtonClick}})],1):_vm._e(),_c('span',{class:[
                        'irisv-simple-modal__container__button-container__primary',
                        (_vm.secondaryButtonText && _vm.secondaryButtonText !== '') ? 'pad-left--tiny' : 'irisv-simple-modal__container__button-container__primary--single'
                    ]},[_c('iris-button',{ref:"primaryButton",attrs:{"kind":"highEmphasis","full-width":(_vm.secondaryButtonText && _vm.secondaryButtonText !== '') ? true : false,"text":_vm.primaryButtonText},on:{"button-click":_vm.primaryButtonClick}})],1)]),_c('div',{attrs:{"tabindex":"0"},on:{"focus":_vm.trapFocusEnd}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }