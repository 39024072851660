
import Vue from 'vue';
import IrisDropdownBottomSlot from './_bottomSlot.vue';
import { IrisDropdownTestHook } from './IrisDropdown.testHooks';

export default Vue.extend({
    name: 'IrisDropdownMobileContainer',
    components: { IrisDropdownBottomSlot },
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        showListDividers: {
            type: Boolean,
            default: true,
        },
        hasFilter: {
            type: Boolean,
            default: false,
        },
        showMobileListTitle: {
            type: Boolean,
            default: true,
        },
        mobileListTitleText: String,
    },
    data() {
        return {
            touchStartPosition: 0 as number,
            testHooks: IrisDropdownTestHook,
        };
    },
    methods: {
        touchStart(event: TouchEvent) {
            this.touchStartPosition = event.touches[0].clientY; // Record where the user touched the screen at the start of the interaction
        },
        touchMove(event: TouchEvent) {
            const menu = this.$refs.menu as HTMLElement; // Get a reference to the menu
            const clientY = event.targetTouches[0].clientY; // Get the current touch position

            if (this.hasFilter) {
                if (clientY > this.touchStartPosition + 24) { // If the the current touch position is lower than the starting touch position
                    menu.style.setProperty('height', `${(window.outerHeight - clientY - 24)}px`); // Set the height to the lower position
                } else { // If the current touch position is higher than the start position
                    menu.style.setProperty('height', 'calc(100% - 24px)'); // Set the height to the original value
                }
            } else {
                if (clientY > (this.touchStartPosition + 24)) { // If the the current touch position is lower than the starting touch position
                    menu.style.setProperty('max-height', `${(window.outerHeight - clientY - 24)}px`); // Set the max-height to the lower position
                } else { // If the current touch position is higher than the start position
                    menu.style.setProperty('max-height', 'calc(100% - 24px)'); // Set the max-height to the original value
                }
            }
        },
        touchEnd(event: TouchEvent) {
            if (event.changedTouches[0].clientY > this.touchStartPosition) { // If the the ending touch position is lower than the starting touch position
                this.$emit('list-request-to-close');
            }
        },
    },
    beforeDestroy() {
        (this.$refs.mobileContainer as HTMLElement).remove();
    },
    mounted() {
        document.body.appendChild(this.$refs.mobileContainer as HTMLElement);
    },
    watch: {
        isOpen(val: boolean) {
            if (val) { // Open
                // Clean up any previous menu height styling before showing the menu again
                const menu = this.$refs.menu as HTMLElement; // Get a reference to the menu
                menu.style.removeProperty('height');
                menu.style.removeProperty('max-height');

                this.touchStartPosition = 0;
            }
        },
    },
});
