
import Vue from 'vue';
import IrisIcon from './IrisIcon.vue';
import IrisButton from './IrisButton.vue';
import { generateUniqueId } from '@/utils';

/**
 * An account component displays essential account information such as name, account number and balance.
 */
export default Vue.extend({
    name: 'IrisSimpleModal',
    components: {
        IrisIcon,
        IrisButton,
    },
    props: {
        /**
         * Sets the id (HTML global attribute) for the component. If an id is not provided, one will be generated automatically.
         */
        elementId: String,
        /**
         * An object containing the key value pairs used to define the Iris Icon at the top of the modal window. Note, only the properties listed below should be used for this implementation of iris-icon.<br /><br />
         * <strong>Keys are:</strong><br /><br />
         * name: Sets the icon name.<br /><br />
         * color: Sets the icon color to overwrite the base color from icon.<br />
         */
        iconProps: {
            type: Object,
            default: () => ({}),
        },
        /**
         * The title to appear at the top of the modal window.
         */
        title: String,
        /**
         * The text to display in the high emphasis iris button.
         */
        primaryButtonText: {
            type: String,
            required: true,
        },
        /**
         * The text to display in the optional medium emphasis iris button.
         */
        secondaryButtonText: String,
        /**
         * Toggles the display of the modal window.
         */
        modalToggle: {
            type: Boolean,
            default: false,
        },
        /**
         * When the user clicks on the scrim, the modal will be closed.
         */
        modalCloseFromScrim: {
            type: Boolean,
            default: true,
        },
        /**
         * Sets default accessibility on modal closing to put focus on the trigger element. Turn off to set up different focus logic.
         */
        autoFocusOnClose: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            identifier_: this.elementId || generateUniqueId('irisv_simple_modal') as string,
            isVisible: false as boolean,
            modalAnimationDuration: getComputedStyle(document.documentElement).getPropertyValue('--motionTimeFast').trim().replace(/\D/g, '') as any,
            previousActiveElement: '',
            debounce: null as any,
            viewportHeight: 0,
        };
    },
    methods: {
        scrimClick(event: Event) {
            if (this.modalCloseFromScrim) {
                /**
                 * Emitted when the scrim is clicked.
                 */
                this.$emit('scrim-click', event);
                this.isVisible = false;
            } else {
                (this.$refs.title as HTMLElement).focus();
            }
        },
        escKeyHandler(keyboardEvent: KeyboardEvent) {
            if (keyboardEvent.code === 'Escape') {
                keyboardEvent.preventDefault();
                keyboardEvent.stopPropagation();
                this.scrimClick(keyboardEvent);
            }
        },
        titleKeyHandler(keyboardEvent: KeyboardEvent) {
            if (keyboardEvent.shiftKey && keyboardEvent.code === 'Tab') {
                keyboardEvent.preventDefault();
                ((this.$refs.primaryButton as Vue).$el as HTMLElement).focus();
            }
        },
        primaryButtonClick(event: Event) {
            /**
             * Emitted when the primary button is clicked.
             */
            this.$emit('primary-button-click', event);
            this.isVisible = false;
        },
        secondaryButtonClick(event: Event) {
            /**
             * Emitted when the secondary button is clicked.
             */
            this.$emit('secondary-button-click', event);
            this.isVisible = false;
        },
        trapFocusEnd() {
            (this.$refs.title as HTMLElement).focus();
        },
        resizeDebounce() {
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.calculateModalMaxHeight(); // Recalculate the max height for the modal after the window resize is done
            }, 500);
        },
        calculateModalMaxHeight() {
            const simpleModal = this.$refs.simpleModal as HTMLElement;
            const scrim = this.$refs.scrim as HTMLElement;

            this.viewportHeight = parseInt(window.getComputedStyle(scrim).getPropertyValue('height'), 10);

            if (this.viewportHeight > 648) { // If window can fit the tallest modal plus margins
                simpleModal.style.setProperty('--simpleModalMaxHeight', '600px');
            } else { // Window is shorter
                simpleModal.style.setProperty('--simpleModalMaxHeight', (this.viewportHeight - 48) + 'px');
            }
        },
    },
    mounted() {
        const slotContainer = this.$refs.slotContainer as HTMLElement; // the <div> that holds the slot
        const topBuffer = this.$refs.topBuffer as HTMLElement; // This is the buffer that we will watch as it intersects with the top of the slot container
        const bottomBuffer = this.$refs.bottomBuffer as HTMLElement; // This is the buffer that we will watch as it intersects with the bottom of the slot container

        // Code to add a top or bottom border to the slot container if the content is scrolled.
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 1,
        };
        const topCallback = (entries: any) => {
            entries.forEach((entry: any) => {
                slotContainer.classList.toggle('irisv-simple-modal__container__content--top-border', !entry.isIntersecting);
            });
        };

        const topObserver = new IntersectionObserver(topCallback, options);
        topObserver.observe(topBuffer);

        const bottomCallback = (entries: any) => {
            entries.forEach((entry: any) => {
                slotContainer.classList.toggle('irisv-simple-modal__container__content--bottom-border', !entry.isIntersecting);
            });
        };

        const bottomObserver = new IntersectionObserver(bottomCallback, options);
        bottomObserver.observe(bottomBuffer);

        window.addEventListener('resize', this.resizeDebounce); // Attach listener for window resize events
    },
    destroyed() {
        const topBuffer = this.$refs.topBuffer as HTMLElement;
        const bottomBuffer = this.$refs.bottomBuffer as HTMLElement;

        if ((Window as any).IntersectionObserver) {
            if (topBuffer) {
                (this as any).observer.unobserve(topBuffer);
            }
            if (bottomBuffer) {
                (this as any).observer.unobserve(bottomBuffer);
            }
        }

        window.removeEventListener('resize', this.resizeDebounce);

        document.body.classList.remove('irisv-simple-modal--body');
    },
    watch: {
        isVisible(val) {
            if (val) {
                const title = this.$refs.title as HTMLElement;
                const slotContainer = this.$refs.slotContainer as HTMLElement;

                document.body.classList.add('irisv-simple-modal--body'); // Adding this class to the body prevents scrolling while the modal is displayed

                this.$nextTick(() => {
                    this.calculateModalMaxHeight(); // Recalculate the max height for the modal on open
                    slotContainer.scrollTop = 0; // Always scroll to the top on open
                });

                if (this.autoFocusOnClose) {
                    const activeEl = document.activeElement;
                    if (activeEl) {
                        activeEl.setAttribute('data-simple-modal-trigger', this.identifier_); // Put an identifier on the modal trigger so we can find it and set focus to it when the modal is closed
                        this.previousActiveElement = `data-simple-modal-trigger=${this.identifier_}`; // Store a reference to this trigger for later
                    }
                }

                setTimeout(() => {
                    title.focus(); // Put focus on the title once the animation is complete
                }, this.modalAnimationDuration);

                /**
                 * Emitted when the modal is opened.
                 */
                this.$emit('modal-opened');
            } else {
                const that = this; // Preserve reference for use inside timeout
                setTimeout(() => {
                    if (that.previousActiveElement) { // Check if there was a previously active element before the modal was displayed
                        const elToReturnFocus = document.querySelectorAll(`[${that.previousActiveElement}]`)[0] as HTMLElement;

                        if (elToReturnFocus) { // Check if that element still exists
                            elToReturnFocus.focus(); // Set focus
                            elToReturnFocus.removeAttribute('data-simple-modal-trigger'); // Remove the identifier from the modal trigger
                            that.previousActiveElement = ''; // Clear the variable
                        }
                    }

                    document.body.classList.remove('irisv-simple-modal--body'); // Restore ability to scroll the body now that the modal is no longer displayed
                }, this.modalAnimationDuration);

                /**
                 * Emitted when the modal is closed.
                 */
                this.$emit('modal-closed');
            }

            /**
             * Emitted when opening/closing the modal internally is needed, and updates the modalToggle prop.<br /><br />
             * The modalToggle set on the component element must use the "sync" feature for this to work.<br /><br />
             * ex: :modalToggle.sync="myDataVarToControlThis"<br /><br />
             * No direct reaction to the event is required.
             */
            this.$emit('update:modalToggle', this.isVisible);
        },
        modalToggle(val) {
            this.isVisible = val; // Update the isVisible data variable with the value from this prop. The watcher for isVisible will catch this activity and emit the event to make sync work for the prop.
        },
    },
});
