
import Vue from 'vue';
import { IrisDropdownTestHook } from './IrisDropdown.testHooks';

export default Vue.extend({
    name: 'IrisDropdownBottomSlot',
    data() {
        return {
            testHooks: IrisDropdownTestHook,
        };
    },
});
