
import Vue from 'vue';
import IrisTextfieldContainer from './IrisTextfieldContainer.vue';
import { textfieldMixin } from './../mixins/textfieldMixin';

export default Vue.extend({
    name: 'IrisTextfieldTelephone',
    model: {
        event: 'textfield-input',
    },
    components: {IrisTextfieldContainer},
    mixins: [textfieldMixin],
    props: {
        /**
         * Sets the aria-label for the clearable icon.
         */
        clearIconAriaLabel: {
            type: String,
            default: 'Delete text',
        },
        /**
         * Sets the aria-label for the trailing icon. We have included the icon's name as a fallback, but please update to something descriptive for screen reader user.
         */
        trailingIconAriaLabel: String,
        /**
         * Displays an icon to clear text from textfield. The clearable icon only appears when the textfield contains a value and is in focus.
         */
        isClearable: {
            type: Boolean,
            default: true,
        },
        /**
         * Overrides the default internal error messaging displayed when a textfield of type 'tel' has a value that does not match the textfield component's expected internal telephone pattern.
         * Ex: (A user enters '5-5264' into the textfield). This prop is typically used in the form of a sitekey for translation purposes.
         */
        telephonePatternMismatchMessage: {
            type: String,
            default: 'Invalid telephone format',
        },
        /**
         * Sets the trailing icon name. Ex: 'info'.
         */
        trailingIconName: String,
        /**
         * Sets the validation behavior to check after user input in addition to after blur.
         */
        validateOnInput: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return ({
            textfieldType: 'tel' as string,
            isFormatting: false as boolean,
            unformattedValue_: '' as string,
        });
    },
    computed: {
        computedValue: { // This will be called whenever computedValue is changed - either from textfieldMixin or v-model
            get(): string {
                return this.internalValue_;
            },
            set(value: string) {
                this.internalValue_ = value;

                if (this.isFormatting) {
                    this.isFormatting = false;
                } else {
                    /**
                     * Emitted when component receives input.
                     */
                    this.$emit('textfield-input', value);

                    if (this.validateOnInput) {
                        this.callToValidateHandler(); // textfieldMixin function
                    }
                }
            },
        },
        listeners(): object {
            return {
                ...this.$listeners,
                'input': this.onInput,
                'blur': [this.onBlur, this.formatInputValue],
                'change': [this.onChange, this.validate],
                'focus': [this.onFocus, this.showUnformattedValue],
                'keypress.enter': this.onChange,
            };
        },
    },
    methods: {
        clearText(): void {
            this.computedValue = '';
            this.unformattedValue_ = '';

            this.$emit('textfield-change', this.computedValue);
            (this.$refs.textfield as HTMLElement).focus();

            /**
             * Emitted when the clearable icon is clicked.
             */
            this.$emit('textfield-clear');
        },
        telephoneFormatter(value: string): string {
            const cleaned = ('' + value).replace(/\D/g, '');
            const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

            if (match && this.isValid) {
                return '(' + match[1] + ') ' + match[2] + '-' + match[3];
            }
            return value;

        },
        formatInputValue(): void {
            if (!this.computedValue) {
                this.unformattedValue_ = ''; // if textfield value has been deleted then clear out unformattedValue
                return;
            }

            // wait for the next update cycle so we can get the results of the validation on blur
            this.$nextTick(() => {
                this.isFormatting = true;
                this.unformattedValue_ = this.computedValue;
                this.computedValue = this.telephoneFormatter(this.computedValue); // Show the formatted value of the textfield on blur
            });
        },
        showUnformattedValue(): void {
            if (this.computedValue && this.unformattedValue_) {
                this.isFormatting = true;
                this.computedValue = this.unformattedValue_;
            }
        },
    },
    watch: {
        value(newValue) {
            if (newValue !== this.computedValue) { // This helps us determine that the change came from v-model, not the user typing
                this.computedValue = newValue;
                this.unformattedValue_ = this.computedValue;
                this.$emit('textfield-change', this.computedValue);
                this.formatInputValue();
                this.validate(); // textfieldMixin function
            }
        },
    },
});
