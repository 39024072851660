/*
 * Takes an input and returns it as formatted currency
 * Uses on Intl.NumberFormat
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
*/

export function _currencyFormatter(value: string, currency: string = 'USD', currencyDisplay: string = 'symbol', locale: string = navigator.language, hasImpliedDecimal: boolean = true, formatToParts: boolean = false) {
    let isNegative: boolean = false;
    let filtered: string = '';
    let filteredAsNumber: number = 0;

    // this test is here for iOS as it doesn't support 'narrowSymbol' as an option
    // so if the NumberFormat returns an error we test for that particular error and change currencyDisplay
    try {
        new Intl.NumberFormat(locale, { style: 'currency', currency, currencyDisplay }).format(123);
    } catch (error) {
        if (error.toString() === 'RangeError: currencyDisplay must be either "code", "symbol", or "name"') {
            currencyDisplay = 'symbol';
        }
    }

    if (typeof(value) !== 'string') {
        console.error('Error: currencyFormatter requires an input value of type \'string\''); // tslint:disable-line
        return '';
    }

    if (value.charAt(0) === '-') { // If the first character is a dash (for a negative number)
        isNegative = true; // Set flag for later
    }

    if (value.charAt(0) === '.') { // If the first character is a dot (for a decimal)
        if (value.length === 1) { // If a period was the only character passed in
            return '';
        } else {
            value = `0${value}`; // This will help make it into a value that can be converted
        }
    }

    filtered = value.replace(/[^0-9\.]/g, ''); // This will remove eveything except numbers and periods

    if (filtered === '') { // If the string is blank, we can quit here
        return '';
    }

    if (hasImpliedDecimal) {
        filtered = filtered.replace(/\D/g, '');

        if (Number(filtered) !== 0) {
            if (filtered.length === 1) { // If the string is only 1 character long
                filtered = `0.0${filtered}`; // The single character should be the furthest right character
            } else if (filtered.length === 2) { // If the string is 2 characters long
                filtered = `0.${filtered}`; // The 2 characters should be the furthest right characters
            } else if (filtered.length > 2) { // If the string is more than 2 characters
                filtered = filtered.slice(0, (filtered.length - 2)) + '.' + filtered.slice(filtered.length - 2);
            }
        }
    }

    if (isNegative) { // If the value was initially negative, let's add it back here
        filtered = `-${filtered}`;
    }

    filteredAsNumber = Number(filtered.replace(/[^0-9\.\-]/g, '')); // Recast the filtered value as a number

    if (isNaN(filteredAsNumber)) { // Prevent converting and returning 'NaN'
        console.error('Error: currencyFormatter: invalid input resulted in NaN'); // tslint:disable-line
        return '';
    }

    try { // Convert the number into the desired output
        if (!formatToParts) {
            return new Intl.NumberFormat(locale, { style: 'currency', currency, currencyDisplay }).format(filteredAsNumber);
        } else {
            return new Intl.NumberFormat(locale, { style: 'currency', currency, currencyDisplay }).formatToParts(filteredAsNumber) as object[];
        }
    } catch (error) {
        console.error(error); // tslint:disable-line
        return '';
    }
}