
import Vue from 'vue';

/**
 * A Cloaked Check Box is used for a list of options where a user may select multiple or none at all. This component is similar to the Check Box but there is no UI or affordance present when an option is not selected.
 */
export default Vue.extend({
    name: 'IrisCloakedCheckmark',
    props: {
        /**
         * The label text of the cloaked checkmark.
         */
        label: {
            type: String,
            default: '',
        },
        /**
         * The state of the cloaked checkmark. Checked will be true, unchecked will be false.
         */
        isChecked: {
            type: Boolean,
            default: false,
        },
        /**
         * A value that can be used independently of the label or checked state.
         */
        value: {
            type: String,
            default: '',
        },
        /**
         * Sets the cloaked checkmark control to behave as radio buttons instead of checkboxes. By default, the
         * control allows multiple selection of items.
         */
        isSingleSelection: {
            type: Boolean,
            default: false,
        },
        /**
         * When using the control in single selection mode, this property identifies all items you wish to be
         * considered as part of the group. Required when in single selection mode.
         */
        name: {
            type: String,
            default: '',
        },
        /**
         * Sets the checkmark to appear on the far right side of the control. By default, the checkmark appears near
         * the end of the label text.
         */
        hasRightAlignCheckmark: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets the appearance of the cloaked checkmark to a dark color scheme when an item is selected. By default,
         * lighter colors are used.
         */
        isDarkMode: {
            type: Boolean,
            default: false,
        },
        /**
         * Disables the cloaked checkmark and shows the disabled state.
         */
        isDisabled: {
            type: Boolean,
            default: false,
        },
        /**
         * Shows the skeleton loader as a placeholder option. This may be useful when page content is loading in
         * the background.
         */
        showSkeleton: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onChange() {
            const input = (this.$refs.input as HTMLInputElement);
            /**
             * Emitted when the cloaked checkmark is clicked. Will return an object containing the checked state
             * and value.
             */
            this.$emit('cloaked-checkmark-change', { checked: input.checked, value: input.value });
        },
    },
});
