
import Vue from 'vue';
import IrisSelectableTile from './IrisSelectableTile.vue';
import { deprecationMixin } from './../mixins/deprecationMixin';

/**
 * The scrollable Selectable Tile component is a container of a group of Selectable Tiles that allows dragging to scroll left and right.
 */

export default Vue.extend({
    name: 'IrisSelectableTileContainer',
    components: {
        IrisSelectableTile,
    },
    mixins: [deprecationMixin],
    props: {
        /**
         * Sets the aria-label for the component. Please include any needed punctuation in the value.
         */
        ariaLabel: {
            type: String,
            default: '',
            required: true,
        },
        /**
         * Set if the tile kind are radio buttons.
         */
        tileKindRadio: {
            type: Boolean,
            default: false,
        },
        /**
         * Prop data to build the group of tiles components. The data should be an object of objects where each key represents a tiles and its props to be passed along. The key for each tiles is used for internal id purposes.
         */
        selectableTilesData: {
            type: Object,
            required: true,
        },
        /**
         * Prop to control the filtering of container event data based on tileKindRadio. Data representing the group of tiles can be filtered by "all", "true", or "false".
         * <br /><br />
         * "all" provides an array of objects describing the current state of all tiles in the container.
         * <br /><br />
         * "true" provides an array of names of tiles where "pressed" is true for toggle tiles and "checked" is true for checkbox tiles.
         * <br /><br />
         * "false" provides an array of names of tiles where "pressed" is false for toggle tiles and "checked" is false for checkbox tiles.
         */
        eventDataFilter: {
            type: String,
            default: 'all',
            validator: (value: string) => {
                return ['all', 'true', 'false'].includes(value.toLowerCase());
            },
        },
    },

    data() {
        return {
            activeTile: 0 as number,
            dragDownTimer: 0 as number,
            duration: '250ms' as string,
            easing: 'ease-out' as string,
            previousItemX: 0 as number,
            sliding: false as boolean,
        };
    },

    methods: {
        hasKey(obj: object, key: keyof any): key is keyof object {
            // this method exists solely so we can use square bracket notation on $refs
            return obj ? key in obj : false;
        },
        onUpdate(data: {name: string, checked: boolean, value: string}) {
            if (this.tileKindRadio) {
                // just pass along the event from the tile
                this.$emit('selectable-tile-change', data);

                // same data as from the tile
                this.$emit('tiles-container-change', data);
            } else {
                // for toggle and checkbox tiles we want to emit current state of all the tiles in the container
                const containedTiles = [] as object[];

                // loop through the tiles to get their current state
                (this.$refs.tiles as any[]).forEach((tile) => {
                    if (this.eventDataFilter === 'all') {
                        containedTiles.push({ name: tile.$el.children[0].name, checked: tile.$el.children[0].checked, value: tile.$el.children[0].value });
                    } else if (this.eventDataFilter === tile.$el.children[0].checked.toString()) {
                        containedTiles.push(tile.$el.children[0].name);
                    }

                });

                // just pass along the event from the tile
                this.$emit('selectable-tile-change', data);

                // different data than from tile
                this.$emit('tiles-container-change', containedTiles);
            }
        },
        leftRightKeyup(e: KeyboardEvent) {
            const tiles = (this.$refs.tiles as Vue[]);
            const tilesLength = tiles.length;
            let nextActiveTile = e.code === 'ArrowRight' ? this.activeTile + 1 : this.activeTile - 1;

            // lock down nextActive to the range of actual number of tiles in container
            nextActiveTile = nextActiveTile < 0 ? 0 : nextActiveTile;
            nextActiveTile = nextActiveTile > tilesLength - 1 ? tilesLength - 1 : nextActiveTile;

            if (this.hasKey(tiles, this.activeTile)) {
                const currentTile = tiles[this.activeTile] as any;

                // set the previous tile out of the tabindex
                currentTile.$refs.input.setAttribute('tabindex', '-1');
            }

            if (this.hasKey(tiles, nextActiveTile)) {
                const currentTile = tiles[nextActiveTile] as any;
                const slider = this.$refs.slider as HTMLUListElement;

                // set new tile with tabindex of 0, then focus it
                currentTile.$refs.input.setAttribute('tabindex', '0');
                currentTile.$refs.input.focus();

                // move slider in relation to new active tile
                slider.style.cssText = `transition: left ${this.duration} ${this.easing}; left: ${-currentTile.$el.offsetLeft}px`;
            }

            this.activeTile = nextActiveTile;
        },
        dragDown() {
            const sliderWrapper = this.$refs.sliderWrapper as HTMLElement;
            const slider = this.$refs.slider as HTMLUListElement;

            // if group is static then don't allow dragging
            // if sliderWrapper is larger than the slider don't allow dragging
            if (sliderWrapper.offsetWidth <= slider.offsetWidth) {
                // timer is to allow for clicking on tile without triggering drag
                this.dragDownTimer = window.setTimeout(() => {
                    this.sliding = true;

                    document.addEventListener('mousemove', this.dragMove);
                    document.addEventListener('touchmove', this.dragMove);
                }, 300);

                document.addEventListener('mouseup', this.dragUp);
                document.addEventListener('touchend', this.dragUp);
            }
        },
        dragMoveHandler(e: MouseEvent | TouchEvent) {
            const slider = this.$refs.slider as HTMLUListElement;
            const sliderX: number = slider.style.left ? parseInt(slider.style.left, 10) : 0;
            const clientX: number = (e as MouseEvent).type === 'mousemove' ? (e as MouseEvent).clientX : Math.floor((e as TouchEvent).touches[0].clientX);

            // we need to know the previous x coordinate of the slider
            // this is so we can compare current and past coordinates to know the direction of the drag
            // but the first time there is no past coordinate so use current coordinate
            // this does produce a lag for first tick, but it's one tick so we can let that one go
            this.previousItemX = this.previousItemX ? this.previousItemX : clientX;

            // use the previous coordinate to shift slider element's left property the correct amount, positive or negative
            // since we're shifting by pixels don't allow a transition as that might mess things up
            slider.style.cssText = `transition: none; left: ${sliderX + (clientX - this.previousItemX)}px; width: auto;`;

            // now save current coordinate to be the next past coordinate for next tick
            this.previousItemX = clientX;
        },
        dragMove(e: MouseEvent | TouchEvent) {
            window.requestAnimationFrame(() => { this.dragMoveHandler(e); });
        },
        dragUp() {
            const sliderWrapper = this.$refs.sliderWrapper as HTMLElement;
            const slider = this.$refs.slider as HTMLUListElement;
            const sliderLeft: number = parseInt(slider.style.left, 10);
            const sliderScrollWidth: number = slider.scrollWidth;

            // clear the timer from the dragDown method
            window.clearTimeout(this.dragDownTimer);

            // this timer is to restore things after a small delay
            window.setTimeout(() => {
                this.sliding = false;

                // if the first tile is to the right of the left side of the group, slide back to the left
                // if the last tile is to the left of the right side of the group, slide back to the right
                // this prevents empty space between the first/last tiles and their relevant sides
                if (sliderLeft > 0) {
                    slider.style.cssText = `transition: left ${this.duration} ${this.easing}; left: 0;`;
                } else if (-sliderLeft > sliderScrollWidth - sliderWrapper.offsetWidth) {
                    slider.style.cssText = `transition: left ${this.duration} ${this.easing}; left: ${-sliderScrollWidth + sliderWrapper.offsetWidth}px;`;
                }

                // clear out relevant events
                document.removeEventListener('mousemove', this.dragMove);
                document.removeEventListener('touchmove', this.dragMove);
                document.removeEventListener('mouseup', this.dragUp);
                document.removeEventListener('touchend', this.dragUp);
            }, 150);

            window.setTimeout(() => {
                // this reset needs a bit more time than the timer above provides
                // don't know why, it just does...
                this.previousItemX = 0;

            }, parseInt(this.duration, 10));
        },
    },

    watch: {
        selectableTilesData() {
            // if selectableTilesData prop changes reset slider positioning, just in case
            (this.$refs.slider as HTMLUListElement).removeAttribute('style');
        },
    },
});
