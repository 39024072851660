
import Vue from 'vue';

export default Vue.extend({
    name: 'IrisIcon',
    props: {
        /**
         * Displays loading icon insted of standard icon
         */
        isLoading: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets the icon name
         */
        name: String,
        /**
         * Sets the icon size. Valid values are 'sm' and 'md'.
         */
        size: {
            type: String,
            default: 'md',
            validator: (value: string) => {
                return ['sm', 'md'].includes(value.toLowerCase());
            },
        },
        /**
         * Sets the icon color to overwrite the base color from icon.
         */
        color: {
            type: String,
        },
        /**
         * (Deprecated: Use isAriaHidden instead!) Sets the icon to have an aria-hidden of true in order to hide it from screen readers. This prop should be used only when the icon is purely presentational and there is other text present to decribe the icon's intent.
         */
        isIconDecorative: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets the icon to have an aria-hidden of true in order to hide it from screen readers. This prop should be used only when the icon is purely presentational and there is other text present to decribe the icon's intent.
         */
        isAriaHidden: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets the aria label for the icon
         */
        ariaLabel: {
            type: String,
        },
    },
});
