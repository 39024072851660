
import Vue from 'vue';
import { generateUniqueId } from '@/utils';
import { deprecationMixin } from './../mixins/deprecationMixin';

/**
 * A display input field functions to guide a user as they input alphanumeric content during an experience.
 */
export default Vue.extend({
    name: 'IrisDisplayInputFieldSpecified',
    inheritAttrs: false,
    model: {
        event: 'display-input-field-input',
    },
    mixins: [deprecationMixin],
    props: {
        /**
         * Sets the id (HTML global attribute) for the component. If an id is not provided, one will be generated automatically.
         */
        elementId: String,
        /**
         * Sets the actionable text
         */
        actiontext: {
            type: String,
            default: '',
        },
        /**
         * Sets the alignment of the input display, action text, error text, and helper text
         */
        align: {
            type: String,
            default: 'center',
            validator: (value: string) => {
                return ['left', 'center', 'right'].includes(value.toLowerCase());
            },
        },
        /**
         * Sets the error state.<br /><br />
         * It is suggested to set hasError to 'false' when event 'display-input-field-focus' is emitted.
         */
        hasError: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets the error message
         */
        errortext: {
            type: String,
            default: '',
        },
        /**
         * Sets the helper text
         */
        helpertext: {
            type: String,
            default: '',
        },
        /**
         * (Deprecated: Use elementId instead!) The textfield id.
         */
        id: String,
        /**
         * Marks the input as either an optional or required field
         */
        isOptional: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets the popup keyboard type on supported mobile devices
         */
        keyboardType: {
            type: String,
            default: 'text',
            validator: (value: string) => {
                return ['text', 'decimal', 'numeric', 'tel', 'search', 'email', 'url'].includes(value.toLowerCase());
            },
        },
        /**
         * Sets the minimum length of the input, should not be set below 1.
         */
        minlength: {
            type: Number,
            default: 1,
            validator: (value: number) => {
                return value > 0 && value < 9;
            },
        },
        /**
         * Sets the maximum length of the input, should not be set above 8.
         */
        maxlength: {
            type: Number,
            default: 8,
            validator: (value: number) => {
                return value > 0 && value < 9;
            },
        },
        /**
         * The input value
         */
        value: String,
        /**
         * An object containing the strings used in the select dropdown.<br /><br />
         * <strong>Keys are:</strong><br /><br />
         * errortext: Sets the text that displays when hasError is true.<br /><br />
         * helpertext: Sets the helperText for the input.<br /><br />
         * actiontext: Sets the actiontext that appears in the field.<br /><br />
         */
        strings: {
            type: Object,
            default: () => ({}),
        },
        /**
         * Sets the spellcheck global attribute for the HTML input.
         */
        allowBrowserSpellCheck: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            identifier_: this.elementId || this.id || generateUniqueId('irisv_display_input_field_specified') as string,
            internalError_: this.hasError,
        };
    },
    computed: {
        mergedStrings(): any {
            return {
                errortext: this.errortext,
                helpertext: this.helpertext,
                actiontext: this.actiontext,
                ...this.strings,
            };
        },
        actionTextIdentifier_(): string {
            return `action_text-${this.identifier_}`;
        },
        errorTextIdentifier_(): string {
            return `error_text-${this.identifier_}`;
        },
        helperTextIdentifier_(): string {
            return `helper_text-${this.identifier_}`;
        },
        showActionText(): boolean {
            return this.value && this.value.length > 0 ? false : true;
        },
    },
    methods: {
        createInitialState() {
            const display = this.$refs.display as HTMLElement;
            let outputString = '';

            // build html string of appropriate number of empty display items based on maxlength of input
            // if there's a value/v-model use that to fill the display items
            for (let i = 0; i < this.maxlength; i++) {
                if (!this.value || this.value.length === 0) {
                    outputString += `<span class="irisv-display-input-field-specified__display-item"></span>`;
                } else {
                    outputString += `<span class="irisv-display-input-field-specified__display-item">${this.value[i] ? this.value[i] : ''}</span>`;
                }
            }

            // make sure display is empty
            while (display.firstChild) {
                display.firstChild.remove();
            }
            // insert html string into display
            display.insertAdjacentHTML('afterbegin', outputString);
        },
        onBlur(event: Event) {
            /**
             * Emitted when the input blurs.
             */
            this.$emit('display-input-field-blur', event);

            const display = this.$refs.display as HTMLElement;
            const displayItems = display.querySelectorAll('.irisv-display-input-field-specified__display-item') as NodeList;

            displayItems.forEach((item: Node) => {
                const html = item as HTMLElement;

                // if the item has no text then wipe out the inline style css variable
                if (html.innerText.length === 0) {
                    html.style.cssText = '';
                }
            });
        },
        onFocus(event: Event) {
            /**
             * Emitted when the input gets focus.
             */
            this.$emit('display-input-field-focus', event);

            const display = this.$refs.display as HTMLElement;
            const displayItems = display.querySelectorAll('.irisv-display-input-field-specified__display-item') as NodeList;

            displayItems.forEach((item: Node) => {
                const html = item as HTMLElement;

                // if the item has text, has a previous element sibling, and the item's previous element sibling has text:
                // make the item display a focus state by inline style css variable
                if (html.innerText.length === 0 && html.previousElementSibling !== null && (html.previousElementSibling as HTMLElement).innerText.length) {
                    html.style.cssText = 'border-color: rgb(var(--colorFormFieldGray900, var(--colorBrandedAffordanceAccessible)));';
                }
            });
        },
        onInput(event: Event) {
            /**
             * Emitted when the input changes.
             */
            this.$emit('display-input-field-input', (event.target as HTMLInputElement).value);

            this.updateDisplay();
        },
        updateDisplay() {
            const display = this.$refs.display as HTMLElement;
            const displayItems = display.querySelectorAll('.irisv-display-input-field-specified__display-item') as NodeList;

            // clear out each display item to rebuild later
            displayItems.forEach((item: Node, index: number) => {
                // wipe out the text and inline style css variable for all items
                (item as HTMLElement).innerText = '';
                (item as HTMLElement).style.cssText = '';

                if (this.value) {
                    const currentDisplayItem = displayItems[index] as HTMLElement;

                    currentDisplayItem.innerText = this.value[index] || '';
                }
            });
        },
    },
    watch: {
        hasError(newVal) {
            this.internalError_ = newVal;
        },
        value() {
            this.updateDisplay();
        },
    },
    mounted() {
        this.createInitialState();
    },
    updated() {
        this.createInitialState();
    },
});
