var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_vm.tag,{tag:"component",staticClass:"irisv-button",class:{
        [`irisv-button--${_vm.kind}`]: _vm.kind,
        [`irisv-button--${_vm.theme}`]: _vm.theme,
        'irisv-button--skeleton': _vm.showSkeleton,
        'irisv-button--full-width': _vm.fullWidth,
        'irisv-button--loading': _vm.isLoading,
        [`text--${_vm.textCasing}`]: _vm.textCasing,
    },style:({'--shapeBrandedSmall': _vm.borderRadiusOverride}),attrs:{"type":!_vm.href && _vm.type,"href":_vm.href && _vm.href.length > 0 ? _vm.href : null,"target":_vm.href && _vm.target ? _vm.target : null,"rel":_vm.target === '_blank' ? 'noreferrer noopener' : null,"tabindex":_vm.showSkeleton && '-1',"aria-disabled":(!_vm.showSkeleton && _vm.isLoading || _vm.isDisabled) ? 'true': null,"aria-label":!_vm.text ? _vm.ariaLabel : null},on:{"click":_vm.onClick}},[(_vm.iconName && _vm.iconName.length > 0 && _vm.iconPosition === 'left' && _vm.text)?_c('iris-icon',{staticClass:"irisv-button__icon",attrs:{"aria-hidden":"true","name":_vm.iconName}}):_vm._e(),(_vm.text)?_c('span',{ref:"text",staticClass:"irisv-button__text",attrs:{"aria-hidden":_vm.isLoading ? 'true' : null}},[_vm._v(" "+_vm._s(_vm.currentText)+" ")]):_vm._e(),(_vm.iconName && _vm.iconName.length > 0 && _vm.iconPosition === 'right' || !_vm.text || _vm.isLoading)?_c('iris-icon',{class:{
            'irisv-button__icon': !_vm.isLoading,
            'irisv-button__loading-icon': _vm.isLoading,
        },attrs:{"aria-hidden":"true","is-loading":_vm.isLoading,"name":_vm.iconName}}):_vm._e(),(_vm.isLoading)?_c('span',{staticClass:"irisv-button__aria-live sr-only",attrs:{"aria-live":"assertive"}},[_vm._v(" "+_vm._s(_vm.ariaLiveMessage)+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }