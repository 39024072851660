import { render, staticRenderFns } from "./IrisAvatar.vue?vue&type=template&id=07f0176b&"
import script from "./IrisAvatar.vue?vue&type=script&lang=ts&"
export * from "./IrisAvatar.vue?vue&type=script&lang=ts&"
import style0 from "./IrisAvatar.vue?vue&type=style&index=0&id=07f0176b&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports