
import Vue, { ComponentInstance, PropType } from 'vue';
import IrisDropdownDesktopContainer from './_desktopContainer.vue';
import IrisDropdownFilter from './_filter.vue';
import IrisDropdownItem from './_listItem.vue';
import IrisDropdownMobileContainer from './_mobileContainer.vue';
import { isListKind, isPosition } from './predicates';
import { DropdownStrings, MenuItem } from './types';
import { IrisDropdownTestHook } from './IrisDropdown.testHooks';

const getEmptyListItem = (): MenuItem => ({ label: '', value: '' });

export default Vue.extend({
    name: 'IrisDropdown',
    components: {
        IrisDropdownDesktopContainer,
        IrisDropdownMobileContainer,
        IrisDropdownFilter,
        IrisDropdownItem,
    },
    props: {
        /**
         * Defines the aria-label for the `ul` element itself.
         */
        ariaLabel: {
            type: String,
        },
        /**
         * Sets the id (HTML global attribute) for the component. If an id is not provided, one will be generated automatically.
         */
        elementId: String,
        /**
         * If the parent component has an input that can filter the dropdown items, it's v-model should be linked here. It will then be passed into this component to filter the dropdown items.
         */
        filter: String,
        /**
         * Toggles the display of the dropdown.
         */
        isOpen: {
            type: Boolean,
            default: false,
        },
        /**
         * Specifies the list type. The value could be `account`, `action`, `list`, or `navigation`.
         * The default value is `list`.
         */
        kind: {
            default: 'list',
            type: String,
            validator: isListKind,
        },
        /**
         * This is an array of objects - each object is one list item. Either an array of standard list objects or account list objects can be passed here.
         */
        items: {
            default: () => [],
            type: Array as () => MenuItem[],
            required: true,
        },
        /**
         * When true, the dropdown will expand to match the width of the trigger
         */
        matchTriggerWidth: {
            type: Boolean,
            default: false,
        },
        /**
         * Defines where the dropdown will appear in relation to its trigger. This style only applies
         * to desktop. The `matchTriggerWidth` prop overrides this one. Possible values are `left`,
         * `right`, and `center`. The default value is `left`.
         */
        positionFromTrigger: {
            default: 'left',
            type: String,
            validator: isPosition,
        },
        /**
         * Sets the initial selected value of the list
         */
        selected: {
            type: Array as () => string[],
            default: () => [''],
        },
        /**
         * When enabled, checkboxes will show.
         * The default value is `false`.
         */
        showCheckboxes: {
            default: false,
            type: Boolean,
        },
        /**
         * When enabled, the list is searchable.
         * The default value is `false`.
         */
        showFilter: {
            default: false,
            type: Boolean,
        },
        /**
         * When enabled, the list dividers will show.
         * The default value is `false`.
         */
        showListDividers: {
            default: false,
            type: Boolean,
        },
        /**
         * When enabled, the mobile list title will show.
         * The default value is `false`.
         */
        showMobileListTitle: {
            default: false,
            type: Boolean,
        },
        /**
         * An object containing the strings used in the Iris Dropdown.<br /><br />
         * <strong>Keys are:</strong><br /><br />
         * filterPlaceholderText: The placeholder for the filter input.<br /><br />
         * noResultsText: The phrase displayed when there are no results from filtering the list.<br /><br />
         * mobileListTitleText: Sets the label to display at the top of the mobile style list.
         */
        strings: {
            type: Object as PropType<DropdownStrings>,
            default: () => ({} as DropdownStrings),
        },
        /**
         * An arrow function that returns a vue style reference to the trigger.
         */
        triggerRefFromParent: {
            type: Function,
            required: true,
        },
        /**
         * An arrow function that returns a vue style reference to the element that needs to stay in focus.
         */
        focusRefFromParent: {
            type: Function,
            required: false,
        },
        /**
         * When enabled, the filter logic in this component will not be used. Helpful if the filter needs to behave as just a normal input.
         * The default value is `false`.
         */
        ignoreFilter: {
            default: false,
            type: Boolean,
        },
    },
    data() {
        return {
            activeDescendant: '',
            allowBubble: true,
            identifier_: this.elementId,
            isMobile: false,
            menuItemHighlight: -1,
            onMobileDevice: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
            selectedMenuItem: getEmptyListItem(),
            triggerRect: new DOMRect() as DOMRect,
            triggerRef: {} as any,
            focusRef: {} as any,
            testHooks: IrisDropdownTestHook,
        };
    },
    computed: {
        containerType(): string {
            return this.isMobile ? 'mobile' : 'desktop';
        },
        filteredItems(): MenuItem[] {
            if (this.ignoreFilter) { return this.items; }
            if (this.items.length === 0) { return []; }

            if (this.kind === 'list') {
                return this.items.filter((item: MenuItem) => (
                    !this.filter ||
                    (!item.disabled && !!item.label && item.label.toLowerCase().includes(
                        this.filter.toLowerCase(),
                    ))
                ));
            }

            const props = [
                'accountNickName',
                'accountNumberEnding',
                'accountNumberDisplay',
                'availableBalanceAmount',
            ];

            return this.items.filter((item: any) => (
                !this.filter || props.some(
                    (key) => typeof item[key] === 'string' && item[key].toLowerCase().includes(this.filter.toLowerCase()),
                )
            ));
        },
        listHasIcons(): boolean {
            return this.items.some((item) => (!!item.iconName && item.iconName !== ''));
        },
        desktopListStyle(): string {
            const desktopDropdownWidth = this.matchTriggerWidth ? `${this.triggerRect.width}px;` : 'auto';

            return `
                --irisDesktopDropdownMaxHeight: 400px;
                --irisDesktopDropdownWidth: ${desktopDropdownWidth};
            `;
        },
    },
    methods: {
        closeMenu(invokedByEscape = false) {
            if (!invokedByEscape) {
                this.allowBubble = true;
            }

            /**
             * Emitted when closing the list internally is needed, and updates the isOpen prop.<br /><br />
             * The isOpen set on the component element must use the "sync" feature for this to work.<br /><br />
             * ex: :isOpen.sync="myDataVarToControlThis"<br /><br />
             * No direct reaction to the event is required.
             */
            this.$emit('update:isOpen', false);
        },
        preventEscapeBubble(keyboardEvent: KeyboardEvent) {
            const code = keyboardEvent.code;

            if (code === 'Escape' && !this.allowBubble) {
                keyboardEvent.stopPropagation();
                this.allowBubble = true;
            }
        },
        filterKeyHandler(keyboardEvent: KeyboardEvent) {
            const code = keyboardEvent.code;
            const listContainerComponent = this.$refs.listContainerComponent as any;

            if (code === 'Escape') {
                keyboardEvent.stopPropagation();
                keyboardEvent.preventDefault(); // These preventDefaults are here individually instead of at the top to allow the user to type normally in the filter
                this.closeMenu(true); // Escape should always close the menu from the filter
                this.$emit('listbox-key-passthrough', keyboardEvent);
                return;
            }

            if (this.filteredItems.length === 0) { // This could be due to no results found from filtering
                if (code === 'ArrowDown' || code === 'ArrowUp') {
                    keyboardEvent.preventDefault();
                    if (this.$slots.bottomSlotFromParent) { // We know the list is 0 in length, but if the bottom slot is being used...
                        (listContainerComponent.$refs.bottomSlot.$el as HTMLInputElement).focus(); // The bottom slot is the only thing you can go to from the filter
                    }
                } else {
                    this.$emit('listbox-key-passthrough', keyboardEvent);
                }

                this.menuItemHighlight = -1; // Initialize the keyboard focused menu item when there are no results from filtering
                this._removeHighlighting(); // Remove any keyboard highlighting
                return;
            }

            switch (code) {
            case 'ArrowDown':
                keyboardEvent.preventDefault();
                if (this.$slots.bottomSlotFromParent && (this.menuItemHighlight === this.filteredItems.length - 1)) { // If the bottom slot is being used and we are at the end of the list
                    this._removeHighlighting(); // Remove highlighting from list items
                    (listContainerComponent.$refs.bottomSlot.$el as HTMLInputElement).focus(); // Focus on the bottom slot (it is right after the last item in the list)
                } else { // No bottom slot
                    this.menuItemHighlight = this.menuItemHighlight < this.filteredItems.length - 1 ? this.menuItemHighlight += 1 : 0; // Determine if we are going to the next item higher, or going to the beginning
                    this.setMenuItemHighlight(this.menuItemHighlight); // Highlight that determined item
                }
                break;
            case 'ArrowUp':
                keyboardEvent.preventDefault();
                if (this.$slots.bottomSlotFromParent && (this.menuItemHighlight === 0 || this.menuItemHighlight === -1)) { // If the bottom slot is being used and we are at the beginning of the list
                    this._removeHighlighting(); // Remove highlighting from list items
                    (listContainerComponent.$refs.bottomSlot.$el as HTMLInputElement).focus(); // Focus on the bottom slot (it would be the item to select if rolling backwards in the list)

                } else { // No bottom slot
                    this.menuItemHighlight = this.menuItemHighlight > 0 ? this.menuItemHighlight -= 1 : this.filteredItems.length - 1; // Determine if we are going to the next item lower, or going to the end
                    this.setMenuItemHighlight(this.menuItemHighlight); // Highlight that determined item
                }
                break;
            case 'Enter':
                this._findHighlightedAndClick(); // Figure out what item is highlighted by keyboard navigation and "click" it
                this.$emit('listbox-key-passthrough', keyboardEvent);
                break;
            case 'Tab':
                keyboardEvent.preventDefault(); // Just here to stop from tabbing away
                break;
            }
        },
        listboxKeyHandler(keyboardEvent: KeyboardEvent) {
            const listContainerComponent = this.$refs.listContainerComponent as any;
            const numItems = this.filteredItems.length - 1;

            switch (keyboardEvent.code) {
            case 'ArrowDown':
                keyboardEvent.preventDefault(); // These preventDefaults are here individually instead of at the top because textfield dropdown handles some of its own keys (see case 'Enter' and default)

                if (this.$slots.bottomSlotFromParent) { // If the bottom slot is being used
                    if (this.menuItemHighlight === numItems) { // If we are at the end of the list
                        this._removeHighlighting(); // Remove highlighting from list items
                        (listContainerComponent.$refs.bottomSlot.$el as HTMLInputElement).focus(); // Focus on the bottom slot (it is right after the last item in the list)
                        return; // No need to do anything else
                    } // No else here because the logic below handles 'not at the end of the list' and 'no bottom slot'
                }

                this.menuItemHighlight = this.menuItemHighlight < numItems ? this.menuItemHighlight += 1 : 0; // Determine if we are going to the next item higher, or going to the beginning
                this.setMenuItemHighlight(this.menuItemHighlight); // Highlight that determined item
                break;
            case 'ArrowUp':
                keyboardEvent.preventDefault();

                if (this.$slots.bottomSlotFromParent) { // If the bottom slot is being used
                    if (this.menuItemHighlight === 0 || this.menuItemHighlight === -1) { // If we are at the beginning of the list
                        this._removeHighlighting(); // Remove highlighting from list items
                        (listContainerComponent.$refs.bottomSlot.$el as HTMLInputElement).focus(); // Focus on the bottom slot (it would be the item to select if rolling backwards in the list)
                        return; // No need to do anything else
                    } // No else here because the logic below handles 'not at the beginning of the list' and 'no bottom slot'
                }

                this.menuItemHighlight = this.menuItemHighlight > 0 ? this.menuItemHighlight -= 1 : numItems; // Determine if we are going to the next item lower, or going to the end
                this.setMenuItemHighlight(this.menuItemHighlight); // Highlight that determined item
                break;
            case 'Enter':
                if (!!this.focusRefFromParent && this.menuItemHighlight === -1) {
                    this.$emit('listbox-key-passthrough', keyboardEvent);
                } else {
                    keyboardEvent.preventDefault();

                    this._findHighlightedAndClick(); // Figure out what item is highlighted by keyboard navigation and "click" it
                }
                break;
            case 'Escape':
                keyboardEvent.preventDefault();

                keyboardEvent.stopPropagation();
                this.closeMenu(true); // Escape should always close the menu from the list
                break;
            default:
                this.$emit('listbox-key-passthrough', keyboardEvent);
                break;
            }
        },
        setMenuItemHighlight(index: number) {
            this.$nextTick(() => {
                const listbox = this.$refs.listbox as HTMLElement;
                const itemsList = listbox.querySelectorAll<HTMLElement>('.irisv-dropdown__menu-item');
                const itemElem = (listbox.querySelector(`#${this.identifier_}__menu-item-${index}`) as HTMLElement);

                if (itemsList.length > 0) { // Check if there any items in the list before continuing
                    this._removeHighlighting(); // Remove any current keyboard selection

                    itemElem.classList.add('irisv-dropdown__menu-item--keyboard-selected'); // Add keyboard selection to the requested index
                    this.activeDescendant = `${this.identifier_}__menu-item-${index}`; // Set this for aria-activedescendant

                    this._scrollToItem(index); // Scroll the list to the items position
                }
            });
        },
        _removeHighlighting() {
            const items = (this.$refs.listbox as HTMLElement).querySelectorAll<HTMLElement>('.irisv-dropdown__menu-item');

            if (items.length > 0) { // Check if there any items in the list before continuing
                for (let i = 0, len = items.length; i < len; i++) { // Loop through all items
                    items[i].classList.remove('irisv-dropdown__menu-item--keyboard-selected'); // Remove any highlighted items
                }
            }
        },
        _scrollToItem(index: number) {
            // Scrolls to a given index in the list
            const listContainerComponent = this.$refs.listContainerComponent as any;
            const menu = listContainerComponent.$refs.menu as HTMLElement;
            const topContainer = listContainerComponent.$refs.topContainer as HTMLElement;
            const itemElem = (menu.querySelector(`#${this.identifier_}__menu-item-${index}`) as HTMLElement);

            if (itemElem) {
                menu.scrollTop = itemElem.offsetTop - topContainer.offsetHeight; // Scroll the item into view accounting for the header in the list
            }
        },
        _findHighlightedAndClick() {
            const items = (this.$refs.listbox as HTMLElement).querySelectorAll<HTMLElement>('.irisv-dropdown__menu-item');
            let highlightedItemValue = '';

            for (let i = 0, len = items.length; i < len; i++) { // Loop through all items
                // Check if the item is highlighted and not disabled
                if (items[i].classList.contains('irisv-dropdown__menu-item--keyboard-selected') && !items[i].classList.contains('item-disabled')) {
                    highlightedItemValue = items[i].dataset.value || ''; // Get the value of that item
                }
            }

            if (highlightedItemValue !== '') { // Check if something was found
                this.setSelectedMenuItem({ label: highlightedItemValue, value: highlightedItemValue }); // Call the click handler for the selected item
            }
        },
        setSelectedMenuItem(newItem: MenuItem, isVModelUpdate = false) {
            if (!isVModelUpdate && newItem.value !== '' && this.selectedMenuItem.value === newItem.value && this.showCheckboxes) {
                this.$nextTick(() => this.closeMenu());
                return;
            }

            const selectedIndex = this.items.findIndex(
                (item: MenuItem) => item.value === newItem.value,
            );

            this.selectedMenuItem = this.items[selectedIndex] || getEmptyListItem();
            this.$emit('list-item-selected-data', {
                isVModelUpdate,
                item: [this.selectedMenuItem.value],
                itemIndex: selectedIndex,
            });

            if (!isVModelUpdate) {
                this.$nextTick(() => {
                    this.closeMenu();
                });
            }
        },
        bottomSlotKeyHandler(keyboardEvent: KeyboardEvent) {
            const code = keyboardEvent.code;
            const filterComponent = this.$refs.filterComponent as any;

            if (code === 'Escape') {
                keyboardEvent.preventDefault(); // These preventDefaults are here individually instead of at the top to allow the user to use any key they need inside the bottom slot
                keyboardEvent.stopPropagation();
                this.closeMenu(true); // Escape should always close the menu from the slot
                return;
            }

            // This prevents shift-tabbing when the slot is highlighted
            if (code === 'Tab' && keyboardEvent.shiftKey) {
                const listContainerComponent = this.$refs.listContainerComponent as any;
                const slot = listContainerComponent.$refs.bottomSlot.$el as HTMLElement;

                if (slot === document.activeElement) {
                    keyboardEvent.preventDefault();
                    keyboardEvent.stopPropagation();
                }
            }

            if (this.filteredItems.length > 0) {
                if (code === 'ArrowDown') {
                    keyboardEvent.preventDefault();

                    if (!!this.focusRefFromParent && !this.isMobile) { // If the focus should stay with the consumer component
                        this.focusRef.focus();
                    } else { // Normal focus handling
                        if (this.showFilter) { // If the filter is being used
                            (filterComponent.$refs.input as HTMLInputElement).focus(); // Focus on The filter so the user can type
                        } else { // No filter
                            (this.$refs.listbox as HTMLElement).focus(); // Put focus back on the list
                        }
                    }

                    // In either case, the highlight should be on the first item in the list
                    this.setMenuItemHighlight(0);
                    this.menuItemHighlight = 0;
                } else if (code === 'ArrowUp') {
                    keyboardEvent.preventDefault();

                    if (!!this.focusRefFromParent && !this.isMobile) { // If the focus should stay with the consumer component
                        this.focusRef.focus();
                    } else { // Normal focus handling
                        if (this.showFilter) { // If the filter is being used
                            (filterComponent.$refs.input as HTMLInputElement).focus(); // Focus on The filter so the user can type
                        } else { // No filter
                            (this.$refs.listbox as HTMLElement).focus(); // Put focus back on the list
                        }
                    }

                    // In either case, the highlight should be on the last item in the list
                    this.setMenuItemHighlight(this.filteredItems.length - 1);
                    this.menuItemHighlight = this.filteredItems.length - 1;
                }
            } else if (this.filteredItems.length === 0) { // This could be due to no results found from filtering
                if (code === 'ArrowDown' || code === 'ArrowUp') {
                    keyboardEvent.preventDefault();
                    if (this.showFilter) { // We know the list is 0 in length, but if the filter is being used...
                        (filterComponent.$refs.input as HTMLInputElement).focus(); // The filter is the only thing you can go to from the bottom slot
                    }
                }
            }
        },
        resizeEventHandler() {
            const focusedElement = document.activeElement as HTMLElement;
            this._setListMode(); // This sets the list mode based on the window size

            if (this.isOpen && (focusedElement === null || focusedElement.getAttribute('type') !== 'text')) { // Check if the list is currently open and the filter is not the focused element (prevents closing of list from android keyboard coming into view)
                this.closeMenu(); // Close the list so it doesn't display the wrong one for the form factor
            }
        },
        _setListMode() {
            this.isMobile = window.matchMedia('(max-width: 560px)').matches;
        },
        _setTriggerGeometry() {
            this.triggerRect = (this.triggerRef as HTMLElement).getBoundingClientRect();
        },
        openList() {
            this._setTriggerGeometry(); // Call to update trigger geometry

            this.allowBubble = false;

            this.$nextTick(() => {
                // Only focus filter if we are on a desktop computer and the filter is present
                if (!this.onMobileDevice && this.showFilter) {
                    const filterComponent = this.$refs.filterComponent as ComponentInstance;
                    const input = filterComponent.$refs.input as HTMLInputElement;

                    input.focus();
                    return;
                }

                // Check if focus should stay with the consumer component or if this the mobile version
                if (this.focusRefFromParent === undefined || this.isMobile) {
                    (this.$refs.listbox as HTMLElement).focus({ preventScroll: true });
                }

                // Don't use onMobileDevice here, use isMobile because this
                // issue will occur with the mobile style,
                // not because the device is a mobile device.
                if (this.isMobile) {
                    const listContainerComponent = this.$refs.listContainerComponent as any;
                    const menu = listContainerComponent.$refs.menu as HTMLElement;

                    menu.scrollTop = 0; // Ensures mobile list is scrolled all the way up
                }
            });

            // Due to different behaviors on iPhone, this code block cannot be placed in the nextTick above
            setTimeout(() => {
                const { selectedMenuItem } = this;
                if (!selectedMenuItem) { return; }
                const index = this.filteredItems.findIndex((item) => item.value === selectedMenuItem.value);
                this._scrollToItem(index);
            }, 10);
        },
        closeList() {
            // Remove any keyboard selection for the next time the menu is opened
            this.menuItemHighlight = -1;
            this._removeHighlighting();

            // // Show the flutter app shell after the menu closes
            if (window.isFlutterWebview && window.f_showAppShell) {
                setTimeout(() => {
                    window.f_showAppShell.postMessage('');
                }, 250); // --motionTimeModerateSlow
            }
        },
    },
    mounted() {
        this._setListMode(); // This sets the list mode based on the window size

        this.triggerRef = this.triggerRefFromParent(); // Execute the function on the parent component to give us a reference to the trigger

        if (typeof this.focusRefFromParent === 'function') { // Needed for implementations like textfield dropdown where the key handler in this component is triggered from typing in the consumer component
            this.focusRef = this.focusRefFromParent();
            this.focusRef.addEventListener('keydown', this.listboxKeyHandler);
        }

        if (Object.prototype.hasOwnProperty.call(this.triggerRef, '$el')) { // Check if the trigger is a VueComponent
            this.triggerRef = this.triggerRef.$el; // Update the reference with the actual HTML instead
        }

        this._setTriggerGeometry(); // Call to get trigger geometry

        const initialItem = this.items.find((item) => item.value === this.selected[0]) || getEmptyListItem();
        this.setSelectedMenuItem(initialItem, true);

        // Prevent resize event due to the hiding/showing of app shell causing
        // the webview to resize and close the menu inadvertently
        if (window.isFlutterWebview === undefined) {
            window.addEventListener('resize', this.resizeEventHandler); // Attach listener for window resize events
        }
    },
    destroyed() {
        window.removeEventListener('resize', this.resizeEventHandler);
    },
    watch: {
        selected(val: string[]) {
            if (val && this.selectedMenuItem.value === val[0]) { return; }
            if (!val || val[0] === '') {
                this.setSelectedMenuItem(getEmptyListItem(), true);
                return;
            }
            const foundItem = this.items.find((item) => item.value === val[0]) || getEmptyListItem();
            this.setSelectedMenuItem(foundItem, true);
        },
        items(newItems: MenuItem[]) {
            if (this.selected && this.selected[0] && this.selected[0].length > 0) {
                const foundItem = newItems.find((item) => item.value === this.selected[0]) || getEmptyListItem();
                this.setSelectedMenuItem(foundItem, true);
            }
        },
        isOpen(shouldOpen: boolean) {
            if (shouldOpen) { // Open
                this.openList();
                return;
            }
            this.closeList();
        },
    },
});
