
import Vue from 'vue';

/**
 * Radio Buttons are used in groupings of two or more options where a user would make a single selection.
 */
export default Vue.extend({
    name: 'IrisRadioButton',
    model: {
        prop: 'model',
        event: 'radio-button-change',
    },
    props: {
        /**
         * If using v-model, declare the initial value inside the data object and reference that data in the v-model attribute on the radio-button-component. [Vue Docs](https://vuejs.org/v2/api/#v-model)
         */
        model: [String, Object],
        /**
         * A required prop. Each label should be different within the group.
         */
        label: {
            type: String,
            required: true,
        },
        /**
         * A required prop. A value can be used independently of the label or checked state.
         */
        value: {
            type: String,
            required: true,
        },
        /**
         * If you don't want the name to be taken from the initial v-model value, you can add your own name prop. Each individual button in a radio group must have that same name passed down to it.
         */
        name: String,
        /**
         * Checked is an optional prop that can be set up on a button to give it a default checked value. If using v-model, setting the v-model to the value of a specific button will also set that button as a default checked button.
         */
        isChecked: {
            type: Boolean,
            default: false,
        },
        /**
         * Optional prop that gives the radio button its smaller low emphasis appearance.
         */
        isLowEmphasis: Boolean,
    },
    methods: {
        onChange(event: Event) {
        /**
         * Emits the value and returns the value of the selected radio button.
         */
            this.$emit('radio-button-change', (event.target as HTMLInputElement).value);
        },
    },
    computed: {
        checkedBool(): boolean {
            if (this.isChecked) {
                return true;
            } else {
                return this.model === this.value;
            }
        },
    },
});
