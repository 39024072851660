
import Vue from 'vue';
import IrisAvatar from './IrisAvatar.vue';
import { deprecationMixin } from './../mixins/deprecationMixin';

/**
 * The quick action button component is used to provide contextual actions within a compact space,
 * and can be grouped together or can be a standalone call to action.
 */
export default Vue.extend({
    name: 'IrisQuickActionButton',
    model: {
        prop: 'modelValue',
        event: 'quick-action-button-toggle',
    },
    components: {
        IrisAvatar,
    },
    mixins: [deprecationMixin],
    props: {
        /**
         * Object of props for the HTML button or anchor element generated by this Quick Action Button.<br /><br />
         * This component will generate a button if the href property is blank. If the href property is populated,
         * an anchor will be generated.<br /<br />
         * Refer to HTML button or anchor element documentation for available props.
         */
        buttonOrAnchorProps: Object,
        /**
         * Object of props for the IrisAvatar child component.<br /><br />
         * Refer to the IrisAvatar docs for available props.<br /<br />
         * Defaults:
         * <ul>
         * <li>backgroundColor: this._avatarBackgroundColors()</li>
         * <li>letterIconColor: this._avatarIconColors()</li>
         * <li>mainIconName: 'help'</li>
         * </ul>
         * Restrictions: borderRadiusOverride, isStatic
         */
        avatarProps: Object,
        /**
         * Sets the button kind. Valid values are 'highEmphasis', 'mediumEmphasis', 'lowEmphasis', or 'noEmphasis'.
         */
        kind: {
            type: String,
            default: 'highEmphasis',
            validator: (value: string) => {
                return ['highemphasis', 'mediumemphasis', 'lowemphasis', 'noemphasis'].includes(
                    value.toLowerCase(),
                );
            },
        },
        /**
         * Sets the button variation. Valid values are 'embedded', 'inline', 'inline-block', 'stacked', or 'stacked-block'.
         * When using 'inline-block' or 'stacked-block' the parent container height must be defined for the quick action button
         * to vertically center itself in the container.
         */
        variation: {
            type: String,
            default: 'embedded',
            validator: (value: string) => {
                return ['embedded', 'inline', 'inline-block', 'stacked', 'stacked-block'].includes(
                    value.toLowerCase(),
                );
            },
        },
        /**
         * When 'variation' attribute is set to 'inline-block' or 'stacked-block', the alignment of the icon and label can be set
         * to 'left', 'center', or 'right' compared to the parent container
         */
        alignment: {
            type: String,
            default: 'left',
            validator: (value: string) => {
                return ['left', 'center', 'right'].includes(value.toLowerCase());
            },
        },
        /**
         * Sets the visible label for all variants except 'embedded'. The 'embedded' variant uses the label text as an aria-label.
         */
        label: {
            type: String,
            required: true,
        },
        /**
         * Sets the button href to a specified URL.
         */
        href: String,
        /**
         * Sets where to open the URL specified in the 'href' attribute.
         */
        target: {
            type: String,
            validator: (value: string) => {
                return ['_self', '_blank', '_parent', '_top'].includes(value.toLowerCase());
            },
        },
        /**
         * If the control should behave similar to a toggle, the 'isToggle' attribute should be set to true.
         */
        isToggle: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets the state of the control (pressed or checked) when 'isToggle' is set to true.
         */
        modelValue: Boolean,
        /**
         * Sets the border radius, but only used as an override since default border radius value should be set by the theme. Expects a css value for border radius. Can be between 1-4 values for each corner of the shape. Examples '44px', '10% 30% 50% 70%'
         */
        borderRadiusOverride: {
            type: String,
        },
    },
    data() {
        return {
            isMousedOver: false,
            isPressed: false,
            isSelected: false,
            highEmphasis: {
                staticAvatarBGEmbedded: 'rgb(var(--colorBrandedAffordance))',
                staticAvatarIconAndTextEmbedded: 'rgb(var(--colorBrandedAffordanceOnColor))',
                hoverAvatarBGEmbedded: 'rgb(var(--colorBrandedAffordance600))',
                hoverAvatarIconAndTextEmbedded: 'rgb(var(--colorBrandedAffordance900))',
                pressedAvatarBGEmbedded: 'rgb(var(--colorBrandedAffordance700))',
                pressedAvatarIconAndTextEmbedded: 'rgb(var(--colorBrandedAffordance100))',
                selectedAvatarBGEmbedded: 'rgb(var(--colorBrandedAffordance500))',
                selectedAvatarIconAndTextEmbedded: 'rgb(var(--colorBrandedAffordance900))',
                staticAvatarBG: 'rgb(var(--colorBrandedAffordance))',
                staticAvatarIconAndText: 'rgb(var(--colorBrandedAffordanceOnColor))',
                hoverAvatarBG: 'rgb(var(--colorBrandedAffordance))',
                hoverAvatarIconAndText: 'rgb(var(--colorBrandedAffordanceOnColor))',
                pressedAvatarBG: 'rgb(var(--colorBrandedAffordance))',
                pressedAvatarIconAndText: 'rgb(var(--colorBrandedAffordanceOnColor))',
                selectedAvatarBG: 'rgb(var(--colorBrandedAffordance))',
                selectedAvatarIconAndText: 'rgb(var(--colorBrandedAffordanceOnColor))',
            },
            mediumEmphasis: {
                staticAvatarBGEmbedded: 'rgb(var(--colorBrandedAffordance100))',
                staticAvatarIconAndTextEmbedded: 'rgb(var(--colorBrandedAffordanceAccessible))',
                hoverAvatarBGEmbedded: 'rgb(var(--colorBrandedAffordance200))',
                hoverAvatarIconAndTextEmbedded: 'rgb(var(--colorBrandedAffordanceAccessible))',
                pressedAvatarBGEmbedded: 'rgb(var(--colorBrandedAffordance))',
                pressedAvatarIconAndTextEmbedded: 'rgb(var(--colorBrandedAffordanceOnColor))',
                selectedAvatarBGEmbedded: 'rgb(var(--colorBrandedAffordance200))',
                selectedAvatarIconAndTextEmbedded: 'rgb(var(--colorBrandedAffordance900))',
                staticAvatarBG: 'rgb(var(--colorBrandedAffordance100))',
                staticAvatarIconAndText: 'rgb(var(--colorBrandedAffordance700))',
                hoverAvatarBG: 'rgb(var(--colorBrandedAffordance100))',
                hoverAvatarIconAndText: 'rgb(var(--colorBrandedAffordanceAccessible))',
                pressedAvatarBG: 'rgb(var(--colorBrandedAffordance))',
                pressedAvatarIconAndText: 'rgb(var(--colorBrandedAffordanceOnColor))',
                selectedAvatarBG: 'rgb(var(--colorBrandedAffordance200))',
                selectedAvatarIconAndText: 'rgb(var(--colorBrandedAffordance900))',
            },
            lowEmphasis: {
                staticAvatarBGEmbedded: 'none',
                staticAvatarIconAndTextEmbedded: 'rgb(var(--colorBrandedAffordanceAccessible))',
                hoverAvatarBGEmbedded: 'none',
                hoverAvatarIconAndTextEmbedded: 'rgb(var(--colorBrandedAffordanceAccessible))',
                pressedAvatarBGEmbedded: 'rgb(var(--colorBrandedAffordance))',
                pressedAvatarIconAndTextEmbedded: 'rgb(var(--colorBrandedAffordanceOnColor))',
                selectedAvatarBGEmbedded: 'rgb(var(--colorBrandedAffordance200))',
                selectedAvatarIconAndTextEmbedded: 'rgb(var(--colorBrandedAffordance900))',
                staticAvatarBG: 'none',
                staticAvatarIconAndText: 'rgb(var(--colorBrandedAffordanceAccessible))',
                hoverAvatarBG: 'none',
                hoverAvatarIconAndText: 'rgb(var(--colorBrandedAffordanceAccessible))',
                pressedAvatarBG: 'none',
                pressedAvatarIconAndText: 'rgb(var(--colorBrandedAffordanceOnColor))',
                selectedAvatarBG: 'none',
                selectedAvatarIconAndText: 'rgb(var(--colorBrandedAffordance900))',
            },
            noEmphasis: {
                staticAvatarBGEmbedded: 'none',
                staticAvatarIconAndTextEmbedded: 'rgb(var(--colorBrandedAffordanceAccessible))',
                hoverAvatarBGEmbedded: 'none',
                hoverAvatarIconAndTextEmbedded: 'rgb(var(--colorBrandedAffordanceAccessible))',
                pressedAvatarBGEmbedded: 'rgb(var(--colorBrandedAffordance))',
                pressedAvatarIconAndTextEmbedded: 'rgb(var(--colorBrandedAffordanceOnColor))',
                selectedAvatarBGEmbedded: 'rgb(var(--colorBrandedAffordance200))',
                selectedAvatarIconAndTextEmbedded: 'rgb(var(--colorBrandedAffordance900))',
                staticAvatarBG: 'none',
                staticAvatarIconAndText: 'rgb(var(--colorBrandedAffordanceAccessible))',
                hoverAvatarBG: 'none',
                hoverAvatarIconAndText: 'rgb(var(--colorBrandedAffordanceAccessible))',
                pressedAvatarBG: 'none',
                pressedAvatarIconAndText: 'rgb(var(--colorBrandedAffordanceOnColor))',
                selectedAvatarBG: 'none',
                selectedAvatarIconAndText: 'rgb(var(--colorBrandedAffordance900))',
            },
        };
    },
    computed: {
        compContainerStyle(): string {
            if (this.variation === 'inline-block' || this.variation === 'stacked-block') {
                return 'display: flex; height: 100%; width: 100%;';
            } else {
                return 'display: inline-block;';
            }
        },
        compAlignment(): string | boolean {
            const alignmentMap: {[index: string]: any} = {
                left: 'flex-start',
                center: 'center',
                right: 'flex-end',
            };

            if (this.variation === 'inline-block' || this.variation === 'stacked-block') {
                return `align-items: center; display: flex; justify-content: ${alignmentMap[this.alignment]};`;
            } else {
                return false;
            }
        },
        compAvatarLabelContainer(): string | boolean {
            if (this.variation === 'inline' || this.variation === 'inline-block') {
                return 'align-items: center; display: flex;';
            } else if (this.variation === 'stacked' || this.variation === 'stacked-block') {
                return 'align-items: center; display: flex; flex-direction: column;';
            } else {
                return false;
            }
        },
        defaultAvatarProps(): object {
            return {
                backgroundColor: this._avatarBackgroundColors(),
                letterIconColor: this._avatarIconColors(),
                mainIconName: 'help',
                ...this.avatarProps,
            };
        },
    },
    watch: {
        isToggle() {
            if (this.isToggle) {
                this.isSelected = this.modelValue;
            }
        },
        modelValue: {
            immediate: true,
            handler(newValue) {
                this.isSelected = this.isToggle ? newValue : false;
            },
        },
    },
    methods: {
        onClick(event: Event) {
            if (this.isToggle) { // Handle toggle clicks differently than standard button clicks
                this.isSelected = !this.isSelected;

                /**
                 * Emitted when the quick action button is toggled. The selected state (true or false) will also be returned.
                 */
                this.$emit('quick-action-button-toggle', this.isSelected);
            }
            /**
             * Emitted when the quick action button is clicked.
             */
            this.$emit('quick-action-button-click', event);
        },
        _hasKey(obj: object, key: keyof any): key is keyof object {
            // this method exists solely so we can use square bracket notation on $refs
            return key in obj;
        },
        _avatarBackgroundColors() {
            if (this._hasKey(this, this.kind)) {
                const kind = this[this.kind] as any;

                if (this.variation === 'embedded') { // Embedded variation only
                    if (this.isToggle && this.isSelected) {
                        return kind.selectedAvatarBGEmbedded;
                    } else {
                        return this.$data.isPressed ? kind.pressedAvatarBGEmbedded : (this.$data.isMousedOver ? kind.hoverAvatarBGEmbedded : kind.staticAvatarBGEmbedded);
                    }
                } else { // All other variations
                    if (this.isToggle && this.isSelected) {
                        return kind.selectedAvatarBG;
                    } else {
                        return this.$data.isPressed ? kind.pressedAvatarBG : (this.$data.isMousedOver ? kind.hoverAvatarBG : kind.staticAvatarBG);
                    }
                }
            } else {
                return '';
            }
        },
        _avatarIconColors() {
            if (this._hasKey(this, this.kind)) {
                const kind = this[this.kind] as any;

                if (this.variation === 'embedded') { // Embedded variation only
                    if (this.isToggle && this.isSelected) {
                        return kind.selectedAvatarIconAndTextEmbedded;
                    } else {
                        return this.$data.isPressed ? kind.pressedAvatarIconAndTextEmbedded : (this.$data.isMousedOver ? kind.hoverAvatarIconAndTextEmbedded : kind.staticAvatarIconAndTextEmbedded);
                    }
                } else { // All other variations
                    if (this.isToggle && this.isSelected) {
                        return kind.selectedAvatarIconAndText;
                    } else {
                        return this.$data.isPressed ? kind.pressedAvatarIconAndText : (this.$data.isMousedOver ? kind.hoverAvatarIconAndText : kind.staticAvatarIconAndText);
                    }
                }
            } else {
                return '';
            }
        },
        focus() {
            this.$nextTick(() => {
                (this.$refs.quickActionButton as HTMLElement).focus();
            });
        },
    },
});
