var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('label',{staticClass:"irisv-cloaked-checkmark",class:{
        'irisv-cloaked-checkmark--disabled' : _vm.isDisabled,
        'irisv-cloaked-checkmark--skeleton' : _vm.showSkeleton
    },attrs:{"tabindex":_vm.showSkeleton && '-1'}},[_c('input',{ref:"input",staticClass:"irisv-cloaked-checkmark__input",attrs:{"type":_vm.isSingleSelection ? 'radio' : 'checkbox',"name":_vm.name,"disabled":_vm.isDisabled || _vm.showSkeleton,"tabindex":_vm.showSkeleton && '-1'},domProps:{"checked":_vm.isChecked,"value":_vm.value},on:{"change":_vm.onChange}}),_c('div',{staticClass:"irisv-cloaked-checkmark__container",class:{
            'irisv-cloaked-checkmark__container--dark' : _vm.isDarkMode,
            'irisv-cloaked-checkmark__container--skeleton' : _vm.showSkeleton
        }},[_c('span',{staticClass:"irisv-cloaked-checkmark__label",class:{
                'irisv-cloaked-checkmark__label--right-align-checkmark' : _vm.hasRightAlignCheckmark,
                'irisv-cloaked-checkmark__label--dark' : _vm.isDarkMode,
                'irisv-cloaked-checkmark__label--skeleton' : _vm.showSkeleton
            }},[_vm._v(" "+_vm._s(_vm.label)+" ")]),(!_vm.showSkeleton)?_c('svg',{staticClass:"irisv-cloaked-checkmark__svg",attrs:{"version":"1.1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","x":"0px","y":"0px","viewBox":"3 5 18.1 13.8","enable-background":"new 3 5 18.1 13.8","xml:space":"preserve"}},[_c('polyline',{staticClass:"irisv-cloaked-checkmark__svg-polyline",attrs:{"fill":"none","stroke":"#231F20","stroke-width":"2.1","stroke-miterlimit":"10","points":"3.7,12.2 8.8,17.3 20.3,5.7 "}})]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }