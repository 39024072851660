
import Vue from 'vue';
import IrisTextfield from './IrisTextfield.vue';
import IrisIcon from './IrisIcon.vue';
import { generateUniqueId } from '@/utils';
import { deprecationMixin } from './../mixins/deprecationMixin';

interface IDimensions {
    trigger: IElementDimensions;
}

interface IElementDimensions {
    top: number;
    left: number;
    bottom: number;
    right: number;
    width: number;
    height: number;
}

/**
 * The input dropdown provides a way for users to make a selection that will then be used as input within a form.
 * Users can select from a list of options or type into the textfield to filter the items of a longer list.
 */
export default Vue.extend({
    name: 'IrisInputDropdown',
    model: {
        prop: 'modelValue',
        event: 'input-dropdown-item-selected-label',
    },
    mixins: [deprecationMixin],
    components: {
        IrisTextfield,
        IrisIcon,
        IrisAccount: () => import('./IrisAccount.vue'),
    },
    props: {
        /**
         * Sets the initial value of the input dropdown textfield.
         */
        modelValue: String,
        /**
         * Sets the label of the input dropdown textfield. This field is required.
         */
        label: {
            type: String,
            required: true,
        },
        /**
         * Marks the input dropdown as either an optional or required field.
         */
        isOptional: {
            type: Boolean,
            default: true,
        },
        /**
         * Marks the input dropdown as readonly or can input text.
         */
        isReadonly: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets the isLoading state with animated icon indicator.
         */
        isLoading: {
            type: Boolean,
            default: false,
        },
        /**
         * Overrides the default internal error messaging. Displayed if a required input dropdown's value is missing
         */
        helperTextErrorMessage: String,
        /**
         * Sets the helper text.
         */
        helperText: String,
        /**
         * This is an array of objects - each object is a list of props for one Iris Account component.
         * Refer to the Iris Account component's documentation for details.
         */
        accountsData: {
            type: Array as () => any[],
        },
        /**
         * Sets the menu variant. Valid values are 'navigation', 'action', 'account'.
         */
        kind: {
            type: String,
            default: 'action',
            validator: (value: string) => {
                return ['action', 'navigation', 'account'].includes(value.toLowerCase());
            },
        },
        /**
         * Sets the aria-label for the caret icon. The icon's name is a fallback by default, but please provide something descriptive for a screen reader user.
         */
        trailingIconAriaLabel: String,
        /**
         * This is an array of objects - each object is one menu item.
         * Required keys are:
         * "label" (string): Sets the menu item label,
         * Optional keys are:
         * "href" (string): Sets the target URL of the menu item. (For navigation menu dropdown only),
         * "iconName" (string): Sets the icon,
         * "to" (string): router-link prop: Sets the target route of the link,
         * "target" (string): Sets the target attribute on the menu item. (For navigation menu dropdown only),
         * "value" (string): Sets the value of the menu item. Default value is set to the menu item's index.
         */
        items: {
            type: Array as () => any[],
        },
        /**
         * Disables the input dropdown and shows the disabled state.
         */
        isDisabled: {
            type: Boolean,
            default: false,
        },
        /**
         * Shows the skeleton loader as a placeholder option. This may be useful when page content is loading in
         * the background.
         */
        showSkeleton: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return({
            activeMenuItem: '' as string,
            focusedMenuItemIndex: 0 as number,
            isOpen: false as boolean,
            menuFlow: 'down' as string,
            menuItems: {} as NodeList,
            triggerId_: generateUniqueId('iris_menu_dropdown') as string,
            listBoxId_: generateUniqueId('listbox') as string,
            dimensions: {trigger: {}} as IDimensions,
            inputValue: '' as string,
            visualItems: [] as any,
            modifiedItems: [] as any,
            modifiedAccountsData: [] as any,
            itemToPreSelect: '' as string,
            isFiltering: false as boolean,
            hasFocus: false as boolean,
            labelTransitionDuration: '150ms',
        });
    },
    computed: {
        compSpacerStyle() {
            // Searches the visual list for items with icons to determine if the spacer should be displayed
            let iconFound: boolean = false;

            for (let i = 0, len = this.visualItems.length; i < len; i++) {
                if (this.visualItems[i].iconName) {
                    iconFound = true;
                }
            }

            if (!iconFound) { // No icons in the whole list, do not show spacer
                return 'display: none;';
            } else {
                return false;
            }
        },
        compMenuStylePosition(): string {
            // Calculates menu's style and position (above or below) relative to the input
            const trigger = this.dimensions.trigger;
            const availableBottomSpace = this._getViewportRect().height - trigger.bottom;
            const availableTopSpace = trigger.top;
            const menuHeight = 256;
            let styleString = '';

            if (menuHeight <= availableBottomSpace) { // Check if the 256px menu can be displayed in the space below the input
                this._setMenuFlowDirection('down');
                styleString = `margin-top: var(--spacingPlatformTiny); max-height: ${menuHeight}px;`;
            } else if (menuHeight <= availableTopSpace) { // Check if the 256px menu can be displayed in the space above the input
                this._setMenuFlowDirection('up');
                styleString = `bottom: calc(100% + 16px); max-height: ${menuHeight}px;`;
            } else { // The 256px menu doesn't fit on screen
                if (availableBottomSpace <= availableTopSpace) { // If there is more space above the input
                    this._setMenuFlowDirection('up');
                    styleString = `max-height: ${availableTopSpace - 24}px; bottom: calc(100% + 16px);`;
                } else { // There is more space below the input
                    this._setMenuFlowDirection('down');
                    styleString = `margin-top: var(--spacingPlatformTiny); max-height: ${availableBottomSpace - 20}px;`;
                }
            }

            return styleString;
        },
    },
    methods: {
        tabOutHandler() {
            this.isOpen = false;
        },
        arrowKeyHandler() {
            const itemIndex = this.visualItems.findIndex((item: any) => item.uuid === this.activeMenuItem);

            this._setMenuItemFocus(itemIndex === -1 ? 0 : itemIndex);
        },
        checkValidKeys(keyboardEvent: KeyboardEvent) {
            const ignoreKeys = ['Tab', 'Enter', 'Shift', 'Ctrl', 'Alt', 'Caps Lock', 'PageUp', 'PageDown', 'End', 'Home', 'ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown', 'Insert'];

            if (ignoreKeys.indexOf(keyboardEvent.key) === -1) {
                this.inputChange();
            }
        },
        textfieldFocus(event: Event) {
            this.hasFocus = true;
            this.isOpen = true;
            this.isFiltering = true; // Used in the modelValue watcher to know if user could be typing
            /**
             * Emitted when the input dropdown textfield gains focus.
             */
            this.$emit('textfield-focus', event);

            // This will apply the filter to the list but not emit its event
            this.inputChange(false);
        },
        textfieldBlur() {
            this.hasFocus = false;
        },
        textfieldEscape() {
            this.inputValue = '';
            this.activeMenuItem = '';
            this.inputChange();
        },
        inputChange(emit: boolean = true) {
            function filterByLabelValue(arr: any, str: string, kind: string) {
                if (kind === 'action' || kind === 'navigation') {
                    return arr.filter((o: any) => o.label.toLowerCase().includes(str.toLowerCase().trim()));
                } else if (kind === 'account') {
                    return arr.filter((o: any) => {
                        return o.accountNickName.toLowerCase().includes(str.toLowerCase().trim()) ||
                               o.accountNumberEnding.includes(str.trim());
                    });
                }
            }

            let theData = [];
            theData = (this.kind === 'action' || this.kind === 'navigation') ? this.modifiedItems : this.modifiedAccountsData;
            this.visualItems = filterByLabelValue(theData, this.inputValue, this.kind);

            this.$nextTick(function() { // Keep this list current as the user filters items in and out
                this._setMenuItems();
            });

            if (emit) {
                this.setSelectedMenuItem(''); // User has changed the input, clear the selection
                this.$emit('input-dropdown-item-selected', null);
                this.$emit('input-dropdown-item-selected-label', ''); // This will clear the v-model
                /**
                 * Emitted when the input dropdown textfield value is changed by the user.
                 */
                this.$emit('textfield-input', this.inputValue);
            }
        },
        caretClick() {
            if (!this.isOpen) {
                this.visualItems = (this.kind === 'action' || this.kind === 'navigation') ? this.modifiedItems : this.modifiedAccountsData;
            }

            this.isOpen = !this.isOpen;

            /**
             * Emitted when the caret icon is clicked.
             */
            this.$emit('textfield-click-icon-caret');
        },
        _focusNextMenuItem() {
            this._setMenuItems();

            if (this.focusedMenuItemIndex < this.menuItems.length - 1) {
                this.focusedMenuItemIndex++;
            } else {
                this.focusedMenuItemIndex = 0;
            }

            this._setMenuItemFocus(this.focusedMenuItemIndex);
        },
        _focusPrevMenuItem() {
            this._setMenuItems();

            if (this.focusedMenuItemIndex === 0) {
                this.focusedMenuItemIndex = this.menuItems.length - 1;
            } else {
                this.focusedMenuItemIndex--;
            }

            this._setMenuItemFocus(this.focusedMenuItemIndex);
        },
        _setMenuItemFocus(index: number) {
            setTimeout(() => {
                this._setMenuItems();
                this.focusedMenuItemIndex = index;
                (this.menuItems[index] as HTMLElement).focus();

                // add aria-activedescendant to input text trigger. Aria-activedescendant will identify which dropdown menuitem is currently focused
                (document.getElementById(this.triggerId_) as HTMLElement).setAttribute('aria-activedescendant', `${this.triggerId_}_menuitem${this.focusedMenuItemIndex}`);
            }, 0);
        },
        menuEscape() {
            this.$nextTick(function() {
                (this.$refs.textfieldControl as any).$refs.textfield.focus();
            });
        },
        _documentClick(event: Event) {
            const target = event.target as HTMLElement;

            if (!this.hasFocus && !(this.$el.contains(target))) {
                this.isOpen = false;

                /**
                 * Emitted when the input dropdown loses focus.
                 */
                this.$emit('textfield-blur', event);
            }
        },
        _getMenuItemTag(item: {[index: string]: any}) {
            if (typeof item.href !== 'undefined') {
                return 'a';
            } else if (typeof item.to !== 'undefined') {
                return 'router-link';
            } else {
                return 'div';
            }
        },
        _onMenuItemClick(item: any) {
            this.setSelectedMenuItem(item.uuid);
            this.inputValue = (this.kind === 'action' || this.kind === 'navigation') ? item.label : item.accountNickName;
            this.closeMenu();
        },
        setSelectedMenuItem(uuid: string) {
            this.activeMenuItem = uuid;
        },
        stopPropCloseMenu(event: Event) {
            if (this.isOpen) {
                event.stopPropagation();
                this.closeMenu();
            } else {
                this.menuEscape();
            }
        },
        closeMenu() {
            // Focus back to textfield on close:
            ((this.$refs.textfieldControl as Vue).$refs.textfield as HTMLElement).focus();
            this.$nextTick(() => {
                this.isFiltering = false;
                this.isOpen = false; // This needs to happen after the focus so the menu doesn't flicker (because it is closing, opening, closing)
            });
        },
        _updateDimensions() {
            this.dimensions.trigger = this._getElementRect(this.$refs.textfieldControl as HTMLElement);
        },
        _getElementRect(el: any) {
            const rect = (el.$el as HTMLElement).getBoundingClientRect();

            return {
                top: Math.round(rect.top),
                left: Math.round(rect.left),
                bottom: Math.round(rect.bottom),
                right: Math.round(rect.right),
                width: Math.round(rect.width),
                height: Math.round(rect.height),
            };
        },
        _getViewportRect() {
            const width = window.innerWidth;
            const height = window.innerHeight;

            return { width, height } as ClientRect;
        },
        _setMenuFlowDirection(direction: string) {
            if (direction.toLowerCase() === 'up' || direction.toLowerCase() === 'down') {
                this.menuFlow = direction.toLowerCase();
            } else {
                return false;
            }
        },
        _preSelectHandler() {
            // Loops through the list of items to find the itemToPreSelect and selects it if it is valid and not a duplicate
            let labels = [];
            let found = 0;

            if (this.kind === 'action' || this.kind === 'navigation') {
                labels = this.items.map((x) => x.label); // Build a list of the item labels

                for (let i = 0, len = labels.length; i < len; i++) { // Loop through labels
                    if (this.itemToPreSelect === labels[i]) { // If item is found
                        found++; // Increase the number of times it was found
                    }
                }

                if (found < 2) { // Check if item is unique in list (we don't want to preselect the wrong item on a guess)
                    for (let i = 0, len = this.modifiedItems.length; i < len; i++) { // Loop through modified items
                        if (this.itemToPreSelect === this.modifiedItems[i].label) { // Match preselect item to item in list
                            // Set selected item
                            this.setSelectedMenuItem(this.modifiedItems[i].uuid);
                            this.inputValue = this.itemToPreSelect;
                            this.focusedMenuItemIndex = i;
                            this.$emit('input-dropdown-item-selected-label', this.modifiedItems[i].label);
                            break;
                        }
                    }
                }
            } else if (this.kind === 'account') {
                labels = this.accountsData.map((x) => x.accountNickName); // Build a list of the account nicknames

                for (let i = 0, len = labels.length; i < len; i++) { // Loop through labels
                    if (this.itemToPreSelect === labels[i]) { // If item is found
                        found++; // Increase the number of times it was found
                    }
                }

                if (found < 2) { // Check if item is unique in list (we don't want to preselect the wrong item on a guess)
                    for (let i = 0, len = this.modifiedAccountsData.length; i < len; i++) { // Loop through modified accounts data
                        if (this.itemToPreSelect === this.modifiedAccountsData[i].accountNickName) { // Match preselect item to item in list
                            // Set selected item
                            this.setSelectedMenuItem(this.modifiedAccountsData[i].uuid);
                            this.inputValue = this.itemToPreSelect;
                            this.focusedMenuItemIndex = i;
                            this.$emit('input-dropdown-item-selected-label', this.modifiedAccountsData[i].accountNickName);
                            break;
                        }
                    }
                }
            }

            if (this.activeMenuItem === '' && this.inputValue === '') { // Check if the passed in value resulted in no selection
                this.$emit('input-dropdown-item-selected', null);
                this.$emit('input-dropdown-item-selected-label', ''); // This will clear the v-model
            }
        },
        _setupModifiedUserData() {
            if (this.items && (this.kind === 'action' || this.kind === 'navigation')) {
                this.modifiedItems = this.items.map((o) => ({...o})); // Copy the user supplied items to the modified list

                for (let i = 0, len = this.modifiedItems.length; i < len; i++) { // Loop through the modified list
                    this.modifiedItems[i].uuid = generateUniqueId('item'); // Add a uuid to each
                }

                this.visualItems = this.modifiedItems; // Copy the modified list to the visual items list
            } else if (this.accountsData && this.kind === 'account') {
                this.modifiedAccountsData = this.accountsData.map((o) => ({...o})); // Copy the user supplied accounts to the modified list

                for (let i = 0, len = this.modifiedAccountsData.length; i < len; i++) { // Loop through the modified list
                    this.modifiedAccountsData[i].uuid = generateUniqueId('item'); // Add a uuid to each
                }

                this.visualItems = this.modifiedAccountsData; // Copy the modified list to the visual items list
            }
        },
        _setMenuItems() {
            this.menuItems = this.kind === 'navigation' ? (this.$refs.menuWrapper as HTMLElement).querySelectorAll('[role="menuitem"]') : (this.$refs.menuWrapper as HTMLElement).querySelectorAll('[role="option"]');
        },
    },
    beforeMount() {
        this._setupModifiedUserData();
    },
    mounted() {
        if (!this.isLoading) {
            const caretRef: any = this.$el.querySelector('.irisv-textfield__trailing-button .irisv-icon');
            caretRef.style.transform = 'rotate(0deg)';
            caretRef.style.transition = 'transform var(--motionTimeFast) var(--motionTimingFunctionStandard)';
        }

        if (this.itemToPreSelect !== '') { // Check if there is an item to preselect from the v-model
            this._preSelectHandler();
        }

        this.labelTransitionDuration = getComputedStyle((this.$refs.root as HTMLElement).querySelector('.irisv-textfield__label') as HTMLElement).getPropertyValue('transition-duration');
    },
    destroyed() {
        document.removeEventListener('click', this._documentClick);
    },
    watch: {
        inputValue: {
            immediate: true,
            handler(nextTextValue, prevTextValue) {
                // watch for input change, if an item is selected and present in textfield, small menu closes. This causes focus to go onto the textfield (see method closeMenu).
                if (this.activeMenuItem && this.activeMenuItem.toString().length > 0 && this.inputValue.length > 0) {
                    if (nextTextValue.length && !prevTextValue.length) {
                        this.isOpen = false;
                    } else {
                        this.isOpen = true;
                    }
                }
            },
        },
        isOpen(bool: boolean) {
            if (!this.isLoading && !this.isReadonly) {
                const caretRef: any = this.$el.querySelector('.irisv-textfield__trailing-button .irisv-icon');
                const triggerId = document.getElementById(this.triggerId_) as HTMLElement;

                if (bool) {
                    this.isFiltering = true; // Menu is open, user could be typing
                    this._updateDimensions();
                    document.addEventListener('click', this._documentClick);

                    caretRef.style.transform = 'rotate(180deg)';
                    caretRef.style.animation = 'var(--motionTimeFast) var(--motionTimingFunctionStandard) rotateCaret';
                    /**
                     * Emitted when the input dropdown menu is opened.
                     */
                    this.$emit('input-dropdown-opened');
                    this.visualItems = (this.kind === 'action' || this.kind === 'navigation') ? this.modifiedItems : this.modifiedAccountsData;

                    ((this.$refs.root as HTMLElement).querySelector('.irisv-textfield__label') as HTMLElement).style.setProperty('transition-delay', this.labelTransitionDuration);
                } else {
                    this.isFiltering = false; // Menu is closed, user is not typing
                    document.removeEventListener('click', this._documentClick);

                    caretRef.style.transform = 'rotate(360deg)';
                    caretRef.style.animation = 'none';

                    if (this.activeMenuItem === '') { // If there is no active selection
                        this.inputValue = ''; // Clear anything the user may have typed in the textfield. They must select from the list.
                        this.$emit('input-dropdown-item-selected', null);
                        this.$emit('input-dropdown-item-selected-label', ''); // This will clear the v-model
                    } else { // There is an active selection
                        if (this.kind === 'action' || this.kind === 'navigation') {
                            for (let i = 0, len = this.modifiedItems.length; i < len; i++) { // Loop through modified list, not the filtered one
                                if (this.activeMenuItem === (this.modifiedItems[i] as any).uuid) { // find the active id in the list
                                    /**
                                     * Emitted when an input dropdown menu item is selected.
                                     */
                                    this.$emit('input-dropdown-item-selected', {selectedMenuItem: this.modifiedItems[i]});
                                    /**
                                     * Emitted when an input dropdown menu item is selected.
                                     */
                                    this.$emit('input-dropdown-item-selected-label', this.modifiedItems[i].label);
                                    break;
                                }
                            }
                        } else if (this.kind === 'account') {
                            for (let i = 0, len = this.modifiedAccountsData.length; i < len; i++) { // Loop through modified list, not the filtered one
                                if (this.activeMenuItem === (this.modifiedAccountsData[i] as any).uuid) { // find the active id in the list
                                    /**
                                     * Emitted when an input dropdown menu item is selected.
                                     */
                                    this.$emit('input-dropdown-item-selected', {selectedMenuItem: this.modifiedAccountsData[i]});
                                    /**
                                     * Emitted when an input dropdown menu item is selected.
                                     */
                                    this.$emit('input-dropdown-item-selected-label', this.modifiedAccountsData[i].accountNickName);
                                    break;
                                }
                            }
                        }
                    }

                    /**
                     * Emitted when the input dropdown menu is closed.
                     */
                    this.$emit('input-dropdown-closed');

                    ((this.$refs.root as HTMLElement).querySelector('.irisv-textfield__label') as HTMLElement).style.removeProperty('transition-delay');
                }

                // remove aria-activedescendant when popup menu is closed
                if (!this.isOpen && triggerId) {
                    triggerId.removeAttribute('aria-activedescendant');
                }
            }
        },
        showSkeleton(bool: boolean) {
            const messageRef: any = this.$el.querySelector('.irisv-textfield__messages');

            if (bool) {
                messageRef.style.display = 'none';
            } else {
                messageRef.style.display = 'flex';
            }
        },
        modelValue: {
            immediate: true,
            handler(newValue) {
                if (this.inputValue !== newValue && typeof(newValue) !== 'undefined') { // Check if newValue is valid
                    if (newValue === '' && !this.isFiltering) { // If newValue is blank and user is not typing, clear it
                        this.setSelectedMenuItem('');
                        this.inputValue = '';
                        return;
                    }

                    // Set the selected item here. Next, we'll Check if the modified list is built. If it is, we'll call the handler. If not, then a call in the mounted hook will do it.
                    this.itemToPreSelect = newValue;

                    if (this.kind === 'action' || this.kind === 'navigation') { // Handle action and navigation kinds
                        if (this.modifiedItems.length > 0) {
                            this._preSelectHandler();
                        }
                    } else if (this.kind === 'account') { // Handle account kind
                        if (this.modifiedAccountsData.length > 0) {
                            this._preSelectHandler();
                        }
                    }
                }
            },
        },
        kind() {
            this._setupModifiedUserData();
        },
        accountsData(newValue, oldValue) {
            if (JSON.stringify(newValue) !== JSON.stringify(oldValue) && this.kind === 'account') {
                this._setupModifiedUserData();
                this._preSelectHandler();
            }
        },
        items(newValue, oldValue) {
            if (JSON.stringify(newValue) !== JSON.stringify(oldValue) && (this.kind === 'action' || this.kind === 'navigation')) {
                this._setupModifiedUserData();
                this._preSelectHandler();
            }
        },
    },
});
