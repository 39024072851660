
import Vue from 'vue';
import IrisAccount from '../IrisAccount.vue';
import IrisIcon from '../IrisIcon.vue';
import IrisDropdownItemCheckbox from './_listItemCheckbox.vue';
import { isListKind } from './predicates';
import { IrisDropdownTestHook } from './IrisDropdown.testHooks';

export default Vue.extend({
    name: 'IrisDropdownItem',
    components: {
        IrisAccount,
        IrisIcon,
        IrisDropdownItemCheckbox,
        IrisDropdownItemNavigationAction: () => import( './_listItemNavigationAction.vue'),
    },
    props: {
        kind: {
            type: String,
            default: 'list',
            validator: isListKind,
        },
        id: {
            type: String,
        },
        item: {
            type: Object,
            default: () => ({}),
        },
        listHasIcons: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        showCheckboxes: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            testHooks: IrisDropdownTestHook,
        };
    },
    methods: {
        emitListItemClick() {
            this.$emit('list-item-click');
        },
    },
});
