export const LIST_KIND_ACCOUNT = 'account';
export const LIST_KIND_ACTION = 'action';
export const LIST_KIND_LIST = 'list';
export const LIST_KIND_NAVIGATION = 'navigation';

export type ListKind =
  | typeof LIST_KIND_ACCOUNT
  | typeof LIST_KIND_LIST
  | typeof LIST_KIND_NAVIGATION;

export type AccountKind = 'single-line' | 'stacked';

export const POSITION_LEFT = 'left';
export const POSITION_CENTER = 'center';
export const POSITION_RIGHT = 'right';

export type Position =
  | typeof POSITION_CENTER
  | typeof POSITION_LEFT
  | typeof POSITION_RIGHT;

export interface DropdownStrings {
  filterPlaceholderText: string;
  mobileListTitleText: string;
  noResultsText: string;
}

export interface ListItem {
  disabled?: boolean;
  iconName?: string;
  label: string;
  value: string;
}

export interface AccountData extends ListItem {
  accountColor?: string;
  accountNickName?: string;
  accountNumberDisplay?: string;
  availableBalanceAmount?: string;
  availableBalanceIcon?: boolean;
  kind?: AccountKind;
}

export interface ItemData extends ListItem {
  href?: string;
  secondLine?: string;
  target?: string;
  to?: string;
}

export type MenuItem = AccountData | ItemData;

export interface ElementDimensions {
  top: number;
  left: number;
  bottom: number;
  right: number;
  width: number;
  height: number;
}
