
import Vue from 'vue';
import IrisLottieAnimation from './IrisLottieAnimation.vue';

export interface LoadingOverlayParams {
    showOverlay: boolean;
    loadingAria: string;
}

export default Vue.extend({
    components: {
        IrisLottieAnimation,
    },
});
