import { generateUniqueId } from '@/utils';
import { validationMixin } from './../mixins/validationMixin';
import { deprecationMixin } from './../mixins/deprecationMixin';

export const textfieldMixin: {[key: string]: any} = {
    mixins: [validationMixin, deprecationMixin],
    props: {
        /**
         * Sets the id (HTML global attribute) for the component. If an id is not provided, one will be generated automatically.
         */
        elementId: String,
        /**
         * Displays a visual counter for input length. The maxlength prop must also be set for the counter to be visible.
         */
        characterCounter: Boolean,
        /**
         * Sets the textfield into a disabled state.
         */
        isDisabled: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets the helper text.
         */
        helperText: String,
        /**
         * (Deprecated: Use elementId instead!) The textfield id.
         */
        id: String,
        /**
         * Sets the isLoading state with animated icon indicator.
         */
        isLoading: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets the label of the textfield. This field is required.
         */
        label: {
            type: String,
            required: true,
        },
        /**
         * Sets the leading icon name. Ex: 'person'.
         */
        leadingIconName: String,
        /**
         * Sets the maximum number of characters that can be entered into the textfield.
         */
        maxlength: {
            type: Number,
            validator: (value: number) => {
                return value > 0;
            },
        },
        /**
         * Sets the minimum number of characters that can be entered into the textfield.
         */
        minlength: {
            type: Number,
            validator: (value: number) => {
                return value > 0;
            },
        },
        /**
         * Specifies if the textfield is required or optional.
         */
        isOptional: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets the string that displays for communicating that a textfield is optional.
         */
         optionalText: {
            type: String,
            default: '(optional)',
        },
        /**
         * Sets the textfield into a read-only state.
         */
        isReadonly: {
            type: Boolean,
            default: false,
        },
        /**
         * Overrides the default screen reader messaging announced as the character count updates as a user types in a textfield that has a maxlength set.
         * (Ex: {10} more characters left to type.) This prop is typically used in the form of a sitekey for translation purposes.
         */
        screenReaderCharacterCounterMessage: {
            type: String,
            default: 'more characters left to type.',
        },
        /**
         * Shows the Skelton loader as a placeholder option.
         */
        showSkeleton: {
            type: Boolean,
            default: false,
        },
        /**
         * Puts a tooltip on the trailing icon. Prop is an object: {message: "", size: "", position: "", triggerByClick: ""}. Only message is required; size can be modified to "large", position to "left" or "right", and triggerByClick to "true".
         */
        trailingIconTooltip: {
            type: Object,
        },
        /**
         * Sets the textfield's initial value.
         */
        value: {
            type: String,
            default: '',
        },
        /**
         * Sets the spellcheck global attribute for the HTML input.
         */
        allowBrowserSpellCheck: {
            type: Boolean,
            default: false,
        },
        /**
         * Overrides the default amount of time that the system waits to validate user input. Value is in milliseconds.
         */
        validationDelay: {
            type: Number,
            validator: (value: number) => {
                return value > -1;
            },
            default: 1000,
        },
    },
    data() {
        return ({
            identifier_: this.elementId || this.id || generateUniqueId('irisv_textfield') as string,
            internalValue_: this.value as string,
            isFocused: false as boolean,
            debounce: null,
            hasChangedSinceFocus: false,
            validateImmediate: false,
        });
    },
    computed: {
        textfieldAttributes(): object {
            // these attributes are added to the input/textarea element
            return {
                'disabled': this.isDisabled,
                'value': this.internalValue_,
                'aria-describedby': this.helperText ? `${this.identifier_}_help_text` : null,
                'aria-invalid': this.shouldValidate && this.hasError ? 'true' : null,
                'aria-labelledby': `${this.identifier_}_label`,
                'aria-required': this.isOptional ? null : 'true',
                'id': this.identifier_,
                'class': 'irisv-textfield__input',
                'readonly': this.isReadonly,
                'required': this.isOptional ? null : 'true',
            };
        },
        textfieldProps(): object {
            // merge component props with data props so we can pass to the textfield container component
            // doing this to avoid having to set each prop individually on the textfield container component
            return {
                ...this.$props,
                isDirty: !!this.internalValue_,
                isFocused: this.isFocused,
                value: this.internalValue_,
                id: this.identifier_,
            };
        },
    },
    methods: {
        focus(): void {
            (this.$refs.textfield as HTMLElement).focus();
        },
        onBlur(event: Event): void {
            this.isFocused = false;

            /**
             * Emitted after the textfield loses focus (before any formatting).
             */
            this.$emit('textfield-blur', event);
        },
        onChange(): void {
            /**
             * Emitted when the change event is triggered by user interaction.
             */
            this.$emit('textfield-change', this.computedValue);
        },
        onFocus(event: Event): void {
            this.isFocused = true;

            /**
             * Emitted when the textfield gains focus.
             */
            this.$emit('textfield-focus', event);
        },
        onInput(event: Event): void {
            const value = (event.target as HTMLInputElement).value;

            this.$nextTick(() => {
                if (event.target) {
                    this.computedValue = value;
                }
            });
        },
        onMouseDown(event: Event): void {
            const target = event.target as HTMLElement;
            const input = target.querySelector('.irisv-textfield__input') as HTMLInputElement;

            if (input) {
                event.preventDefault();
                input.focus();
            }
        },
        onTrailingIconClick(): void {
            this.$emit('textfield-click-trailing-icon');
        },
        callToValidateHandler(): void {
            if (this.validateImmediate) { // Programmatic updates should go through here
                this.validateImmediate = false;
                this.validate();
            } else { // User interactions should go through here
                clearTimeout(this.$data.debounce);
                this.$data.debounce = setTimeout(() => {
                    this.hasChangedSinceFocus = true;
                    this.validate();
                }, this.validationDelay);
            }
        },
    },
};