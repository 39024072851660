
import Vue, { PropType } from 'vue';
import IrisChipsContainer from './IrisChipsContainer.vue';
import IrisMenuDropdown from './IrisMenuDropdown.vue';
import IrisQuickActionButton from './IrisQuickActionButton.vue';
import IrisButton from './IrisButton.vue';
import IrisTableLoadingOverlay, { LoadingOverlayParams } from './IrisTableLoadingOverlay.vue';
import IrisTableNoRowsOverlay, { NoRowsOverlayParams } from './IrisTableNoRowsOverlay.vue';

import { AgGridVue } from 'ag-grid-vue';

import { generateUniqueId } from '@/utils';
import { GridApi, GridReadyEvent, RowNode } from 'ag-grid-community';

interface BulkAction {
    buttonText: string;
    bulkFunction: (selectedRows: RowNode[]) => void;
}

interface AppliedFilter {
    key: string;
    chipText: string;
    filterFunction: (row: RowNode) => boolean;
}

export default Vue.extend({
    name: 'IrisTable',
    components: {
        AgGridVue,
        IrisChipsContainer,
        IrisMenuDropdown,
        IrisQuickActionButton,
        IrisButton,
        /* eslint-disable-next-line vue/no-unused-components */
        IrisTableLoadingOverlay,
        /* eslint-disable-next-line vue/no-unused-components */
        IrisTableNoRowsOverlay,
    },
    props: {
        /**
         * Sets the id (HTML global attribute) for the component. If an id is not provided, one will be generated automatically.
         */
        elementId: String,
        /**
         * Whether or not to show the loading indicator after table creation until rowData is set.
         */
        showLoader: {
            type: Boolean,
            default: true,
        },
        /**
         * The delay in milliseconds from table creation to showing the loading indicator (if rowData has not been set yet).
         */
        loaderDelayMs: {
            type: Number,
            default: 1000,
            validator: (value: number) => {
                return value >= 0;
            },
        },
        /**
         * Whether or not to show the No Rows overlay if rowData has been set but is empty (either by having no data or by having the data filtered entirely).
         */
        showNoRowsOverlay: {
            type: Boolean,
            default: true,
        },
        /**
         * Whether or not to show the subline in the No Rows overlay.
         */
        showNoRowsOverlaySubline: {
            type: Boolean,
            default: true,
        },
        /**
         * An object containing the parameters used to configure the pagination of the table.<br/><br/>
         * <strong>Key/Values are:</strong><br/><br/>
         * <strong>type</strong>: The type of pagination. Allowed values are 'table' and 'more'.<br/>
         * <strong>pageSizeOptions</strong>: The page sizes that can be selected as an array of integers. For use with type 'table' only.<br/>
         * <strong>initialPageSize</strong>: The initial selected page size as an integer. Default is 100<br/>
         * <strong>additionalLoadSize</strong>: The number of rows (as an integer) to load when the more button is clicked. Default is the value of initialPageSize. For use with type 'more' only.<br/>
         */
        paginationConfig: {
            type: Object,
            default: () => ({}),
        },
        /**
         * Show filter chips for the active filters.
         */
        showFilterChips: {
            type: Boolean,
            default: true,
        },
        /**
         * An array of objects containing the active filters (filters that are being applied to the table).<br/><br/>
         * <strong>Key/Values are:</strong><br/><br/>
         * <strong>key</strong>: The key for the filter. Must be unique.<br/><br/>
         * <strong>chipText</strong>: The text to display on the filter's chip.<br/><br/>
         * <strong>filterFunction</strong>: The function to test if a row should be filtered.<br/>
         *   - Takes a <a href="https://www.ag-grid.com/angular-data-grid/row-object/">ag-grid RowNode</a> parameter called <strong>row</strong>.<br/>
         *   - Returns true if the row should pass this specific filter.<br/>
         *   - Returns false if the row should not pass this specific filter.<br/>
         *   - A row must pass <strong>all</strong> filters in order to remain in the grid.<br/>
         */
        activeFilters: {
            type: Array as PropType<AppliedFilter[]>,
            validator: (items: AppliedFilter[]) => {
                return items.every((item) => {
                    const props = [
                        Object.keys(item).includes('key'),
                        Object.keys(item).includes('chipText'),
                        Object.keys(item).includes('filterFunction'),
                    ];

                    return !props.includes(false);
                });
            },
            default: () => ([]),
        },
        /**
         * An array of objects containing the bulk actions.<br/><br/>
         * <strong>Key/Values are:</strong><br/><br/>
         * <strong>buttonText</strong>: The text to display in the bulk action bar.<br/><br/>
         * <strong>bulkFunction</strong>: The callback function to be called when the bulk action button is clicked.<br/>
         *   - Takes an array of <a href="https://www.ag-grid.com/angular-data-grid/row-object/">ag-grid RowNodes</a>.<br/>
         *   - Returns void.<br/>
         */
        bulkActions: {
            type: Array as PropType<BulkAction[]>,
            validator: (items: BulkAction[]) => {
                return items.every((item) => {
                    const props = [
                        Object.keys(item).includes('buttonText'),
                        Object.keys(item).includes('bulkFunction'),
                    ];

                    return !props.includes(false);
                });
            },
            default: () => ([]),
        },
        /**
         * An object containing the strings used in the table.<br/><br/>
         * <strong>Keys are:</strong><br/><br/>
         * <strong>activeFiltersContainerAria</strong>: The aria label for the active filters container. Default is 'Table filters',<br/>
         * <strong>bulkActionBarClose</strong>: The aria label for the close bulk action bar button. Default is 'Close',<br/>
         * <strong>bulkActionBarItemsSelected</strong>: Items selected text. Default is 'item(s) selected',<br/>
         * <strong>loadingAria</strong>: The aria label for the loading spinner. Default is 'Loading',<br/>
         * <strong>noRowsHeading</strong>: The label for the heading of the no rows overlay. Default is 'No results found'<br/>
         * <strong>noRowsSubline</strong>: The label for the subline of the no rows overlay. Default is 'We couldn't find what you are looking for.'<br/>
         * <strong>paginationEntries</strong>: Entries per page text: "Show 1 [entries]". Default is 'entries',<br/>
         * <strong>paginationLoadMore</strong>: The label for the more button to load additional rows. Default is 'Load More',<br/>
         * <strong>paginationNextPageAria</strong>: The aria label for the next page button. Default is 'Next page',<br/>
         * <strong>paginationOf</strong>: Page controls text: "Page 1 [of] 10". Default is 'of',<br/>
         * <strong>paginationPage</strong>: Page controls text: "[Page] 1 of 10". Default is 'Page',<br/>
         * <strong>paginationPreviousPageAria</strong>: The aria label for the previous page button. Default is 'Previous page',<br/>
         * <strong>paginationShow</strong>: Entries per page text: "[Show] 1 entries". Default is 'Show',<br/>
         * <strong>paginationUnknownTotalPages</strong>: Page controls text when the total number of pages is unknown: "Page 1 of [more]". Default is 'more',<br/>
         * <strong>paginationUnknownTotalRows</strong>: Total rows text when the total number of rows is unknown: "1-5 of [more]". Default is 'more',<br/>
         */
        strings: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            identifier_: this.elementId || generateUniqueId('irisv_table') as string,
            gridApi: null as GridApi | null,
            selectedRowsCount: 0,
            defaultColDef: {
                headerCheckboxSelectionFilteredOnly: true,
                suppressKeyboardEvent: (params: any) => {
                    const e = params.event;
                    if (e.code === 'Tab' || e.key === 'Tab') {
                        // get focusable children of parent cell
                        const focusableChildrenOfParent = e.srcElement.closest('.ag-cell')
                            .querySelectorAll('button, [href], :not(.ag-hidden) > input, select, textarea, [tabindex]:not([tabindex="-1"])');

                        if (focusableChildrenOfParent.length === 0 ||
                            (e.shiftKey === false && e.srcElement === focusableChildrenOfParent[focusableChildrenOfParent.length - 1]) ||
                            (e.shiftKey === true && e.srcElement === focusableChildrenOfParent[0]) ||
                            (e.shiftKey === true && e.srcElement.classList.contains('ag-cell'))) {
                            return false; // do not suppress
                        }
                        return true; // suppress
                    }
                    return false; // do not suppress by default
                },
            },
            loaderTimeout: null as NodeJS.Timeout | null,
            isGridShowing: false,
            isHorizontalScrollbarShowing: false,
            hasDataRendered: false,
            showMoreButton: false,
            pageSizeLabel: '',
            entriesRangeLower: 0,
            entriesRangeUpper: 0,
            totalEntriesCount: 0 as number | null,
            currentPageNumber: 1,
            totalPageCount: 1 as number | null,
        };
    },
    methods: {
        onGridReady(params: GridReadyEvent) {
            this.gridApi = params.api;
            this._setupLoader();
            this._setupGridControls();

            /**
             * Emitted when the table is ready. The argument is the initialized <a href="https://www.ag-grid.com/vue-data-grid/grid-api/">Grid API</a>.
             */
            this.$emit('table-ready', this.gridApi);
        },
        onColumnResized() {
            // Table events are asynchronous so we await the next tick before displaying the grid
            Vue.nextTick(() => {
                this.isGridShowing = true;
            });
        },
        onFirstDataRendered() {
            this.hasDataRendered = true;
            if (this.gridApi) {
                if (this.loaderTimeout) {
                    clearTimeout(this.loaderTimeout);
                }
                this.gridApi.hideOverlay();
                this.isGridShowing = true;
                this.isHorizontalScrollbarShowing = true;
            }
        },
        onPaginationChanged() {
            this.updateTransportControls();
            this.setMoreButton();
        },
        onRowDataUpdated() {
            // Only called when using the client-side row model, not with infinite row model
            this._setupGridControls();
        },
        _setupGridControls() {
            this._setupPaginationControls();
            this.onSelectionChanged();
        },
        _setupLoader() {
            if (!this.gridApi) {
                return;
            }

            // AG grid's default behavior is to show the loading overlay we've provided immediately.
            // In order to delay the overlay from showing, we hide it here and show after a configurable timeout.
            // Note: We always want to hide the overlay here because if we don't (and don't provide our own),
            // AG grid will use its own "Loading..." overlay.
            this.gridApi.hideOverlay();
            if (!this.showLoader || this.gridApi.getModel().getType() === 'infinite') {
                return;
            }

            this.loaderTimeout = setTimeout(() => {
                if (this.gridApi) {
                    this.gridApi.showLoadingOverlay();
                }
            }, this.loaderDelayMs);
        },
        _setupPaginationControls() {
            if (!this.paginationConfig || !this.gridApi) {
                return;
            }
            this.gridApi.paginationSetPageSize(this.paginationConfig.initialPageSize); // Set the grid to display the configured amount of rows
            if (this.paginationConfig.type === 'table') {
                this.pageSizeLabel = this.paginationConfig.initialPageSize.toString(); // Set the label of the menu ddlb
                this.updateTransportControls();
            } else if (this.paginationConfig.type === 'more') {
                this.setMoreButton(); // Checks if the more button should be shown/hidden
            }
        },

        // Bulk Actions
        onSelectionChanged(): void {
            if (this.gridApi) {
                this.selectedRowsCount = this.gridApi.getSelectedNodes().length;
            }
        },
        onBulkActionClick(bulkAction: BulkAction): void {
            if (this.gridApi) {
                const selectedNodes = this.gridApi.getSelectedNodes();
                bulkAction.bulkFunction(selectedNodes);
            }
        },
        closeBulkActionBar(): void {
            this.selectedRowsCount = 0;
            if (this.gridApi) {
                this.gridApi.deselectAll();
            }
        },

        // Filters
        onFilterChipClicked(key: string): void {
            /**
             * Emitted when a filter chip is clicked. The argument is the associated filter's key.
             */
            this.$emit('filter-chip-clicked', key);
        },
        isExternalFilterPresent(): boolean {
            return this.activeFilters.length > 0;
        },
        doesExternalFilterPass(row: RowNode): boolean {
            if (this.activeFilters.length === 0) {
                return true;
            }
            return this.activeFilters.every((filter) => filter.filterFunction(row));
        },

        deselectHiddenRows() {
            if (this.gridApi) {
                // Hidden rows should never be selected
                this.gridApi.forEachNode((node) => {
                    if (node.rowIndex == null) {
                        // The node is filtered out
                        node.setSelected(false);
                    }
                });
            }
        },

        // Page Size
        onPageSizeSelected(e: any): void {
            if (e.selectedMenuItem) {
                this.pageSizeLabel = e.selectedMenuItem.label; // Set the label of the menu ddlb
                if (this.gridApi) {
                    // Dilemma! We want to maintain our current position in the table regardless of our page size changing.
                    // To accomplish this, we store the index of our first visible row and move to that row's page after the page size changes.
                    const firstVisibleRow = this.gridApi.paginationGetCurrentPage() * this.gridApi.paginationGetPageSize();
                    this.gridApi.paginationSetPageSize(e.selectedMenuItem.label); // Set the grid to display the selected amount of rows

                    const newPageSize = this.gridApi.paginationGetPageSize();
                    const newCurrentPage = Math.floor(firstVisibleRow / newPageSize);
                    this.gridApi.paginationGoToPage(newCurrentPage);
                }

                this.updateTransportControls();
            }
        },
        changePage(increment: boolean): void {
            if (this.gridApi) {
                if (increment) {
                    this.gridApi.paginationGoToNextPage();
                } else {
                    this.gridApi.paginationGoToPreviousPage();
                }

                this.updateTransportControls();
            }
        },
        updateTransportControls(): void {
            if (!this.gridApi) {
                return;
            }

            const rowCount = this.gridApi.paginationGetRowCount();
            const currentPage = this.gridApi.paginationGetCurrentPage();
            const pageSize = this.gridApi.paginationGetPageSize();
            const totalPages = this.gridApi.paginationGetTotalPages();

            let isLastRowKnown = true;

            if (this.gridApi.getModel().getType() === 'infinite') {
                isLastRowKnown = this.gridApi.isLastRowIndexKnown() || false;
            }

            this.entriesRangeLower = rowCount === 0 ? 0 : Math.min(currentPage * pageSize + 1, rowCount); // Set lower row range
            this.entriesRangeUpper = Math.min(this.entriesRangeLower + pageSize - 1, rowCount); // Set upper row range
            this.currentPageNumber = Math.max(1, currentPage + 1); // Set the current page

            if (isLastRowKnown) {
                this.totalEntriesCount = rowCount; // Set total number of rows
                this.totalPageCount = totalPages || 1; // Set the total number of pages
            } else {
                this.totalEntriesCount = null;
                this.totalPageCount = null;
                this.entriesRangeUpper = this.entriesRangeLower + pageSize - 1;
            }
        },

        // More Button
        moreButtonClicked(): void {
            if (this.gridApi) {
                let initialPageSize;
                let additionalLoadSize;

                if (!this.paginationConfig.initialPageSize || isNaN(this.paginationConfig.initialPageSize)) {
                    initialPageSize = 100; // This should match ag-grid's default
                } else {
                    initialPageSize = Number(this.paginationConfig.initialPageSize);
                }

                if (!this.paginationConfig.additionalLoadSize || isNaN(this.paginationConfig.additionalLoadSize)) {
                    additionalLoadSize = initialPageSize; // Use the initialPageSize as the value instead
                } else {
                    additionalLoadSize = Number(this.paginationConfig.additionalLoadSize);
                }

                const currentlyShowing = this.gridApi.paginationGetPageSize(); // Find out how many rows are currently on screen
                const newAmountToShow = currentlyShowing + additionalLoadSize; // Determine how many rows to show after clicking the more button

                this.gridApi.paginationSetPageSize(newAmountToShow); // Set the number of rows to show
                this.setMoreButton(); // Checks if the more button should be shown/hidden
            }
        },
        setMoreButton(): void {
            if (this.gridApi) {
                if (this.gridApi.paginationGetPageSize() >= this.gridApi.paginationGetRowCount()) {
                    this.showMoreButton = false;
                } else {
                    this.showMoreButton = true;
                }
            }
        },
    },
    computed: {
        mergedStrings(): any {
            return {
                activeFiltersContainerAria: 'Table filters',
                bulkActionBarClose: 'Close',
                bulkActionBarItemsSelected: 'item(s) selected',
                loadingAria: 'Loading',
                noRowsHeading: 'No results found',
                noRowsSubline: `We couldn't find what you are looking for.`,
                paginationEntries: 'entries',
                paginationLoadMore: 'Load More',
                paginationNextPageAria: 'Next page',
                paginationOf: 'of',
                paginationPage: 'Page',
                paginationPreviousPageAria: 'Previous page',
                paginationShow: 'Show',
                paginationUnknownTotalPages: 'more',
                paginationUnknownTotalRows: 'more',
                ...this.strings,
            };
        },

        // Bulk Action Bar
        showBulkActionBarComp(): boolean {
            return this.bulkActions.length > 0 && this.selectedRowsCount > 0;
        },

        // Loading Overlay
        loadingOverlayParams(): LoadingOverlayParams {
            return {
                showOverlay: this.showLoader,
                loadingAria: this.mergedStrings.loadingAria,
            };
        },

        // No Rows Overlay
        noRowsOverlayParams(): NoRowsOverlayParams {
            return {
                showOverlay: this.showNoRowsOverlay,
                showSubline: this.showNoRowsOverlaySubline,
                heading: this.mergedStrings.noRowsHeading,
                subline: this.mergedStrings.noRowsSubline,
            };
        },

        // Filters
        showFilterChipsComp(): boolean {
            return !this.showBulkActionBarComp && this.showFilterChips && this.activeFilters.length > 0;
        },
        chipsData() {
            const chipsData: { [key: string]: {} } = {};

            this.activeFilters.forEach((filter) => {
                chipsData[filter.key] = {
                    label: filter.chipText,
                    value: filter.key,
                    name: filter.key,
                    trailingIconName: 'times',
                };
            });

            return chipsData;
        },

        // Pagination
        usePagination(): boolean {
            const paginationTypes = ['table', 'more'];
            return !!this.paginationConfig && paginationTypes.includes(this.paginationConfig.type);
        },
        pageSizeOptions(): any[] {
            if (!this.paginationConfig || !this.paginationConfig.pageSizeOptions) {
                return [];
            }
            return this.paginationConfig.pageSizeOptions.map((option: number) => ({
                label: option.toString(),
                value: option,
            }));
        },
    },
    watch: {
        activeFilters() {
            if (!this.gridApi) {
                return;
            }

            this.gridApi.onFilterChanged();
            this.deselectHiddenRows();

            if (!this.showNoRowsOverlay) {
                return;
            }
            if (this.gridApi.getDisplayedRowCount() === 0) {
                this.gridApi.showNoRowsOverlay();
            } else {
                this.gridApi.hideOverlay();
            }
        },
    },
});
