
import Vue from 'vue';

/**
 * Checkboxes are used for a list of options where a user may select multiple or none at all.
 */
export default Vue.extend({
    name: 'IrisCheckbox',

    model: {
        prop: 'isChecked',
        event: 'checkbox-change',
    },

    props: {
        /**
         * The text label for the checkbox.
         */
        label: String,
        /**
         * Prop for cases of passing initial value into component without using v-model.<br /><br />
         * Without v-model listen for event 'checkbox-change' for changes to component.
         */
        isChecked: Boolean,
        /**
         * Boolean to set checkbox to its low emphasis appearance.
         */
        isLowEmphasis: Boolean,
    },

    data() {
        return {
            selected: false,
        };
    },

    methods: {
        selectValue(newVal: any) {
            this.$emit('checkbox-change', newVal.target.checked);
            this.selected = newVal.target.checked;
        },
        onAfterEnterTransition() {
            (this.$refs.polyline as HTMLElement).style.cssText = this.selected
                ? 'stroke-dashoffset: 0;'
                : 'stroke-dashoffset: 24;';
        },
    },

    watch: {
        isChecked(value) {
            this.selected = value;
        },
    },

    mounted() {
        this.selected = this.isChecked;
    },
});
