var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"irisvQuickActionButtonContainer",staticClass:"irisv-quick-action-button-container",class:{
        'irisv-quick-action-button-container--sliding': _vm.sliding,
    }},[_c('div',{ref:"sliderWrapper",staticClass:"irisv-quick-action-button-container__slider-wrapper",on:{"mousedown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"]))return null;if('button' in $event && $event.button !== 0)return null;return _vm.dragDown.apply(null, arguments)},"touchstart":_vm.dragDown,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"])&&_vm._k($event.keyCode,"right",39,$event.key,["Right","ArrowRight"]))return null;if('button' in $event && $event.button !== 0)return null;if('button' in $event && $event.button !== 2)return null;return _vm.leftRightKeyup.apply(null, arguments)}}},[_c('transition-group',{ref:"slider",staticClass:"irisv-quick-action-button-container__slider",class:{ vertical : _vm.vertical },attrs:{"tag":"ul","name":"button_container","role":"listbox","aria-label":_vm.ariaLabel}},_vm._l((_vm.quickActionButtonsData),function(button,key){return _c('li',{key:`${key}_button`,staticClass:"irisv-quick-action-button-container__slider-item",attrs:{"role":"option"}},[_c('iris-quick-action-button',{ref:"buttons",refInFor:true,attrs:{"kind":button.kind,"variation":_vm.variation,"alignment":button.alignment,"href":button.href,"target":button.target,"label":button.label,"border-radius-override":button.borderRadiusOverride,"avatar-props":{
                        mainIconName: button.mainIconName,
                        fallbackLetter: button.fallbackLetter,
                        image: button.image,
                        size: button.size,
                        borderRadiusOverride: button.borderRadiusOverride
                    },"is-toggle":button.isToggle,"model-value":button.vModel},on:{"quick-action-button-click":function($event){return _vm.clickHandler(button)},"quick-action-button-toggle":function($event){return _vm.toggleHandler($event, button)}}})],1)}),0)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }