
import { generateUniqueId } from '@/utils';
import Vue from 'vue';
import { deprecationMixin } from './../mixins/deprecationMixin';
import { textfieldMixin } from './../mixins/textfieldMixin';
import IrisTextfieldContainer from './IrisTextfieldContainer.vue';
import IrisDropdown from './IrisDropdown/index.vue';
import { MenuItem } from './IrisDropdown/types';

export default Vue.extend({
    name: 'IrisTextfieldDropdown',
    inheritAttrs: false,
    components: {
        IrisTextfieldContainer,
        IrisDropdown,
    },
    model: {
        event: 'textfield-input',
    },
    mixins: [textfieldMixin, deprecationMixin],
    props: {
        /**
         * Sets the id (HTML global attribute) for the component. If an id is not provided, one will be generated automatically.
         */
        elementId: String,
        /**
         * (Deprecated: Use elementId instead!) The textfield id.
         */
        id: String,
        /**
         * Sets the aria-label for the clearable icon. You can leave it as the default, 'Delete text'
         */
        clearIconAriaLabel: {
            type: String,
            default: 'Delete text',
        },
        /**
         * Sets the aria-label for the trailing icon. We have included the icon's name as a fallback, but please update to something descriptive for screen reader user.
         */
        trailingIconAriaLabel: String,
        /**
         * Displays an icon to clear text from textfield. The clearable icon only appears when the textfield contains a value and is in focus.
         */
        isClearable: {
            type: Boolean,
            default: true,
        },
        /**
         * Sets the trailing icon name. Ex: 'info'.
         */
        trailingIconName: String,
        /**
         * Displays dividing lines between list items.
         */
        showListDividers: {
            type: Boolean,
            default: true,
        },
        /**
         * This is an array of objects - each object is one list item.<br /><br />
         * <strong>Required keys are:</strong><br />
         * "label" (string): Sets the list item label.<br /><br />
         * <strong>Optional keys are:</strong><br />
         * "iconName" (string): Sets the icon.<br /><br />
         * "disabled" (boolean): Disables the list item.
         */
        items: {
            default: () => [],
            type: Array as () => any[],
        },
        /**
         * Toggles the display of the list.
         */
        listToggle: {
            type: Boolean,
            default: false,
        },
        /**
         * Displays a label at the top of the mobile style list
         */
        showLabelInMobileList: {
            type: Boolean,
            default: true,
        },
        /**
         * Sets the label to display at the top of the mobile style list. If no value is provided, the value for 'label' is used by default.
         */
        labelForMobileList: String,
        /**
         * Sets the placeholder text for filter input of the mobile style list. The default value is 'Search'.
         */
        placeholderForMobileFilterInput: {
            default: 'Search',
            type: String,
        },
    },
    data() {
        return {
            identifier_: this.elementId || this.id || generateUniqueId('irisv_textfield_dropdown') as string,
            isOpen: false, // Since the listToggle.sync is an optional prop, we need this independent variable to control the list
            allowBubble: true,
            isMobile: false,
            ignoreFilter: true,
            itemsCopy: [] as MenuItem[],
        };
    },
    computed: {
        computedValue: { // This will be called whenever computedValue is changed - either from textfieldMixin or v-model
            get(): string {
                return this.internalValue_;
            },
            set(value: string) {
                this.internalValue_ = value;
                this.callToValidateHandler(); // textfieldMixin function
                /**
                 * Emitted when component receives input.
                 */
                this.$emit('textfield-input', value);
            },
        },
        listeners(): object {
            return {
                ...this.$listeners,
                'focus': this.focusHandler,
                'input': [this.inputHandler, this.onInput],
                'blur': this.blurHandler,
                'change': this.onChange,
                'keypress.enter': this.onChange,
            };
        },
    },
    methods: {
        clickHandler() {
            if (!this.isOpen) {
                this.setIsOpen(true);
            }
        },
        clearText(): void {
            this.computedValue = '';
            this.$emit('textfield-change', this.computedValue);
            (this.$refs.textfield as HTMLElement).focus();

            /**
             * Emitted when the clearable icon is clicked.
             */
            this.$emit('textfield-clear');
        },
        focusHandler(event: Event) {
            if (!this.isFocused && !this.isOpen) {
                this.triggerFocus(event);
            }
        },
        triggerFocus(event: Event) {
            // this.IsFocused is from the textfieldMixin.
            if (this.isFocused === true) { return; }

            // Since we are not using the textfieldMixin onFocus handler, we need to set this flag for ourselves
            this.isFocused = true;

            /**
             * Emitted when textfield gains focus.
             */
            this.$emit('textfield-focus', event);
        },
        inputHandler() {
            // Once the user types anything, we want the filter to start working again (desktop version)
            this.ignoreFilter = false;
        },
        blurHandler(event: Event) {
            if (this.isFocused && !this.isOpen) {
                this.triggerBlur(event);
            }
        },
        triggerBlur(event: any) {
            // this.IsFocused is from the textfieldMixin.
            if (this.isFocused === false) { return; }

            // Since we are not using the textfieldMixin onFocus handler, we need to set this flag for ourselves
            this.isFocused = false;

            /**
             * Emitted after the textfield loses focus
             */
            this.$emit('textfield-blur', event);

            // This won't be needed anymore after the official blur of the control. If we don't remove it, it can continue firing every time the document is clicked
            document.removeEventListener('click', this._documentClick);
        },
        preventEscapeBubble(keyboardEvent: KeyboardEvent) {
            if (this.allowBubble) { return; }

            keyboardEvent.stopPropagation();
            this.allowBubble = true;
        },
        listFilterInput(event: Event) {
            // Once the user types anything, we want the filter to start working again (mobile version)
            this.ignoreFilter = false;
            this.computedValue = (event.target as HTMLInputElement).value;
        },
        listItemSelectedData(event: {isVModelUpdate: boolean; item: string[]; itemIndex: number}) {
            if (event.isVModelUpdate) { return; }

            const correspondingItem: any = this.itemsCopy.find((item) => item.value === event.item[0]);

            // The computed function for computedValue will set the internalValue_ then emit the event
            this.computedValue = correspondingItem.label;
        },
        rootKeyHandler(keyboardEvent: KeyboardEvent) {
            const code = keyboardEvent.code;
            const ignoreKeys = ['Tab', 'Meta', 'Shift', 'Ctrl', 'Control', 'CapsLock', 'Alt', 'Caps Lock', 'PageUp', 'PageDown', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Insert'];

            switch (code) {
            case 'Tab':
                if (this.isOpen) {
                    // This prevents tabbing away from the control while the list is open
                    keyboardEvent.preventDefault();
                }
                break;
            case 'Enter':
            case 'Escape':
                // Check if the menu is still open after the user pressed enter/escape
                if (this.isOpen) {
                    this.setIsOpen(false);
                }
                break;
            case 'Backspace':
                this.$nextTick(() => {
                    // Check if the menu is closed
                    if (!this.isOpen) {
                        this.setIsOpen(true);
                    }
                });
                break;
            default:
                // Check if the key pressed was something we should ignore
                if (ignoreKeys.indexOf(keyboardEvent.key) === -1) {
                    this.$nextTick(() => {
                        this.setIsOpen(true);
                    });
                }
                break;
            }
        },
        _setListMode() {
            this.isMobile = window.matchMedia('(max-width: 560px)').matches;
        },
        _documentClick(event: Event) {
            const elementThatWasClicked = event.target as HTMLElement;
            const listRef = (document.querySelector(`#${this.identifier_}__list-container`) as HTMLElement);

            if (this.$el.contains(elementThatWasClicked)) { return; }
            if (listRef.contains(elementThatWasClicked)) { return; }

            this.triggerBlur(event);
        },
        _getItemsWithValues(): MenuItem[] {
            return this.items.map((item, index) => ({ ...item, value: item.value || `${index}-${item.label}` }));
        },
        setIsOpen(open: boolean) {
            // This shall be the only way to trigger the watcher
            this.isOpen = open;
        },
        handleTextfieldDropdownOpen() {
            /*
             * When the menu is open, we don't want to allow escape keys to bubble, the list will trigger the close if the escape
             * key is pressed. If the escape key is pressed while the textfield dropdown rootKeyHandler is in control, it will allow
             * escape key bubbling.
             */
            this.allowBubble = false;

            // Disable the filter logic so that all items are shown. We clear it on open so we don't see list items flash during the close animation.
            this.ignoreFilter = true;

            // Reset the contents to the original list
            this.itemsCopy = [...this._getItemsWithValues()];

            /**
             * Emitted when dropdown menu is opened.
             */
            this.$emit('textfield-dropdown-opened');
            document.addEventListener('click', this._documentClick);
        },
        handleTextfieldDropdownClose() {
            /*
             * Often when the list is being closed, the user has shifted focus to something inside that list. It could
             * be an item they clicked, the filter, or a slot. We need to send it back to the textfield.
             */
            this._focusTextfield();

            this.validate();

            /**
             * Emitted when dropdown menu is closed.
             */
            this.$emit('textfield-dropdown-closed');
        },
        _focusTextfield() {
            const textfieldRef = this.$refs.textfield as HTMLElement;

            // If the active element is not the textfield and the textfield is currently 'focused'
            if (document.activeElement !== textfieldRef && this.isFocused) {
                textfieldRef.focus();
            }
        },
    },
    mounted() {
        // This sets the list mode based on the window size
        this._setListMode();

        // Make a copy of the provided items so we can manipulate it
        this.itemsCopy = [...this._getItemsWithValues()];

        // Prevent resize event due to the hiding/showing of app shell causing the webview to resize and close the menu inadvertently
        if (window.isFlutterWebview === undefined) {
            // Attach listener for window resize events
            window.addEventListener('resize', this._setListMode);
        }
    },
    destroyed() {
        document.removeEventListener('click', this._documentClick);
        window.removeEventListener('resize', this._setListMode);
    },
    watch: {
        value(newValue: string) {
            // If these values don't match, then the change came from v-model, not the user typing
            if (newValue === this.computedValue) { return; }

            // this.validateImmediate is textfieldMixin data
            this.validateImmediate = true;
            this.computedValue = newValue;
        },
        internalValue_() {
            // Start with the provided list
            const newItemsCopy = [...this._getItemsWithValues()];

            if (this.internalValue_ !== '' && this.isOpen) {
                // Make a new list item from what the user typed and add it to the top of the provided list
                newItemsCopy.unshift({ label: this.internalValue_, value: this.internalValue_ });
            }

            this.itemsCopy = [...newItemsCopy];
        },
        isOpen(open: boolean) {
            // This should be the only place that emits the update event for listToggle.
            /**
             * Emitted when closing the list internally is needed, and updates the listToggle prop.<br /><br />
             * The listToggle set on the component element must use the "sync" feature for this to work.<br /><br />
             * ex: :listToggle.sync="myDataVarToControlThis"<br /><br />
             * No direct reaction to the event is required.
             */
            this.$emit('update:listToggle', open);

            if (open) {
                this.handleTextfieldDropdownOpen();
            } else {
                this.handleTextfieldDropdownClose();
            }
        },
        listToggle(open: boolean) {
            if (this.isOpen === open) { return; }

            // Re-synchronize listToggle and isOpen
            this.setIsOpen(open);
        },
    },
});
