// tslint:disable:no-console
export const deprecationMixin: {[key: string]: any} = {
    methods: {
        deprecationWarning(): void {
            const deprecatedComponent = this.$el.dataset.deprecated;

            // Id prop
            if (this.id !== '' && this.id !== undefined) {
                console.warn(`WARNING: The id prop for this component (${this.$options.name ? this.$options.name : 'no component name'}) is deprecated. Refer to the component documentation and use elementId prop to set the id attribute instead.`);
            }

            // accountNumberMask prop
            if (this.accountNumberMask !== '' && this.accountNumberMask !== undefined) {
                console.warn('WARNING: The accountNumberMask prop for Account components is deprecated. Refer to the Account component documentation and use the accountNumberDisplay prop instead.');
            }

            // accountNumberMaskAriaLabel prop
            if (this.accountNumberMaskAriaLabel !== '' && this.accountNumberMaskAriaLabel !== undefined) {
                console.warn('WARNING: The accountNumberMaskAriaLabel prop for Account components is deprecated.');
            }

            // accountNumberEnding prop
            if (this.accountNumberEnding !== '' && this.accountNumberEnding !== undefined) {
                console.warn('WARNING: The accountNumberEnding prop for Account components is deprecated. Refer to the Account component documentation and use the accountNumberDisplay prop instead.');
            }

            // isIconDecorative prop
            if (this.isIconDecorative !== '' && this.isIconDecorative !== undefined) {
                console.warn('WARNING: The isIconDecorative prop for Icon components is deprecated. Refer to the Icon component documentation and use the isAriaHidden prop instead.');
            }

            // iconIsIconDecorative prop
            if (this.iconIsIconDecorative !== '' && this.iconIsIconDecorative !== undefined) {
                console.warn('WARNING: The iconIsIconDecorative prop for Notification components is deprecated. Refer to the Notification component documentation and use the iconIsAriaHidden prop instead.');
            }

            if (deprecatedComponent) {
                console.warn(`WARNING: ${deprecatedComponent} has been deprecated. Please discontinue use of this component.`);
            }
        },
    },
    mounted() {
        this.deprecationWarning();
    },
    updated() {
        this.deprecationWarning();
    },
};