import {
    LIST_KIND_ACCOUNT,
    LIST_KIND_ACTION,
    LIST_KIND_LIST,
    LIST_KIND_NAVIGATION,
    ListKind,
    POSITION_CENTER,
    POSITION_LEFT,
    POSITION_RIGHT,
    Position,
} from '@/components/IrisDropdown/types';

/**
 * Determines if a given string is of type, `ListKind`.
 * @param value The string value to test.
 * @returns Returns `true` if the given string is a `ListKind`.
 */
export const isListKind = (value: unknown): value is ListKind =>
    value === LIST_KIND_ACCOUNT ||
  value === LIST_KIND_ACTION ||
  value === LIST_KIND_LIST ||
  value === LIST_KIND_NAVIGATION;

/**
 * Determines if a given string is of type, `Position`.
 * @param value The string value to test.
 * @returns Returns `true` if the given string is a `Position`.
 */
export const isPosition = (value: unknown): value is Position =>
    value === POSITION_CENTER ||
  value === POSITION_LEFT ||
  value === POSITION_RIGHT;
