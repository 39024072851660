
import Vue from 'vue';
import IrisIcon from './IrisIcon.vue';
import { deprecationMixin } from './../mixins/deprecationMixin';

/**
 * The button component is a small molecule that serves as a call-to-action affordance.
 */
export default Vue.extend({
    name: 'IrisButton',
    components: {
        IrisIcon,
    },
    mixins: [deprecationMixin],
    props: {
        /**
         * Sets the button variant. Valid values are 'highEmphasis', 'mediumEmphasis', 'lowEmphasis', or 'compact'.
         */
        kind: {
            type: String,
            default: 'highEmphasis',
            validator: (value: string) => {
                return ['highemphasis', 'mediumemphasis', 'lowemphasis', 'compact'].includes(
                    value.toLowerCase(),
                );
            },
        },
        /**
         * Sets the button type. Valid values are 'button', 'submit' or 'reset'.
         */
        type: {
            type: String,
            default: 'button',
            validator: (value: string) => {
                return ['button', 'submit', 'reset'].includes(
                    value.toLowerCase(),
                );
            },
        },
        /**
         * Sets the button href to a valid url.
         */
        href: String,
        /**
         * Sets the text casing of the button text. Valid values are 'sentence', 'capitalize', 'uppercase', 'lowercase' or 'none'.
         */
        textCasing: {
            type: String,
            default: 'none',
            validator: (value: string) => {
                return ['sentence', 'capitalize', 'uppercase', 'lowercase', 'none'].includes(
                    value.toLowerCase(),
                );
            },
        },
        /**
         * Sets the border radius, but only used as an override since default border radius value should be set by the theme. Expects a css value for border radius. Can be between 1-4 values for each corner of the shape. Examples '44px', '10% 30% 50% 70%'
         */
        borderRadiusOverride: {
            type: String,
        },
        /**
         * Sets button to span the full-width of it's parent container.
         */
        fullWidth: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets the icon name
         */
        iconName: {
            type: String,
            default: '',
        },
        /**
         * Sets the aria-label for icon only button.
         */
        ariaLabel: String,
        /**
         * Sets the icon position. Valid values are 'left' and 'right'.
         */
        iconPosition: {
            type: String,
            default: 'left',
            validator: (value: string) => {
                return ['left', 'right'].includes(value.toLowerCase());
            },
        },
        /**
         * Sets the isLoading state with animated icon indicator.
         */
        isLoading: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets the loading text to display in the button.
         */
        loadingText: {
            type: String,
            default: 'Loading',
        },
        /**
         * Sets the loading has started alert message announced by screen reader.
         */
        loadingAlertMessage: {
            type: String,
            default: 'Loading',
        },
        /**
         * Shows the skeleton loader as a placeholder option.
         */
        showSkeleton: {
            type: Boolean,
            default: false,
        },
        /**
         * Disables the button and shows the disabled state.
         */
        isDisabled: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets the displayed text in the button.
         */
        text: {
            type: String,
            default: '',
        },
        /**
         * Sets the color scheme to account for the surface color below the button.
         */
        theme: {
            type: String,
            default: 'onLight',
            validator: (value: string) => {
                return ['onlight', 'ondark'].includes(value.toLowerCase());
            },
        },
        /**
         * Sets the target of button set to be a link with href.
         */
        target: {
            type: String,
            validator: (value: string) => {
                return ['_self', '_blank', '_parent', '_top'].includes(value.toLowerCase());
            },
        },
    },
    computed: {
        ariaLiveMessage(): string {
            return this.isLoading ? this.loadingAlertMessage : '';
        },
        currentText(): string {
            return this.isLoading ? this.loadingText : this.text;
        },
        tag(): string {
            return this.href ? 'a' : 'button';
        },
    },
    methods: {
        onClick($event: Event) {
            if (this.isDisabled || this.isLoading) {
                $event.preventDefault();
                $event.stopPropagation();
                return;
            }
            /**
             * Emitted when the button is clicked.
             */
            this.$emit('button-click', $event);
        },
    },
});
