var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
        'irisv-textfield-dropdown',
        _vm.isOpen ? 'irisv-textfield-dropdown--open': '',
    ],attrs:{"id":_vm.identifier_,"aria-haspopup":"true","aria-expanded":_vm.isOpen ? 'true' : 'false'}},[_c('iris-textfield-container',{ref:"trigger",attrs:{"textfield-props":_vm.textfieldProps,"validation-data":_vm.validationData},on:{"textfield-click":_vm.clickHandler,"textfield-mousedown":_vm.onMouseDown,"textfield-clear":_vm.clearText,"textfield-click-trailing-icon":_vm.onTrailingIconClick}},[_c('input',_vm._g(_vm._b({ref:"textfield",staticClass:"font-body-1",attrs:{"type":"text","spellcheck":_vm.allowBrowserSpellCheck},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"escape",undefined,$event.key,undefined))return null;return _vm.preventEscapeBubble.apply(null, arguments)}}},'input',[_vm.textfieldAttributes, _vm.$attrs],false),_vm.listeners))]),_c('iris-dropdown',{attrs:{"element-id":_vm.identifier_,"strings":{
            mobileListTitleText: !!_vm.labelForMobileList && _vm.labelForMobileList !== '' ? _vm.labelForMobileList : _vm.label,
            filterPlaceholderText: _vm.placeholderForMobileFilterInput,
        },"kind":"list","match-trigger-width":true,"show-list-dividers":_vm.showListDividers,"show-mobile-list-title":_vm.showLabelInMobileList,"items":_vm.itemsCopy,"is-open":_vm.isOpen,"trigger-ref-from-parent":() => _vm.$refs['trigger'],"focus-ref-from-parent":() => _vm.$refs['textfield'],"show-filter":_vm.isMobile,"show-filter-search-icon":false,"filter":_vm.computedValue,"ignore-filter":_vm.ignoreFilter},on:{"update:isOpen":_vm.setIsOpen,"list-filter-input":_vm.listFilterInput,"list-item-selected-data":_vm.listItemSelectedData,"listbox-key-passthrough":_vm.rootKeyHandler},scopedSlots:_vm._u([{key:"bottomSlotFromParent",fn:function(){return [_vm._t("default")]},proxy:true}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }