
import Vue from 'vue';
import IrisTextfieldContainer from './IrisTextfieldContainer.vue';
import { textfieldMixin } from './../mixins/textfieldMixin';

export default Vue.extend({
    name: 'IrisTextfieldDate',
    model: {
        prop: 'value',
        event: 'textfield-input',
    },
    components: { IrisTextfieldContainer },
    mixins: [textfieldMixin],
    props: {
        /**
         * The input value
         */
        value: String,
        /**
         * Displays an icon to clear text from textfield. The clearable icon only appears when the textfield contains a value and is in focus.
         */
        isClearable: {
            type: Boolean,
            default: true,
        },
        /**
         * Sets the aria-label for the clearable icon.
         */
        clearIconAriaLabel: {
            type: String,
            default: 'Delete text',
        },
        showPlaceholder: {
            type: Boolean,
            default: true,
        },
        /**
         * Sets the aria-label that describes the expected input for a date textfield.
         */
        instructionsAriaLabel: {
            type: String,
            default: 'Using only numbers, enter a two digit month, two digit day, and four digit year that represents a date.',
        },
        /**
         * Overrides the default internal error messaging displayed when the date textfield's value does not match the component's expected internal pattern.
         * Ex: (A user enters '98/76/5432' into the textfield). This prop is typically used in the form of a sitetext key for translation purposes.
         */
        datePatternMismatchMessage: {
            type: String,
            default: 'Invalid date format',
        },
        /**
         * Sets the string that displays next to the value when the entered date is today's date.
         */
        todayText: {
            type: String,
            default: '(Today)',
        },
        /**
         * Sets the validation behavior to check after user input in addition to after blur.
         */
        validateOnInput: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return ({
            formattedValue: '',
            startingValue: '',
            textfieldType: 'date',
            debounce: null,
        });
    },
    computed: {
        listeners(): object {
            return {
                ...this.$listeners,
                input: this.onInput,
                blur: this.onBlur,
                focus: this.onFocus,
            };
        },
        compHiddenLabel(): string {
            return this.isOptional ? `${this.label}, ${this.optionalText}, ${this.instructionsAriaLabel}` : `${this.label}, ${this.instructionsAriaLabel}`;
        },
    },
    methods: {
        clearText(): void {
            this.internalValue_ = ''; // Set this internalValue_ needed by the textfieldMixin
            this.formattedValue = '';
            this.$emit('textfield-input', '');
            this.$emit('textfield-input-date', {
                dateString: '',
                dayNumber: null,
                monthNumber: null,
                yearNumber: null,
            });

            /**
             * Emitted when the clearable icon is clicked.
             */
            this.$emit('textfield-clear');
        },
        onFocus(event: Event): void { // Replaces textfieldMixin onFocus method
            this.isFocused = true; // textfieldMixin data
            this.startingValue = this.formattedValue; // Capture the value that is in the input as the 'starting value' before it is changed

            /**
             * Emitted when the textfield gains focus.
             */
            this.$emit('textfield-focus', event);
        },
        onBlur(event: Event): void { // Replaces textfieldMixin onBlur method
            this.isFocused = false; // textfieldMixin data

            if (this.formattedValue === '') { // Field is blank
                if (this.isOptional) {
                    this.errorList = []; // Clear out the error list
                } else {
                    this.internalValue_ = ''; // Sets internalValue_ needed by the textfieldMixin
                    this.validate(); // Call the validationMixin version of validate function to get the 'field is required' error
                }
            } else { // Field has a value
                this._cleanInput(); // Call this to remove 'today' string if present
                this._validate(); // Call internal validate function to check what user entered
            }

            if (this.formattedValue !== this.startingValue) { // Check if the current input value is different than the starting value
                /**
                 * Emitted when the change event is triggered by user interaction.
                 */
                this.$emit('textfield-change', this.formattedValue);
            }

            /**
             * Emitted after the textfield loses focus (before any formatting).
             */
            this.$emit('textfield-blur', event);
        },
        onInput(event: any): void { // Replaces textfieldMixin onInput method
            this._cleanInput(); // Will remove any invalid characters

            if (event.inputType === 'deleteContentForward' || event.inputType === 'deleteContentBackward') { // Check if the user is deleting or backspacing
                this._handleDelete();
            }

            if (event.inputType === 'insertText') { // Check if user is just typing in the field
                this._handleInsert();
            }

            this.internalValue_ = this.formattedValue; // Sets internalValue_ needed by the textfieldMixin

            /**
             * Emitted when component receives input.
             */
            this.$emit('textfield-input', this.formattedValue);

            if (this.validateOnInput) {
                clearTimeout(this.$data.debounce);
                this.$data.debounce = setTimeout(() => {
                    this._validate();
                }, this.validationDelay); // textfieldMixin prop
            }
        },
        _cleanInput(): void {
            this.formattedValue = this.formattedValue.replace(/[^0-9-./]/g , ''); // Strip all alpha and symbols except /.-
            this.formattedValue = this.formattedValue.replace(/[.|-]/g , '/'); // Replace . and - with a /
        },
        _handleDelete(): void {
            const textfield = (this.$refs.textfield as HTMLInputElement); // Get a reference to the textfield
            const cursorPos = textfield.selectionStart; // Get the position of the cursor
            const charArr = this.formattedValue.split(''); // Break the value into pieces

            if (charArr.length > 0) { // Only continue if there is a value
                if (cursorPos === 2) { // Check if cursor is at the first slash
                    // Check if there is anything in the position after the first slash, or, if the character in position 2 is not a slash
                    if (charArr[3] !== undefined || (charArr[2] !== undefined && charArr[2].search(/\//) === -1)) {
                        charArr.splice(cursorPos, 0, '/'); // They deleted a slash, let's put it back in
                        this.formattedValue = charArr.join(''); // Re-assemble the value
                    }
                }

                if (cursorPos === 5) { // Check if cursor is at the second slash
                    // Check if there is anything in the position after the second slash, or, if the character in position 5 is not a slash
                    if (charArr[6] !== undefined || (charArr[5] !== undefined && charArr[5].search(/\//) === -1)) {
                        charArr.splice(cursorPos, 0, '/'); // They deleted a slash, let's put it back in
                        this.formattedValue = charArr.join(''); // Re-assemble the value
                    }
                }
            }
        },
        _handleInsert(): void {
            const textfield = (this.$refs.textfield as HTMLInputElement); // Get a reference to the textfield
            const cursorPos = textfield.selectionStart; // Get the position of the cursor
            const charArr = this.formattedValue.split(''); // Break the value into pieces

            if (cursorPos !== null) {
                if (charArr[cursorPos - 1] === undefined) { // If there is no value where the input is detected, quit
                    return;
                }
            }

            /* A helpful key:
             on-screen: 1 2 / 3 4 / 5 6 7 8
             cursorPos: 1 2 3 4 5 6 7 8 9 10
             charArr:   0 1 2 3 4 5 6 7 8 9
            */
            if (charArr.length > 0) { // Only continue if there is a value
                switch (cursorPos) {
                case 1:  // Mm/dd/yyyy
                    if (charArr[0].search(/[0-9]/) === -1) { // Check if the user typed something other than a number
                        charArr[0] = ''; // Remove what they typed
                        this.formattedValue = charArr.join(''); // Re-assemble the value
                        setTimeout(() => {
                            textfield.setSelectionRange(cursorPos - 1, cursorPos - 1);
                        }, 0);
                    }

                    if ((charArr[1] !== undefined && charArr[1].search(/[0-9]/) === 0) && (charArr[2] !== undefined && charArr[2].search(/[0-9]/) === 0)) { // Check if the first 2 numbers already exist
                        charArr[cursorPos - 1] = ''; // Remove what they typed
                        this.formattedValue = charArr.join(''); // Re-assemble the value
                        setTimeout(() => {
                            textfield.setSelectionRange(cursorPos - 1, cursorPos - 1);
                        }, 0);
                    }
                    break;
                case 4:  // mm/Dd/yyyy
                case 7:  // mm/dd/Yyyy
                case 8:  // mm/dd/yYyy
                case 9:  // mm/dd/yyYy
                case 10: // mm/dd/yyyY
                    if (charArr[cursorPos - 1].search(/[0-9]/) === -1) { // Check if the user typed something other than a number
                        charArr[cursorPos - 1] = ''; // Remove what they typed
                        this.formattedValue = charArr.join(''); // Re-assemble the value
                        setTimeout(() => {
                            textfield.setSelectionRange(cursorPos - 1, cursorPos - 1);
                        }, 0);
                    }
                    break;
                case 2: // mM/dd/yyyy
                case 5: // mm/dD/yyyy
                    if (charArr[cursorPos - 1] === '/' && (charArr[cursorPos] === undefined || charArr[cursorPos] === '/')) { // Check if user typed a slash early
                        // Need to turn a value like '1' into '01'
                        charArr[cursorPos - 1] = charArr[cursorPos - 2]; // Move the number they typed forward 1 position
                        charArr[cursorPos - 2] = '0'; // Add a 0 to the correct position
                        charArr[cursorPos] = '/'; // Add the slash to the correct position
                        this.formattedValue = charArr.join(''); // Re-assemble the value
                    } else if (charArr[cursorPos - 1].search(/[0-9]/) === -1) { // Check if the user typed something other than a number
                        charArr[cursorPos - 1] = ''; // Remove what they typed
                        this.formattedValue = charArr.join(''); // Re-assemble the value
                    } else if (charArr[cursorPos] !== undefined && charArr[cursorPos].search(/[0-9]/) === 0) { // Check if a number already exists to the right
                        charArr[cursorPos - 1 ] = ''; // Remove what they typed
                        this.formattedValue = charArr.join(''); // Re-assemble the value
                    } else { // Recieved expected input
                        charArr[cursorPos] = '/'; // Automatically make the next character a slash
                        this.formattedValue = charArr.join(''); // Re-assemble the value
                    }
                    break;
                case 3: // First slash
                case 6: // Second slash
                    if (charArr[cursorPos - 1].search(/\//) === -1) { // Check if the user typed something other than a slash
                        const usrVal = charArr[cursorPos - 1]; // save off what they typed
                        charArr[cursorPos - 1] = '/'; // place a slash automatically in the correct position
                        charArr.push(usrVal); // place their number at the end of the array
                    } else {
                        charArr.splice(cursorPos, 1); // If an extra slash would occur, this will delete it
                    }

                    this.formattedValue = charArr.join(''); // Re-assemble the on-screen value
                    break;
                }
            }
        },
        _validate() {
            const textfield = (this.$refs.textfield as HTMLInputElement); // Get a reference to the textfield

            if (this.formattedValue.search(/^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d\d$/) === 0) { // Check if the value closely matches a good date format
                const d = new Date(this.formattedValue); // Create a new date object from the value

                if (Object.prototype.toString.call(d) === '[object Date]') { // Check if it is an object
                    if (isNaN(d.getTime())) { // Check if the value is a time in milliseconds
                        this.errorList = [this.datePatternMismatchMessage]; // Add the error to the list
                    } else { // This is valid
                        this.errorList = []; // Clear out the error list

                        /**
                         * Emitted when complete, valid date input is recieved.<br /><br />
                         * Provides an object with data associated to dates.<br /><br />
                         * Key descriptions:<br />
                         * <strong>'dateString'</strong>: This is the input value formatted into a date. Omits the 'Today' text, if present.<br /><br />
                         * <strong>'dayNumber'</strong>: This is the day portion of the formatted value as a number.<br /><br />
                         * <strong>'monthNumber'</strong>: This is the month portion of the formatted value as a number.<br /><br />
                         * <strong>'yearNumber'</strong>: This is the year portion of the formatted value as a number.<br /><br />
                         */
                        this.$emit('textfield-input-date', {
                            dateString: this.formattedValue,
                            dayNumber: d.getDate(),
                            monthNumber: d.getMonth() + 1,
                            yearNumber: d.getFullYear(),
                        });

                        const today = new Date(); // Create another new date object
                        if (d.toDateString() === today.toDateString()) { // Compare the entered value to today
                            this.formattedValue = `${this.formattedValue} ${this.todayText}`; // Update the value with the 'today' string
                            if (this.isFocused) {
                                setTimeout(() => {
                                    textfield.setSelectionRange(10, 10);
                                }, 0);
                            }
                        }
                    }
                } else { // Not a date object
                    this.errorList = [this.datePatternMismatchMessage]; // Add the error to the list
                }
            } else { // Did not match a good date format
                this.errorList = [this.datePatternMismatchMessage]; // Add the error to the list
            }
        },
    },
    watch: {
        value(newValue: string) {
            if (!this.isFocused) { // Only need to update and validate if the field is not focused, otherwise it will error out as they type
                this.formattedValue = newValue;
                this._validate(); // Call validation after input handler
            }
        },
    },
    mounted() {
        if (this.value && this.value !== '') {
            this.formattedValue = this.value;
            this._validate(); // Call validation after input handler
        }
    },
});
