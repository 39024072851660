export { default as IrisAccount } from './IrisAccount.vue';
export { default as IrisAvatar } from './IrisAvatar.vue';
export { default as IrisButton } from './IrisButton.vue';
export { default as IrisCard } from './IrisCard.vue';
export { default as IrisCheckbox } from './IrisCheckbox.vue';
export { default as IrisChip } from './IrisChip.vue';
export { default as IrisChipCreator } from './IrisChipCreator.vue';
export { default as IrisChipsContainer } from './IrisChipsContainer.vue';
export { default as IrisCloakedCheckmark } from './IrisCloakedCheckmark.vue';
export { default as IrisCollapsible } from './IrisCollapsible.vue';
export { default as IrisDisplayInputFieldKeypad } from './IrisDisplayInputFieldKeypad.vue';
export { default as IrisDisplayInputFieldSpecified } from './IrisDisplayInputFieldSpecified.vue';
export { default as IrisDisplayInputFieldUnspecified } from './IrisDisplayInputFieldUnspecified.vue';
export { default as IrisDisplayInputFieldVariable } from './IrisDisplayInputFieldVariable.vue';
export { default as IrisDropdown } from './IrisDropdown/index.vue';
export { default as IrisFloatingActionButton } from './IrisFloatingActionButton.vue';
export { default as IrisIcon } from './IrisIcon.vue';
export { default as IrisInputDropdown } from './IrisInputDropdown.vue';
export { default as IrisLottieAnimation } from './IrisLottieAnimation.vue';
export { default as IrisMenuDropdown } from './IrisMenuDropdown.vue';
export { default as IrisNotification } from './IrisNotification.vue';
export { default as IrisProgressIndicator } from './IrisProgressIndicator.vue';
export { default as IrisQuickActionButton } from './IrisQuickActionButton.vue';
export { default as IrisQuickActionButtonContainer } from './IrisQuickActionButtonContainer.vue';
export { default as IrisRadioButton } from './IrisRadioButton.vue';
export { default as IrisSearchfield } from './IrisSearchfield.vue';
export { default as IrisSelectableTile } from './IrisSelectableTile.vue';
export { default as IrisSelectableTileContainer } from './IrisSelectableTileContainer.vue';
export { default as IrisSelectDropdown } from './IrisSelectDropdown.vue';
export { default as IrisSheet } from './IrisSheet/index.vue';
export { default as IrisSimpleModal } from './IrisSimpleModal.vue';
export { default as IrisSwitch } from './IrisSwitch.vue';
export { default as IrisTable } from './IrisTable.vue';
export { default as IrisTableLoadingOverlay } from './IrisTableLoadingOverlay.vue';
export { default as IrisTableNoRowsOverlay } from './IrisTableNoRowsOverlay.vue';
export { default as IrisTextarea } from './IrisTextarea.vue';
export { default as IrisTextfield } from './IrisTextfield.vue';
export { default as IrisTextfieldContainer } from './IrisTextfieldContainer.vue';
export { default as IrisTextfieldCurrency } from './IrisTextfieldCurrency.vue';
export { default as IrisTextfieldDate } from './IrisTextfieldDate.vue';
export { default as IrisTextfieldDropdown } from './IrisTextfieldDropdown.vue';
export { default as IrisTextfieldPassword } from './IrisTextfieldPassword.vue';
export { default as IrisTextfieldTelephone } from './IrisTextfieldTelephone.vue';

// tslint:disable-next-line
require('focus-visible');