
import Vue from 'vue';
import IrisIcon from './IrisIcon.vue';
import { generateUniqueId } from '@/utils';

export default Vue.extend ({
    name: 'IrisNotification',
    components: {
        IrisIcon,
    },
    props: {
        /**
         * Sets the id (HTML global attribute) for the component. If an id is not provided, one will be generated automatically.
         */
        elementId: String,
        /**
         * Sets the aria label for type/kind of notification being used, must be labeled for accessibility, e.g. 'alert', 'alertdialog', 'status'
         * The default alertdialog, will not display or use a timeout duration for accessibility purposes.
         */
        roleType: {
            type: String,
            default: 'alertdialog',
            required: true,
            validator: (value: string) => {
                return ['alert', 'status', 'alertdialog'].includes(
                    value.toLowerCase(),
                );
            },
        },
        /**
         * Sets the aria label for the close button, must be labelled for accessibility, e.g. 'Close'
         */
        closeAriaLabel: {
            type: String,
            default: 'Close Notification',
            required: true,
        },
        /**
         * Sets the show property.
         * This is the main way to call the notification inside the parent component.
         * Once the notification times out or is dismissed an event will be fired, and the parent component or app must then close the child's show property.
         * 'method:close', emits through the close method being called by either the button or upon timeout.
         * 'notification:closing-notifier-mutation', emits if the notifier data is mutated but the prop is false, e.g. you modified the prop to close instead of letting the time out or button execute.
         * Steps have been taken to ensure the notification will not open again when closing this prop (setting it back to false).
         * Rapidly altering this prop outside of the event calls will put this component in a bad state.
         * We plan to rework this into more of a global api call fed by json at a later date to rectify these issues.
         */
        show: {
            type: Boolean,
            default: false,
        },
        /**
         * Toggle to use the timeout feature even if you're not showing the timeoutbar itself.
         * This cannot be turned on if roleType is set to alertdialog, for accessibility reasons
         */
        useDuration: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets the duration period.
         */
        duration: {
            type: Number,
            default: 10000,
        },
        /**
         * Defines the behavior and type of the notification, e.g. 'banner', 'inline', 'toast'
         */
        kind: {
            type: String,
            default: 'banner',
            validator: (value: string) => {
                return ['banner', 'inline', 'toast'].includes(
                    value.toLowerCase(),
                );
            },
        },
        /**
         * Toggle the display of the leading icon.
         */
        showLeadingIcon: {
            type: Boolean,
            default: true,
        },
        /**
         * Message to display
         */
        message: {
            type: String,
            default: '',
            required: true,
        },
        /**
         * Optional leading subtitle for message, e.g. 'Error:'
         */
        messageHeading: {
            type: String,
            default: '',
        },
        /**
         * Message type, controls color scheming for notification, e.g. 'error','caution', 'success', 'info', 'error-light', 'caution-light', 'success-light', 'info-light', 'bulletin',
         */
        messageType: {
            type: String,
            required: true,
            default: 'error',
            validator: (value: string) => {
                return [
                    'error',
                    'caution',
                    'success',
                    'info',
                    'error-light',
                    'caution-light',
                    'success-light',
                    'info-light',
                    'bulletin',
                ].includes(
                    value.toLowerCase(),
                );
            },
        },
        /**
         * Toggles the display of the close icon.
         */
        showCloseIcon: {
            type: Boolean,
            default: true,
        },
        /**
         * Sets the icon name for the close button.
         */
        closeIconName: {
            type: String,
            default: 'cancel-x',
        },
        /**
         * Sets the icon size for the close button.
         */
        closeIconSize: {
            type: String,
            default: 'sm',
            validator: (value: string) => {
                return ['sm', 'md'].includes(value.toLowerCase());
            },
        },
        /**
         * Optional animated bar for the timeout of the notification.  useDuration and a duration prop value must be specified to use this feature.
         */
        showTimeoutBar: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets the position of the notification when using toast.  Note: toast placement can get finicky at smaller screen sizes.
         */
        toastPosition: {
            type: String,
            default: 'top-left',
            validator: (value: string) => {
                return [
                    'top-left',
                    'top-right',
                    'bottom-left',
                    'bottom-right',
                ].includes(
                    value.toLowerCase(),
                );
            },
        },
        /**
         * Allows input of hrefs or buttons through a slot, it is inadvisable to use this and a duration timer, as it creates a problematic scenario for accessiblity.
         */
        showTrailingContent: {
            type: Boolean,
            default: true,
        },
        /**
         * Displays loading icon insted of standard icon
         */
        iconIsLoading: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets the icon name
         */
        iconName: {
            type: String,
            default: 'alert-line',
        },
        /**
         * Sets the icon size. Valid values are 'sm' and 'md'.
         */
        iconSize: {
            type: String,
            default: 'md',
            validator: (value: string) => {
                return ['md', 'sm'].includes(value.toLowerCase());
            },
        },
        /**
         * Sets the icon color to overwrite the based color from icon.
         */
        iconColor: {
            type: String,
        },
        /**
         * (Deprecated: Use iconIsAriaHidden instead!) Sets the icon to have an aria-hidden of true in order to hide it from screen readers. This prop should be used only when the icon is purely presentational and there is other text present to decribe the icon's intent.
         */
        iconIsIconDecorative: {
            type: Boolean,
            default: true,
        },
        /**
         * Sets the icon to have an aria-hidden of true in order to hide it from screen readers. This prop should be used only when the icon is purely presentational and there is other text present to decribe the icon's intent.
         */
        iconIsAriaHidden: {
            type: Boolean,
            default: true,
        },
        /**
         * Sets the aria label for the icon
         */
        iconAriaLabel: {
            type: String,
        },
    },
    data() {
        return {
            notifier: false as boolean,
            timerBarId: this.duration as number,
            timerId: this.duration as number,
            start: this.duration as number,
            remaining: this.duration as number,
            paused: false as boolean,
            identifier_: this.elementId || generateUniqueId('irisv_notification') as string,
        };
    },
    computed: {
        /**
         * sets element width based on remaining time
         */
        widthRemaining(): number {
            return (this.remaining / this.duration) * 100;
        },
    },
    updated() {
        if (this.showTrailingContent && this.useDuration && this.roleType !== 'alertdialog') {
            this.$nextTick(() => {
                const slotContent = this.$refs.irisvNotificationShowTrailingContentSlot as HTMLElement;

                if (slotContent!.childNodes !== null) {
                    const childList = slotContent!.childNodes;

                    childList.forEach((element) => {
                        element.addEventListener('focus', () => {
                            this.pause();
                        });
                    });
                }
            });
        }
    },
    methods: {
        forceWidthUpdater(): number {
            return (this.remaining / this.duration) * 100;
        },
        /**
         * pauses timer on notification
         */
        pause() {
            if (this.useDuration === true && this.roleType !== 'alertdialog' && this.paused === false) {
                const subtractedNo = Date.now() - this.start;
                const timerGoesHere = (this.$refs.timerLocation as HTMLElement);
                window.clearTimeout(this.timerId);
                window.clearTimeout(this.timerBarId);
                this.paused = true;
                this.remaining -= subtractedNo;
                timerGoesHere.style.width = `${this.forceWidthUpdater()}%`;
                timerGoesHere.style.transitionDuration = `100ms`;
            }
        },
        /**
         * starts or resumes timer on notification
         */
        resume() {
            if (this.useDuration === true && this.roleType !== 'alertdialog') {
                const timerGoesHere = (this.$refs.timerLocation as HTMLElement);
                window.clearTimeout(this.timerId);
                this.paused = false;
                this.start = Date.now();
                this.timerId = window.setTimeout(() => {
                    this.closeNotification();
                    timerGoesHere.style.width = `100%`;
                }, this.remaining);

                // this lets us escape the vue lifecycle to play the animation
                this.timerBarId = window.setTimeout(() => {
                    timerGoesHere.style.transitionDuration = `${this.remaining}ms`;
                    timerGoesHere.style.width = `0`;
                }, 0);

            }
        },
        trapFocus(event: any) {
            const mNotification = this.$refs.irisvNotification as HTMLElement;
            const mNotificationContainer = this.$refs.irisvNotificationContainer as HTMLElement;
            const mHeading = this.$refs.messageHeading as HTMLElement;
            const mBody = this.$refs.messageBody as HTMLElement;
            const mClose = this.$refs.messageClose as HTMLElement;
            const mshowTrailingContent = this.$refs.showTrailingContent as HTMLElement;
            const focusable: any = [
                mNotification,
                mNotificationContainer,
                mHeading,
                mBody,
                mClose,
                mshowTrailingContent,
            ];
            const firstFocusable = focusable[0];

            if (event.code === 'Escape') {
                focusable.forEach((element: any) => {element.blur(); });
            } else {
                if (!focusable.includes(event.target)) {
                    firstFocusable.focus();
                }
            }
        },
        closeNotification(): void {
            if (this.notifier === true && this.show === true) {
                this.notifier = false;
                this.pause();
                this.remaining = 10;
                this.timerId = 10;
                this.start = 10;
                this.$emit('notification-close');
            }
        },
        /**
         * sets visibility and calls timers
         */
        notificationViewer(): void {
            if (this.notifier === false && this.show === true) {
                this.notifier = true;
                this.resume();
                Vue.nextTick(() => {
                    if (this.roleType === 'alertdialog') {
                        (this.$refs.irisvNotification as HTMLElement).focus();
                    }
                });
            }

            if (this.notifier === true && this.show === false) {
                this.notifier = !this.notifier;
                this.pause();
                this.$emit('notification-close');
            }
        },
    },
    /**
     * removes timers cleans component states
     */
    beforeDestroy() {
        if (this.roleType !== 'alertdialog') {
            this.pause();
        }
    },
    watch: {
        /**
         * watches for changes on the prop to switch the display on the component
         */
        show: {
            handler() {
                if (this.show) {
                    this.remaining = this.duration;
                    this.timerId = this.duration;
                    this.start = this.duration;
                }
                this.notificationViewer();
            },
            immediate: true,
        },
        notifier() {
            this.$emit('notification-show', this.notifier);
        },
    },
});
