
import Vue from 'vue';
import { generateUniqueId } from '@/utils';

export default Vue.extend({
    name: 'IrisCollapsible',
    props: {
        /**
         * Sets the id (HTML global attribute) for the component. If an id is not provided, one will be generated automatically.
         */
        elementId: String,
        /**
         * The visible content for the user to click on can be an HTML
         * element or it can be text, but in storybook you can only
         * use text due to limitations with the storybook playground.
         */
        visibleContent: String,
        /**
         * The hidden content for the user to click on can be an HTML
         * element or it can be text, but in storybook you can only
         * use text due to limitations with the storybook playground.
         */
        hiddenContent: String,
    },
    data() {
        return {
            isOpen: false,
            identifier_: this.elementId || generateUniqueId('irisv_collapsible') as string,
        };
    },
    methods: {
        toggleCollapsible() {
            this.isOpen = !this.isOpen;
            const collapsible = this.$refs.collapsible as HTMLElement;
            const content = this.$refs.content as HTMLElement;
            collapsible.style.cssText = this.isOpen ? `height: ${content.getBoundingClientRect().height}px; opacity:1;` : 'height:0;opacity:0;';
            /**
             * Emits the click event and tells whether
             * the collapsible is collapsed or not collapsed.
             */
            this.$emit('collapsible-click', `collapsed: ${this.isOpen}`);
        },
    },
});
