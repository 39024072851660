
import Vue from 'vue';
import IrisTextfield from './IrisTextfield.vue';
import IrisQuickActionButton from './IrisQuickActionButton.vue';
import IrisButton from './IrisButton.vue';

export default Vue.extend({
    name: 'IrisSearchfield',
    components: {
        IrisTextfield,
        IrisQuickActionButton,
        IrisButton,
    },
    model: {
        event: 'searchfield-input',
    },
    props: {
        /**
         * Object of props for the IrisQuickActionButton child component.<br /><br />
         * Refer to the [IrisQuickActionButton docs](/?path=/docs/components-iris-quick-action-button--playground) for available props.<br /<br />
         * Defaults:
         * <ul>
         * <li>variation: 'embedded'</li>
         * <li>label: this.quickActionButtonLabel</li>
         * </ul>
         */
        openButtonProps: Object,
        /**
         * Object of props for the IrisAvatar child component.<br /><br />
         * Refer to the [IrisAvatar docs](/?path=/docs/components-iris-avatar--playground) for available props.<br /<br />
         * Defaults:
         * <ul>
         * <li>mainIconName: 'search'</li>
         * </ul>
         */
        openButtonAvatarProps: Object,
        /**
         * Object of props for the IrisButton child component.<br /><br />
         * Refer to the [IrisButton docs](/?path=/docs/components-iris-button--playground) for available props.<br /<br />
         * Defaults:
         * <ul>
         * <li>kind: 'compact'</li>
         * <li>text: 'cancel'</li>
         * </ul>
         */
        cancelButtonProps: Object,
        /**
         * Object of props for the IrisTextfield child component.<br /><br />
         * Refer to the [IrisTextfield docs](/?path=/docs/components-iris-text-field-text-field--playground) for available props.<br /<br />
         * Defaults:
         * <ul>
         * <li>label: this.textFieldLabel</li>
         * <li>leadingIconName: 'search'</li>
         * <li>showSkeleton: this.showSkeleton</li>
         * <li>clearIconAriaLabel: this.textfieldClearIconAriaLabel</li>
         * <li>value: this.value</li>
         * </ul>
         * Restricted: is-optional, is-clearable
         */
        textfieldProps: Object,
        /**
         * The 'kind' of searchfield designates what the trigger will be. There are two kinds: 'persistent' and 'withQuickAction'.<br />
         * For persistent, the textfield is the trigger.<br />
         * For withQuickAction, the quick action button is the trigger.
         */
        kind: {
            type: String,
            default: 'persistent',
            validator: (value: string) => {
                return ['persistent', 'withquickaction'].includes(
                    value.toLowerCase(),
                );
            },
        },
        /**
         * Only applies to the 'withQuickAction' kind. Since the only variation of quick action button used in the searchfield is the 'embedded' variation, we need to have an aria-label to give guidance as to what the button's function is. You can use this prop to change the quick action button aria-label, or you can let it use the default which is 'search'.
         */
        quickActionButtonLabel: {
            type: String,
            default: 'search',
        },
        /**
         * Sets the label of the textfield. The label is only shown when there is no search field value in the searchfield. You can change the label or leave the default value, which is 'search'
         */
        textFieldLabel: {
            type: String,
            default: 'Search',
        },
        /**
         * Sets the aria-label for the clearable icon. You can leave it as the default, 'Delete text'
         */
        textfieldClearIconAriaLabel: {
            type: String,
            default: 'Delete text',
        },
        /**
         * Shows the skeleton loader as a placeholder option.
         */
        showSkeleton: {
            type: Boolean,
            default: false,
        },
        /**
         * The text value entered as the search query.
         */
        value: {
            type: String,
        },
    },
    data() {
        return {
            quickActionIsClicked: false,
            isFocused: false,
            isBlurred: true,
        };
    },
    methods: {
        clickQuickAction($event: Event) {
            this.quickActionIsClicked = true;

            this.$nextTick(() => {
                const input = (this.$refs.searchfield as Vue).$el.querySelector('.irisv-textfield__input') as HTMLInputElement;

                this.$emit('searchfield-open', $event);
                input.focus();
                input.select();
            });
        },
        clickCompactButton($event: Event) {
            this.quickActionIsClicked = false;

            this.$nextTick(() => {
                const qab = this.$el.querySelector('.irisv-searchfield--search-button-container button') as HTMLButtonElement;

                this.$emit('searchfield-cancel', $event);
                qab.focus();
            });
        },
        toggleFocusAndBlur() {
            this.isFocused = !this.isFocused;
            this.isBlurred = !this.isBlurred;
            this.$nextTick(() => {
                const input = (this.$refs.searchfield as Vue).$el.querySelector('.irisv-textfield__input') as HTMLInputElement;
                const label = (this.$refs.searchfield as Vue).$el.querySelector('.irisv-textfield__label') as HTMLLabelElement;

                if (this.isFocused && input.value.length === 0) {
                    label.classList.add('label-fade');
                }

                if (this.isFocused && input.value.length === 0) {
                    label.classList.add('label-fade');

                    setTimeout(() => { // Need this to delay the application of 'sr-only until after the opacity transition'
                        label.classList.add('sr-only');
                    }, 175);
                }

                if (this.isBlurred && input.value.length === 0) {
                    label.classList.remove('sr-only');
                    label.classList.remove('label-fade');
                }
            });
        },
        onInput($event: Event) {
            const label = (this.$refs.searchfield as Vue).$el.querySelector('.irisv-textfield__label') as HTMLLabelElement;

            if (this.value !== '' && label.classList.contains('sr-only') === false) { // Hide label if v-model is updated
                label.classList.add('sr-only');
            } else if (this.value === '' && label.classList.contains('sr-only') === true && this.isBlurred) {
                label.classList.remove('sr-only');
                label.classList.remove('label-fade');
            }

            this.$emit('searchfield-input', $event);
        },
        onChange($event: Event) {
            this.$emit('searchfield-change', $event);
        },
    },
    computed: {
        defaultCancelButtonProps(): object {
            return {
                kind: 'compact',
                text: 'cancel',
                ...this.cancelButtonProps,
            };
        },
        defaultTextfieldProps(): object {
            return {
                label: this.textFieldLabel,
                leadingIconName: 'search',
                showSkeleton: this.showSkeleton,
                clearIconAriaLabel: this.textfieldClearIconAriaLabel,
                value: this.value,
                ...this.textfieldProps,
            };
        },
        defaultOpenButtonProps(): object {
            return {
                variation: 'embedded',
                label: this.quickActionButtonLabel,
                ...this.openButtonProps,
            };
        },
        defaultOpenButtonAvatarProps(): object {
            return {
                mainIconName: 'search',
                ...this.openButtonAvatarProps,
            };
        },
    },
    mounted() {
        const label = (this.$refs.searchfield as Vue).$el.querySelector('.irisv-textfield__label') as HTMLLabelElement;
        label.innerHTML = label.innerHTML.replace('(optional)', ''); // Take off the '(optional)' for searchfields
        label.classList.remove('font-caption');
        label.classList.add('font-body-1');

        if (this.value && this.value !== '') { // Hide label if value is provided through v-model
            label.classList.add('sr-only');
        }
    },
});
