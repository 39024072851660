
import Vue from 'vue';
import { generateUniqueId } from '@/utils';
import { currencyFormatter } from '@/filters/currencyFormatter';
import { deprecationMixin } from './../mixins/deprecationMixin';

/**
 * A display input field functions to guide a user as they input alphanumeric content during an experience.
 */
export default Vue.extend({
    name: 'IrisDisplayInputFieldKeypad',

    inheritAttrs: false,

    filters: {
        currencyFormatter,
    },

    model: {
        prop: 'value',
        event: 'display-input-field-input',
    },

    mixins: [deprecationMixin],

    props: {
        /**
         * Sets the id (HTML global attribute) for the component. If an id is not provided, one will be generated automatically.
         */
        elementId: String,
        /**
         * Controls the currency and locale formatting for isCurrencyInput being 'true'.<br /><br />
         * This is an object that mostly follows the guidelines of the <a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat">Intl.NumberFormat</a> standard.<br /><br />
         * Most use cases will be changing the currency to use as locale defaults to the browser's language settings.<br /><br />
         * Examples:<br />
         * { currency: 'USD' } // US Dollar currency<br />
         * { currency: 'USD', locale: 'en-US' } // US Dollar currency and US English local
         */
        currencyFormatterOptions: Object,
        /**
         * Sets the alignment of the input display, error text, and helper text
         */
        align: {
            type: String,
            default: 'right',
            validator: (value: string) => {
                return ['left', 'center', 'right'].includes(value.toLowerCase());
            },
        },
        /**
         * Sets the error state.<br /><br />
         * It is suggested to set hasError to 'false' when event 'display-input-field-focus' is emitted.
         */
        hasError: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets the error message
         */
        errortext: {
            type: String,
            default: '',
        },
        /**
         * Sets the helper text
         */
        helpertext: {
            type: String,
            default: '',
        },
        /**
         * (Deprecated: Use elementId instead!) The textfield id.
         */
        id: String,
        /**
         * Marks the input as either an optional or required field
         */
        isOptional: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets the popup keyboard type on supported mobile devices
         */
        keyboardType: {
            type: String,
            default: 'decimal',
            validator: (value: string) => {
                return ['text', 'decimal', 'numeric', 'tel', 'search', 'email', 'url'].includes(value.toLowerCase());
            },
        },
        /**
         * The input value
         */
        value: String,
        /**
         * Sets the placeholder for the input
         */
        placeholder: {
            type: String,
            default: '0.00',
        },
        /**
         * Sets the input mode of the control to currency. When enabled, the 'keyboardType' property will be set to 'decimal' automatically.
         */
        isCurrencyInput: {
            type: Boolean,
            default: false,
        },
        /**
         * An object containing the strings used in the select dropdown.<br /><br />
         * <strong>Keys are:</strong><br /><br />
         * errortext: Sets the text that displays when hasError is true.<br /><br />
         * helpertext: Sets the helperText for the input.<br /><br />
         * placeholder: Sets the placeholder that appears in the field.<br /><br />
         */
        strings: {
            type: Object,
            default: () => ({}),
        },
        /**
         * Sets the spellcheck global attribute for the HTML input.
         */
        allowBrowserSpellCheck: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            identifier_: this.elementId || this.id || generateUniqueId('irisv_display_input_field_keypad') as string,
            internalError_: this.hasError,
            formattedValue: '',
            unformattedValue: '',
        };
    },

    computed: {
        computedCurrencyFormatterOptions(): object {
            return {
                ...this.currencyFormatterOptions,
                hasImpliedDecimal: true,
            };
        },
        mergedStrings(): any {
            return {
                errortext: this.errortext,
                helpertext: this.helpertext,
                placeholder: this.placeholder,
                ...this.strings,
            };
        },
        errorTextIdentifier_(): string {
            return `error_text-${this.identifier_}`;
        },
        helperTextIdentifier_(): string {
            return `helper_text-${this.identifier_}`;
        },
    },

    methods: {
        clearEverything() {
            this.formattedValue = '';
            this.$emit('display-input-field-input-currency', {
                formatted: '',
                unformatted: '',
                formatParts: {},
            });
            this.$emit('display-input-field-input', '');
            (this.$refs.input as HTMLInputElement).value = '';
        },
        onFocus(event: Event) {
            /**
             * Emitted when the input gets focus.
             */
            this.$emit('display-input-field-focus', event);
        },
        onKeyDown(event: KeyboardEvent) {
            if (this.isCurrencyInput && event.key === 'Backspace' && this.unformattedValue === '0.00') {
                this.clearEverything();
            }
        },
        onInput() {
            const value = (this.$refs.input as HTMLInputElement).value;

            if (this.isCurrencyInput) {
                const formatParts = this.$options.filters!.currencyFormatter(value, { ...this.computedCurrencyFormatterOptions, formatToParts: true });
                let joinedParts = '';

                if (typeof formatParts === 'object') {
                    joinedParts = formatParts
                        .filter((x: any) => x.type === 'minusSign' || x.type === 'integer' || x.type === 'decimal' || x.type === 'fraction')
                        .map((x: any) => x.type === 'decimal' ? '.' : x.value)
                        .join('');

                    this.formattedValue = this.$options.filters!.currencyFormatter(value, this.computedCurrencyFormatterOptions);
                }

                /**
                 * Emitted when input values change.<br /><br />
                 * Provides an object with data associated to currency formatting.<br /><br />
                 * Key descriptions:<br />
                 * <strong>'formatted'</strong>: this is the current input value formatted into currency<br /><br />
                 * <strong>'unformatted'</strong>: this is the current input value with no formatting except decimals<br /><br />
                 * <strong>'formatParts'</strong>: data provided by <a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/formatToParts">Intl.NumberFormat.prototype.formatToParts()</a>
                 */
                this.$emit('display-input-field-input-currency', {
                    formatted: this.formattedValue,
                    unformatted: joinedParts,
                    formatParts,
                });
                this.$emit('display-input-field-input', joinedParts);
                this.internalValue_ = this.formattedValue; // Set this internalValue_ needed by the textfieldMixin
                this.unformattedValue = joinedParts;

                (this.$refs.input as HTMLInputElement).value = this.formattedValue;
            } else {
                this.$emit('display-input-field-input-currency', {
                    formatted: '',
                    unformatted: '',
                    formatParts: {},
                });
                /**
                 * Emitted when the input changes.
                 */
                this.$emit('display-input-field-input', value);
            }

            this.updateDisplaySize();
        },
        updateDisplaySize() {
            const label = this.$refs.label as HTMLElement;
            const input = (this.$refs.input as HTMLInputElement);

            this.$nextTick(() => {
                if (input.value) {
                    label.style.cssText = `--clampValue: ${100 / input.value.length}vw;`;
                } else {
                    label.style.cssText = '--clampValue: 100vw;';
                }
            });
        },
        onBlur(event: Event) {
            /**
             * Emitted when the input blurs.
             */
            this.$emit('display-input-field-blur', event);
        },
    },

    watch: {
        hasError(newVal) {
            this.internalError_ = newVal;
        },
        isCurrencyInput(newVal) {
            if (newVal) {
                this.onInput();
                this.updateDisplaySize();
            } else {
                this.$emit('display-input-field-input-currency', {
                    formatted: '',
                    unformatted: '',
                    formatParts: {},
                });
            }
        },
        value(newVal) {
            const input = (this.$refs.input as HTMLInputElement);

            if (this.isCurrencyInput && input.value !== newVal) {
                input.value = newVal;
                this.onInput();
            } else {
                this.$emit('display-input-field-input-currency', {
                    formatted: '',
                    unformatted: '',
                    formatParts: {},
                });
            }

            if (newVal === '') {
                this.clearEverything();
            }

            this.$nextTick(() => {
                this.updateDisplaySize();
            });
        },
        currencyFormatterOptions() {
            this.onInput();
        },
    },

    mounted() {
        const input = (this.$refs.input as HTMLInputElement);

        if (this.isCurrencyInput) {
            input.value = this.value;
            this.onInput();
        } else {
            this.$emit('display-input-field-input-currency', {
                formatted: '',
                unformatted: '',
                formatParts: {},
            });
        }

        this.$nextTick(() => {
            this.updateDisplaySize();
        });
    },
});
