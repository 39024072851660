
import Vue from 'vue';

/**
 * Cards are used as containers that can be tapped or clicked in order to provide the user with specific content.
 */
export default Vue.extend({
    name: 'IrisCard',
    props: {
        /**
         * Boolean that sets card to have a hover state.
         */
        hasHoverState: {
            type: Boolean,
            default: false,
        },
        /**
         * Boolean that sets card to have a clickable, "active", state similar to a button.
         */
        hasActiveState: {
            type: Boolean,
            default: false,
        },
        /**
         * Boolean that sets card to have a focus state when interacted with, similar to a toggle button.
         */
        hasFocusState: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets the height of the card, css values for height are valid.<br /><br />
         * Some values will be dependent on the context of the card and its position in the DOM, such as a percentage value.
         */
        cardHeight: {
            type: String,
            default: 'auto',
        },
        /**
         * Sets the width of the card, css values for width are valid.<br /><br />
         * Some values will be dependent on the context of the card and its position in the DOM, such as a percentage value.
         */
        cardWidth: {
            type: String,
            default: 'auto',
        },
        /**
         * Defines the cusor to use, css values for cursor are valid.
         */
        cardCursorPointer: {
            type: String,
            default: 'auto',
        },
    },
});
