var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"sheet",staticClass:"irisv-sheet",class:{
        'irisv-sheet--opened': _vm.isOpen,
        [`irisv-sheet--${_vm.location}`]: _vm.location,
        [`irisv-sheet--${_vm.kind}`]: _vm.kind,
        'irisv-sheet--suppressed': _vm.suppressed,
        'irisv-sheet--full-height-partial': _vm.fullHeightPartial,
        'irisv-sheet--navbar': _vm.irisSheetNav
    },attrs:{"id":_vm.identifier_,"role":"dialog","aria-label":_vm.sheetAriaLabel,"aria-hidden":_vm.suppressed}},[(_vm.showScrim)?_c('iris-sheet-scrim',{ref:"scrim",attrs:{"element-id":_vm.identifier_},on:{"scrim-click":function($event){return _vm.closeSheet()}}}):_vm._e(),_c('div',{ref:"container",staticClass:"irisv-sheet__container",attrs:{"tabindex":_vm.kind === 'partial' ? '0' : '-1',"aria-label":_vm.kind === 'partial' && !_vm.isOpen ? _vm.sheetAriaLabel : null},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.focusReturn.apply(null, arguments)},"keydown":function($event){return _vm.focusTrapStart($event)}}},[(_vm.isMobilePartial)?_c('button',{ref:"grabber",staticClass:"irisv-sheet__grabber",attrs:{"type":"button","aria-label":_vm.isOpen ? 'Close sheet' : 'Open Sheet'},on:{"click":_vm.handleHeaderClick}}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showHeader),expression:"showHeader"}],ref:"irisSheetHeader",staticClass:"irisv-sheet__header",attrs:{"tabindex":_vm.autoFocusOnOpen ? '-1' : null},on:{"click":function($event){$event.stopPropagation();return _vm.handleHeaderClick.apply(null, arguments)},"touchstart":function($event){$event.stopPropagation();return _vm.touchStart.apply(null, arguments)},"touchend":function($event){$event.stopPropagation();return _vm.touchEnd.apply(null, arguments)},"touchmove":function($event){$event.stopPropagation();return _vm.touchMove.apply(null, arguments)}}},[(_vm.showBackstackButton && _vm.kind !== 'partial')?_c('iris-quick-action-button',{ref:"backstack",staticClass:"irisv-sheet__header-backstack-button",attrs:{"kind":"mediumEmphasis","variation":"embedded","href":_vm.headerQuickActionButtonHref,"target":_vm.headerQuickActionButtonTarget,"label":_vm.headerQuickActionButtonLabel,"avatar-props":{
                    mainIconName: _vm.headerQuickActionButtonMainIconName,
                    size: 'xsmall',
                }},on:{"quick-action-button-click":function($event){$event.stopPropagation();return _vm.handleHeaderQABClick.apply(null, arguments)},"keydown":function($event){return _vm.focusTrapStart($event)}}}):_vm._e(),(_vm.showCloseButton && _vm.kind !== 'partial' && !_vm.showBackstackButton && _vm.location === 'bottom')?_c('iris-quick-action-button',{staticClass:"irisv-sheet__header-close-button",attrs:{"kind":"mediumEmphasis","variation":"embedded","label":_vm.closeButtonLabel,"avatar-props":{
                    mainIconName: _vm.closeButtonIconName,
                    size: 'xsmall',
                }},on:{"quick-action-button-click":function($event){return _vm.closeSheet()}}}):_vm._e(),(_vm.irisSheetHeader)?_c('h2',{ref:"title",staticClass:"irisv-sheet__header-text",attrs:{"id":_vm.identifier_+'_title'},on:{"keydown":function($event){return _vm.focusTrapStart($event)}}},[_vm._v(" "+_vm._s(_vm.irisSheetHeaderText)+" ")]):_vm._e(),(_vm.showCompactButton && _vm.kind !== 'partial')?_c('iris-button',{staticClass:"irisv-sheet__header-compact-button",attrs:{"kind":"compact","theme":"onLight","text-casing":_vm.buttonTextCasing,"is-disabled":_vm.buttonIsDisabled,"type":_vm.buttonType,"href":_vm.buttonHref,"target":_vm.buttonTarget,"is-loading":_vm.buttonIsLoading,"loading-text":_vm.buttonLoadingText,"loading-alert-message":_vm.buttonLoadingAlertMessage,"text":_vm.buttonText},on:{"button-click":function($event){$event.stopPropagation();return _vm.handleHeaderCompactButtonClick.apply(null, arguments)}}}):_vm._e(),(_vm.showCloseButton && _vm.location === 'side')?_c('iris-quick-action-button',{staticClass:"irisv-sheet__header-close-button",attrs:{"kind":"mediumEmphasis","variation":"embedded","label":_vm.closeButtonLabel,"avatar-props":{
                    mainIconName: _vm.closeButtonIconName,
                    size: 'xsmall',
                }},on:{"quick-action-button-click":function($event){return _vm.closeSheet()}}}):_vm._e()],1),_c('div',{ref:"content",staticClass:"irisv-sheet__content"},[_c('div',{ref:"irisSheetTopBuffer",staticClass:"top-buffer"}),_c('div',{ref:"innerContent",staticClass:"irisv-sheet__inner-content"},[_vm._t("irisSheetContent")],2),_c('div',{ref:"irisSheetBottomBuffer",staticClass:"bottom-buffer"})]),(_vm.kind !== 'partial')?_c('div',{ref:"navBar",staticClass:"irisv-sheet__nav",class:{
                'irisv-sheet__nav--visible': _vm.irisSheetNav,
                'irisv-sheet__nav--expanded': _vm.isNavOpen
            }},[_c('iris-quick-action-button',{staticClass:"irisv-sheet__nav--toggle",attrs:{"label":_vm.navQuickActionButtonLabel,"avatar-props":{
                    mainIconName: _vm.navQuickActionButtonMainIconName,
                },"kind":"noEmphasis","variation":"embedded","size":"xsmall"},on:{"quick-action-button-click":function($event){$event.stopPropagation();return _vm.handleNavQABClick.apply(null, arguments)}}}),(_vm.irisSheetNav)?_c('IrisQuickActionButtonContainer',{ref:"navMenuButtons",staticClass:"irisv-sheet__nav-container",attrs:{"aria-label":_vm.quickActionButtonContainerAriaLabel,"quick-action-buttons-data":_vm.quickActionButtonContainerQuickActionButtonsData,"direction-shifting":true,"variation":_vm.quickActionButtonVariation},on:{"quick-action-button-container-click":_vm.handleQABContainerClick}}):_vm._e()],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.irisSheetFooter),expression:"irisSheetFooter"}],ref:"irisSheetFooter",staticClass:"irisv-sheet__footer"},[_vm._t("irisSheetFooter")],2),_c('div',{ref:"focusTrapperEnd",attrs:{"tabindex":"0"},on:{"focus":function($event){return _vm.trapFocusEnd($event)}}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }