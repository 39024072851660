
import Vue from 'vue';
import IrisQuickActionButton from '../IrisQuickActionButton.vue';
import IrisQuickActionButtonContainer from '../IrisQuickActionButtonContainer.vue';
import IrisButton from '../IrisButton.vue';
import IrisSheetScrim from './_scrim.vue';
import { deprecationMixin } from '@/mixins/deprecationMixin';
import { generateUniqueId } from '@/utils';

import {
    FORM_ELEM_TYPES,
    ANCHORS, PossibleAnchors,
    LOCATIONS, PossibleLocations,
    KINDS, // , PossibleKinds
} from './_constants';

/**
 * The Side Sheet is often analogous in its use to the bottom sheet on mobile.
 */
export default Vue.extend({
    name: 'IrisSheet',

    components: {
        IrisQuickActionButton,
        IrisQuickActionButtonContainer,
        IrisButton,
        IrisSheetScrim,
    },

    mixins: [deprecationMixin],

    props: {
        /**
         * Sets the id (HTML global attribute) for the component. If an id is not provided, one will be generated automatically.
         */
        elementId: String,
        /**
         * Controls visual and functional behavior of the sheet between mobile and desktop.
         * Desktop: partial is of limited functionality, fullscreen has full functionality
         * Mobile: partial is persistent and touch draggable, fullscreen is contextual.
         * The width of the side sheet is not affected, but header configurations are.
         */
        kind: {
            type: String,
            default: KINDS.partial,
            validator: (value: string) => {
                return value.toLowerCase() in KINDS;
            },
        },
        /**
         * Toggles the display of the sheet.
         */
        contentToggle: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets the overlay over the content behind the sheet.
         */
        showScrim: {
            type: Boolean,
            default: true,
        },
        /**
         * Sets the header section to be available.
         */
        irisSheetHeader: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets the heading title text.
         */
        irisSheetHeaderText: {
            type: String,
        },
        /**
         * Controls appearance of the backstack button in the header.
         */
        showBackstackButton: {
            type: Boolean,
            default: false,
        },
        /**
         * Controls appearance of the compact button in the header.
         */
        showCompactButton: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets the href of the backstack button.
         */
        headerQuickActionButtonHref: {
            type: String,
        },
        /**
         * Sets the target of the backstack button.
         */
        headerQuickActionButtonTarget: {
            type: String,
            validator: (value: string) => {
                return ['', '_self', '_blank', '_parent', '_top'].includes(value.toLowerCase());
            },
        },
        /**
         * Sets the label for inline and stacked variants. The embedded variant cannot have a label.
         */
        headerQuickActionButtonLabel: {
            type: String,
            default: 'close',
        },
        /**
         * Sets the icon for the backstack button.
         */
        headerQuickActionButtonMainIconName: {
            type: String,
            default: 'angle-left',
        },
        /**
         * Sets the text casing of the compact button text. Valid values are 'sentence', 'capitalize', 'uppercase' or 'lowercase'.
         */
        buttonTextCasing: {
            type: String,
            default: 'lowercase',
            validator: (value: string) => {
                return ['sentence', 'capitalize', 'uppercase', 'lowercase'].includes(
                    value.toLowerCase(),
                );
            },
        },
        /**
         * Disables the high emphasis button and shows the disabled state.
         * Low and Medium emphasis buttons have no disabled state.
         */
        buttonIsDisabled: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets the compact button type. Valid values are 'button', 'submit' or 'reset'.
         */
        buttonType: {
            type: String,
            default: 'button',
            validator: (value: string) => {
                return ['button', 'submit', 'reset'].includes(
                    value.toLowerCase(),
                );
            },
        },
        /**
         * Sets the compact button href to a valid url.
         */
        buttonHref: {
            type: String,
        },
        /**
         * Sets the target of the compact button set to be a link with href.
         */
        buttonTarget: {
            type: String,
            validator: (value: string) => {
                return ['_self', '_blank', '_parent', '_top'].includes(value.toLowerCase());
            },
        },
        /**
         * Sets the loading state with animated icon indicator in the compact button.
         */
        buttonIsLoading: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets the loading text to display in the compact button.
         */
        buttonLoadingText: {
            type: String,
            default: 'Loading',
        },
        /**
         * Sets the loading has started alert message announced by screen reader in the compact button.
         */
        buttonLoadingAlertMessage: {
            type: String,
            default: 'Loading',
        },
        /**
         * Sets the displayed text in the compact button.
         */
        buttonText: {
            type: String,
        },
        /**
         * Controls the appearance of the close button in the header section.
         */
        showCloseButton: {
            type: Boolean,
            default: true,
        },
        /**
         * Sets the label for the close button.
         */
        closeButtonLabel: {
            type: String,
            default: 'close',
        },
        /**
         * An optional function to run when the user requests the sheet be closed. Your function should return true (allows sheet to close) or false (prevents the sheet from closing). Functions that return either a boolean value or a promise are supported.
         */
        onBeforeClosing: {
            type: Function,
            default() {
                return () => Promise.resolve(true);
            },
        },
        /**
         * Sets the footer section to be available.
         */
        irisSheetFooter: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets the nav section to be available.
         */
        irisSheetNav: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets the label for the nav menus quick action button
         */
        navQuickActionButtonLabel: {
            type: String,
            default: 'navigation menu',
        },
        /**
         * Sets the icon name for the nav menus quick action button
         */
        navQuickActionButtonMainIconName: {
            type: String,
            default: 'menu2',
        },
        /**
         * Sets the aria-label for the navbar container. Please include any needed punctuation in the value.
         */
        quickActionButtonContainerAriaLabel: {
            type: String,
            default: '',
        },
        /**
         * Prop data to build the group of buttons components. The data should be an object of objects where each key represents a Quick Action Button and its props to be passed along. The key for each buttons is used for internal id purposes.
         */
        quickActionButtonContainerQuickActionButtonsData: {
            type: Object,
        },
        /**
         * Makes the partial bottom sheet to be contextual and must be triggered to appear.
         */
        suppressed: {
            type: Boolean,
            default: false,
        },
        /**
         * Makes the partial bottom sheet always expand to full height.
         */
        fullHeightPartial: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets default accessibility on sheet opening to put focus on the title of header, or on the container if there is no header. Turn off to set up different focus logic.
         */
        autoFocusOnOpen: {
            type: Boolean,
            default: true,
        },
        /**
         * Sets default accessibility on sheet closing to put focus on the trigger element. Turn off to set up different focus logic.
         */
        autoFocusOnClose: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            isNavOpen: false,
            quickActionButtonVariation: 'embedded' as string,
            location: '' as PossibleLocations,
            touchStartPosition: 0,
            touchMoved: false,
            timeThreshold: 250,
            anchorPoint: 'bottom' as PossibleAnchors,
            isOpen: false,
            previousActiveElement: '',
            identifier_: this.elementId || generateUniqueId('irisv_sheet') as string,
            isMidTransition: false,
            centerAnchorPosition: '40%',
            minimumHeight: 0,
            focusTrapped: false,
            intersectionObserver: null as IntersectionObserver | null,
            resizeObserver: null as ResizeObserver | null,
        };
    },

    methods: {
        focusReturn() {
            if (!this.previousActiveElement) {
                this.closeSheet();
                return;
            }

            if (!this.autoFocusOnClose) {
                this.closeSheet();
                return;
            }

            const { previousActiveElement } = this;
            setTimeout(() => {
                const elToReturnFocus = document.querySelector(`[${previousActiveElement}]`) as HTMLElement;
                if (elToReturnFocus) {
                    elToReturnFocus.focus();
                    elToReturnFocus.removeAttribute('data-sheet-trigger');
                }
                this.previousActiveElement = '';
            }, this.transitionDurationSheet);

            this.closeSheet();
        },
        sheetScroll() {
            document.dispatchEvent(new Event('iris-sheet-scroll'));
        },
        throttleScroll(fn: any, wait: number) {
            let time = Date.now() as number;

            return () => {
                if ((time + wait - Date.now()) < 0) {
                    fn();
                    time = Date.now();
                }
            };
        },
        handleHeaderClick() {
            if (!this.isMobilePartial) { return; }

            if (this.isOpen) {
                this.closeSheet();
            } else {
                this.openSheet();
            }
        },
        calcMinimumContentHeight() {
            const {
                sheet,
                container,
                content,
                innerContent,
            } = this.$refs as Record<string, HTMLElement>;

            const sheetHidden = sheet.style.getPropertyValue('display') === 'none';

            if (sheetHidden) { sheet.style.removeProperty('display'); }
            const totalHeight = (
                container.clientHeight
                - content.clientHeight
                + innerContent.clientHeight
            );
            if (sheetHidden) { sheet.style.setProperty('display', 'none'); }

            const centerAnchorHeight = window.innerHeight * .60;
            this.minimumHeight = Math.min(totalHeight, centerAnchorHeight);
            this.centerAnchorPosition = `calc(100% - ${this.minimumHeight})`;
        },
        touchStart(e: TouchEvent) {
            const container = this.$refs.container as HTMLElement;

            this.touchStartPosition = e.touches[0].clientY;

            if (this.isMobilePartial) {
                container.style.setProperty('transition', 'none');
                document.body.classList.add('irisv-sheet--body');
            }
        },
        touchMove(e: TouchEvent) {
            const container = this.$refs.container as HTMLElement;
            const clientY = e.targetTouches[0].clientY;

            this.touchMoved = true;

            if (this.isMobilePartial) {
                container.style.setProperty('top', `${clientY}px`);
            }
        },
        touchEnd(e: TouchEvent) {
            const container = this.$refs.container as HTMLElement;
            const clientY = e.changedTouches[0].clientY;

            /* negative = down | positive = up */
            const drag = this.touchStartPosition - clientY;

            container.style.removeProperty('top');
            container.style.removeProperty('transition');

            this.moveAnchorPoint(drag);

            this.touchMoved = false;

            if (this.anchorPoint === ANCHORS.bottom) {
                this.closeSheet();
            } else {
                this.openSheet();
            }
        },
        handleHeaderQABClick($event: Event) {
            /**
             * Emitted when the header quick action button is clicked.
             */
            this.$emit('click:headerQuickActionButton', $event);
        },
        handleHeaderCompactButtonClick($event: Event) {
            /**
             * Emitted when the compact button is clicked.
             */
            this.$emit('click:compactButtonClick', $event);
        },
        handleNavQABClick($event: Event) {
            this.isNavOpen = !this.isNavOpen;
            this.quickActionButtonVariation = this.isNavOpen ? 'inline-block' : 'embedded';
            /**
             * Emitted when the nav quick action button is clicked.
             */
            this.$emit('click:navQuickActionButton', $event);
        },
        handleQABContainerClick($event: Event) {
            /**
             * Emitted when a navbar item is clicked.
             */
            this.$emit('drawer-navbar-item-click', $event);
        },
        focusTargetElement() {
            const {
                container,
                title,
                irisSheetHeader: header,
            } = this.$refs as Record<string, HTMLElement>;

            const backstack = this.$refs.backstack as Vue;

            let backstackButton;

            if (backstack) {
                backstackButton = backstack.$refs.quickActionButton;
            }

            if (this.autoFocusOnOpen) {
                if (header.style.display === 'none') {
                    // put focus on container
                    container.setAttribute('tabindex', '0'),
                    container.focus();
                } else {
                    // put focus on backstack
                    if (backstack) {
                        (backstackButton as HTMLElement).setAttribute('tabindex', '0');
                        (backstackButton as HTMLElement).focus();
                    } else {
                        if (title && this.irisSheetHeaderText && this.irisSheetHeaderText.length > 0) {
                            title.setAttribute('tabindex', '0');
                            title.focus();
                        } else {
                            container.setAttribute('tabindex', '0');
                            container.focus();
                        }
                    }
                }
            }
        },
        focusTrapStart(e: any) {
            const { title, container, grabber, focusTrapperEnd } = this.$refs as Record<string, HTMLElement>;

            const backstack = this.$refs.backstack as Vue;

            let backstackButton;
            if (backstack) {
                backstackButton = backstack.$refs.quickActionButton as Vue;
            }
            // trap focus on title when shift + tab
            if (e.target === title) {
                if (e.shiftKey && e.code === 'Tab') {
                    if (!this.showBackstackButton) {
                        e.preventDefault();
                    }
                }
            }
            // trap focus on container when shift + tab
            if (e.target === container) {
                if (e.shiftKey && e.code === 'Tab') {
                    if (this.kind === KINDS.partial && !this.isOpen) {
                        this.focusReturn();
                    } else {
                        e.preventDefault();
                    }
                }
            }
            // prevent focus inside of content when partial and closed
            if (grabber && e.target === grabber && !this.isOpen) {
                if (e.shiftKey && e.code === 'Tab') {
                    this.focusReturn();
                } else if (e.code === 'Tab') {
                    focusTrapperEnd.focus();
                }
            }
            // trap focus on backstack when shift + tab
            if (backstackButton && e.target === backstackButton) {
                if (e.shiftKey && e.code === 'Tab') {
                    e.preventDefault();
                }
            }
        },
        trapFocusEnd(e: any) {
            const { grabber } = this.$refs as Record<string, HTMLElement>;

            /* This is handling for mobile partial sheets that are closed and focus is coming from any element except the grabber.
               All other cases should skip this block including mobile partial sheets that are open, or if focus is coming from the grabber.
               This is because mobile partial sheets are the only configuration with 2 different focus loop behaviors - 1 for closed and 1 for open. */
            if (this.kind === KINDS.partial && !this.isOpen && e.relatedTarget !== grabber) {
                grabber.focus(); // Send focus to the grabber
                this.focusTrapStart(e); // Start the focus trap
                return;
            }

            this.focusTrapped = true; // Normal handling for all other sheet types
        },
        childElementHasFocus(e: FocusEvent) {
            if (this.location !== LOCATIONS.bottom) { return; }

            const target = e.target as HTMLElement;
            const focusType = e.type;

            if (!target) { return; }

            const typeProp = target.getAttribute('type') as string;

            if (
                target.tagName !== 'TEXTAREA' &&
                target.tagName !== 'INPUT' ||
                !FORM_ELEM_TYPES.includes(typeProp)
            ) { return; }

            const {
                navBar,
                irisSheetFooter: footer,
            } = this.$refs as Record<string, HTMLElement>;

            if (this.irisSheetFooter) {
                footer.classList.toggle('irisv-sheet__footer--hidden', focusType === 'focus');
            }

            if (this.irisSheetNav) {
                navBar.classList.toggle('irisv-sheet__nav--visible', focusType !== 'focus');
            }
        },
        handleSheetOpen() {
            /**
             * Emitted when the sheet is opening
             */
            this.$emit('sheet:opening');

            const { sheet } = this.$refs as Record<string, HTMLElement>;

            if ((window as any).isFlutterWebview && (window as any).f_hideAppShell) {
                (window as any).f_hideAppShell.postMessage('');

                setTimeout(() => {
                    document.body.classList.add('irisv-sheet--body');
                    sheet.setAttribute('aria-hidden', 'false');
                    sheet.style.visibility = 'visible';
                    this.focusTargetElement();
                }, 710);
            } else {
                document.body.classList.add('irisv-sheet--body');
                sheet.setAttribute('aria-hidden', 'false');
                sheet.style.visibility = 'visible';
            }

            if (this.location === LOCATIONS.bottom && this.kind === KINDS.fullscreen) {
                document.documentElement.classList.add('irisv-sheet--html');
            }

            this.anchorPoint = ANCHORS.bottom;
            this.moveAnchorPoint(1);

            this.calcLocation();

            setTimeout(() => {
                /**
                 * Emitted when the sheet is opened
                 */
                this.$emit('sheet:opened');
            }, this.transitionDurationSheet);

            // put identifier on trigger to reset focus on close
            if (this.autoFocusOnClose) {
                const activeEl = document.activeElement;
                if (activeEl) {
                    activeEl.setAttribute('data-sheet-trigger', this.identifier_);
                    this.previousActiveElement = `data-sheet-trigger=${this.identifier_}`;
                }
            }

            this.focusTargetElement();
        },
        handleSheetClose() {
            const { sheet } = this.$refs as Record<string, HTMLElement>;

            // hide sheet so not focusable when sheet is closed
            if ((window as any).isFlutterWebview && (window as any).f_showAppShell) {
                (window as any).f_showAppShell.postMessage('');
            }

            setTimeout(() => {
                sheet.setAttribute('aria-hidden', this.suppressed ? 'true' : 'false');
                sheet.style.visibility = 'hidden';
            }, this.transitionDurationSheet + 100);

            document.documentElement.classList.remove('irisv-sheet--html');
            document.body.classList.remove('irisv-sheet--body');
            this.isNavOpen = false;

            this.anchorPoint = ANCHORS.bottom;

            /**
             * Emitted when the sheet is closing
             */
            this.$emit('sheet:closing');

            setTimeout(() => {
                /**
                 * Emitted when the sheet is closed
                 */
                this.$emit('sheet:closed');
            }, this.transitionDurationSheet);

            // return focus to trigger
            this.focusReturn();
        },
        openSheet() {
            if (this.isOpen) { return; }
            this.$emit('update:content-toggle', true);
        },
        closeSheet() {
            if (!this.isOpen) { return; }
            this.$emit('update:content-toggle', false);
        },
        moveAnchorPoint(direction: number = 0) {
            const { anchorPoint } = this;

            // A fullHeightPartial is either opened or closed
            if (this.kind === KINDS.partial && this.fullHeightPartial === true) {
                this.anchorPoint = direction > 0 ? ANCHORS.top : ANCHORS.bottom;
                return;
            }

            if (direction > 0) {
                switch (anchorPoint) {
                case ANCHORS.center:
                    this.anchorPoint = ANCHORS.top;
                    break;
                case ANCHORS.bottom:
                    this.anchorPoint = ANCHORS.center;
                }
            } else if (direction < 0) {
                switch (anchorPoint) {
                case ANCHORS.center:
                    this.anchorPoint = ANCHORS.bottom;
                    break;
                case ANCHORS.top:
                    if (this.kind === KINDS.partial) {
                        this.anchorPoint = ANCHORS.center;
                    } else {
                        this.anchorPoint = ANCHORS.bottom;
                    }
                }
            }
        },
        updateTop() {
            const container = this.$refs.container as HTMLElement;

            container.style.removeProperty('top');
            container.style.removeProperty('transition');

            if (this.location !== LOCATIONS.bottom) { return; }
            if (this.kind === KINDS.fullscreen) { return; }

            this.calcMinimumContentHeight();

            const snapPoints = {
                bottom: 'calc(100% - 68px)',
                center: this.centerAnchorPosition,
                top: '68px',
            };

            if (this.fullHeightPartial) {
                snapPoints.top = '1%';
            }

            if (this.suppressed) {
                snapPoints.bottom = '100%';
            }

            this.$nextTick(() => {
                switch (this.anchorPoint) {
                case ANCHORS.center:
                    container.style.setProperty('top', snapPoints.center);
                    break;
                case ANCHORS.top:
                    container.style.setProperty('top', snapPoints.top);
                    break;
                case ANCHORS.bottom:
                    container.style.setProperty('top', snapPoints.bottom);
                    break;
                }
            });
        },
        calcLocation() {
            const container = this.$refs.container as HTMLElement;

            let isBottomSheet;
            if (container && 'clientWidth' in container) {
                isBottomSheet = container.clientWidth < 550;
            } else {
                isBottomSheet = window.matchMedia('(max-width: 560px)').matches;
            }
            this.location = isBottomSheet ? LOCATIONS.bottom : LOCATIONS.side;
        },
    },

    computed: {
        transitionDurationSheet() {
            const { container } = this.$refs as Record<string, HTMLElement>;
            const styleSheet = window.getComputedStyle(container);
            return parseFloat(styleSheet.getPropertyValue('transition-duration')) * 1000 as number;
        },
        isMobilePartial(): boolean {
            return this.location === LOCATIONS.bottom && this.kind === KINDS.partial;
        },
        sheetAriaLabel(): string {
            let ariaLabel = 'Dialog';

            if (this.irisSheetHeader) {
                if (this.irisSheetHeaderText !== '') {
                    ariaLabel = this.irisSheetHeaderText;
                }
            }

            return ariaLabel;
        },
        showHeader(): boolean {
            if (this.kind === KINDS.partial) {
                return true;
            } else {
                return this.irisSheetHeader;
            }
        },
        closeButtonIconName(): string {
            if (this.kind === KINDS.fullscreen) {
                return this.location === LOCATIONS.side ? 'cancel-x' : 'caret';
            } else {
                return 'cancel-x';
            }
        },
    },

    watch: {
        anchorPoint() {
            this.updateTop();
        },
        fullHeightPartial() {
            this.updateTop();
        },
        suppressed() {
            this.updateTop();
        },
        location() {
            this.updateTop();

            if (this.location === LOCATIONS.bottom) {
                this.quickActionButtonVariation = 'stacked';
            } else {
                this.quickActionButtonVariation = this.isNavOpen ? 'inline-block' : 'embedded';
            }

            /**
             * Emitted when sheet orientation changes.
             */
            this.$emit('sheet-orientation-change', this.location);
        },
        kind() {
            this.updateTop();
        },
        focusTrapped() {
            const container = this.$refs.container as HTMLElement;

            if (this.focusTrapped && this.isOpen) {
                this.focusTargetElement();
                container.setAttribute('tabindex', '0');
                container.focus();
                this.focusTrapped = false;
            }
        },
        async contentToggle(open: boolean) {
            this.$emit('sheet:content-toggle-request', open);

            if (this.isOpen === open) { return; } // isOpen and contentToggle already match, no need to do anything else.

            if (open) {
                this.isOpen = open;
                this.handleSheetOpen();
                return;
            }

            let shouldClose = true;

            try {
                shouldClose = await this.onBeforeClosing();
            } catch (err) {
                // tslint:disable-next-line:no-console
                console.error(err);
            }

            if (shouldClose === false) {
                this.isOpen = true;
                this.$emit('update:content-toggle', true);
                return;
            }

            this.isOpen = open;
            this.handleSheetClose();
        },
        content() {
            this.focusTargetElement();
        },
    },

    mounted() {
        const {
            sheet,
            container,
            content,
            innerContent,
            navBar,
            irisSheetHeader: header,
            irisSheetFooter: footer,
            irisSheetTopBuffer: topBuffer,
            irisSheetBottomBuffer: bottomBuffer,
        } = this.$refs as Record<string, HTMLElement>;

        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 1,
        };

        const callback = (entries: any) => {
            entries.forEach((entry: any) => {
                const target = entry.target;

                if (target === topBuffer) {
                    header.classList.toggle('irisv-sheet__header--shadow', !entry.isIntersecting);
                }

                if (target === bottomBuffer) {
                    if (this.location === LOCATIONS.bottom && this.irisSheetNav) {
                        navBar.classList.toggle('irisv-sheet__nav--shadow', !entry.isIntersecting);
                    } else {
                        footer.classList.toggle('irisv-sheet__footer--shadow', !entry.isIntersecting);
                    }
                }
            });
        };

        this.intersectionObserver = new IntersectionObserver(callback, options);

        if (topBuffer) {
            this.intersectionObserver.observe(topBuffer);
        }
        if (bottomBuffer) {
            this.intersectionObserver.observe(bottomBuffer);
        }

        this.resizeObserver = new ResizeObserver((entries: any[]) => {
            entries.forEach((entry: any) => {
                if (entry.target === container) {
                    this.calcLocation();
                }
            });
        });

        this.resizeObserver.observe(container, { box: 'border-box' });
        this.resizeObserver.observe(content, { box: 'border-box' });
        this.resizeObserver.observe(innerContent, { box: 'border-box' });

        this.calcLocation();

        sheet.addEventListener('animationstart', this.calcLocation);
        content.addEventListener('scroll', this.throttleScroll(this.sheetScroll, 100));

        sheet.addEventListener('focus', this.childElementHasFocus, true);
        sheet.addEventListener('blur', this.childElementHasFocus, true);

        this.calcMinimumContentHeight();
    },

    beforeDestroy() {
        const {
            sheet,
            container,
            content,
            innerContent,
            irisSheetTopBuffer: topBuffer,
            irisSheetBottomBuffer: bottomBuffer,
        } = this.$refs as Record<string, HTMLElement>;

        const { intersectionObserver, resizeObserver } = this;

        if (resizeObserver && resizeObserver instanceof ResizeObserver) {
            resizeObserver.unobserve(container);
            resizeObserver.unobserve(content);
            resizeObserver.unobserve(innerContent);
        }

        if (intersectionObserver && intersectionObserver instanceof IntersectionObserver) {
            if (topBuffer) { intersectionObserver.unobserve(topBuffer); }
            if (bottomBuffer) { intersectionObserver.unobserve(bottomBuffer); }
        }

        sheet.removeEventListener('animationstart', this.calcLocation);
        sheet.removeEventListener('scroll', this.throttleScroll(this.sheetScroll, 100));

        sheet.removeEventListener('focus', this.childElementHasFocus, true);
        sheet.removeEventListener('blur', this.childElementHasFocus, true);

        document.body.classList.remove('irisv-sheet--body');
        document.documentElement.classList.remove('irisv-sheet--html');
    },
});
