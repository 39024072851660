
import Vue from 'vue';
import IrisDropdownBottomSlot from './_bottomSlot.vue';
import { isPosition } from './predicates';
import { ElementDimensions } from './types';
import { platformIsIOS } from '@/utils';
import { IrisDropdownTestHook } from './IrisDropdown.testHooks';

export default Vue.extend({
    name: 'IrisDropdownDesktopContainer',
    components: { IrisDropdownBottomSlot },
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        positionFromTrigger: {
            default: 'left',
            type: String,
            validator: isPosition,
        },
        showListDividers: {
            type: Boolean,
            default: true,
        },
        triggerRect: DOMRect,
        triggerRef: {
            type: undefined,
            default: {},
        },
    },
    data() {
        return {
            menuDimensions: {} as ElementDimensions,
            menuFlow: 'down',
            menuMaxHeight: 400,
            menuItemHeight: 40,
            menuStyle: '',
            isMenuAppended: false,
            ticking: false,
            scrollEventTarget: {} as any,
            scrollEventType: '',
            testHooks: IrisDropdownTestHook,
        };
    },
    computed: {
        calculateMenuLeftPosition(): string {
            const trigger = this.triggerRect;
            const menu = this.menuDimensions;
            let left = 0;

            if (this.positionFromTrigger === 'right') {
                left += trigger.left + trigger.width - menu.width;
            } else if (this.positionFromTrigger === 'center') {
                left += (trigger.left + trigger.right - menu.width) / 2;
            } else {
                left += trigger.left;
            }

            return `left: ${left + window.scrollX}px`;
        },
    },
    methods: {
        onAfterEnter() { // Add the listener after opening to prevent firing immediately
            document.addEventListener('click', this._documentClick);
        },
        onBeforeLeave() { // Remove the listener right away during close
            document.removeEventListener('click', this._documentClick);
        },
        _appendMenuElement() {
            document.body.appendChild(this.$refs.menu as HTMLElement); // Always append the dropdown itself to the body

            this.scrollEventTarget = this._getClosestScrollingParentOfTrigger(); // Find the closest scrolling parent of the dropdown trigger
            this.scrollEventType = platformIsIOS() ? 'touchmove' : 'scroll'; // Check if the platform is iOS and use touchmove instead of scroll

            if (['HTML', 'BODY'].includes(this.scrollEventTarget.tagName)) { // If that scrolling parent is the html or body tag
                this.scrollEventTarget = window; // Then the target will be the window itself
            }

            this.scrollEventTarget.addEventListener(this.scrollEventType, this._closeMenuOnScroll); // Listen for the determined event on the determined target
        },
        _getClosestScrollingParentOfTrigger(): Element {
            let elementToTest = (this.triggerRef as HTMLElement).parentElement;

            while (elementToTest) {
                if (this._elementIsScrollable(elementToTest)) {
                    return elementToTest;
                }
                elementToTest = elementToTest.parentElement;
            }

            return document.scrollingElement || document.documentElement;
        },
        _elementIsScrollable(el: Element): boolean {
            if (el === document.scrollingElement) { // If element is BODY or HTML
                return el.scrollHeight > el.clientHeight;
            }

            return el.scrollHeight > el.clientHeight && ['scroll', 'auto'].includes(getComputedStyle(el).overflowY);
        },
        _closeMenuOnScroll() {
            if (this.ticking) { return; }
            this.ticking = true;

            window.requestAnimationFrame(() => {
                if (this.isOpen) {
                    this.$emit('list-request-to-close');
                }

                this.ticking = false;
            });
        },
        _updateMenuStyleAndFlow() {
            // Calculates menu's style and position (above or below) relative to the dropdown
            this.menuStyle = ''; // Clears out anything from before
            const availableBottomSpace = window.innerHeight - this.triggerRect.bottom;
            const availableTopSpace = this.triggerRect.top;
            const trigger = this.triggerRect;
            this.menuDimensions = this._getMenuRect();
            const menu = this.menuDimensions;
            const menuDisplayHeight = menu.height > this.menuMaxHeight ? this.menuMaxHeight : menu.height;
            const marginSpacer = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--spacingPlatformTiny')) || 8;
            let top = 0;

            // Check if the menu can be displayed in its entirety below the trigger
            if (menuDisplayHeight <= availableBottomSpace) {
                this._setMenuFlowDirection('down');
                top += trigger.bottom;

                this.menuStyle = ` top: ${top + window.scrollY}px; `;
                return;
            }

            // If not, check if the menu can be displayed in its entirety above the trigger
            if (menuDisplayHeight <= availableTopSpace) {
                this._setMenuFlowDirection('up');

                this.menuStyle = `bottom: ${ window.innerHeight - trigger.top - window.scrollY + marginSpacer }px; `;
                return;
            }

            // If not, check if there is more usable space above the trigger
            if (availableBottomSpace <= availableTopSpace) {
                this._setMenuFlowDirection('up');
                this.menuStyle = `max-height: ${availableTopSpace - this.menuItemHeight}px;`;

                this.menuStyle += `bottom: ${ window.innerHeight - trigger.top - window.scrollY + marginSpacer }px; `;
                return;
            }

            // Looks like the most space is below the trigger
            this._setMenuFlowDirection('down');
            this.menuStyle = `max-height: ${availableBottomSpace - this.menuItemHeight}px;`;
            top += trigger.bottom;

            this.menuStyle += ` top: ${top + window.scrollY}px; `;
        },
        _getMenuRect() {
            const el = this.$el as HTMLElement;
            const elChild = this.$refs.menu as HTMLElement;
            const elComputedStyle = window.getComputedStyle(el);
            const elComputedDisplay = elComputedStyle.display;

            const elStyleDisplay: any = el.style.display;
            const elStyleVisibility: any = el.style.visibility;
            const elStylePosition: any = el.style.position;

            const menuListStyleMaxHeight: any = elChild.style.maxHeight;

            // if it's not hidden just return rects
            if (elComputedDisplay !== 'none') {
                return this._getElementRect(el);
            }

            // the element is hidden so:
            // making the el block so we can measure it but still be hidden
            el.style.position = 'absolute';
            el.style.visibility = 'hidden';
            el.style.display = 'block';
            elChild.style.maxHeight = 'none'; // reset maxHeight so we can get correct menu height

            const rect = el.getBoundingClientRect();

            // reverting to the original values
            el.style.display = elStyleDisplay;
            el.style.position = elStylePosition;
            el.style.visibility = elStyleVisibility;
            elChild.style.maxHeight = menuListStyleMaxHeight;

            return {
                top: Math.round(rect.top),
                left: Math.round(rect.left),
                bottom: Math.round(rect.bottom),
                right: Math.round(rect.right),
                width: Math.round(rect.width),
                height: Math.round(rect.height),
            };
        },
        _getElementRect(el: HTMLElement) {
            const rect = el.getBoundingClientRect();

            return {
                top: Math.round(rect.top),
                left: Math.round(rect.left),
                bottom: Math.round(rect.bottom),
                right: Math.round(rect.right),
                width: Math.round(rect.width),
                height: Math.round(rect.height),
            };
        },
        _setMenuFlowDirection(direction: string) {
            this.menuFlow = direction;
        },
        _documentClick(event: Event) {
            if (!this.isOpen) { return; }

            const target = event.target as HTMLElement;

            if (!this.$el.contains(target)) { // If the element that was clicked is not in the iris list
                this.$emit('list-request-to-close');
            }
        },
    },
    beforeDestroy() {
        if (!this.isMenuAppended) { return; }
        (this.$refs.menu as HTMLElement).remove();
        this.scrollEventTarget.removeEventListener(this.scrollEventType, this._closeMenuOnScroll);
    },
    destroyed() {
        document.removeEventListener('click', this._documentClick);
    },
    watch: {
        isOpen(val: boolean) {
            if (val === false) { return; }

            if (!this.isMenuAppended) {
                this.isMenuAppended = true;
                this._appendMenuElement();
            }

            this._updateMenuStyleAndFlow();
        },
    },
});
