/* eslint-disable */
import _Vue, { PluginFunction } from 'vue';

// Import vue components
import * as components from './components';

// Import directives components
import * as directives from './directives';

// Import filters components
import * as filters from './filters';

// Define typescript interfaces for autoinstaller
// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface InstallFunction extends PluginFunction<any> {
    installed?: boolean;
}

// install function executed by Vue.use()
const install: InstallFunction = function installIrisVue(Vue: typeof _Vue) {
    if (install.installed) {
        return;
    }

    install.installed = true;

    Object.entries(components).forEach(([componentName, component]) => {
        Vue.component(componentName, component);
    });

    Object.entries(directives).forEach(([directiveName, directive]) => {
        Vue.directive(directiveName, directive);
    });

    Object.entries(filters).forEach(([filterName, filter]) => {
        Vue.filter(filterName, filter);
    });
};

// Create module definition for Vue.use()
const plugin = {
    install,
};

// To auto-install when vue is found
/* global window, global */
let GlobalVue = null;
if (typeof window !== 'undefined') {
    GlobalVue = (window as any).Vue;
} else if (typeof global !== 'undefined') {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    GlobalVue = (global as any).Vue;
}

if (GlobalVue) {
    (GlobalVue as typeof _Vue).use(plugin);
}

// tslint:disable-next-line
console.log('Iris Vue v1.43.2');

// Default export is library as a whole, registered via Vue.use()
export default plugin;

// To allow individual component use, export components
// each can be registered via Vue.component()
export * from './components';
export * from './directives';
export * from './filters';
export * from './components/test-hooks';
export { getTestSelector } from '@/utils/getTestSelector';