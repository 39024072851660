
import Vue from 'vue';
import IrisIcon from './IrisIcon.vue';
import IrisQuickActionButton from './IrisQuickActionButton.vue';

/**
 * Chip is a compact UI element representing a selection or an action.
 */
export default Vue.extend({
    name: 'IrisChip',

    components: {
        IrisIcon,
        IrisQuickActionButton,
    },

    props: {
        /**
         * Object of props for the IrisAvatar child component.<br /><br />
         * Refer to the [IrisAvatar docs](/?path=/docs/components-iris-avatar--playground) for available props.<br /><br />
         * This prop is assigned to the remove button within the chip if it is removeable.
         * Defaults:
         * <ul>
         * <li>mainIconName: 'cancel-x'</li>
         * <li>size: 'xsmall'</li>
         * <li>letterIconColor: 'rgb(var(--colorBrandedAffordanceAccessible))'</li>
         * </ul>
         */
        removeButtonAvatarProps: Object,
        /**
         * Determines how chip functions with interactions. Valid values are 'button', 'toggle', 'checkbox', or 'radio'.
         */
        kind: {
            type: String,
            default: 'button',
            validator: (value: string) => {
                return ['button', 'toggle', 'checkbox', 'radio'].includes(value.toLowerCase());
            },
        },
        /**
         * Disables the chip and shows the disabled state.
         */
        isDisabled: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets Avatar Image.
         */
        chipAvatarImage: {
            type: String,
            default: '',
        },
        /**
         * Sets alt label for the Avatar Image. We have default it with the words Avatar Image,
         * but please update to something more descriptive such as the name of the user.
         */
        chipAvatarAltText: {
            type: String,
        },
        /**
         * Sets the icon to use on the left side of the chip.
         */
        leadingIconName: {
            type: String,
            default: '',
        },
        /**
         * Sets the aria-label for the leading icon. We made this prop null as fallback,
         * but please update to something descriptive for screen reader user.
         */
        leadingIconAriaLabel: {
            type: String,
        },
        /**
         * Sets the icon to use on the right side of the chip.
         * trailingIconName should not be used with the hasCheckIcon prop.
         */
        trailingIconName: {
            type: String,
            default: '',
        },
        /**
         * Sets the aria-label for the trailing icon. We have made this prop null as fallback,
         * but please update to something descriptive for screen reader user.
         */
        trailingIconAriaLabel: {
            type: String,
        },
        /**
         * Sets the Chip label text
         */
        label: {
            type: String,
            default: '',
        },
        /**
         * All radio kind Chips in the same chips group must have the same name. This ensures
         * that selecting any chips that behaves as a radio button in that group automatically deselects any chip currently
         * selected in that group (since only one chip behaving as a radio button in a given group can be selected at the same time.)
         */
        name: {
            type: String,
            default: '',
            required: true,
        },
        /**
         * A value for the chip when kind is checkbox or radio.
         */
        value: {
            type: String,
            default: '',
        },
        /**
         * The initial state (pressed or checked) of the chip when kind is toggle, checkbox, or radio.
         */
        selected: {
            type: Boolean,
            default: false,
        },
        /**
         * Displays a check icon to show it was selected. The check icon only appears when the chip is selected.
         */
        hasCheckIcon: {
            type: Boolean,
            default: false,
        },
        /**
         * Adds delete button to chip. Intended for use with IrisChipCreator.
         */
        isRemovable: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets the isLoading state with animated icon indicator.
         */
        isLoading: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            ariaPressed: this.selected,
            enterPressed : false,
        };
    },

    methods: {
        removeChip(e: Event) {
            const input = this.$refs.input as HTMLInputElement;

            e.preventDefault();
            /**
             * Emitted when chip is removed from the chip creator component.
             */
            this.$emit('chip-remove', input.value);
        },

        onEnter(e: Event) {
            if ((this.kind === 'checkbox' || this.kind === 'radio') && e.type === 'keyup') {
                const inputElement = this.$refs.input as HTMLInputElement;
                inputElement.checked = !inputElement.checked;
                this.onChange();
            } else if (this.kind === 'button') {
                this.enterPressed = e.type === 'keydown';
            }
        },

        onBlur() {
            if (this.kind === 'button' && this.enterPressed) {
                this.enterPressed = false;
            }
        },

        onClick() {
            const buttonElement = this.$refs.chip as any;

            if (this.kind === 'toggle') {
                this.ariaPressed = !this.ariaPressed;
            }

            this.$nextTick(() => {
                if (this.kind === 'button') {
                    /**
                     * Emitted when the button or toggle is clicked.
                     */
                    this.$emit('chip-click', buttonElement.dataset.name);
                } else if (this.kind === 'toggle') {
                    this.$emit('chip-click', {
                        name: buttonElement.dataset.name,
                        pressed: this.ariaPressed,
                    });
                }
            });
        },
        onChange() {
            const input = this.$refs.input as HTMLInputElement;

            if (this.kind === 'checkbox') {
                /**
                 *  Emitted when the checkbox or radio is clicked.
                 */
                this.$emit('chip-change', {
                    name: input.name,
                    checked: input.checked,
                    value: input.value,
                });
            } else if (this.kind === 'radio') {
                this.$emit('chip-change', {
                    name: input.name,
                    value: input.value,
                });
            }
        },
    },

    computed: {
        defaultRemoveButtonAvatarProps(): object {
            return {
                mainIconName: 'cancel-x',
                size: 'xsmall',
                letterIconColor: 'rgb(var(--colorBrandedAffordanceAccessible))',
                ...this.removeButtonAvatarProps,
            };
        },
    },

    watch: {
        selected(value) {
            this.ariaPressed = value;
        },
    },
});
