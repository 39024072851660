
import Vue from 'vue';
import IrisChipsContainer from './IrisChipsContainer.vue';
import IrisQuickActionButton from './IrisQuickActionButton.vue';
import IrisIcon from './IrisIcon.vue';

/**
 * The chip creator component gives users the ability to add and remove chips.
 */

export default Vue.extend({
    name: 'IrisChipCreator',

    components: {
        IrisChipsContainer,
        IrisQuickActionButton,
        IrisIcon,
    },

    props: {
        /**
         * Used when async feature is needed for adding chips. The values can be 'default', 'ready', 'pending', 'add', or 'reject'.<br /><br />
         * <b>Prop Values:</b>
         * <ul>
         * <li><p><b>default:</b> No async feature on component when adding chips</p></li>
         * <li><p><b>ready:</b> Async starting and ending state</p></li>
         * <li><p><b>pending:</b> Process is ongoing. Waiting for a response</p></li>
         * <li><p><b>add:</b> Success, process of adding will move forward. After completed will return to 'ready' state</p></li>
         * <li><p><b>reject:</b> Not successful, process of adding should not move forward. After, will return to 'ready' state</p></li>
         * </ul>
         */
        asyncAdd: {
            type: String,
            default: 'default',
            validator: (value: string) => {
                return ['default', 'ready', 'pending', 'add', 'reject'].includes(value.toLowerCase());
            },
        },
        /**
         * Used when async feature is needed for removing chips. The values can be 'default', 'ready', 'pending', 'remove', or 'reject'.<br /><br />
         * <b>Prop Values:</b>
         * <ul>
         * <li><p><b>default:</b> No async feature on component when removing chips</p></li>
         * <li><p><b>ready:</b> Async starting and ending state</p></li>
         * <li><p><b>pending:</b> Process is ongoing. Waiting for a response</p></li>
         * <li><p><b>remove:</b> Success, process of removing will move forward. After completed will return to 'ready' state</p></li>
         * <li><p><b>reject:</b> Not successful, process of removing should not move forward. After, will return to 'ready' state</p></li>
         * </ul>
         */
        asyncRemove: {
            type: String,
            default: 'default',
            validator: (value: string) => {
                return ['default', 'ready', 'pending', 'remove', 'reject'].includes(value.toLowerCase());
            },
        },
        /**
         * Object of props for the IrisQuickActionButton child component.<br /><br />
         * Refer to the IrisQuickActionButton docs for available props.<br /<br />
         * This prop is assigned to the add chip button.
         * Defaults:
         * <ul>
         * <li>kind: 'mediumEmphasis'</li>
         * <li>label: this.ariaLabel</li>
         * </ul>
         */
        addButtonProps: Object,
        /**
         * Object of props for the IrisAvatar child component.<br /><br />
         * Refer to the IrisAvatar docs for available props.<br /<br />
         * This prop is assigned to the add chip button's avatar.
         * Defaults:
         * <ul>
         * <li>mainIconName: 'cancel-x'</li>
         * <li>size: 'small'</li>
         * </ul>
         */
        addButtonAvatarProps: Object,
        /**
         * Object of props for the IrisQuickActionButton child component.<br /><br />
         * Refer to the IrisQuickActionButton docs for available props.<br /<br />
         * This prop is assigned to the close new chip input button.
         * Defaults:
         * <ul>
         * <li>kind: 'noEmphasis'</li>
         * <li>label: 'Cancel'</li>
         * </ul>
         */
        closeButtonProps: Object,
        /**
         * Object of props for the IrisAvatar child component.<br /><br />
         * Refer to the IrisAvatar docs for available props.<br /<br />
         * This prop is assigned to the close new chip input button's avatar.
         * Defaults:
         * <ul>
         * <li>mainIconName: 'cancel-x'</li>
         * <li>size: 'small'</li>
         * </ul>
         */
        closeButtonAvatarProps: Object,
        /**
         * Sets the aria-label for the chip creator button. This prop is typically used in the form of a sitetext key for translation purposes.
         */
        ariaLabel: {
            type: String,
            default: 'Add a chip',
        },
        /**
         * Object of props for the IrisChipsContainer child component.<br /><br />
         * Refer to the IrisChipsContainer docs for available props.<br /<br />
         * <br /><br />
         * Defaults:
         * <ul>
         * <li>chipKind: 'checkbox'</li>
         * </ul>
         */
        chipsContainerProps: {
            type: Object,
            required: true,
        },
        /**
         * Set placeholder text for the chip input. This prop is typically used in the form of a sitetext key for translation purposes.
         */
        chipInputPlaceholder: {
            type: String,
            default: 'Start Typing',
        },
        /**
         * Sets position of a newly inserted chip. Options are 'beginning' or 'end'.
         */
        chipInsertPosition: {
            type: String,
            default: 'beginning',
            validator: (value: string) => {
                return ['beginning', 'end'].includes(value.toLowerCase());
            },
        },
        /**
         * Set type of container. Options are 'pinned', 'static', or 'condensed'.
         */
        chipsContainerKind: {
            type: String,
            default: 'pinned',
            validator: (value: string) => {
                return ['pinned', 'static', 'condensed'].includes(value.toLowerCase());
            },
        },
        /**
         *  Set position of the add chip icon relative to the group of chips. Options are 'before' or 'after'.
         */
        position: {
            type: String,
            default: 'before',
            validator: (value: string) => {
                return ['before', 'after'].includes(value.toLowerCase());
            },
        },
    },

    data() {
        return {
            ariaLiveMessage: '' as string,
            chips: {} as { [key: string]: any },
            chipInputWidth: 0 as number,
            newChipInputText: '' as string,
            showChipInput: false as boolean,
            asyncPending: false as boolean,
            asyncRemoveChip: '' as string,
            chipIndexToRemove: -1 as number,
        };
    },

    computed: {
        chipContainerSlot(): string {
            // controls the dynamic slot name
            // if the group of chips are pinned, the 'add chip' icon is injected in the 'prependChipCreator' slot (located at the beginning of the 'group of chips)'.
            // if the group of chips are static or condensed, the 'add chip' icon is injected in the 'appendChipCreator' slot (after the last chip 'item'),
            // so that it is inline with the group of chips. The positioning of the icon at the beginning or end of the chip items is controlled
            // by the css 'order' property to change the order it appears visually.
            return this.chipsContainerKind === 'pinned' ? 'prependChipCreator' : 'appendChipCreator';
        },
        defaultAddButtonProps(): object {
            return {
                kind: 'mediumEmphasis',
                label: this.ariaLabel,
                ...this.addButtonProps,
            };
        },
        defaultAddButtonAvatarProps(): object {
            return {
                mainIconName: 'cancel-x',
                size: 'small',
                ...this.addButtonAvatarProps,
            };
        },
        defaultCloseButtonProps(): object {
            return {
                kind: 'noEmphasis',
                label: 'Cancel',
                ...this.closeButtonProps,
            };
        },
        defaultCloseButtonAvatarProps(): object {
            return {
                mainIconName: 'cancel-x',
                size: 'xsmall',
                ...this.closeButtonAvatarProps,
            };
        },
        defaultChipsContainerProps(): object {
            return {
                chipKind: this.chipsContainerProps && this.chipsContainerProps.chipKind !== undefined ? this.chipsContainerProps.chipKind : 'checkbox',
                ...this.chipsContainerProps,
            };
        },
    },

    methods: {
        onAddChip(): void {
            if (!this.newChipInputText) {
                return;
            }

            // Create a new chip
            const newChipData = {
                label: this.newChipInputText,
                name: 'chips',
                value: this.newChipInputText,
                isRemovable: true,
                kind: 'checkbox', // assuming all newly created chips will be of the checkbox type
            };
            const avatarLabel = this.chipsContainerProps && this.chipsContainerProps.avatarLabel ? this.chipsContainerProps.avatarLabel : 'chips';

            if (this.asyncAdd === 'default' || this.asyncAdd === 'add') {
                // add new chip to beginning or end of the group of chips
                if (this.chipInsertPosition === 'beginning') {
                    this.chips = {[this.newChipInputText]: newChipData, ...this.chips};
                } else {
                    this.chips = {...this.chips, [this.newChipInputText]: newChipData};
                }

                /**
                 * Emitted when a new chip is added.
                 */
                this.$emit('chip-creator-chip-added', { addedChip: newChipData, chipContainer: this.chips });

                this.ariaLiveMessage = `${this.newChipInputText} added to ${avatarLabel}.`;
                this.newChipInputText = '';
                this.showChipInput = false;

                this.$nextTick(() => {
                    (this.$refs.addChipQuickActionButton as HTMLElement).focus();
                });

                if (this.asyncAdd === 'add') {
                    /**
                     * Emitted when a chip is successfully added after the pending state (when in async mode).<br /><br />
                     * The asyncAdd set on the component element must use the "sync" feature for this to work.<br /><br />
                     * ex: :asyncAdd.sync="asyncAddState"<br /><br />
                     */
                    this.$emit('update:async-add', 'ready', '');
                }
            } else if (this.asyncAdd === 'ready') {
                this.$emit('update:async-add', 'pending', this.newChipInputText);
            }
        },
        onRemoveChip(chip: string) {
            // find the key whose value matches the deleted chip's value and remove the key from the group of chips
            const key = Object.keys(this.chips).find((item, index) => {
                const match = this.chips[item].value === chip;
                // we store index as chipIndexToRemove, so when looping through chips in the asyncDisableChips method, we will use that index to add isLoading state to the chip being removed
                this.chipIndexToRemove = match ? index : -1;
                return match;
            });

            if (key) {
                this.asyncRemoveChip = chip;

                if (this.asyncRemove === 'default' || this.asyncRemove === 'remove') {
                    const deletedChipData = this.chips[key];
                    this.$delete(this.chips, key);
                    this.$nextTick(() => {
                        (this.$refs.addChipQuickActionButton as HTMLElement).focus();
                    });

                    /**
                     * Emitted when a chip is deleted.
                     */
                    this.$emit('chip-creator-chip-removed', { deletedChip: deletedChipData, chipContainer: this.chips });

                    this.chipIndexToRemove = -1;
                    this.ariaLiveMessage = `${deletedChipData.label} chip removed.`;

                    if (this.asyncRemove === 'remove') {
                        /**
                         * Emitted when a chip is successfully removed after the pending state (when in async mode).<br /><br />
                         * The asyncRemove set on the component element must use the "sync" feature for this to work.<br /><br />
                         * ex: :asyncRemove.sync="asyncRemoveState"<br /><br />
                         */
                        this.$emit('update:async-remove', 'ready', '');
                    }
                } else if (this.asyncRemove === 'ready') {
                    this.$emit('update:async-remove', 'pending', this.asyncRemoveChip);
                }
            }
        },
        setChipInputWidth() {
            // Set width of chip input to the width of the placeholder text
            const placeholderText = document.createElement('SPAN');
            placeholderText.classList.add('font-caption');
            placeholderText.textContent = this.chipInputPlaceholder;
            placeholderText.style.cssText = 'position: absolute; visibility: hidden;';
            document.body.appendChild(placeholderText);
            this.chipInputWidth = placeholderText.clientWidth;
            placeholderText.remove();
        },
        toggleChipInput() {
            this.newChipInputText = '';
            this.showChipInput = !this.showChipInput;

            if (this.showChipInput) {
                // set focus to chip input
                this.$nextTick(() => {
                    (this.$refs.chipInput as HTMLElement).focus();
                });
            } else {
                // set focus back to chip trigger (quick action button)
                this.$nextTick(() => {
                    (this.$refs.addChipQuickActionButton as HTMLElement).focus();
                });
            }

            if (this.asyncAdd === 'reject') {
                this.$emit('update:async-add', 'ready', '');
            }
        },
        onInput(event: Event): void {
            /**
             * Emitted when the chip value is changed by the user (when creating a new chip to add).<br /><br />
             */
            this.$emit('chip-creator-input', (event.target as HTMLInputElement).value);
        },
        asyncDisableChips(bool: boolean) {
            Object.keys(this.chips).forEach((key: string, index: number) => {
                this.chips[key].isDisabled = bool;
                this.chips[key].isLoading = bool && this.chipIndexToRemove === index;
                this.chips[key].isRemovable = !this.chips[key].isLoading;
            });
        },
    },

    watch: {
        asyncAdd: {
            immediate: true,
            handler(state: string) {
                // 'default', 'ready', 'pending', 'add', 'reject'

                if (!['default', 'ready', 'pending', 'add', 'reject'].includes(state)) {
                    this.$emit('update:async-add', 'default', '');
                }

                this.asyncDisableChips(state === 'pending');
                this.asyncPending = state === 'pending';

                if (state === 'add') {
                    this.onAddChip();
                }

                if (state === 'reject') {
                    this.toggleChipInput();
                }
            },
        },
        asyncRemove: {
            immediate: true,
            handler(state: string) {
                // 'default', 'ready', 'pending', 'remove', 'reject'
                if (!['default', 'ready', 'pending', 'remove', 'reject'].includes(state)) {
                    this.$emit('update:async-remove', 'default', '');
                }

                this.asyncDisableChips(state === 'pending');
                this.asyncPending = state === 'pending';

                if (state === 'remove') {
                    this.onRemoveChip(this.asyncRemoveChip);
                    this.chipIndexToRemove = -1;
                }

                if (state === 'reject') {
                    this.$emit('update:async-remove', 'ready', '');
                    this.chipIndexToRemove = -1;
                }
            },
        },
        // watch for changes to nested chipsData property
        chipsContainerProps(newValue) {
            // update local copy of chips if chipData prop changes
            this.chips = JSON.parse(JSON.stringify(newValue.chipsData));
        },
        chipInputPlaceholder() {
            this.setChipInputWidth();
        },
    },

    created() {
        // Make a copy of the chipsData object passed into the component to avoid mutating prop
        this.chips = this.chipsContainerProps && this.chipsContainerProps.chipsData ? this.chipsContainerProps.chipsData : {};
        this.setChipInputWidth();
    },
});
