import validateByType from './textfieldValidatePatterns';

export const validationMixin: {[key: string]: any} = {
    props: {
        /**
         * Sets the textfield into a disabled state.
         */
        isDisabled: {
            type: Boolean,
            default: false,
        },
        /**
         * Manually sets the error state of the textfield.
         * Typically used when you would like to handle validation externally and not rely solely on the textfield component's internal validation engine.
         */
        hasError: {
            type: Boolean,
            default: false,
        },
        /**
         * Manually puts the textfield in an error state and passes through external error messages.
         * Typically used when you would like to handle validation externally and not rely solely on the textfield component's internal validation engine.
         * Error messages added to this prop will be combined with any errors that come from the components internal validation. It's up to the developer to manage the external error messages.
         */
        errorMessages: {
            type: Array,
            default: () => [],
        },
        /**
         * Overrides the default, internal error messaging displayed when the length of the text value of the textfield is greater than maxlength.
         * Ex: (You've set the 'maxlength' prop to be 100 and a user enters 102 characters into the textfield. This prop is typically used in the form of a sitekey for translation purposes.
         */
        maxLengthValidationErrorMessage: {
            type: String,
            default: 'This field contains more characters than the maximum allowed character length',
        },
        /**
         * Overrides the default, internal error messaging displayed when the length of the text value of the textfield is less than minlength.
         * Ex: (You've set the 'minlength' prop to be 5 and a user enters 4 characters into the textfield. This prop is typically used in the form of a sitekey for translation purposes.
         */
        minLengthValidationErrorMessage: {
            type: String,
            default: 'This field does not meet the minimum character length',
        },
        /**
         * Specifies if the textfield is required or optional.
         */
        isOptional: {
            type: Boolean,
            default: false,
        },
        /**
         * Overrides the default internal error messaging displayed if a required textfield's value is missing
         */
        requiredValidationErrorMessaging: {
            type: String,
            default: 'This input field is required',
        },
        /**
         * Disables the textfield's internal validation engine. Validation will need to be provided externally through the error and errorMessages props.
         */
        validation: {
            type: Boolean,
            default: true,
        },
        /**
         * Feed a single or multiple validation rules into the textfield's internal validation engine. The prop accepts an array of callbacks that take the textfields current value as an argument and returns either
         * either true or an error message (a string). Ex: [(value) => (value && value.length >= 5) || 'Must have 5+ characters'].
         * The validation engine will iterate through each rule and evaluate its function. If a rule returns true, that rule will pass as valid.
         * If a rule returns false, the engine will add its error message to a 'internal error bucket' and invalidate the textfield.
         */
        validationRules: {
            type: Array,
        },
    },
    data() {
        return {
            errorList: [] as string[],
            hasFocused: false as boolean,
            internalValue_: this.value as string,
            isFocused: false as boolean,
            isValid: false as boolean,
        };
    },
    computed: {
        externalError(): boolean {
            return this.errorMessages && this.errorMessages.length > 0 || this.hasError;
        },
        externalErrorMessages(): string[] {
            if (typeof(this.errorMessages) === 'string') { // Corrects for some cases of error messages being displayed character by character
                this.errorMessages = [this.errorMessages];
            }
            return this.errorMessages;
        },
        errorFound(): boolean {
            if (this.isOptional && this.computedValue && this.computedValue.length === 0) {
                return false;
            }
            return this.externalError || this.errorList.length > 0;
        },
        shouldValidate(): boolean {
            if (this.externalError) {
                return true;
            }

            /*
             * Check if textfield has been touched and is no longer in focus before we show an error message.
             * Prevents errors from showing on call to validate in beforeMount
             */
            return (this.hasFocused && !this.isFocused) || this.hasChangedSinceFocus;
        },
        showErrorMessages(): boolean {
            return this.errorFound && this.shouldValidate;
        },
        validationData(): object {
            return {
                showErrorMessages: this.showErrorMessages,
                externalErrorMessages: this.externalErrorMessages,
                errorList: this.errorList,
                hasError: this.errorFound && this.shouldValidate,
            };
        },
    },
    methods: {
        validate(): boolean {
            // if the validation prop has been set to false, skip validation and let user handle manually
            if (!this.validation) {
                return true;
            }

            // Test the current value against our builtin validation rules
            const builtinRulesErrorList = this._builtinValidator(this.internalValue_);

            // Test the current value against any custom validation rules passed in through the validationRules prop
            const customRulesErrorList = this._customValidator(this.internalValue_);

            this.errorList = [...builtinRulesErrorList, ...customRulesErrorList];
            this.isValid = this.errorList.length === 0;

            return this.isValid;
        },
        _customValidator(value: string): string[] {
            const errors = [] as string[];

            // loop through each rule in the validationRules prop and invoke each function
            if (this.validationRules && this.validationRules.length > 0) {
                this.validationRules.forEach( (rule: any) => {
                    const result = typeof rule === 'function' ? rule(value) : rule;

                    // if the result of the test is false or a string (the error message) add it to the list of errors
                    if (result === false || typeof result === 'string') {
                        errors.push(result || '');
                    }
                });
            }

            return errors;
        },
        _builtinValidator(value: string): string[] {
            const errors = [] as string[];
            const validationRules = [
                this._validateRequired(value),
                this._validatePattern(value, this.textfieldType),
                this._validateMinLength(value),
                this._validateMaxLength(value),
            ];

            // test value against each rule in the list
            validationRules.forEach( (rule) => {
                // if the result of the test is a string (the error message) add it to the list of errors
                if (typeof rule === 'string') {
                    errors.push(rule);
                }
            });

            return errors;
        },
        _validateMinLength(value: string): boolean | string {
            if (this.minlength && this.minlength > 0) {
                return String(value).length >= this.minlength ? true : this.minLengthValidationErrorMessage;
            }

            return true;
        },
        _validateMaxLength(value: string): boolean | string {
            if (this.maxlength && this.maxlength > 0) {
                return String(value).length <= this.maxlength ? true : this.maxLengthValidationErrorMessage;
            }

            return true;
        },
        _validatePattern(value: string, type: string): boolean | string {
            if (!Object.keys(validateByType).includes(type)) { return true; }

            const errorMessagesByType: {[key: string]: string} = {
                email: this.emailPatternMismatchMessage,
                tel: this.telephonePatternMismatchMessage,
                url: this.urlPatternMismatchMessage,
            };

            return validateByType[type](value) || errorMessagesByType[type];
        },
        _validateRequired(value: string): boolean | string {
            if (!this.isOptional) {
                return !!value || this.requiredValidationErrorMessaging;
            }

            return true;
        },
    },
    watch: {
        isFocused(val: boolean) {
            // if textfield is in focus, set hasFocused to true so that we know the field has been touched
            if (val && !this.isDisabled) {
                this.hasFocused = true;
            } else if (!val && !this.isDisabled && this.textfieldType !== 'date') {
                this.validate();
            }
        },
        errorList(list: string[]) {
            // if field is required: emit list
            // if field is optional and has a value: emit list
            // if field is date type and errors exist: emit list
            if (!this.isOptional ||
                (this.isOptional && this.computedValue && this.computedValue.length > 0) ||
                (this.textfieldType === 'date' && list.length > 0)) {
                /**
                 * Emits the error list when the validation check updates the list. The list is provided as an array of error message strings. If the list is empty then it can be perceived as passing validation.
                 */
                this.$emit('validation-check', list);
            } else {
                this.$emit('validation-check', {});
            }
        },
        isOptional() {
            this.validate();
        },
    },
    beforeMount() {
        this.validate();
    },
};