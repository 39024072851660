
import Vue from 'vue';
declare var lottie: any;

/**
 * The Lottie Component provides a way to render a lottie JSON file or a url path.
 * In order to use this component is essential to import/install the lottie library in your project.
 */
export default Vue.extend({
    name: 'IrisLottieAnimation',
    props: {
        /**
         * Sets the the alt value for accessibility purposes to describe what this animation is about.
         */
        alt: {
            type: String,
            required: true,
        },
        /**
         * Sets the autoplay of the animation. The default is set to true.
         */
        autoPlay: {
            type: Boolean,
            default: true,
        },
        /**
         * Sets the height for the container that holds the animation. If this prop is not used the height would take 100% of its parent container.
         */
        height: {
            type: Number,
        },
        /**
         * Sets the looping for the animation.
         */
        isLooping: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets the animationData value from the lottie loadAnimation. This is required to be a JSON object.
         */
        animationJSON: {
            type: Object,
        },
        /**
         * Sets the path value from the lottie loadAnimation. This is required to be a url string.
         */
        animationURL: {
            type: String,
        },
        /**
         * Sets the speed for the animation. Lottie speeds goes from 0 to 1.
         */
        speed: {
            type: Number,
            default: 1,
        },
        /**
         * Sets the width for the container that holds the animation. If this is prop is not used the width would take 100% of its parent container
         */
        width: {
            type: Number,
        },
        /**
         * Sets the animation to have an aria-hidden of true in order to hide it from screen readers. This prop should only be used when this animation is purely presentational and there is other text present to decribe the animation's intent.
         */
        isAriaHidden: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            style: {
                width: this.width ? `${this.width}px` : '100%',
                height: this.height ? `${this.height}px` : '100%',
                overflow: 'hidden',
                margin: '0 auto',
            },
            animation: {} as any,
            rendererSettings: {
                scaleMode: 'noScale',
                clearCanvas: false,
                progressiveLoad: false,
                hideOnTransparent: true,
            },
        };
    },
    mounted() {
        this.animation = lottie.loadAnimation({
            container: (this.$refs.irisLottieAnimationContainer as HTMLElement),
            renderer: 'svg',
            loop: this.isLooping,
            autoplay: this.autoPlay,
            animationData: this.animationJSON ? this.animationJSON : null,
            path: this.animationURL ? this.animationURL : null,
            rendererSettings: this.rendererSettings,
        });
        this.animation.setSpeed(this.speed);
        this.$emit('lottie-created', this.animation);
    },
});
