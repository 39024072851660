
import Vue from 'vue';
import IrisTextfieldContainer from './IrisTextfieldContainer.vue';
import { textfieldMixin } from './../mixins/textfieldMixin';

export default Vue.extend({
    name: 'IrisTextarea',
    model: {
        event: 'textfield-input',
    },
    components: {IrisTextfieldContainer},
    mixins: [textfieldMixin],
    props: {
        /**
         * Sets the aria-label for the clearable icon.
         */
        clearIconAriaLabel: {
            type: String,
            default: 'Delete text',
        },
        /**
         * Sets the aria-label for the trailing icon. We have included the icon's name as a fallback, but please update to something descriptive for screen reader user.
         */
        trailingIconAriaLabel: String,
        /**
         * Displays an icon to clear text from textfield. The clearable icon only appears when the textfield contains a value and is in focus.
         */
        isClearable: {
            type: Boolean,
            default: true,
        },
        /**
         * Sets the trailing icon name. Ex: 'info'.
         */
        trailingIconName: String,
    },
    computed: {
        computedValue: { // This will be called whenever computedValue is changed - either from textfieldMixin or v-model
            get(): string {
                return this.internalValue_;
            },
            set(value: string) {
                this.internalValue_ = value;
                this.callToValidateHandler(); // textfieldMixin function
                /**
                 * Emitted when component receives input.
                 */
                this.$emit('textfield-input', value);
            },
        },
        listeners(): object {
            return {
                ...this.$listeners,
                'input': [this.onInput, this.resizeTextarea],
                'blur': this.onBlur,
                'change': this.onChange,
                'focus': this.onFocus,
                'keypress.enter': this.onChange,
            };
        },
    },
    methods: {
        clearText(): void {
            this.computedValue = '';
            this.$emit('textfield-change', this.computedValue);

            // reset the height of the textarea
            (this.$refs.textfield as HTMLElement).style.height = 'auto';
            (this.$refs.textfield as HTMLElement).focus();

            /**
             * Emitted when the clearable icon is clicked.
             */
            this.$emit('textfield-clear');
        },
        resizeTextarea(): void {
            const target = this.$refs.textfield as HTMLTextAreaElement;
            if (!target) { return; }

            this.$nextTick(() => {
                target.style.height = 'auto';
                target.style.height = `${target.scrollHeight}px`;
            });
        },
    },
    watch: {
        value(newValue: string) {
            if (newValue !== this.computedValue) { // This helps us determine that the change came from v-model, not the user typing
                this.validateImmediate = true; // textfieldMixin data
                this.computedValue = newValue;
            }
        },
    },
    mounted() {
        this.resizeTextarea();
        window.addEventListener('resize', () => this.resizeTextarea());
    },
});
