export const FORM_ELEM_TYPES = [
    'color', 'date', 'datetime-local', 'email', 'month', 'number', 'password', 'search', 'tel', 'text', 'time', 'url', 'week',
];

/* ANCHORS */
export enum PossibleAnchors {
    top = 'top',
    center = 'center',
    bottom = 'bottom',
}
// This is just a convenience constant where, ANCHORS.top = 'top', etc...
export const ANCHORS = Object.fromEntries(
    ['top', 'center', 'bottom'].map((anchor) => [anchor, anchor]),
) as Record<PossibleAnchors, PossibleAnchors>;

/* LOCATIONS */
export enum PossibleLocations {
    side = 'side',
    bottom = 'bottom',
}
// This is just a convenience constant where, LOCATIONS.side = 'side', etc...
export const LOCATIONS = Object.fromEntries(
    ['side', 'bottom'].map((loc) => [loc, loc]),
) as Record<PossibleLocations, PossibleLocations>;

/* KINDS */
export enum PossibleKinds {
    partial = 'partial',
    fullscreen = 'fullscreen',
}
// This is just a convenience constant where, KINDS.partial = 'partial', etc...
export const KINDS = Object.fromEntries(
    ['partial', 'fullscreen'].map((loc) => [loc, loc]),
) as Record<PossibleKinds, PossibleKinds>;
