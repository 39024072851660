
import IrisIcon from '@/components/IrisIcon.vue';
import Vue, { PropType } from 'vue';
import IrisDropdownItemCheckbox from './_listItemCheckbox.vue';
import { ItemData } from './types';
import { IrisDropdownTestHook } from './IrisDropdown.testHooks';

export default Vue.extend({
    name: 'IrisDropdownItemNavigationAction',
    components: {
        IrisIcon,
        IrisDropdownItemCheckbox,
    },
    props: {
        isAction: {
            default: false,
            type: Boolean,
        },
        id: {
            type: String,
        },
        item: {
            default: () => ({} as ItemData),
            type: Object as PropType<ItemData>,
        },
        selected: {
            default: false,
            type: Boolean,
        },
        showCheckboxes: {
            default: false,
            type: Boolean,
        },
    },
    data() {
        return {
            testHooks: IrisDropdownTestHook,
        };
    },
    computed: {
        menuItemTag() {
            if (this.item.href !== undefined) {
                return 'a';
            } else if (this.item.to !== undefined) {
                return 'router-link';
            }
            return 'div';
        },
    },
});
