var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"root",class:[
        'irisv-select-dropdown',
        _vm.isOpen ? 'irisv-select-dropdown--open' : '',
        _vm.isDisabled ? 'irisv-select-dropdown--disabled' : '',
        _vm.showError ? 'irisv-select-dropdown--error' : '',
        _vm.isReadonly ? 'irisv-select-dropdown--readonly' : '',
        _vm.isLoading ? 'irisv-select-dropdown--loading' : '',
        _vm.showSkeleton ? 'irisv-select-dropdown--skeleton' : '',
        _vm.hasFocus ? 'irisv-select-dropdown--focused' : '',
        `irisv-select-dropdown--${_vm.tokenKind}`,
    ],attrs:{"id":_vm.identifier_,"tabindex":(_vm.showSkeleton || _vm.isDisabled) ? -1 : 0,"role":"combobox","aria-labelledby":`${_vm.identifier_}__label ${_vm.identifier_}__value`,"aria-expanded":_vm.isOpen ? 'true' : 'false',"aria-controls":`${_vm.identifier_}__listbox`,"aria-activedescendant":_vm.activeDescendant,"aria-required":!_vm.isOptional,"aria-readonly":_vm.isReadonly,"aria-disabled":_vm.isDisabled},on:{"focus":_vm.focusHandler,"focusout":_vm.blurHandler,"keydown":_vm.rootKeyHandler,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"escape",undefined,$event.key,undefined))return null;return _vm.preventEscapeBubble.apply(null, arguments)}}},[_c('div',{staticClass:"irisv-select-dropdown__control",style:(_vm.showSkeleton ? 'visibility: hidden;' : '')},[_c('div',{staticClass:"irisv-select-dropdown__input-wrapper"},[_c('div',{staticClass:"irisv-select-dropdown__label font-caption",attrs:{"id":`${_vm.identifier_}__label`,"aria-hidden":"true"}},[_vm._v(" "+_vm._s(_vm.isOptional ? `${_vm.mergedStrings.label} ${_vm.mergedStrings.optionalText}` : _vm.mergedStrings.label)+" ")]),_c('div',{class:[
                    'irisv-select-dropdown__value font-body-1',
                    {'text--truncate': _vm.kind === 'list'},
                    {'irisv-select-dropdown__value--stacked': (_vm.kind === 'account' && _vm.accountKindDisplay === 'stacked')}
                ],attrs:{"id":`${_vm.identifier_}__value`,"aria-hidden":"true"}},[(_vm.kind === 'list')?[_vm._v(_vm._s(_vm.activeMenuItem.label))]:(_vm.kind === 'account')?[(_vm.itemIndexToDisplay > -1)?_c('iris-account',_vm._b({attrs:{"kind":_vm.accountKindDisplay === 'single-line' ? 'single-line' : 'stacked-simple',"balance-display-class":"subtitle2"}},'iris-account',_vm.accountsData[_vm.itemIndexToDisplay],false)):_vm._e()]:_vm._e()],2)]),_c('iris-icon',{staticClass:"irisv-select-dropdown__caret",attrs:{"name":"caret","size":"md","is-loading":_vm.isLoading,"is-aria-hidden":""}}),_c('button',{ref:"trigger",staticClass:"irisv-select-dropdown__trigger",attrs:{"tabindex":"-1"},on:{"click":_vm.toggleList}})],1),(_vm.messages !== '')?_c('div',{staticClass:"irisv-select-dropdown__messages font-caption",attrs:{"role":_vm.showError ? 'alert' : ''}},[_vm._v(" "+_vm._s(_vm.messages)+" ")]):_vm._e(),_c('iris-dropdown',{attrs:{"element-id":_vm.identifier_,"strings":{
            filterPlaceholderText: _vm.mergedStrings.filterPlaceholder,
            noResultsText: _vm.mergedStrings.noResultsText,
            mobileListTitleText: _vm.mergedStrings.labelForMobileList,
        },"kind":_vm.kind,"match-trigger-width":true,"show-filter":_vm.showFilter,"show-list-dividers":_vm.showListDividers,"show-checkboxes":true,"show-mobile-list-title":_vm.showLabelInMobileList,"items":_vm.aggregateItems,"is-open":_vm.isOpen,"trigger-ref-from-parent":() => _vm.$refs['trigger'],"filter":_vm.parentFilter,"selected":_vm.modelValue},on:{"update:isOpen":_vm.setIsOpen,"list-filter-input":_vm.listFilterInput,"list-item-selected-data":_vm.listItemSelectedData,"listbox-key-passthrough":_vm.rootKeyHandler},scopedSlots:_vm._u([{key:"bottomSlotFromParent",fn:function(){return [_vm._t("default")]},proxy:true}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }