
import { _currencyFormatter } from '@/utils';
import Vue from 'vue';
import { deprecationMixin } from './../mixins/deprecationMixin';
import IrisIcon from './IrisIcon.vue';

/**
 * An account component displays essential account information such as name, account number and balance.
 */
export default Vue.extend({
    name: 'IrisAccount',
    components: {
        IrisIcon,
    },
    mixins: [deprecationMixin],
    props: {
        /**
         * Sets the account variant. Valid values are 'stacked', 'stacked-simple', 'single-line', 'double-line' or 'triple-line''.
         */
        kind: {
            type: String,
            default: 'stacked',
            validator: (value: string) => {
                return ['stacked', 'stacked-simple', 'single-line', 'double-line', 'triple-line'].includes(value.toLowerCase());
            },
        },
        /**
         * Sets the account color.
         */
        accountColor: {
            type: String,
            default: '0',
            validator: (value: string) => {
                return ['0', '1', '2', '3', '4', '5', '6', '7'].includes(value.toLowerCase());
            },
        },
        /**
         * Displays an account colored dot to the left of the account information.
         */
        showAccountColor: {
            type: Boolean,
            default: true,
        },
        /**
         * Sets the account nickname.
         */
        accountNickName: {
            type: String,
            default: '',
        },
        /**
         * An object containing account nickname hyperlink options.<br /><br />
         * <strong>Keys/Values are:</strong><br /><br />
         * href: The URL that the hyperlink points to. Links are not restricted to HTTP-based URLs, they can use any URL scheme supported by browsers.<br /><br />
         * target: Where to display the linked URL. Valid values are '\_self', '\_blank', '\_parent', and '\_top'.<br />
         */
        accountNickNameOptions: {
            type: Object,
            default: () => ({}),
        },
        /**
         * (Deprecated: Use accountNumberDisplay instead!) Sets the account number masking characters.
         */
        accountNumberMask: {
            type: String,
            default: '',
        },
        /**
         * (Deprecated: Use accountNumberDisplay instead!) Sets the last account digits
         */
        accountNumberEnding: {
            type: String,
            default: '',
        },
        /**
         * Sets the account number to display. This should include any desired formatting and masked characters.
         */
        accountNumberDisplay: {
            type: String,
            default: '',
        },
        /**
         * Sets the shared icon for account relationship.
         */
        accountRelationshipSharedIcon: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets the aria-label property value for account relationship shared icon.
         */
        accountRelationshipSharedIconAriaLabel: {
            type: String,
            default: 'Shared Account',
        },
        /**
         * Sets the linked icon for account relationship.
         */
        accountRelationshipLinkedIcon: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets the aria-label property value for account relationship linked icon.
         */
        ariaLabelAccountRelationshipLinkedIcon: {
            type: String,
            default: 'Linked Account',
        },
        /**
         * Sets the Business Household icon for account relationship.
         */
        accountRelationshipBusinessIcon: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets the aria-label property value for account relationship business icon.
         */
        accountRelationshipBusinessIconAriaLabel: {
            type: String,
            default: 'Business Account',
        },
        /**
         * Sets the External icon for account relationship.
         */
        accountRelationshipExternalIcon: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets the aria-label property value for account relationship external icon.
         */
        accountRelationshipExternalIconAriaLabel: {
            type: String,
            default: 'External Account',
        },
        /**
         * Sets the payment due text, example: Payment Due:
         */
        paymentDueText: {
            type: String,
            default: '',
        },
        /**
         * Sets the payment due amount.
         */
        paymentDueAmount: {
            type: String,
            default: null,
        },
        /**
         * Sets the payment due date.
         */
        paymentDueDate: {
            type: String,
            default: '',
        },
        /**
         * Sets the available balance icon.
         */
        availableBalanceIcon: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets the available aria-label property value for the available balance icon.
         */
        availableBalanceIconAriaLabel: {
            type: String,
            default: 'Available Balance',
        },
        /**
         * Sets the account available balance amount.
         */
        availableBalanceAmount: {
            type: String,
            default: '',
        },
        /**
         * Sets the available now text.
         */
        availableNowText: {
            type: String,
            default: '',
        },
        /**
         * Sets the aggregated account icon.
         */
        aggregatedAccountIconName: {
            type: String,
            default: null,
            validator: (value: string) => {
                return ['retry', 'error', 'alert-filled'].includes(value.toLowerCase());
            },
        },
        /**
         * Sets the aggregated account aria-label for the button.
         */
        aggregatedAccountButtonAriaLabel: {
            type: String,
            default: 'Refresh Aggregated Account',
        },
        /**
         * Sets the current balance amount.
         */
        currentBalanceAmount: {
            type: String,
            default: '',
        },
        /**
         * Sets the balance as of date.
         */
        balanceDate: {
            type: String,
            default: '',
        },
        /**
         * Sets the font Caption for the elements that have the Body 1 type class on single-line account kind
         */
        fontCaptionClass: {
            type: Boolean,
            default: false,
        },
        /**
         * An object containing the values used by the currencyFormatter logic.<br /><br />
         * <strong>Keys are:</strong><br /><br />
         * currency: The currency to use in formatting. Possible values are from the ISO 4217 currency codes list, such as "USD" for the US dollar, "EUR" for the Euro, or "CNY" for the Chinese RMB. Default is "USD".<br /><br />
         * currencyDisplay: How to display the currency in formatting. Possible values are:
         * <ul>
         * <li>"narrowSymbol" - Displays a narrow format symbol ("$100.00" instead of "USD 100.00"). This is not available on iOS at this time.</li>
         * <li>"symbol" - Displays a localized currency symbol such as $, €, ¥, etc. This is the default.</li>
         * <li>"code" - Displays the character representation of the currency ("USD 100.00" instead of "$100.00").</li>
         * <li>"name" - Displays the localized currency name such as "dollar".</li>
         * </ul>
         * locale: Formats based on language settings. The default for this comes from the browser's language setting automatically. If you wish to override this, specify a value found in the BCP 47 language list.<br /><br />
         */
        currencyDisplayOptions: {
            type: Object,
            default: () => ({}),
        },
        /**
         * Overides the currency symbol returned from the currencyFormatting utility.
         */
        currencySymbol: {
            type: String,
            default: '',
        },
        /**
         * Sets the font style and size for the available or current balance. Applies to 'stacked' and 'stacked-simple' kinds only. Valid values are 'mediumHeading', 'subtitle2', or 'body1'. The default for 'stacked' is 'mediumHeading'. The default for 'simple-stacked is 'subtitle2'.'
         */
        balanceDisplayClass: {
            type: String,
            default: '',
            validator: (value: string) => {
                return ['', 'mediumHeading', 'subtitle2', 'body1'].includes(value);
            },
        },
        /**
         * Optionally displays a disclosure message at the bottom.
         */
        disclosureMessage: {
            default: '',
            type: String,
        },
    },
    data() {
        return {
            dataBalanceDisplayClass: '' as string,
        };
    },
    computed: {
        compCurrencyFormatterOptions(): any {
            return {
                currency: 'USD',
                currencyDisplay: 'symbol',
                locale: navigator.language,
                ...this.currencyDisplayOptions,
            };
        },
        compPaymentDueAmount(): string {
            const valueToFormat = this.paymentDueAmount.replace(/[^0-9.-]/g, ''); // This will remove eveything except numbers, periods, and negative symbols
            return this._composeCurrencyOutput(valueToFormat);
        },
        compAvailableBalanceAmount(): string {
            const valueToFormat = this.availableBalanceAmount.replace(/[^0-9.-]/g, ''); // This will remove eveything except numbers, periods, and negative symbols
            return this._composeCurrencyOutput(valueToFormat);
        },
        compCurrentBalanceAmount(): string {
            const valueToFormat = this.currentBalanceAmount.replace(/[^0-9.-]/g, ''); // This will remove eveything except numbers, periods, and negative symbols
            return this._composeCurrencyOutput(valueToFormat);
        },
        compAccountNumberDisplay(): string {
            if (this.accountNumberDisplay === '') {
                return this.accountNumberEnding !== '' ? (this.accountNumberMask + this.accountNumberEnding) : '';
            } else {
                return this.accountNumberDisplay;
            }
        },
    },
    methods: {
        aggregatedOnClick($event: Event) {
            /**
             * Emitted when the button is clicked.
             */
            this.$emit('account-click-aggregated', $event);
        },
        _composeCurrencyOutput(valueToFormat: string): string {
            const formattedParts: any = _currencyFormatter(valueToFormat, this.compCurrencyFormatterOptions.currency, this.compCurrencyFormatterOptions.currencyDisplay, this.compCurrencyFormatterOptions.locale, false, true);
            let formattedOutput = '';

            for (let i = 0, len = formattedParts.length; i < len; i++ ) {
                if (formattedParts[i].type === 'currency') {
                    if (this.currencySymbol.trim() !== '') {
                        formattedOutput = formattedOutput + this.currencySymbol;
                    } else {
                        formattedOutput = formattedOutput + formattedParts[i].value;
                    }
                } else {
                    formattedOutput = formattedOutput + formattedParts[i].value;
                }
            }
            return formattedOutput;
        },
        _setBalanceDisplayClass() {
            if (this.balanceDisplayClass === '') {
                if (this.kind === 'stacked') {
                    this.dataBalanceDisplayClass = 'mediumHeading';
                } else if (this.kind === 'stacked-simple') {
                    this.dataBalanceDisplayClass = 'subtitle2';
                }
            } else {
                this.dataBalanceDisplayClass = this.balanceDisplayClass;
            }
        },
    },
    mounted() {
        this._setBalanceDisplayClass();
    },
    watch: {
        balanceDisplayClass() {
            this._setBalanceDisplayClass();
        },
    },
});
