
import Vue from 'vue';
import IrisIcon from './IrisIcon.vue';
import { deprecationMixin } from './../mixins/deprecationMixin';

/**
 * Selectable Tiles can be used in multiple ways. They can be used for an either/or action or used to select multiple items.
 */
export default Vue.extend({
    name: 'IrisSelectableTile',
    components: {
        IrisIcon,
    },
    mixins: [deprecationMixin],
    props: {
        /**
         * Sets the icon name.
         */
        iconName: {
            type: String,
            default: '',
        },
        /**
         * The label text of the selectable tile.
         */
        label: {
            type: String,
            default: '',
        },
        /**
         * The 2nd line of label text of the selectable tile. Optional.
         */
        subLabel: {
            type: String,
            default: '',
        },
        /**
         * The state of the selectable tile. Checked will be true, unchecked will be false.
         */
        isChecked: {
            type: Boolean,
            default: false,
        },
        /**
         * A value that can be used independently of the label or checked state.
         */
        value: {
            type: String,
            default: '',
        },
        /**
         * Sets the selectable tile control to behave as radio buttons instead of checkboxes. By default, the
         * control allows multiple selection of items.
         */
        isSingleSelection: {
            type: Boolean,
            default: false,
        },
        /**
         * When using the control in isSingleSelection mode, this property identifies all items you wish to be
         * considered as part of the group. Required when in isSingleSelection mode.
         */
        name: {
            type: String,
            default: '',
        },
        /**
         * Disables the selectable tile and shows the disabled state.
         */
        isDisabled: {
            type: Boolean,
            default: false,
        },
        /**
         * Shows the skeleton loader as a placeholder option. This may be useful when page content is loading in
         * the background.
         */
        showSkeleton: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onChange() {
            const input = (this.$refs.input as HTMLInputElement);
            /**
             * Emitted when the selectable tile is clicked. Will return an object containing the checked state
             * and value.
             */
            this.$emit('selectable-tile-change', { checked: input.checked, value: input.value });
        },
        checkedMethod() {
            const input = this.$refs.input as HTMLInputElement;

            input.checked = !input.checked;
            this.$emit('selectable-tile-change', { checked: input.checked, value: input.value });
        },
        _adjustLabelSizes(elem: any, baseFontSize: number, minFontSize: number) {
            const maxContainerWidth = 96; // This is the width of the span in the container

            elem.style.fontSize = baseFontSize + 'px'; // Set the font size of the element to baseline, then run the check

            if (elem.scrollWidth > maxContainerWidth) { // Check if text is longer than container can display
                let size = baseFontSize - 1;

                while (elem.scrollWidth > maxContainerWidth) { // Loop until the text fits
                    elem.style.fontSize = size + 'px'; // Set the font size of the element
                    if (elem.scrollWidth <= maxContainerWidth || size === minFontSize) { // Check if further adjustment is needed
                        break;
                    }
                    size--; // Decrease the font size in preparation for the next pass
                }
            }
        },
    },
    mounted() {
        this._adjustLabelSizes(this.$refs.label, 12, 8);
        this._adjustLabelSizes(this.$refs.subLabel, 10, 8);
    },
    updated() {
        this._adjustLabelSizes(this.$refs.label, 12, 8);
        this._adjustLabelSizes(this.$refs.subLabel, 10, 8);
    },
});
