var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
        `irisv-menu-dropdown`,
        `irisv-menu-dropdown--${_vm.kind}`,
        `irisv-menu-dropdown--${_vm.triggerType}`,
        { 'irisv-menu-dropdown--isOpen': _vm.isOpen }
    ]},[_c('div',{ref:"menuButton",staticClass:"irisv-menu-dropdown__menu-button",attrs:{"id":_vm.identifier_,"aria-expanded":_vm.isOpen,"aria-haspopup":"","role":_vm.triggerType !== 'quickactionbutton' ? 'button' : undefined,"tabindex":_vm.triggerType !== 'quickactionbutton' ? 0 : undefined},on:{"click":_vm.toggleList,"keydown":_vm.rootKeyHandler,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"escape",undefined,$event.key,undefined))return null;return _vm.preventEscapeBubble.apply(null, arguments)}}},[(_vm.triggerType === 'label')?[_c('span',{staticClass:"irisv-menu-dropdown__menu-button-label font-body-1"},[_vm._v(" "+_vm._s(_vm.triggerLabel)+" ")]),(_vm.triggerHasCaret || _vm.triggerIconName)?_c('iris-icon',{class:{
                    'irisv-menu-dropdown__menu-button-caret': _vm.triggerHasCaret,
                    'irisv-menu-dropdown__menu-button-icon': _vm.triggerIconName
                },attrs:{"name":_vm.triggerHasCaret ? 'caret' : _vm.triggerIconName,"size":"md","aria-hidden":"true"}}):_vm._e()]:(_vm.triggerType === 'icon')?[_c('iris-icon',{staticClass:"irisv-menu-dropdown__menu-button-icon",attrs:{"aria-label":_vm.triggerIconAriaLabel,"name":_vm.triggerIconName,"size":"md"}})]:[_c('iris-quick-action-button',_vm._b({ref:"quickActionButton",attrs:{"button-or-anchor-props":_vm.qabButtonProps,"avatar-props":_vm.defaultAvatarProps}},'iris-quick-action-button',_vm.defaultQuickActionButtonProps,false))]],2),_c('iris-dropdown',{attrs:{"aria-label":_vm.triggerType === 'icon' || _vm.triggerType === 'quickactionbutton' ? _vm.triggerIconAriaLabel : undefined,"element-id":_vm.identifier_,"is-open":_vm.isOpen,"items":_vm.aggregateItems,"kind":_vm.kind,"position-from-trigger":_vm.menuAlignment,"selected":_vm.selectedMenuItemIndex > -1 && _vm.aggregateItems[_vm.selectedMenuItemIndex] ? [_vm.aggregateItems[_vm.selectedMenuItemIndex].value] : undefined,"show-checkboxes":_vm.kind !== 'action',"trigger-ref-from-parent":() => _vm.$refs['menuButton']},on:{"update:isOpen":_vm.setIsOpen,"list-item-selected-data":_vm.listItemSelectedData,"listbox-key-passthrough":_vm.rootKeyHandler}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }