export enum IrisDropdownTestHook {
    container = 'irisv-dropdown__container',
    scrim = 'irisv-dropdown__scrim',
    grabber = 'irisv-dropdown__list-grabber',
    listTitle = 'irisv-dropdown__list-title',
    filterContainer = 'irisv-dropdown__filter-container',
    filterInput = 'irisv-dropdown__filter-input',
    listBox = 'irisv-dropdown__listbox',
    listItem = 'irisv-dropdown__listitem',
    listItemText = 'irisv-dropdown__listitem__text',
    listItemTextSecondLine = 'irisv-dropdown__listitem__text-secondline',
    checkboxContainer = 'irisv-dropdown__checkbox-container',
    checkboxChecked = 'irisv-dropdown__checkbox-checked',
    noResults = 'irisv-dropdown__no-results',
    bottomSlot = 'irisv-dropdown__bottom-slot',
}
