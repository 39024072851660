import { _currencyFormatter } from '@/utils';

export function currencyFormatter(value: string, args: {[index: string]: any}): string | any[] {
    const argCurrency = (args !== undefined && args.currency !== '') ? args.currency : 'USD';
    const argCurrencyDisplay = (args !== undefined && args.currencyDisplay !== '') ? args.currencyDisplay : 'symbol';
    const argLocale = (args !== undefined && args.locale !== '') ? args.locale : navigator.language;
    const argHasImpliedDecimal = (args !== undefined && args.hasImpliedDecimal !== undefined) ? args.hasImpliedDecimal : false;
    const argFormatToParts = (args !== undefined && args.formatToParts !== undefined) ? args.formatToParts : false;

    return _currencyFormatter(value, argCurrency, argCurrencyDisplay, argLocale, argHasImpliedDecimal, argFormatToParts);
}