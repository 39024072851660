
import Vue from 'vue';
import { generateUniqueId } from '@/utils';

export default Vue.extend({
    name: 'IrisSheetScrim',

    props: {
        /**
         * Sets the id (HTML global attribute) for the component. If an id is not provided, one will be generated automatically.
         */
        elementId: String,
    },

    data() {
        return {
            identifier_: `${this.elementId}__scrim` || generateUniqueId('irisv_scrim') as string,
        };
    },

    mounted() {
        if (!this.$parent) { return; }

        const refs = this.$parent.$refs || [];

        if (!this.$refs.self) { return; }

        Object.entries(refs)
            .filter(([, ref]) => ref === this)
            .forEach(([key]) => { refs[key] = this.$refs.self; });
    },
});
