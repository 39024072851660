
import Vue from 'vue';
import IrisChip from './IrisChip.vue';

/**
 * The scrollable chips component is a container of a group of chips that allows dragging to scroll.
 */

export default Vue.extend({
    name: 'IrisChipsContainer',

    components: {
        IrisChip,
    },

    props: {
        /**
         * Sets the aria-label for the component. Please include any needed punctuation in the value.
         */
        ariaLabel: {
            type: String,
            default: '',
            required: true,
        },
        /**
         * Set type of chips expected in container. Options are 'button', 'toggle', 'checkbox', 'radio'.
         */
        chipKind: {
            type: String,
            default: 'button',
            validator: (value: string) => {
                return ['button', 'toggle', 'checkbox', 'radio'].includes(value.toLowerCase());
            },
        },
        /**
         * Data to build the group of chip components.<br />
         * The data should be an object of objects where each key represents a chip and its props to be passed along.<br />
         * The key for each chip is used for internal id purposes.
         */
        chipsData: {
            type: Object,
            required: true,
        },
        /**
         * Prop to control the filtering of container event data based on chipKind of "toggle" or "checkbox". Data representing the group of chips can be filtered by "all", "true", or "false".
         * <br /><br />
         * "all" provides an array of objects describing the current state of all chips in the container.
         * <br /><br />
         * "true" provides an array of names of chips where "pressed" is true for toggle chips and "checked" is true for checkbox chips.
         * <br /><br />
         * "false" provides an array of names of chips where "pressed" is false for toggle chips and "checked" is false for checkbox chips.
         * <br /><br />
         * Button and radio chips do not make use of this prop.
         */
        eventDataFilter: {
            type: String,
            default: 'all',
            validator: (value: string) => {
                return ['all', 'true', 'false'].includes(value.toLowerCase());
            },
        },
        /**
         * Set type of container. Options are 'scrollable', 'pinned', 'static', 'condensed'.
         */
        kind: {
            type: String,
            default: 'scrollable',
            validator: (value: string) => {
                return ['scrollable', 'pinned', 'static', 'condensed'].includes(value.toLowerCase());
            },
        },
    },

    data() {
        return {
            activeChip: 0 as number,
            canThisExpand: false as boolean,
            chipMargin: '4px' as string,
            dragDownTimer: 0 as number,
            duration: '250ms' as string,
            easing: 'ease-out' as string,
            previousItemX: 0 as number,
            sliding: false as boolean,
            isCondensed: true as boolean,
        };
    },

    methods: {
        hasKey(obj: object, key: keyof any): key is keyof object {
            // this method exists solely so we can use square bracket notation on $refs
            return obj ? key in obj : false;
        },
        onUpdate(data: {name: string, pressed: string, checked: boolean, value: string}) {
            if (this.chipKind === 'button' || this.chipKind === 'radio') {
                // just pass along the event from the chip
                this.$emit(this.chipKind === 'button' ? 'chip-click' : 'chip-change', data);

                // same data as from the chip
                this.$emit(this.chipKind === 'button' ? 'chips-container-click' : 'chips-container-change', data);
            } else {
                // for toggle and checkbox chips we want to emit current state of all the chips in the container
                const containedChips = [] as object[];

                // loop through the chips to get their current state
                (this.$refs.chips as any[]).forEach((chip) => {
                    // data emitted is different between toggle and checkbox chips
                    // if filter is "all", create array of objects describing all chips
                    // if filter is "true" or "false", create array of chip names matching that checked or pressed state
                    if (this.chipKind === 'toggle') {
                        if (this.eventDataFilter === 'all') {
                            containedChips.push({ name: chip.$el.dataset.name, pressed: chip.$el.ariaPressed });
                        } else if (chip.$el.ariaPressed && this.eventDataFilter === chip.$el.ariaPressed.toString()) {
                            containedChips.push(chip.$el.dataset.name);
                        }
                    } else {
                        if (this.eventDataFilter === 'all') {
                            containedChips.push({ name: chip.$el.dataset.name, checked: chip.$el.children[0].checked, value: chip.$el.children[0].value });
                        } else if (this.eventDataFilter === chip.$el.children[0].checked.toString()) {
                            containedChips.push(chip.$el.dataset.name);
                        }
                    }
                });

                // just pass along the event from the chip
                this.$emit('chip-change', data);

                // different data than from chip
                this.$emit('chips-container-change', containedChips);
            }
        },
        leftRightKeyup(e: KeyboardEvent) {
            const chips = (this.$refs.chips as Vue[]);
            const chipsLength = chips.length;
            const isPinned = this.kind === 'pinned';

            let nextActiveChip = e.code === 'ArrowRight' ? this.activeChip + 1 : this.activeChip - 1;

            // lock down nextActive to the range of actual number of chips in container
            nextActiveChip = nextActiveChip < 0 ? 0 : nextActiveChip;
            nextActiveChip = nextActiveChip > chipsLength - 1 ? chipsLength - 1 : nextActiveChip;

            if (this.hasKey(chips, this.activeChip)) {
                const currentChip = chips[this.activeChip] as any;

                // take the old chip out of the tabindex
                currentChip.$el.setAttribute('tabindex', '-1');

                // for pinned state, perform same transition same as being dragged
                if (isPinned && e.code === 'ArrowRight') {
                    currentChip.$el.style.cssText = `transition: ${this.duration} ${this.easing}; transform-origin: center right; transform: scale3d(0, 0, 1); opacity: 0`;
                }
            }

            if (this.hasKey(chips, nextActiveChip)) {
                const currentChip = chips[nextActiveChip] as any;
                const slider = (this.$refs.slider as any).$el;

                // put the new chip in the tabindex, then focus it
                currentChip.$el.setAttribute('tabindex', '0');
                currentChip.$el.focus();

                // move slider in relation to new active chip
                if (isPinned || this.kind === 'scrollable') {
                    slider.style.cssText = `transition: left ${this.duration} ${this.easing}; left: ${-currentChip.$el.offsetLeft}px`;
                }

                // for pinned state, perform same transition same as being dragged
                if (isPinned && e.code === 'ArrowLeft') {
                    currentChip.$el.style.cssText = `transition: ${this.duration} ${this.easing}; transform-origin: center right; transform: scale3d(1, 1, 1); opacity: 1`;
                }
            }

            if (chipsLength > 0) {
                this.activeChip = nextActiveChip;
            } else {
                this.activeChip = 0;
            }
        },
        condenseOrExpand() {
            // this assumes that all chips have the same height
            // this assumes consistent margins to determine height and number of rows
            const slider = (this.$refs.slider as any).$el;
            const sliderItems: HTMLElement[] = Array.from(slider.children);
            const chipMargin = parseInt(this.chipMargin, 10);
            const leadingItems = [] as any;

            sliderItems.forEach((item, index) => {
                if (item.classList.contains('irisv-chips-container__add-chip-icon')) {
                    // don't need the chip creator item involved
                    return false;
                }

                if (index === 0) {
                    leadingItems.push(item);
                } else if (item.offsetLeft === chipMargin) {
                    leadingItems.push(item);
                }
            });

            const condensedHeight = (leadingItems[0].offsetHeight + (chipMargin * 2)) * 2;
            const expandedHeight = (leadingItems[0].offsetHeight + (chipMargin * 2)) * leadingItems.length;

            this.canThisExpand = leadingItems.length > 2;

            if (this.canThisExpand) {
                if (this.isCondensed) {
                    (this.$refs.slider as any).$el.style.cssText = `height: ${condensedHeight}px;`;
                } else {
                    (this.$refs.slider as any).$el.style.cssText = `height: ${expandedHeight}px;`;
                }
            } else {
                (this.$refs.slider as any).$el.style.cssText = 'height: auto;';
            }
        },
        dragDown() {
            const bag = this.$refs.bag as HTMLElement;
            const slider = (this.$refs.slider as any).$el;

            // if group is static then don't allow dragging
            // if bag is larger than the slider don't allow dragging
            if (this.kind !== 'static' && this.kind !== 'condensed' && bag.offsetWidth <= slider.offsetWidth) {
                // timer is to allow for clicking on chip without triggering drag
                this.dragDownTimer = window.setTimeout(() => {
                    this.sliding = true;

                    document.addEventListener('mousemove', this.dragMove);
                    document.addEventListener('touchmove', this.dragMove);
                }, 300);

                document.addEventListener('mouseup', this.dragUp);
                document.addEventListener('touchend', this.dragUp);
            }
        },
        dragMove(e: MouseEvent | TouchEvent) {
            // throttling the move event, sorta
            window.requestAnimationFrame(() => {
                const bag = this.$refs.bag as HTMLElement;
                const slider = (this.$refs.slider as any).$el;
                // v-for $refs like sliderItems aren't populated in the same order as the source object (chipsData)
                // we need to grab the children from the parent ref as this reflects the correct order
                // this is dependent on the current template structure and could break if a new element is ever added before the slider
                const sliderItems: HTMLElement[] = Array.from(slider.children);
                const lastSliderItem = sliderItems[sliderItems.length - 1];
                const sliderX: number = slider.style.left ? parseInt(slider.style.left, 10) : 0;
                const clientX: number = (e as MouseEvent).type === 'mousemove' ? (e as MouseEvent).clientX : Math.floor((e as TouchEvent).touches[0].clientX);

                // we need to know the previous x coordinate of the slider
                // this is so we can compare current and past coordinates to know the direction of the drag
                // but the first time there is no past coordinate so use current coordinate
                // this does produce a lag for first tick, but it's one tick so we can let that one go
                this.previousItemX = this.previousItemX ? this.previousItemX : clientX;

                // use the previous coordinate to shift slider element's left property the correct amount, positive or negative
                // since we're shifting by pixels don't allow a transition as that might mess things up
                slider.style.cssText = `transition: none; left: ${sliderX + (clientX - this.previousItemX)}px; width: auto;`;

                // now save current coordinate to be the next past coordinate for next tick
                this.previousItemX = clientX;

                // this is additional behavior if the group is "pinned" to the left
                // expected behavior is for the chip to shrink and fade away as it moves left
                if (this.kind === 'pinned') {
                    // item is the li container that holds the chip
                    sliderItems.forEach((item) => {
                        const itemBCR = item.getBoundingClientRect();
                        const itemPosition: number = itemBCR.left - bag.getBoundingClientRect().left;
                        const itemChip = item.children[0] as HTMLElement;

                        if (itemPosition < 0) {
                            // the container is to the left of the left side of the group

                            const itemWidth = itemBCR.width;
                            // determine percentage of condensed part of container
                            let itemPercent = parseFloat(((itemWidth + itemPosition) / itemWidth).toFixed(2));

                            // if percentage is below 0 then lock it to 0 to avoid negative percentage
                            itemPercent = itemPercent >= 0 ? itemPercent : 0;

                            // now we make changes to the chip inside the container
                            // scale the chip according to percentage
                            // set opacity of chip according to percentage
                            // transform origin is set to the right so the chip should shrink to fit the available space until it disappears
                            if (item !== lastSliderItem) {
                                itemChip.style.cssText = `transform-origin: center right; transform: scale3d(${itemPercent}, ${itemPercent}, 1); opacity: ${itemPercent}`;
                            } else {
                                // unless it is the last item, then don't allow slider to move further to the left
                                slider.style.cssText = `transition: none; left: ${sliderX}px; width: auto;`;
                            }
                        } else {
                            // the container is to the right of the left side of the group

                            // force the chip to be in default state with no scale or opacity
                            // without this it's possible for a chip to be slightly shrunk and transparent
                            itemChip.style.cssText = 'transform: scale3d(1, 1, 1); opacity: 1';
                        }
                    });
                }
            });
        },
        dragUp() {
            const bag = this.$refs.bag as HTMLElement;
            const slider = (this.$refs.slider as any).$el;
            // v-for $refs like sliderItems aren't populated in the same order as the source object (chipsData)
            // we need to grab the children from the parent ref as this reflects the correct order
            // this is dependent on the current template structure and could break if a new element is ever added before the slider
            const sliderItems: HTMLElement[] = Array.from(slider.children);
            const sliderLeft: number = parseInt(slider.style.left, 10);
            const sliderScrollWidth: number = slider.scrollWidth;

            // clear the timer from the dragDown method
            window.clearTimeout(this.dragDownTimer);

            // this timer is to restore things after a small delay
            window.setTimeout(() => {
                this.sliding = false;

                // if the first chip is to the right of the left side of the group, slide back to the left
                // if the last chip is to the left of the right side of the group, slide back to the right
                // except, if pinned then don't slide back to the right
                // this prevents empty space between the first/last chips and their relevant sides
                if (sliderLeft > 0) {
                    slider.style.cssText = `transition: left ${this.duration} ${this.easing}; left: 0;`;
                } else if (-sliderLeft > sliderScrollWidth - bag.offsetWidth && this.kind !== 'pinned') {
                    slider.style.cssText = `transition: left ${this.duration} ${this.easing}; left: ${-sliderScrollWidth + bag.offsetWidth}px;`;
                }

                // clear out relevant events
                document.removeEventListener('mousemove', this.dragMove);
                document.removeEventListener('touchmove', this.dragMove);
                document.removeEventListener('mouseup', this.dragUp);
                document.removeEventListener('touchend', this.dragUp);
            }, 150);

            // this timer is to clean up pinned items after the duration amount
            window.setTimeout(() => {
                // this reset needs a bit more time than the timer above provides
                // don't know why, it just does...
                this.previousItemX = 0;

                if (this.kind === 'pinned') {
                    sliderItems.forEach((item) => {
                        // item is the li container that holds the chip
                        const itemBCR = item.getBoundingClientRect();
                        const itemPositionLeft: number = itemBCR.left - bag.getBoundingClientRect().left;
                        const itemOffsetLeft: number = item.offsetLeft;
                        const itemOffsetRight: number = item.offsetLeft + item.offsetWidth;
                        const itemWidth: number = itemBCR.width;
                        const itemPercent: number = parseFloat(((itemWidth + itemPositionLeft) / itemWidth).toFixed(2));
                        const itemChip = item.children[0] as HTMLElement;

                        if (itemPercent > 0 && itemPercent < 1) {
                            // if a chip is being scaled on the left side of the group

                            if (itemPercent >= 0.5) {
                                // if percentage is greater than half
                                // push slider to the right relevant distance to show chip
                                // reset chip to default display state
                                itemChip.style.cssText = 'transform: scale3d(1, 1, 1); opacity: 1;';
                                slider.style.cssText = `left: ${-itemOffsetLeft}px; transition: left ${this.duration} ${this.easing};`;
                            } else {
                                // if percentage is less than half
                                // push slider to the left relevant distance to hide chip
                                // push scale and opacity on chip to 0
                                itemChip.style.cssText = 'transform: scale3d(0, 0, 1); opacity: 0;';

                                slider.style.cssText = `left: ${-itemOffsetRight}px; transition: left ${this.duration} ${this.easing};`;
                            }
                        } else {
                            // in some cases a chip may not return to the default display state, so force it
                            // this can happen while dragging with a quick motion

                            itemChip.style.cssText = 'transform: scale3d(1, 1, 1); opacity: 1;';
                        }
                    });
                }
            }, parseInt(this.duration, 10));
        },
        mouseEnter() {
            const bag = this.$refs.bag as HTMLElement;
            const slider = (this.$refs.slider as any).$el;

            if (bag.offsetWidth <= slider.offsetWidth) {
                bag.dataset.scrollable = 'true';
            }
        },
        mouseLeave() {
            const bag = this.$refs.bag as HTMLElement;
            const slider = (this.$refs.slider as any).$el;

            if (bag.offsetWidth <= slider.offsetWidth) {
                bag.dataset.scrollable = 'false';
            }
        },
    },

    computed: {
        testCondensed() {
            return this.$data.isCondensed ? 'Show all' : 'Show less';
        },
    },

    watch: {
        chipsData() {
            // if chipsData prop changes reset slider positioning, just in case
            (this.$refs.slider as any).$el.removeAttribute('style');
        },
        isCondensed() {
            this.condenseOrExpand();
        },
        kind() {
            // if the kind of container is changed then reset some stuff
            (this.$refs.slider as any).$el.removeAttribute('style');

            (this.$refs.chips as any[]).forEach((chip) => {
                chip.$el.removeAttribute('style');
            });

            if (this.kind === 'condensed') {
                this.isCondensed = true;
                this.condenseOrExpand();
            }

            if (this.kind !== 'condensed') {
                this.canThisExpand = false;
            }
        },
    },

    mounted() {
        // get these values from tokens in case they change in the future
        this.duration = getComputedStyle(document.documentElement).getPropertyValue('--motionTimeModerateSlow') || '250ms';
        this.easing = getComputedStyle(document.documentElement).getPropertyValue('--motionTimingFunctionStandard') || 'cubic-bezier(0.5, 0.0, 0.2, 1.0)';
        this.chipMargin = getComputedStyle(document.documentElement).getPropertyValue('--spacingPlatformNano') || '4px';

        // condensed settings are method controlled, so fire that now
        if (this.kind === 'condensed') {
            this.$nextTick(() => {
                this.condenseOrExpand();
            });
        }
    },

    updated() {
        // condensed settings are method controlled, so fire that now
        if (this.kind === 'condensed') {
            this.$nextTick(() => {
                this.condenseOrExpand();
            });
        }
    },
});
