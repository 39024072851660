
import Vue from 'vue';
import IrisIcon from './IrisIcon.vue';
import { tooltip } from '../directives/tooltip';

export default Vue.extend({
    name: 'IrisTextfieldContainer',
    components: {
        IrisIcon,
    },
    directives: {
        tooltip,
    },
    props: {
        textfieldProps: Object,
        validationData: Object,
    },
    data() {
        return ({
            tokenKind: 'filled' as string,
        });
    },
    computed: {
        computedCounter(): number {
            if (this.textfieldProps.value && this.textfieldProps.maxlength) {
                return this.textfieldProps.value.length;
            }
            return 0;
        },
        hasHelperTextSlot(): boolean {
            return !!this.$slots.helperText;
        },
        hasTrailingIconSlot(): boolean {
            return !!this.$slots.trailingIcon;
        },
        screenReaderCounterMessage(): string {
            if (this.textfieldProps.maxlength) {
                return `${this.textfieldProps.maxlength - this.computedCounter} ${this.textfieldProps.screenReaderCharacterCounterMessage}`;
            }
            return '';
        },
        showHelperText(): boolean {
            if (this.hasHelperTextSlot) {
                return this.textfieldProps.isFocused || !this.validationData.showErrorMessages;
            }

            return !!this.textfieldProps.helperText && !this.validationData.showErrorMessages;
        },
        showClearIcon(): boolean {
            return this.textfieldProps.isClearable && this.textfieldProps.isDirty && !this.textfieldProps.isReadonly && this.textfieldProps.isFocused;
        },
        textfieldClasses(): object {
            return {
                'irisv-textfield--disabled': this.textfieldProps.isDisabled,
                'irisv-textfield--focused': this.textfieldProps.isFocused,
                'irisv-textfield--dirty': this.textfieldProps.isDirty,
                'irisv-textfield--error': this.validationData.hasError,
                'irisv-textfield--has-helper-text': !!this.textfieldProps.helperText,
                'irisv-textfield--readonly': this.textfieldProps.isReadonly,
                'irisv-textfield--skeleton': this.textfieldProps.showSkeleton,
                'irisv-textfield--filled': this.tokenKind === 'filled',
                'irisv-textfield--outline': this.tokenKind === 'outline',
                'irisv-textfield--underline': this.tokenKind === 'underline',
                'irisv-textfield--is-loading': this.textfieldProps.isLoading,
            };
        },
    },
    mounted() {
        const validValues = ['filled', 'outline', 'underline'];
        const designTokenValue = getComputedStyle(document.documentElement).getPropertyValue('--styleBrandedFormField').trim();

        if (designTokenValue !== undefined && validValues.includes(designTokenValue)) {
            this.tokenKind = designTokenValue;
        }
    },
});
