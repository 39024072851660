type TextfieldValidator = (value: string) => boolean;

export const email: TextfieldValidator = (value) => {
    const regex = /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/;

    return regex.test(value);
};
export const tel: TextfieldValidator = (value) => {
    const standardRegex = /^$|^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
    const tollfreeRegex = /^$|^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*(?:8(?:00|8[0-9]|([2-8])\1))\s*\)|(?:8(?:00|8[0-9]|([2-8])\2)))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2}|[2-8]11)\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;

    return standardRegex.test(value) || tollfreeRegex.test(value);
};
export const url: TextfieldValidator = (value) => {
    const regex = /^$|^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

    return regex.test(value);
};

export default { email, tel, url } as Record<string, TextfieldValidator>;
