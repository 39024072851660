
import Vue from 'vue';
import IrisQuickActionButton from './IrisQuickActionButton.vue';
import { generateUniqueId } from '@/utils';

export default Vue.extend({
    name: 'IrisProgressIndicator',
    components: {
        IrisQuickActionButton,
    },
    props: {
        /**
         * Sets the id (HTML global attribute) for the component. If an id is not provided, one will be generated automatically.
         */
        elementId: String,
        /**
         * Sets the progress indicator style to use. Valid values are 'bar' or 'circle'. Default is 'bar'.
         */
        indicatorStyle: {
            type: String,
            default: 'bar',
            validator: (value: string) => {
                return ['bar', 'circle'].includes(value.toLowerCase());
            },
        },
        /**
         * Sets the color for the progress indicator. This will not have an effect if the configuration is a bar with multiple tracks. Valid values are 'guidance', 'info', 'error', 'caution', and 'success'. Default is 'guidance.'
         */
        color: {
            type: String,
            default: 'guidance',
            validator: (value: string) => {
                return ['guidance', 'info', 'error', 'caution', 'success'].includes(value.toLowerCase());
            },
        },
        /**
         * An object containing the parameters used to configure the bar style of progress indicator.<br /><br />
         * <strong>Key/Values are:</strong><br /><br />
         * layout: The layout to use. Valid values are 'inline' and 'stacked'. Required.<br /><br />
         * size: Sets the height of the bar. Valid values are 'small', 'medium', and 'large'. Default is 'medium'.<br /><br />
         * progress: Sets the amount of progress completed. 0 - 100 as a number.<br /><br />
         * tracks: An array of objects that represent each segment to display in a multitrack bar. The total amount from all segments should not exceed 100. Each object should have 3 value/pairs:<br />&nbsp;&nbsp;amount: 0 - 100 as a number<br />&nbsp;&nbsp;color: Color name or color hex as a string<br />&nbsp;&nbsp;ariaLabel: A string for screen readers to describe this segment<br /><br />
         * label: When the 'layout' key is 'inline', this label is displayed to the left of the bar.<br /><br />
         * showPercent: Sets the amount of progress to be displayed to the right of the bar when the 'layout' key is 'inline'.<br /><br />
         * heading: When the 'layout' key is 'stacked', this heading is displayed above the bar.<br /><br />
         * showRetryCancelButtons: Sets the retry and cancel buttons to display above the bar when the 'layout' key is 'stacked' and the bar is not multitrack. The retry button will be hidden by default. It will be displayed when 'hasError' is true.<br /><br />
         * helperText: When the 'layout' key is 'stacked', this text is displayed below the bar.<br /><br />
         * errorText: When the 'layout' key is 'stacked', this text is displayed below the bar when 'hasError' key is true and the bar is not multitrack.<br /><br />
         * hasError: Sets the bar and helper text into an error state when the 'layout' key is 'stacked' and the bar is not multitrack.
         */
        barConfig: {
            type: Object,
            default: () => ({}),
        },
        /**
         * An object containing the parameters used to configure the circle style of progress indicator.<br /><br />
         * <strong>Key/Values are:</strong><br /><br />
         * kind: The kind of circle progress indicator to use. Valid values are 'determinate' and 'indeterminate'. Required.<br /><br />
         * size: Sets the size of the circle. Valid values are 'xsmall', 'small', 'medium', 'large' and 'xlarge'. Default is 'medium'.<br /><br />
         * progress: Sets the amount of progress completed. 0 - 100 as a number.
         */
        circleConfig: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            identifier_: this.elementId || generateUniqueId('irisv_progress_indicator') as string,
        };
    },
    computed: {
        compAriaLabel(): string | null {
            let ariaLabel = '';

            if (this.indicatorStyle === 'bar') {
                if (this.barConfig.layout === 'inline' && this.hasLabel) {
                    ariaLabel = `${this.barConfig.label}`;
                } else if (this.barConfig.layout === 'stacked' && this.hasHeading) {
                    ariaLabel = `${this.barConfig.heading}`;
                }

                if (this.isMultitrack) {
                    for (let i = 0, len = this.barConfig.tracks.length; i < len; i++) {
                        if (ariaLabel !== '') {
                            ariaLabel += ', ';
                        }
                        ariaLabel += `${this.barConfig.tracks[i].ariaLabel} ${this.barConfig.tracks[i].amount}%`;
                    }
                }
            }

            return ariaLabel === '' ? null : ariaLabel;
        },
        compAriaValueNow(): string | null {
            let ariaValueNow = '';

            if (this.indicatorStyle === 'bar') {
                if (this.barConfig.layout === 'inline' && !this.isMultitrack) {
                    ariaValueNow = this.barConfig.progress;
                } else if (this.barConfig.layout === 'stacked' && !this.isMultitrack && !this.barConfig.hasError) {
                    ariaValueNow = this.barConfig.progress;
                }
            }

            return ariaValueNow === '' ? null : ariaValueNow;
        },
        compAriaDescribedBy(): string | null {
            let ariaDescribedBy = '';

            if (this.indicatorStyle === 'bar') {
                if (this.barConfig.layout === 'stacked') {
                    if ((!this.barConfig.hasError && this.hasHelperText) || (this.isMultitrack && this.hasHelperText)) {
                        ariaDescribedBy = `${this.identifier_}_helper_text`;
                    }
                }
            }

            return ariaDescribedBy === '' ? null : ariaDescribedBy;
        },
        compAriaErrorMessage(): string | null {
            let ariaErrorMessage = '';

            if (this.indicatorStyle === 'bar') {
                if (this.barConfig.layout === 'stacked' && !this.isMultitrack && this.hasErrorText) {
                    ariaErrorMessage = `${this.identifier_}_error_text`;
                }
            }

            return ariaErrorMessage === '' ? null : ariaErrorMessage;
        },
        compAriaInvalid(): boolean | null {
            let ariaInvalid;

            if (this.indicatorStyle === 'bar') {
                if (this.barConfig.layout === 'stacked' && !this.isMultitrack && this.barConfig.hasError) {
                    ariaInvalid = true;
                }
            }

            return ariaInvalid === undefined ? null : ariaInvalid;
        },
        hasLabel(): boolean {
            return (this.barConfig.label !== undefined && this.barConfig.label !== '');
        },
        hasHeading(): boolean {
            return (this.barConfig.heading !== undefined && this.barConfig.heading !== '');
        },
        isMultitrack(): boolean {
            return (this.barConfig.tracks !== undefined && this.barConfig.tracks.length > 0);
        },
        hasHelperText(): boolean {
            return (this.barConfig.helperText !== undefined && this.barConfig.helperText !== '');
        },
        hasErrorText(): boolean {
            return (this.barConfig.hasError && this.barConfig.errorText !== undefined && this.barConfig.errorText !== '');
        },
        showStackedUpper(): boolean {
            let showUpper = false;

            if (this.barConfig.layout === 'stacked') {
                if (this.hasHeading || (this.barConfig.showRetryCancelButtons === true && !this.isMultitrack)) {
                    showUpper = true;
                }
            }

            return showUpper;
        },
        compDynamicStyle(): string {
            let styleString = '';
            const colorMap: {[index: string]: any} = {
                guidance: '--indicatorTrackColor: rgb(var(--colorBrandedGuidance200)); --indicatorProgressColor: rgb(var(--colorBrandedGuidanceAccessible));',
                info: '--indicatorTrackColor: rgb(var(--colorBrandedInfo200)); --indicatorProgressColor: rgb(var(--colorBrandedInfo700));',
                error: '--indicatorTrackColor: rgb(var(--colorBrandedError200)); --indicatorProgressColor: rgb(var(--colorBrandedError700));',
                caution: '--indicatorTrackColor: rgb(var(--colorBrandedCaution200)); --indicatorProgressColor: rgb(var(--colorBrandedCaution300));',
                success: '--indicatorTrackColor: rgb(var(--colorBrandedSuccess200)); --indicatorProgressColor: rgb(var(--colorBrandedSuccess700));',
            };
            const barSizeMap: {[index: string]: any} = {
                small: '--barSize: 4px; --barShape: var(--shapeBrandedSmall);',
                medium: '--barSize: 8px; --barShape: var(--shapeBrandedLarge);',
                large: '--barSize: 16px; --barShape: var(--shapeBrandedLarge);',
            };

            styleString += colorMap[this.color];

            if (this.indicatorStyle === 'bar') {
                if (this.barConfig.size !== undefined && this.barConfig.size !== '') {
                    barSizeMap[this.barConfig.size.toLowerCase()] === undefined ? styleString += barSizeMap.medium : styleString += barSizeMap[this.barConfig.size.toLowerCase()];
                } else {
                    styleString += barSizeMap.medium;
                }

                if (!this.isMultitrack && this.barConfig.progress !== undefined) {
                    const amount = this.barConfig.progress > 100 ? 100 : this.barConfig.progress;
                    styleString += `--barProgressAmount: ${amount}%;`;
                }
            }

            return styleString;
        },
    },
    methods: {
        retryOnClick($event: Event) {
            /**
             * Emitted when the retry button is clicked.
             */
            this.$emit('progress-indicator-click-retry', $event);
        },
        cancelOnClick($event: Event) {
            /**
             * Emitted when the cancel button is clicked.
             */
            this.$emit('progress-indicator-click-cancel', $event);
        },
    },
});
