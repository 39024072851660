
import { generateUniqueId } from '@/utils';
import Vue from 'vue';
import IrisIcon from './IrisIcon.vue';
import IrisDropdown from '@/components/IrisDropdown/index.vue';

export default Vue.extend({
    name: 'IrisMenuDropdown',
    components: {
        IrisIcon,
        IrisDropdown,
        IrisQuickActionButton: () => import('./IrisQuickActionButton.vue'),
    },
    props: {
        /**
         * Sets the id (HTML global attribute) for the component. If an id is not provided, one will be generated automatically.
         */
        elementId: String,
        /**
         * The text a screen reader will anounce when the user focuses the menu icon. This setting only
         * applies when the triggerType is 'icon' or 'quickactionbutton'. When the triggerType is
         * 'label', the value of triggerLabel will be anounced by the screen reader
         */
        triggerIconAriaLabel: {
            type: String,
            default: 'Menu',
        },
        /**
         * This is an array of objects - each object is one menu item.<br /><br />
         * <strong>Required keys are:</strong><br />
         * "label" (string): Sets the menu item label.<br /><br />
         * <strong>Optional keys are:</strong><br />
         * "href" (string): Sets the target URL of the menu item. (For navigation menu dropdown only)<br /><br />
         * "iconName" (string): Sets the icon.<br /><br />
         * "to" (string): router-link prop: Sets the target route of the link<br /><br />
         * "target" (string): Sets the target attribute on the menu item. (For navigation menu dropdown only)<br /><br />
         * "value" (string): Sets the value of the menu item. Default value is set to the menu item's index and label. Ex: 2-Account.<br /><br />
         * "disabled" (boolean): Disables the list item. (For navigation menu dropdown only)
         */
        items: {
            type: Array as () => any[],
        },
        /**
         * Prop to pre-select an item in the menu dropdown to display selected state at first render.
         */
        selectItem: {
            type: Number,
        },
        /**
         * This is an array of objects - each object is a list of props for one Iris Account component.
         * Refer to the Iris Account component's documentation for details.
         */
        accountsData: {
            type: Array as () => any[],
        },
        /**
         * Object of props for the IrisAvatar child component.<br /><br />
         * Refer to the IrisAvatar docs for available props.<br /<br />
         * This prop is assigned to the add chip button's avatar.
         * Defaults:
         * <ul>
         * <li>mainIconName: 'more'</li>
         * </ul>
         */
        avatarProps: Object,
        /**
         * Object of props for the IrisQuickActionButton child component.<br /><br />
         * Refer to the IrisQuickActionButton docs for available props.<br /<br />
         * Defaults:
         * <ul>
         * <li>kind: 'lowEmphasis'</li>
         * <li>variation: 'embedded'</li.
         * <li>size: 'medium'</li>
         * <li>label: this.triggerLabel</li>
         * </ul>
         */
        quickActionButtonProps: Object,
        /**
         * Sets the menu variant. Valid values are 'navigation', 'action', or 'account'.
         */
        kind: {
            type: String,
            default: 'action',
            validator: (value: string) => {
                return ['action', 'navigation', 'account'].includes(value.toLowerCase());
            },
        },
        /**
         * Sets the alignment of the menu dropdown in relation to the menu trigger.
         * Valid values are 'left', 'right', 'center'.
         */
        menuAlignment: {
            type: String,
            default: 'left',
            validator: (value: string) => {
                return ['left', 'right', 'center'].includes(value.toLowerCase());
            },
        },
        /**
         * Sets the type of icon used for the menu trigger when the triggerType is 'icon'
         * The default value is the 'more' icon.
         */
        triggerHasCaret: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets the type of icon used for the menu trigger when the triggerType is 'icon'
         * The default value is the 'more' icon.
         */
        triggerIconName: {
            type: String,
            default: 'more',
        },
        /**
         * The label text of the menu trigger.
         */
        triggerLabel: {
            type: String,
            default: 'Menu',
        },
        /**
         * The trigger type.
         * Valid values are 'label', 'icon', and 'quickactionbutton'.
         */
        triggerType: {
            type: String,
            default: 'label',
            validator: (value: string) => {
                return ['label', 'icon', 'quickactionbutton'].includes(value.toLowerCase());
            },
        },
    },
    data() {
        return ({
            isOpen: false,
            identifier_: this.elementId || generateUniqueId('irisv_menu_dropdown'),
            selectedMenuItemIndex: -1,
            allowBubble: true,
        });
    },
    computed: {
        aggregateItems() {
            if (!this.items && !this.accountsData) { return []; }
            if (this.kind === 'account' && this.accountsData) {
                return this.accountsData.map((account) => ({
                    ...account,
                    value: `${account.accountNickName}-${account.accountNumberDisplay}`,
                }));
            }
            return this.items.map((item, index) => ({ ...item, value: item.value || `${index}-${item.label}` }));
        },
        defaultAvatarProps(): object {
            return {
                mainIconName: 'more',
                ...this.avatarProps,
            };
        },
        defaultQuickActionButtonProps(): object {
            return {
                kind: 'lowEmphasis',
                variation: 'embedded',
                label: this.triggerIconAriaLabel,
                ...this.quickActionButtonProps,
            };
        },
        qabButtonProps(): object {
            return {
                'aria-haspopup': true,
            };
        },
    },
    methods: {
        listItemSelectedData(event: { isVModelUpdate: boolean; item: string[]; itemIndex: number }) {
            // Look for the item from the event in our list
            const correspondingItem = this.aggregateItems.find((item) => item.value === event.item[0]);

            if (event.isVModelUpdate || correspondingItem === undefined) { return; }

            // Match the local index with the event one
            this.selectedMenuItemIndex = event.itemIndex;

            /**
             * Emitted when a menu item is selected.
             */
            this.$emit('menu-dropdown-item-selected', {
                selectedMenuItem: {
                    ...correspondingItem,
                    index: event.itemIndex,
                },
            });
        },
        rootKeyHandler(keyboardEvent: KeyboardEvent) {
            const code = keyboardEvent.code;

            if (code === 'Tab' && this.isOpen) {
                // This prevents tabbing away from the control while the list is open
                keyboardEvent.preventDefault();
            }

            // If the list is open, we will be ignoring root keys
            if (this.isOpen) { return; }

            if (code === 'ArrowDown' || code === 'ArrowUp' || code === 'Space' || code === 'Enter') {
                keyboardEvent.preventDefault();
                // Re-open the list
                this.toggleList(keyboardEvent);
            }
        },
        toggleList(event: Event) {
            /**
             * Emitted when the trigger to toggle the menu is clicked, before the menu is opened or closed.
             */
            this.$emit('menu-dropdown-toggle-click', event);

            this.setIsOpen(!this.isOpen);
        },
        preventEscapeBubble(keyboardEvent: KeyboardEvent) {
            if (this.allowBubble) { return; }

            keyboardEvent.stopPropagation();
            this.allowBubble = true;
        },
        setIsOpen(open: boolean) {
            // This shall be the only way to trigger the watcher
            this.isOpen = open;
        },
        handleMenuDropdownOpen() {
            /*
             * When the menu is open, we don't want to allow escape keys to bubble, the list will trigger the close if the escape
             * key is pressed. If the escape key is pressed while the menu dropdown has focus, it will allow escape key bubbling.
             */
            this.allowBubble = false;

            /**
             * Emitted when the menu dropdown is opened.
             */
            this.$emit('menu-dropdown-opened');
        },
        handleMenuDropdownClose() {
            /*
             * Often when the list is being closed, the user has shifted focus to something inside that list. It could
             * be an item they clicked, the filter, or a slot. We need to send it back to the menu.
             */
            this._focusMenu();

            /**
             * Emitted when the menu dropdown is closed.
             */
            this.$emit('menu-dropdown-closed');
        },
        _focusMenu() {
            const listRef = (document.querySelector(`#${this.identifier_}__list-container`) as HTMLElement);

            if (!listRef || !listRef.contains(document.activeElement)) { return; }

            // The element that has focus is inside the list (which is now closed)
            const menuButton = this.$refs.quickActionButton && (this.$refs.quickActionButton as any).$el || this.$refs.menuButton;

            (menuButton as HTMLElement).focus();
        },
    },
    watch: {
        selectItem: {
            handler(value) {
                this.selectedMenuItemIndex = value >= 0 && value < this.aggregateItems.length ? value : -1;
            },
            immediate: true,
        },
        isOpen(open: boolean) {
            if (open) {
                this.handleMenuDropdownOpen();
            } else {
                this.handleMenuDropdownClose();
            }
        },
    },
});
