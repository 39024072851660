
import { MenuItem } from '@/components/IrisDropdown/types';
import { generateUniqueId } from '@/utils';
import Vue from 'vue';
import { deprecationMixin } from './../mixins/deprecationMixin';
import IrisIcon from './IrisIcon.vue';
import IrisDropdown from './IrisDropdown/index.vue';

const INVALID_V_MODEL_ERROR = `
    Invalid value supplied for v-model. Must be an array containing the value(s) of
    the requested item(s) as strings.
`;
const DEFAULT_MENU_ITEM: MenuItem = {
    label: '',
    value: '',
};

export default Vue.extend({
    name: 'IrisSelectDropdown',
    components: {
        IrisIcon,
        IrisAccount: () => import('./IrisAccount.vue'),
        IrisDropdown,
    },
    model: {
        prop: 'modelValue',
        event: 'select-dropdown-item-selected',
    },
    mixins: [deprecationMixin],
    props: {
        /**
         * Sets the id (HTML global attribute) for the component. If an id is not provided, one will be generated automatically.
         */
        elementId: String,
        /**
         * (Deprecated: Use elementId instead!) The textfield id.
         */
        id: String,
        /**
         * Sets the initial value of the select dropdown. Must be an array of strings.
         */
        modelValue: {
            type: Array as () => string[],
        },
        /**
         * An object containing the strings used in the select dropdown.<br /><br />
         * <strong>Keys are:</strong><br /><br />
         * label: Sets the label.<br /><br />
         * optionalText: The phrase displayed when the select dropdown is optional. Default is 'optional'.<br /><br />
         * helperText: Sets the helper text.<br /><br />
         * helperTextErrorMessage: Overrides the default error message. Displayed when the select dropdown is in an error state. Default is 'This input is required'.<br /><br />
         * filterPlaceholder: The placeholder for the filter input. Default is 'Search'.<br /><br />
         * noResultsText: The phrase displayed when there are no results from filtering the list. Default is 'No results found.'<br />
         * labelForMobileList: Sets the label to display at the top of the mobile style list. If no value is provided, the value for 'label' is used by default.
         */
        strings: {
            type: Object,
            default: () => ({}),
        },
        /**
         * Sets the menu variant. Valid values are 'list' or 'account'.
         */
        kind: {
            type: String,
            default: 'list',
            validator: (value: string) => {
                return ['list', 'account'].includes(value.toLowerCase());
            },
        },
        /**
         * Sets the display for the trigger when the kind is 'account'. Valid values are 'single-line' or 'stacked'.
         */
        accountKindDisplay: {
            type: String,
            default: 'single-line',
            validator: (value: string) => {
                return ['single-line', 'stacked'].includes(value);
            },
        },
        /**
         * Sets the select dropdown as either an optional or required field.
         */
        isOptional: {
            type: Boolean,
            default: false,
        },
        /**
         * Disables the select dropdown.
         */
        isDisabled: {
            type: Boolean,
            default: false,
        },
        /**
         * Shows the skeleton loader as a placeholder option.
         */
        showSkeleton: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets the loading state with animated icon indicator.
         */
        isLoading: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets the select dropdown as readonly.
         */
        isReadonly: {
            type: Boolean,
            default: false,
        },
        /**
         * Forces the select dropdown into an error state.
         */
        hasError: {
            type: Boolean,
            default: false,
        },
        /**
         * Displays a label at the top of the mobile style list
         */
        showLabelInMobileList: {
            type: Boolean,
            default: true,
        },
        /**
         * Displays an input at the beginning of the list where users can filter results.
         */
        showFilter: {
            type: Boolean,
            default: false,
        },
        /**
         * Displays dividing lines between list items.
         */
        showListDividers: {
            type: Boolean,
            default: true,
        },
        /**
         * This is an array of objects - each object is a list of props for one Iris Account component.<br /><br />
         * <strong>Required keys are:</strong><br />
         * "value" (string): Sets the value of the account item. This value should be unique in the array.<br /><br />
         * Any keys that would be required for an Iris Account component would also be required here.<br /><br />
         * <strong>Optional keys are:</strong><br />
         * Any keys that would be optional for an Iris Account component would also be optional here.<br /><br />
         * Refer to the Iris Account component's documentation for details.
         */
        accountsData: {
            type: Array as () => any[],
        },
        /**
         * This is an array of objects - each object is one list item.<br /><br />
         * <strong>Required keys are:</strong><br />
         * "label" (string): Sets the list item label.<br /><br />
         * "value" (string): Sets the value of the list item. This value should be unique in the array.<br /><br />
         * <strong>Optional keys are:</strong><br />
         * "iconName" (string): Sets the icon.<br /><br />
         * "secondLine" (string): Sets the second line of text below the label.<br /><br />
         * "disabled" (boolean): Disables the list item.
         */
        items: {
            default: () => [],
            type: Array as () => any[],
        },
        /**
         * Toggles the display of the list.
         */
        listToggle: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            identifier_: this.elementId || this.id || generateUniqueId('irisv_select_dropdown') as string,
            itemIndexToDisplay: -1,
            parentFilter: '',
            isOpen: false, // Since the listToggle.sync is an optional prop, we need this independent variable to control the list
            hasInternalError: false,
            filterInput: '',
            tokenKind: 'filled',
            hasFocus: false, // Used to mark active focus
            activeMenuItem: DEFAULT_MENU_ITEM,
            hasFocused: false, // Used to mark focus has happened at some point
            allowBubble: true,
            activeDescendant: '',
        };
    },
    computed: {
        aggregateItems() {
            return this.kind === 'list' ? this.items : this.accountsData;
        },
        mergedStrings(): any {
            return {
                label: '',
                optionalText: '(optional)',
                helperText: '',
                helperTextErrorMessage: 'This input is required.',
                filterPlaceholder: 'Search',
                noResultsText: 'No results found.',
                labelForMobileList: '',
                ...this.strings,
            };
        },
        messages(): string {
            return this.showError ? this.mergedStrings.helperTextErrorMessage : this.mergedStrings.helperText;
        },
        showError(): boolean {
            const noConflictingProps = !this.isDisabled && !this.showSkeleton && !this.isLoading && !this.isReadonly && !this.hasFocus && !this.isOpen;
            return this.hasError || noConflictingProps && !this.isOptional && this.hasInternalError && this.hasFocused;
        },
    },
    methods: {
        listItemSelectedData(event: {isVModelUpdate: boolean; item: string[]; itemIndex: number}) {
            const correspondingItem = this.aggregateItems.find((item) => item.value === event.item[0]);
            this.activeMenuItem = correspondingItem || DEFAULT_MENU_ITEM;
            this.itemIndexToDisplay = event.itemIndex;

            if (event.isVModelUpdate) { return; }

            this.$emit('select-dropdown-item-selected', [this.activeMenuItem.value]);
            this.$emit('select-dropdown-item-selected-data', [{selectedMenuItem: this.activeMenuItem}]);
        },
        listFilterInput(event: Event) {
            this.parentFilter = (event.target as HTMLInputElement).value;
            this.$emit('select-dropdown-filter-input');
        },
        focusHandler(event: Event) {
            if (!this.hasFocus && !this.isOpen) {
                this.triggerFocus(event);
            }
        },
        blurHandler(event: FocusEvent) {
            const elementThatReceivedFocus = event.relatedTarget as HTMLElement;
            const listRef = (document.querySelector(`#${this.identifier_}__list-container`) as HTMLElement);

            // If the element that received focus is not the list itself, and not contained in the list, then we can blur
            if ((elementThatReceivedFocus === null || listRef === null) || (elementThatReceivedFocus.id !== `${this.identifier_}__listbox` && !listRef.contains(elementThatReceivedFocus))) {
                this.triggerBlur(event);
            }
        },
        triggerFocus(event: Event) {
            if (this.hasFocus === true) { return; }

            this.hasFocus = true;
            /**
             * This flag is different than hasFocus. This flag only tells
             * us that the user has touched this field at least once.
             * Once it is true, it is always true.
             */
            this.hasFocused = true;
            /**
             * Emitted when component gains focus.
             */
            this.$emit('select-dropdown-focus', event);
        },
        triggerBlur(event: any) {
            if (this.hasFocus === false) { return; }

            this.hasFocus = false;

            /**
             * Emitted when component loses focus.
             */
            this.$emit('select-dropdown-blur', event);

            // Remove listener after the official blur of the control. If we don't remove it, it can continue firing every time the document is clicked
            document.removeEventListener('click', this._documentClick);
        },
        rootKeyHandler(keyboardEvent: KeyboardEvent) {
            const code = keyboardEvent.code;

            if (code === 'Tab' && this.isOpen) {
                // This prevents tabbing away from the control while the list is open
                keyboardEvent.preventDefault();
            }

            // If the list is open, we will be ignoring root keys
            if (this.isOpen) { return; }

            if (code === 'ArrowDown' || code === 'ArrowUp' || code === 'Space' || code === 'Enter') {
                keyboardEvent.preventDefault();
                // Re-open the list
                this.toggleList(keyboardEvent);
            }
        },
        toggleList(event: Event) {
            event.preventDefault();

            // If flutter webview, wait for app shell to hide before popping the menu
            if (window.isFlutterWebview && window.f_hideAppShell) {
                window.f_hideAppShell.postMessage('');

                setTimeout(() => {
                    this.setIsOpen(!this.isOpen);
                }, 100);
            } else {
                this.setIsOpen(!this.isOpen);
            }

            // This is for users that "focus" the component by clicking it with a mouse
            this.triggerFocus(event);

            /**
             * Emitted when component is clicked.
             */
            this.$emit('select-dropdown-click');
        },
        preventEscapeBubble(keyboardEvent: KeyboardEvent) {
            if (this.allowBubble) { return; }

            keyboardEvent.stopPropagation();
            this.allowBubble = true;
        },
        _documentClick(event: Event) {
            const elementThatWasClicked = event.target as HTMLElement;
            const listRef = (document.querySelector(`#${this.identifier_}__list-container`) as HTMLElement);

            if (this.$el.contains(elementThatWasClicked)) { return; }
            if (listRef.contains(elementThatWasClicked)) { return; }

            this.triggerBlur(event);
        },
        _validateMenuItem(itemValues: any): boolean {
            let invalid = false;

            if (itemValues === null) {
                invalid = true;
            } else if (!Array.isArray(itemValues)) {
                invalid = true;
            } else if (itemValues.length > 0 && itemValues.some((value) => typeof value !== 'string')) {
                invalid = true;
            } else if (!this.isOptional && !itemValues.length) {
                invalid = true;
            }

            this.activeMenuItem = DEFAULT_MENU_ITEM;

            let errors: string[] = [];
            if (invalid) {
                errors = [...errors, INVALID_V_MODEL_ERROR];
                // tslint:disable-next-line
                console.error(INVALID_V_MODEL_ERROR);
            } else if (!this.aggregateItems.find((item) => item.value === itemValues[0]) && !this.isOptional) {
                errors = [
                    ...errors,
                    `Requested value: '${itemValues[0]}', was not found in the provided list.`,
                ];
            } else {
                const correspondingItem = this.aggregateItems.find((item) => item.value === itemValues[0]);
                this.activeMenuItem = correspondingItem || DEFAULT_MENU_ITEM;
            }
            this._emitValidationResult(errors);
            return !errors.length;
        },
        _emitValidationResult(messages: string[] = []) {
            let errors = messages;
            this.hasInternalError = !this.isOptional && (!!this.activeMenuItem && this.activeMenuItem.value === '');

            if (this.hasInternalError) {
                errors = [
                    ...errors,
                    this.mergedStrings.helperTextErrorMessage,
                ];
            }

            /**
             * Emits the error list when the validation check updates the list.
             * The list is provided as an array of error message strings.
             * If the list is empty then it can be perceived as passing validation.
             */
            this.$emit('validation-check',  errors);
        },
        setIsOpen(open: boolean) {
            // This shall be the only way to trigger the watcher
            this.isOpen = open;
        },
        handleSelectDropdownOpen() {
            /*
             * When the menu is open, we don't want to allow escape keys to bubble, the list will trigger the close if the escape
             * key is pressed. If the escape key is pressed while the select dropdown rootKeyHandler is in control, it will allow
             * escape key bubbling.
             */
            this.allowBubble = false;

            // Clear the filter of any previous entries. We clear it on open so we don't see list items flash during the close animation.
            this.parentFilter = '';

            /**
             * Emitted when dropdown menu is opened.
             */
            this.$emit('select-dropdown-opened');
            document.addEventListener('click', this._documentClick);
        },
        handleSelectDropdownClose() {
            /*
             * Often when the list is being closed, the user has shifted focus to something inside that list. It could
             * be an item they clicked, the filter, or a slot. We need to send it back to the root.
             */
            this._focusSelectDropdown();

            this._emitValidationResult();

            /**
             * Emitted when dropdown menu is closed.
             */
            this.$emit('select-dropdown-closed');
        },
        _focusSelectDropdown() {
            const listRef = (document.querySelector(`#${this.identifier_}__list-container`) as HTMLElement);

            if (!listRef || !listRef.contains(document.activeElement)) { return; }

            (this.$refs.root as HTMLElement).focus();
        },
    },
    mounted() {
        const validValues = ['filled', 'outline', 'underline'];
        const designTokenValue = getComputedStyle(document.documentElement).getPropertyValue('--styleBrandedFormField').trim();

        if (designTokenValue !== undefined && validValues.includes(designTokenValue)) {
            this.tokenKind = designTokenValue;
        }
        this._validateMenuItem(this.modelValue);
    },
    destroyed() {
        document.removeEventListener('click', this._documentClick);
    },
    watch: {
        modelValue(value) {
            if (value === undefined) { return; }
            this._validateMenuItem(value);
        },
        aggregateItems(newItems, oldItems) {
            if (JSON.stringify(newItems) === JSON.stringify(oldItems)) { return; }
            this._validateMenuItem(this.modelValue);
        },
        listToggle(open: boolean) {
            if (this.isOpen === open) { return; }

            // Re-synchronize listToggle and isOpen
            this.setIsOpen(open);
        },
        isOpen(open: boolean) {
            // This should be the only place that emits the update event for listToggle.
            /**
             * Emitted when closing the list internally is needed, and updates the listToggle prop.<br /><br />
             * The listToggle set on the component element must use the "sync" feature for this to work.<br /><br />
             * ex: :listToggle.sync="myDataVarToControlThis"<br /><br />
             * No direct reaction to the event is required.
             */
            this.$emit('update:listToggle', open);

            if (open) {
                this.handleSelectDropdownOpen();
            } else {
                this.handleSelectDropdownClose();
            }
        },
        isOptional() {
            this._emitValidationResult();
        },
    },
});
