
import Vue from 'vue';

export interface NoRowsOverlayParams {
    showOverlay: boolean;
    showSubline: boolean;
    heading: string;
    subline: string;
}

export default Vue.extend({});
